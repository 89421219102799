import { CSSProperties } from 'react'
import defaultTheme from 'config/defaultTheme'
import styled from 'styled-components'

import { FigureProps } from '../types/StylesInterfaces'

export const InfoBox = styled.div`
  p{
    font-size: 14px
  }

  p + p {
    font-size: 16px
  }
`

export const ContentCardList = styled.section`
  h2{
    margin-right: 8px;
  }

  > div > div:first-child > div {
    padding: 2px 8px;
    max-height: 24px;
    line-height: 24px;
    margin-top: 3px;
  }

  div[role=divider]{
    border-top: 1px solid ${defaultTheme.divisors.standard};
    margin: 15px 0;
  }

  a{
    font-size: 14px;
    font-weight: bold;
    border-bottom-color: transparent;

    &:hover{
      background-color: transparent;
      color: ${defaultTheme.colors.secondary};
    }


  }
`

export const ContentSectionFAQ = styled.section`
  > ul > li > div > div {
      margin: 0;
  }
`

export const Figure = styled.figure<FigureProps>`
  img {
    height: ${({ height }) => height || '136px'}; // Aplica a altura recebida ou usa 136px como padrão
  }
`

export const CargoView: CSSProperties = {
  gap: '24px',
  minHeight: 'calc(100vh - 162px)',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '100px'
}
