import React from 'react'
import { Divider, Spacer, Text, Title, View } from '@youse-seguradora/cargo-components'

import { useTheme } from '@material-ui/core/styles'

const CheckoutResumeItem = ({ item }: any) => {
  const theme = useTheme()

  return (
    <>
      <View spacing={{ bottom: 4, top: 4 }}>
        <Title size='xsmall' color={theme.colors.primary}>
          {item.title}
        </Title>
      </View>

      {item.fields.map((field: any, index: number) => {
        return (
          <div key={index}>
            <Text size='xsmall' color={theme.colors.secondary}>
              {field.title}
            </Text>
            <Text size='small' color={theme.colors.primary}>
              {field.value || '-'}
            </Text>
            <Divider top={1} color={theme.divisors.standard} />
            <Spacer top={2} />
          </div>
        )
      })}

      {item.notes.map((note: any, index: number) => {
        return (
          <div key={index}>
            <Text size='xsmall' color={theme.colors.secondary}>
              {note}
            </Text>
            <Spacer top={2} />
          </div>
        )
      })}
    </>
  )
}

export default CheckoutResumeItem
