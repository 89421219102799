import React from 'react'
import { designTokens, Icon, Text, View } from '@youse-seguradora/cargo-components'

import { hasNumber, hasSpecialCharacter, hasUpperAndLowerCase } from './helpers'

import { Step, ValidationStatus } from './styles'

interface PasswordValidationProps {
  label: string
  password: string
}

export default function PasswordValidation ({ label, password }: PasswordValidationProps) {
  return (
    <View>
      <Text color={designTokens.colors.grey500} align='center'>{label}</Text>

      <ValidationStatus>
        <Step isValid={hasUpperAndLowerCase(password)}>
          <Icon name='check' size={5} />
          <span>Pelo menos uma letra maiúscula e uma letra minuscula;</span>
        </Step>

        <Step isValid={hasNumber(password)}>
          <Icon name='check' size={5} />
          <span>Pelo menos um caracter numérico;</span>
        </Step>

        <Step isValid={hasSpecialCharacter(password)}>
          <Icon name='check' size={5} />
          <span>Pelo menos um caracter especial.</span>
        </Step>
      </ValidationStatus>
    </View>
  )
}
