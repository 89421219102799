import { gql } from '@apollo/client'

export const GET_STATES_COUNTRIES = gql`
  query GetStatesCountries {
    countries {
      code
      text
    }

    states {
      acronym
      name
    }
  }
`
