import React, { useContext } from 'react'
import { designTokens, InvisibleButton, Spacer } from '@youse-seguradora/cargo-components'
import styled from 'styled-components'

import { ResourceFlowContext } from '../../../contexts/ResourceFlowContext'
import { Impersonate } from '../../Impersonate/Impersonate'
import LayoutBaseWithSidebar from '../../LayoutBaseWithSidebar'
import { SidebarCardProps } from '../../LayoutBaseWithSidebar/Sidebar/SidebarCard'
import { SidebarIconsProps } from '../../LayoutBaseWithSidebar/Sidebar/SidebarIcons'

import ModalBonusesClass from './ModalBonusesClass/ModalBonusesClass'
import Summary from './Summary/Summary'

type ImpersonateCheckoutProps = {
  productName: string
  seo: any
  sidebarIcons?: SidebarIconsProps
  sidebarCard?: SidebarCardProps
  iconHeaderPartner?: string[]
}

const StyledFooterImpersonate = styled.div`
  width: auto;
  @media screen and (min-width: ${designTokens.breakpoints.desktop}px) {
    width: 66.5%;
  }

  padding: 24px;
  position: fixed;
  display: flex;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  border-top: solid 1px #dbdbdb;
  margin: 0;
  background-color: ${(props) => props.theme.color.background.secondary};
`

const FooterImpersonate = () => {
  const context = useContext<any>(ResourceFlowContext)

  /* We can't return to assistance selection section because we don't have a planId when select a custom plan flow. */
  const planId = context.data.resource.pricing.planId

  return (
    <>
      <Spacer top={14} />
      <StyledFooterImpersonate>
        <InvisibleButton
          onClick={
            planId === null ? context.navigateToAssistancesSelection : context.navigateToPlans
          }
          data-testid={'checkout-assistances-selection-button'}
        >
          VOLTAR
        </InvisibleButton>
      </StyledFooterImpersonate>
    </>
  )
}

const ImpersonateCheckout = ({
  productName,
  seo,
  sidebarIcons,
  sidebarCard,
  iconHeaderPartner,
}: ImpersonateCheckoutProps) => {
  const context = useContext<any>(ResourceFlowContext)
  const showBonusesClass = context.data.resource.bonusesClass?.visible

  return (
    <LayoutBaseWithSidebar
      productName={productName}
      seo={seo}
      customFooter={<FooterImpersonate />}
      iconHeaderPartner={iconHeaderPartner}
      sidebarIcons={sidebarIcons}
      sidebarCard={sidebarCard}
    >
      <Spacer top={4} />

      {showBonusesClass && <ModalBonusesClass />}

      <Impersonate />

      <Spacer top={6} />

      <Summary data={context.data.resource} />

      <Spacer top={2} />
    </LayoutBaseWithSidebar>
  )
}

export default ImpersonateCheckout
