import React from 'react'
import { Checkbox, Column, Text, View } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import { Policy } from 'types/Policy'

import FriendlyDate from 'components/FriendlyDate'

export default function PolicySelectOption({
  policy,
  selectPolicy,
  unselectPolicy,
  checked,
}: {
  policy: Policy
  selectPolicy: (Policy) => void
  unselectPolicy: (Policy) => void
  checked: boolean
}) {
  return (
    <Column tabletSize={6}>
      <strong>
        <View
          style={{
            marginLeft: -16,
          }}
        >
          <Checkbox
            label={policy.product}
            register={{
              checked,
            }}
            value={checked}
            onChange={(e) => {
              e.target.checked ? selectPolicy(policy) : unselectPolicy(policy)
            }}
          />
        </View>
      </strong>
      <View
        style={{
          marginLeft: 36,
          marginTop: -28,
        }}
        onClick={() => {
          checked ? unselectPolicy(policy) : selectPolicy(policy)
        }}
      >
        <Text size={'small'} weight={'regular'} color={defaultTheme.colors.secondary}>
          {policy.summary}
        </Text>
        <Text size={'small'} weight={'regular'} color={defaultTheme.colors.secondary}>
          Contrato: {policy.number}
        </Text>
        <Text size={'small'} weight={'regular'} color={defaultTheme.colors.secondary}>
          Vigência: <FriendlyDate date={policy.effective_until} />
        </Text>
      </View>
    </Column>
  )
}
