enum Steps {
  'overview',
  'origem-dos-dados',
  'confirmacao',
  'redirecionamento',
  'endorsement-confirm',
  'claim-confirm'
}

const title = (step: Steps): String => {
  return {
    [Steps['overview']]: 'Você fez uma solicitação de compartilhamento de dados',
    [Steps['origem-dos-dados']]: 'Origem dos dados',
    [Steps['confirmacao']]: 'Confira os dados antes de concluir a solicitação',
    [Steps['endorsement-confirm']]: 'Solicitação de alteração do contrato',
    [Steps['claim-confirm']]: 'Solicitação de aviso de sinistro',
  }[step]
}

const subtitle = (step: Steps): String => {
  return {
    [Steps['overview']]: 'Se liga nos dados que serão compartilhados',
    [Steps['origem-dos-dados']]: 'O compartilhamento dos seus dados será do seguinte contrato',
    [Steps['confirmacao']]: 'Confira os dados antes de concluir a solicitação',
    [Steps['endorsement-confirm']]: 'Confirme as informações abaixo pra gente continuar a solicitação com a seguradora ',
    [Steps['claim-confirm']]: 'Confirme as informações abaixo pra gente continuar a solicitação com a seguradora ',
  }[step]
}

const nextPath = (step: Steps): String => {
  return {
    [Steps['overview']]: 'origem-dos-dados',    
    [Steps['origem-dos-dados']]: 'confirmacao',
    [Steps['confirmacao']]: 'redirecionamento',
  }[step]
}

export { 
  nextPath, 
  Steps, 
  subtitle, 
  title }
