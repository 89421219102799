export const pathName = (context: any) => {
  const locationPathName = window.location.pathname.split('/')
  const basePathName = locationPathName[1]

  if (context.data.resource.partnerId) {
    const partnerId = locationPathName[2]
    const productPath = locationPathName[3]

    return `${basePathName}/${partnerId}/${productPath}`
  } else {
    return basePathName
  }
}
