import React, { useContext, useEffect, useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import {
  Column,
  ColumnsContainer,
  Container,
  DataPrivacyDisclaimer,
  Icon,
  Message as MessageDisclaimer,
  MessageType,
  Spacer,
  Text,
  Title,
  View,
} from '@youse-seguradora/cargo-components'

import { ResourceFlowContext } from '../../../contexts/ResourceFlowContext'
import Message from '../CheckoutSection/Message/Message'
import ModalLGPDContent from '../CheckoutSection/ModalLGPDContent/ModalLGPDContent'

import AcceptTermsConditions from './AcceptTermsConditions'

import { useTheme } from '@material-ui/core/styles'

type PaymentProps = {
  productName: string
  message?: React.ReactNode
  isLGPGEnabled?: boolean
}

export const Payment = ({ message, productName, isLGPGEnabled }: PaymentProps) => {
  const theme = useTheme()
  const context = useContext<any>(ResourceFlowContext)
  const [messageError, setMessageError] = useState<boolean>(false)

  useEffect(() => {
    if (context.status !== 200) {
      setMessageError(true)
    }
  }, [])

  return (
    <>
      <Title
        size='xsmall'
        align='center'
        color={theme.colors.primary}
        htmlChildren='Para garantir sua renovação, <br/> insira os dados de pagamento.'
      />
      <Spacer top={4} />
      <Message statusError={messageError} message={message} />
      <ColumnsContainer>
        <Column desktopSize={6} desktopOffset={3}>
          {context.data.currentSection === 'payment_condition_selection' && (
            <>
              <Spacer top={4} />
              <Container>
                <MessageDisclaimer
                  icon='info'
                  title='Atenção'
                  description='Fique tranquilo, você não será cobrado agora. A cobrança ocorrerá um dia após o fim da vigência do seguro atual, quando a nova apólice for emitida.'
                  type={MessageType.warning}
                />
              </Container>
              <Spacer top={8} />
            </>
          )}
          <Card
            elevation={2}
            style={{
              borderRadius: '12px',
              border: '4px solid #E9EAEB',
              padding: '10px',
              boxShadow: 'none',
            }}
          >
            <CardContent>
              <div id='component-container'></div>
            </CardContent>
          </Card>
          <Spacer top={4} />
          <Card>
            <View spacing={{ horizontal: 8, vertical: 4 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Text weight='bold' size='big'>
                  Pague com seu Cartão Débito Virtual Caixa
                </Text>
                <Icon name='debit-card-caixa' size={10} />
              </div>
            </View>
          </Card>
        </Column>
      </ColumnsContainer>
      {isLGPGEnabled && (
        <DataPrivacyDisclaimer
          title='Como meus dados são usados?'
          children={<ModalLGPDContent />}
        />
      )}
      <Spacer top={6} />
      <AcceptTermsConditions productName={productName} />
    </>
  )
}
