import React, { useContext, useEffect, useState } from 'react'
import { Loading } from '@youse-seguradora/cargo-components'

import DataLayerClient from '../../../clients/DataLayerClient'
import { ResourceFlowContext } from '../../../contexts/ResourceFlowContext'
import Loader from '../../Loader'
import InsuranceNotAccepted from '../../pages/InsuranceNotAccepted'
import SomethingWrong from '../../pages/SomethingWrong'

type RiskAcceptanceSectionProps = {
  loadingContent?: {
    url: string,
    text: string
  }[],
  triggerGTMEvent?: (statusRiskAcceptance: string, context) => void
}

const RiskAcceptanceSection = ({ loadingContent, triggerGTMEvent }: RiskAcceptanceSectionProps) => {
  const context = useContext<any>(ResourceFlowContext)
  const [viewState, setViewState] = useState<any>('Loader')
  const insuredPersonName =
    context?.data?.resource?.insuredPerson?.name?.split(' ')?.[0] || 'Youser'

  const isImpersonate = context?.data?.resource?.impersonate
  const hasTriggerGTMEvent = typeof triggerGTMEvent === 'function'

  // This time was defined by the product team (15s) and when is impersonate flow the time is 2s
  const RETRY = isImpersonate ? 2000 : 15000

  useEffect(() => {
    new DataLayerClient().trigger('Risk Acceptance')
    new DataLayerClient().triggerOrder(
      context?.data?.resourceId,
      context?.data?.resource?.salesChannel
    )

    const updateResource = () => {
      context.updateResource().then((response: any) => {
        if (response.status === 200) {
          clearInterval(interval)
          context.submit()

          hasTriggerGTMEvent && triggerGTMEvent('aprovado', context.currentPlan)

          return
        }

        if (
          response.status === 403 &&
          response.data.error === 'Resource status is "denied"'
        ) {
          clearInterval(interval)
          setViewState('InsuranceNotAccepted')

          hasTriggerGTMEvent && triggerGTMEvent('desaprovado', context.currentPlan)

          return
        }

        if (response.status === 422) {
          if (
            response.data.resource.errors?.vehicle ||
            response.data.resource.errors?.vehicleVin
          ) {
            setViewState('WithoutPlateOrVin')
            clearInterval(interval)
          } else {
            setViewState('Loader')
          }
          return
        }

        clearInterval(interval)
        setViewState('')
      })
    }

    const interval = setInterval(() => {
      updateResource()
    }, RETRY)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.currentPlan])

  const getLoaderType = () => {
    if (isImpersonate) {
      return <Loader data='Aguarde só mais um pouco...' />
    } else {
      return (
        <Loading
          loadingText={`${insuredPersonName}, estamos montando sua apólice`}
          contentList={loadingContent || [{ url: '', text: '' }]}
        />
      )

    }
  }

  function handleStatesView() {
    switch (viewState) {
    case 'InsuranceNotAccepted':
      return <InsuranceNotAccepted />
    case 'Loader':
      return getLoaderType()
    case 'WithoutPlateOrVin':
      return (
        <SomethingWrong
          title='Hey, falta pouco para você continuar!'
          body='Precisamos que vc nos informe a placa ou o chassi do veículo.'
          withoutPlateOrVin
        />
      )
    default:
      return <SomethingWrong />
    }
  }

  return handleStatesView()
}

export default RiskAcceptanceSection
