import React from 'react'
import { FormRadio, inputStyles } from '@tiny-fronts/resource-flow-components'

type LgpdConsentProps = {
  errors?: string
  value?: string
  updateValue?: any
}

const LgpdConsent = ({ errors, value, updateValue }: LgpdConsentProps) => {
  const updateRadio = (_, value) => updateValue(value)

  return (
    <FormRadio
      errors={errors}
      classes={inputStyles()}
      field={value || ''}
      name={'consent'}
      label={'Quer receber novidades e ofertas da Youse e dos nossos parceiros?'}
      obj={'lgpd'}
      trueLabel='Quero'
      falseLabel='Não Quero'
      onChange={updateRadio}
      spacing={{ top: 2 }}
      size='default'
      horizontal={false}
    />
  )
}

export default LgpdConsent
