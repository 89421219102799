import React, { useContext } from 'react'
import { Spacer, Text, Title } from '@youse-seguradora/cargo-components'
import moment from 'moment'

import { ResourceFlowContext } from '../../../contexts/ResourceFlowContext'

import { useTheme } from '@material-ui/core/styles'

export const OrderSummary = () => {
  const theme = useTheme()
  const context = useContext<any>(ResourceFlowContext)
  return (
    <>
      <Spacer top={6} />
      <Title size='xsmall' align='center' color={theme.colors.primary}>
        Protocolo
      </Title>
      <Text align='center' color={theme.colors.highlight}>
        {context.data.resource.protocolNumber}
      </Text>
      <Spacer top={2} />
      <Text align='center' color={theme.colors.primary}>
        Gerado em {moment(context.data.resource.createdAt).format('DD/MM/YYYY')}
      </Text>
      <Spacer top={2} />
    </>
  )
}
