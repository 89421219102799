import fingerprintjs from '@fingerprintjs/fingerprintjs'

import Cache from '../Cache/Cache'

const VISITOR_KEY = '@youse/visitor_id'

class Fingerprint {
  static async getVisitorId () {
    const visitorId = Cache.get(VISITOR_KEY)

    if (visitorId) {
      return visitorId
    }

    const fp = await fingerprintjs.load()
    const result = await fp.get()
    Cache.set(VISITOR_KEY, result.visitorId, 60)

    return result.visitorId
  }
}

export default Fingerprint
