/* eslint-disable max-len */
import React from 'react'
import {
  Background,
  Column,
  ColumnsContainer,
  Container,
  Icon,
  IconName,
  Image,
  Layout,
  Spacer,
  Text,
  View,
} from '@youse-seguradora/cargo-components'

import LogoGlobalsign from '../images/globalsign-logo.png'

import { useTheme } from '@material-ui/core/styles'

const disclaimer =
  'Youse é uma plataforma de seguros 100% digital. Os produtos comercializados são garantidos pela Caixa Seguradora S.A., inscrita no CNPJ nº 34.020.354/0001-10, sediada no SHN, Quadra 01, Conjunto A, Bloco E, CEP 70701 050 – Brasília DF, sendo intermediados pela Wiz Soluções e Corretagem de Seguros S.A. O registro do produto é automático e não representa aprovação ou recomendação por parte da Susep. Processo SUSEP Seguro Auto nº 15414.900039/2016-18; Processo SUSEP Seguro Residencial nº 15.414.900040/2016-34 e Processo SUSEP Seguro Vida nº 15.414.900041/2016-89. As condições contratuais/regulamento deste produto protocolizadas pela sociedade/entidade junto à SUSEP poderão ser consultadas no endereço eletrônico www.susep.gov.br, de acordo com o número de processo constante da apólice/proposta. Ouvidoria 0800.730.9991 / Atendimento ao Público Susep: 0800.021.8484. Nos termos da Resolução CNSP nº 382/2020, a Caixa Seguradora S.A. informa que a CAIXA, por meio de sua subsidiária integral Caixa Seguridade Participações S.A., detém participação societária direta sobre a Caixa Seguros Holding S.A., no percentual de 48,25% e, indireta sobre a Wiz Soluções e Corretagem de Seguros S.A., no percentual de 12,05%. Informamos ainda que a Caixa Seguros Holding S.A. detém participação societária direta sobre a Wiz Soluções e Corretagem de Seguros S.A., com percentual de 25%. A Caixa Seguradora S.A. remunera seus intermediários em até 13% pela comercialização dos seus Seguros de Automóvel, Vida e Residencial, comercializados sob a marca Youse.'

const creditCardOptions: IconName[] = [
  'visa',
  'mastercard',
  'amex',
  'visaelectron',
  'elo',
  'maestro',
  'diners',
  'discover',
  'caixa',
  'hipercard',
  'jcb',
  'aura',
]

const Footer = () => {
  const theme = useTheme()

  return (
    <footer>
      <Background color={theme.backgrounds.disable}>
        <Container>
          <Spacer top={7} bottom={7}>
            <ColumnsContainer>
              <Column desktopSize={9}>
                <Text color={theme.colors.primary} size='xsmall'>
                  {disclaimer}
                </Text>
              </Column>
              <Column desktopSize={3}>
                <Spacer vertical={6} desktopVertical={0}>
                  <Layout vertical desktopAlignRight>
                    <View
                      layout={{
                        wrap: true,
                        horizontal: true,
                        alignHorizontalCenter: true,
                        desktopAlignRight: true,
                      }}
                    >
                      {creditCardOptions.map((item, i) => (
                        <Spacer key={i} left={i === 0 ? 0 : 2}>
                          <Icon size={5} name={item} maxwidth='40px' />
                        </Spacer>
                      ))}
                    </View>
                    <Spacer top={4}>
                      <Image src={LogoGlobalsign} alt='globalsign' height={6} />
                    </Spacer>
                  </Layout>
                </Spacer>
              </Column>
            </ColumnsContainer>
          </Spacer>
        </Container>
      </Background>
    </footer>
  )
}

export default Footer
