import { TagType } from '@youse-seguradora/cargo-components'

import { BookingStatus, ClaimStatus, InspectionStatus, InvoiceStatus, PolicyStatus, RenewalStatus } from '../types/types'

const claimStatusColors = {
  open: TagType.success,
  reopen: TagType.success,
  close: TagType.error,
  validation_pending: TagType.warning,
  cancelled: TagType.error,
  refused: TagType.error,
  accept: TagType.success,
  denied: TagType.error,
  registered: TagType.success,
  settled: TagType.success,
  error: TagType.error,
}

export function claimTagColor(status: ClaimStatus) {
  return status in claimStatusColors
    ? claimStatusColors[status]
    : claimStatusColors['error']
}

const inspectionStatusColors = {
  ACCEPTED: TagType.success,
  EXEMPTED: TagType.success,
  MOVED: TagType.disable,
  EXPIRED: TagType.disable,
  CANCELED: TagType.error,
  REFUSED: TagType.error,
  PENDING: TagType.warning,
  SCHEDULED: TagType.warning
}

export function inspectionTagColor(status: InspectionStatus) {
  return status in inspectionStatusColors
    ? inspectionStatusColors[status]
    : inspectionStatusColors['ERROR']
}

const renewalStatusColors = {
  REFUSED: TagType.error,
  CANCELLED: TagType.error,
  ACCEPTED: TagType.success,
  PENDING: TagType.warning,
  SUSPENDED: TagType.warning,
  RENEWED: TagType.success,
  PENDING_PAYMENT: TagType.warning,
  FAILED: TagType.error,
}

export function renewalTagColor(status: RenewalStatus) {
  return status && status in renewalStatusColors ? renewalStatusColors[status] : renewalStatusColors['FAILED']
}

const bookingStatusColors = {
  DONE: TagType.success,
  NO_SHOW: TagType.warning,
  SCHEDULED: TagType.warning,
  CANCELED: TagType.error,
  ERROR: TagType.error,
}

export function bookingTagColor(status: BookingStatus) {
  return status in bookingStatusColors ? bookingStatusColors[status] : bookingStatusColors['ERROR']
}

const policyStatusColors = {
  ACTIVE: TagType.success,
  PROPOSAL: TagType.warning,
  PENDING: TagType.warning,
  EXPIRED: TagType.warning,
  CANCELLED: TagType.error,
  REFUSED: TagType.error,
  FAILED: TagType.error,
  ERROR: TagType.error,
}

export function policyTagColor(status: PolicyStatus) {
  return status in policyStatusColors ? policyStatusColors[status] : policyStatusColors['ERROR']
}

const invoiceStatusColors = {
  REFUSED: TagType.error,
  APPROVED: TagType.success,
  PENDING: TagType.disable,
  WAITING_FOR_APPROVAL: TagType.warning,
}

export function invoiceTagColor(status: InvoiceStatus) {
  return status in invoiceStatusColors ? invoiceStatusColors[status] : invoiceStatusColors['ERROR']
}
