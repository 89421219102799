import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Column,
  ColumnsContainer,
  RadioInput,
  Spacer,
  View } from '@youse-seguradora/cargo-components'
import { desktopContent, mobileContent } from 'styles/ContentStyle'
import { isMobile } from 'utils/IsMobile'

import { RequestSummary } from 'components/RequestSummary'

export const SharingPeriod = ({ form, setForm, isReception }) => {
  const history = useHistory()
  const currentPath =  history.location.pathname.toString()

  return (
    <View
      style={isMobile() ? mobileContent : desktopContent}
    >
      <ColumnsContainer>
        {window.innerWidth < 900 &&
          <Column tabletSize={6}>
            <RequestSummary segment={form.segment} brand={form.brand.CustomerFriendlyName} />

            <Spacer top={4} />
          </Column>
        }

        <Column tabletSize={6}>
          <View layout={{ horizontal: false }}>
            {(!!form.month?.length && currentPath.includes('/renovacao')) ?
              <RadioInput
                disabled={true}
                options={[
                  { label: `${form.month} meses`, name: 'months', value: form.month, defaultChecked: true }
                ]}
              /> :
              <RadioInput
                disabled={isReception}
                onChange={(e: any) => setForm({ ...form, month: e.target.value })}
                options={[
                  { label: '1 mês', name: 'months', value: '1', defaultChecked: form.month === '1' || form.month === '2' || form.month === '3' },
                  { label: '4 meses', name: 'months', value: '4', defaultChecked: form.month === '4' || form.month === '5' || form.month === '6' || form.month === '7' },
                  { label: '8 meses', name: 'months', value: '8', defaultChecked: form.month === '8' || form.month === '9' || form.month === '10' || form.month === '11' },
                  { label: '12 meses', name: 'months', value: '12', defaultChecked: form.month === '12' }
                ]}
              />
            }
          </View>
        </Column>

        {window.innerWidth > 900 &&
          <Column tabletSize={6}>
            <RequestSummary segment={form.segment} brand={form.brand.CustomerFriendlyName} />
          </Column>
        }
      </ColumnsContainer>
    </View>
  )
}
