import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Loading } from '@tiny-fronts/basement'
import {
  Container,
  Image,
  Message,
  MessageType,
  Spacer,
  Text,
  Title,
  View,
} from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import useAuth from 'hooks/useAuth'

import { CancellationModal } from 'components/ConsentCancellation'

import ActionButtons from '../../components/BottomBar/BottomBar'

import { AboutDataUsage } from './AboutDataUsage'
import { ChooseInstitution } from './ChooseInstitution'
import { ChooseOptionalData } from './ChooseOptionalData'
import { ClientIdentification } from './ClientIdentification'
import { ConsentParams, ConsentProps, FormProps, PermissionsList, UserResponse } from './interfaces'
import { Redirect } from './Redirect'
import { RequiredSharedData } from './RequiredSharedData'
import { SharingPeriod } from './SharingPeriod'
import { Terms } from './Terms'

class ResponseError extends Error {
  response!: Response
}

export const ReceptionFlow = () => {
  const [step, setStep] = useState<string>('1')
  const [loading, setLoading] = useState<boolean>(true)
  const [form, setForm] = useState<FormProps>({
    segment: 'residencial',
  })
  const [permissions, setPermissions] = useState<PermissionsList>()
  const [cancellationModal, setCancellationModal] = useState<boolean>(false)

  const history = useHistory()
  const { token } = useAuth()
  const { consentId } = useParams<ConsentParams>()
  const currentPath =  history.location.pathname.toString()

  const isDisabled = () => {
    if (step === '3' && !form.brand?.CustomerFriendlyName?.length) return true

    if (step === '5' && !form.optionalData && !form.optionalDataAll) return true

    if (step === '6' && !form.month?.length) return true

    if (step === '7' && !form.acceptTerms) return true

    return false
  }

  const stepStrategy = {
    '1': {
      children: <ClientIdentification form={form} />,
      title: 'Compartilhamento de dados Open Insurance',
      subTitle: 'Identificação do cliente',
    },
    '2': {
      children: <AboutDataUsage isReception={currentPath === '/recepcao'} form={form} setForm={setForm} />,
      title: 'Sobre o uso dos dados',
      subTitle: 'Escolha a finalidade do uso dos dados e o segmento',
    },
    '3': {
      children: <ChooseInstitution form={form} setForm={setForm} />,
      title: 'Instituição que você quer trazer seus dados',
      subTitle: 'Procure a intituição que você quer trazer seus dados pra Youse',
    },
    '4': {
      children: <RequiredSharedData form={form} permissions={permissions} />,
      title: 'Sobre os dados necessários que nós vamos trazer',
      subTitle: 'Estes são os dados mínimo que precisamos no compartilhamento',
    },
    '5': {
      children: <ChooseOptionalData form={form} setForm={setForm} permissions={permissions} />,
      title: 'Selecione os dados opcionais que você também pode trazer',
      subTitle:
        'Trazendo os dados da sua apólice podemos te oferecer um pacote de serviço personalizado',
    },
    '6': {
      children: <SharingPeriod isReception={currentPath.includes('/renovacao')} form={form} setForm={setForm} />,
      title: 'Selecione o período que você gostaria de compartilhar seus dados',
      subTitle: 'No final do período, o compartilhamento será finalizado',
    },
    '7': {
      children: <Terms form={form} setForm={setForm} />,
      title: 'Termos e condições',
      subTitle: 'Leia os termos e condições antes de confirmar sua solicitação',
    },
    '8': {
      children: <Redirect form={form} />,
      title: '',
      subTitle: '',
    },
  }

  const defaultRequest = (path, method, payload, nextStep) => {
    fetch(`${process.env.REACT_APP_OPIN_SERVICE_URL}${path}`, {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.status === 200) {
          return response
        } else {
          const error = new ResponseError(response.statusText)
          error.response = response
          throw error
        }
      })
      .then((response) => response.json())
      .then((response) => {
        setForm({ ...form, authorization_url: response.authorization_url })

        setStep(nextStep)
      })
      .catch(async (err) => {
        let errorPage = '/erro-desconhecido'
        const res = await err.response.json()
        const isTransmitterError = parseInt(res.status)

        if (isTransmitterError >= 400  && isTransmitterError <= 499 ) {
          errorPage = `/erro-transmissora/${form.name}/${form.document_number}/${form.email}/${form.brand?.CustomerFriendlyName?.replace('/', '|')}/`
        }
        history.push(errorPage)
      })
  }

  const createConsent = () => {
    if (currentPath === '/recepcao') {
      const payload = {
        data: {
          consentTerm: form.month?.toString() || '',
          permissions: [
            'CUSTOMERS_PERSONAL_IDENTIFICATIONS_READ',
            'CUSTOMERS_PERSONAL_ADDITIONALINFO_READ'
          ]
        },
        segment: form.segment,
        organizationName: form.brand?.CustomerFriendlyName?.replace('/', '|'),
      }

      if (form.optionalData || form.optionalDataAll) {
        payload.data.permissions.push('CUSTOMERS_PERSONAL_QUALIFICATION_READ')
      }

      defaultRequest('/consents/data-reception', 'POST', payload, '8')
    } else if ((currentPath === '/renovacao')) {
      const payload = {
        consentTerm: form.month?.toString() || '',
        segment: form.segment,
        organizationName: form.brand?.CustomerFriendlyName?.replace('|', '/'),
      }

      defaultRequest(`/consents/data-reception/${consentId}`, 'PATCH', payload, '8')
    } else {
      const segmentStrategy = {
        Home: 'residencial',
        Housing: 'residencial',
        Auto: 'auto',
        Life: 'vida'
      }
      const payload = {
        data: {
          consentTerm: form.month?.toString() || '',
        },
        segment: segmentStrategy[form.segment || 'Home'],
        organizationName: form.brand?.CustomerFriendlyName?.replace('|', '/'),
      }

      defaultRequest(`/consents/data-reception/${consentId}`, 'PUT', payload, '8')
    }
  }

  const nextStep = () => {
    if (step === '7') {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'Event_Data',
          event_type: 'new',
          ga_event: {
            page_title: 'tela-termos-e-condições',
            page_location: window.location.href,
            action: 'click',
            element_name: 'tela-termos-e-condições: Continuar'
          },
          product: {
            flow: 'OPIN Recepção'
          }
        })
      }

      return createConsent()
    }

    if (step === '2' && currentPath  !== '/recepcao') return setStep((Number(step) + 2).toString())

    if (step === '3') {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'Event_Data',
          event_type: 'new',
          ga_event: {
            page_title: 'tela-instituição-que-você-quer-trazer-seus-dados',
            page_location: window.location.href,
            action: 'click',
            element_name: 'tela-instituição-que-você-quer-trazer-seus-dados: Continuar'
          },
          product: {
            flow: 'OPIN Recepção'
          }
        })
      }
    }

    if (step === '6') {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'Event_Data',
          event_type: 'new',
          ga_event: {
            page_title: 'tela-selecione-o-período-que-você-gostaria-de-compartilhar-seus-dados',
            page_location: window.location.href,
            action: 'click',
            element_name: 'tela-selecione-o-período-que-você-gostaria-de-compartilhar-seus-dados: Continuar'
          },
          product: {
            flow: 'OPIN Recepção'
          }
        })
      }
    }

    if (step === '4' && currentPath  !== '/recepcao') return setStep((Number(step) + 2).toString())

    const newStep = Number(step) + 1

    setStep(newStep.toString())
  }

  useEffect(() => {
    setLoading(true)

    if (currentPath === '/recepcao') {
      fetch(`${process.env.REACT_APP_OPIN_SERVICE_URL}/user/me`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response
          } else {
            const error = new ResponseError(response.statusText)
            error.response = response
            throw error
          }
        })
        .then<UserResponse>((response) => response.json())
        .then((response) =>
          setForm({
            ...form,
            document_number: response?.document_number,
            name: response.name,
            email: response.email
          }),
        )
        .catch((err) => {
          if (err.response.status === 401) return history.push('/logout')
          return history.push('/erro-desconhecido')
        })
    } else if (currentPath.includes('/renovacao') || currentPath.includes('/alteracao')) {
      fetch(`${process.env.REACT_APP_OPIN_SERVICE_URL}/consents/data-reception/${consentId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response
          } else {
            const error = new ResponseError(response.statusText)
            error.response = response
            throw error
          }
        })
        .then<ConsentProps>((response) => response.json())
        .then((response) => setForm({
          acceptTerms: false,
          name: response.user.name,
          email: response.user.email,
          document_number: response.user.document_number,
          month: response.consentTerm.toString(),
          segment: response.product,
          optionalData: true,
          brand: {
            CustomerFriendlyName: response.organizationName,
            CustomerFriendlyLogoUri: response.organizationLogo
          }
        }))
        .catch(() => {
          history.push('/erro-desconhecido')
        })
        .finally(() => setLoading(false))
    }

    fetch(`${process.env.REACT_APP_OPIN_SERVICE_URL}/permissions`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response
        } else {
          const error = new ResponseError(response.statusText)
          error.response = response
          throw error
        }
      })
      .then<PermissionsList>((response) => response.json())
      .then((response) => setPermissions(response))
      .catch(() => {
        history.push('/erro-desconhecido')
      })
      .finally(() => setLoading(false))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) return <Loading />

  return (
    <>
      <Container
        style={{
          minHeight: 'calc(100vh - 64px)',
        }}
      >
        <View>
          {step === '7' && (
            <>
              <Spacer top={6} />

              <Message
                icon='calendar'
                title=''
                children={
                  <Text size={'small'} weight={'bold'} color={defaultTheme.colors.highlight}>
                    Ao continuar você será direcionado de forma segura para o site ou app da
                    instituição escolhida. Você pode confirmar a solicitação em até 60 minutos, se
                    ultrapassar esse tempo você terá que realizar uma nova.
                  </Text>
                }
                type={MessageType.highlight}
              />
            </>
          )}

          <Spacer top={6} />

          {step !== '8' ? (
            <>
              <Title size={'small'} weight={'bold'} color={defaultTheme.colors.primary}>
                {stepStrategy[step].title}
              </Title>

              <Spacer top={2} />

              <Title size={'xsmall'} weight={'light'} color={defaultTheme.colors.secondary}>
                {stepStrategy[step].subTitle}
              </Title>
            </>
          ) : (
            <>
              <View style={{ maxWidth: '170px' }}>
                <Image
                  src='https://cdn.youse.com.br/opin/images/opin-logo.svg'
                  alt='OpenInsurance'
                />
              </View>

              <Spacer top={2} />

              <Title size={'xsmall'} weight={'light'} color={defaultTheme.colors.secondary}>
                Fica numa boa que este compartilhamento de dados é seguro e realizado através do
                Open Insurance.
              </Title>
            </>
          )}

          {step === '8' && (
            <>
              <Spacer top={6} />

              <Message
                icon='calendar'
                title='Prazo da solicitação'
                description='O prazo para que o compartilhamento dos seus dados fique ativo é de até 60 minutos.'
                type={MessageType.warning}
              />
            </>
          )}

          <Spacer top={4} />

          {stepStrategy[step].children}
        </View>

        {step === '8' && (
          <View layout={{ alignHorizontalCenter: true, horizontal: true }}>
            <Title
              size={'xsmall'}
              weight={'light'}
              align='left'
              color={defaultTheme.colors.secondary}
            >
              Só mais um pouquinho, já estamos te direcionando...
            </Title>
          </View>
        )}
      </Container>

      {step !== '8' &&
        <ActionButtons
          nextStep={nextStep}
          previousStep={() => setCancellationModal(true)}
          isDisabled={isDisabled()}
          nextStepText={'Continuar'}
        />
      }

      <CancellationModal
        isOpen={cancellationModal}
        close={() => {
          setCancellationModal(false)
        }}
        handleCancellation={() => history.push('/')}
      />
    </>
  )
}
