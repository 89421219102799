import React, { useContext, useEffect, useState } from 'react'
import AdyenCheckout from '@adyen/adyen-web'
import {
  PrimaryButton,
  Spacer,
  TrackingContainer,
  TrackingContext,
} from '@youse-seguradora/cargo-components'

import DataLayerClient from '../../../clients/DataLayerClient'
import { ResourceFlowContext } from '../../../contexts/ResourceFlowContext'
import SeoInterface from '../../../interfaces/SeoInterface'
import { Impersonate } from '../../Impersonate/Impersonate'
import LayoutBaseWithSidebar from '../../LayoutBaseWithSidebar'
import { SidebarCardProps } from '../../LayoutBaseWithSidebar/Sidebar/SidebarCard'
import { SidebarIconsProps } from '../../LayoutBaseWithSidebar/Sidebar/SidebarIcons'
import ConfirmEmail from '../CheckoutSection/ConfirmEmail/ConfirmEmail'

import { OrderSummary } from './OrderSummary'
import { Payment } from './Payment'
import PaymentConditionFooter from './PaymentConditionFooter'
import PaymentConditionHeader from './PaymentConditionHeader'
import { Success } from './Success'

import '@adyen/adyen-web/dist/adyen.css'

type PaymentConditionSelectionProps = {
  productName: string
  message?: React.ReactNode
  sidebarIcons?: SidebarIconsProps
  sidebarCard?: SidebarCardProps
  isLGPGEnabled?: boolean
  iconHeaderPartner?: string[]
  isSidebarNotifyOrder?: boolean
}

const PaymentConditionSelection = ({
  message,
  productName,
  sidebarIcons,
  sidebarCard,
  isLGPGEnabled,
  iconHeaderPartner,
  isSidebarNotifyOrder,
}: PaymentConditionSelectionProps) => {
  const context = useContext<any>(ResourceFlowContext)
  const [creditCard, setCreditCard] = useState<any>()
  const [isNotEqual, setIsNotEqual] = useState<boolean>(true)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true)
  const [creditCardValid, setCreditCardValid] = useState<boolean>(false)
  const seo: SeoInterface = { title: `${productName} | Youse` }
  const { attendant } = context?.data?.resource || {}
  const [insuredPersonEmail, setInsuredPersonEmail] = useState<string>(
    context?.data?.resource?.insuredPerson?.email,
  )
  const [insuredPersonEmailConfirm, setInsuredPersonEmailConfirm] = useState<string>()
  const [browserInfo, setBrowserInfo] = useState<any>()
  const [riskData, setRiskData] = useState<any>()

  useEffect(() => {
    setDisabledSubmit(!creditCardValid)
  }, [creditCardValid])

  useEffect(() => {
    if (context.status === 200 && context.config.method === 'put') setShowSuccess(true)
  }, [context])

  useEffect(() => {
    new DataLayerClient().trigger('Page Renewal')
    const adyenEnvironment = process.env.REACT_APP_LIVE_ADYEN === 'true' ? 'live' : 'test'
    const translations = {
      pt_BR: { 'creditCard.holderName.placeholder': 'Nome como no cartão' },
    }
    const originKey = process.env.REACT_APP_ADYEN_CLIENT_KEY
    const configuration = {
      locale: 'pt_BR',
      translations: translations,
      environment: adyenEnvironment,
      originKey: originKey,
      hasHolderName: true,
      holderNameRequired: true,
      paymentMethodsResponse: {
        paymentMethods: [
          {
            brands: [
              'mc',
              'visa',
              'elo',
              'amex',
              'hipercard',
              'maestro',
              'diners',
              'discover',
              'hiper',
              'jcb',
            ],
            details: [
              {
                key: 'number',
                type: 'text',
              },
              {
                key: 'expiryMonth',
                type: 'text',
              },
              {
                key: 'expiryYear',
                type: 'text',
              },
              {
                key: 'cvc',
                type: 'text',
              },
              {
                key: 'holderName',
                optional: true,
                type: 'text',
              },
            ],
            name: 'Credit Card',
            type: 'scheme',
          },
        ],
      },
      onChange: (state, component) => {
        setBrowserInfo(state.data.browserInfo)
        setRiskData(state.data.riskData)
        if (!state.isValid) {
          setCreditCardValid(false)
          return
        }
        const brand = component.state.brand === 'mc' ? 'MasterCard' : component.state.brand
        setCreditCard(Object.assign({}, state.data.paymentMethod, { brand: brand }))
        setCreditCardValid(true)
      },
    }

    const checkout = new AdyenCheckout(configuration)
    // eslint-disable-next-line no-lone-blocks
    !attendant && !showSuccess && checkout.create('card').mount('#component-container')
  }, [])

  const submit = () => {
    context.updateResource(
      {
        insuredPerson: {
          email: insuredPersonEmail,
        },
        paymentCondition: {
          paymentMethod: 'credit_card',
        },
        payment: {
          creditCard: {
            number: creditCard.encryptedCardNumber,
            holder: creditCard.holderName,
            due_date_month: creditCard.encryptedExpiryMonth,
            due_date_year: creditCard.encryptedExpiryYear,
            security_code: creditCard.encryptedSecurityCode,
            brand: creditCard.brand,
          },
        },
        browserInfo,
        riskData,
      },
      false,
    )
  }

  return attendant ? (
    <LayoutBaseWithSidebar
      iconHeaderPartner={iconHeaderPartner}
      customHeader={PaymentConditionHeader({ fixed: false })}
      customFooter={PaymentConditionFooter()}
      seo={seo}
      productName={productName}
      sidebarIcons={sidebarIcons}
      sidebarCard={sidebarCard}
    >
      <Spacer top={4} />
      <Impersonate />
      {showSuccess ? <Success /> : <OrderSummary />}
    </LayoutBaseWithSidebar>
  ) : (
    <LayoutBaseWithSidebar
      iconHeaderPartner={iconHeaderPartner}
      customHeader={PaymentConditionHeader({ fixed: false })}
      customFooter={PaymentConditionFooter()}
      isSidebarNotifyOrder={isSidebarNotifyOrder}
      seo={seo}
      productName={productName}
      sidebarIcons={sidebarIcons}
      sidebarCard={sidebarCard}
      centerFooterButton
      footerButton={
        <TrackingContext category='route-change'>
          <TrackingContainer action='buy' label='finalizar renovacao'>
            {!showSuccess && (
              <PrimaryButton disabled={disabledSubmit} onClick={submit} style={{ height: '50px' }}>
                Finalizar
              </PrimaryButton>
            )}
          </TrackingContainer>
        </TrackingContext>
      }
    >
      <Spacer top={6} />
      {showSuccess ? (
        <Success />
      ) : (
        <Payment productName={productName} message={message} isLGPGEnabled={isLGPGEnabled} />
      )}
      {context.data.currentSection !== 'payment_condition_selection' && (
        <>
          <Spacer top={6} />
          <ConfirmEmail
            insuredPersonEmail={insuredPersonEmail}
            setInsuredPersonEmail={setInsuredPersonEmail}
            insuredPersonEmailConfirm={insuredPersonEmailConfirm}
            setInsuredPersonEmailConfirm={setInsuredPersonEmailConfirm}
            isNotEqual={isNotEqual}
            setNotEqual={setIsNotEqual}
          />
        </>
      )}
      {!showSuccess && <OrderSummary />}
    </LayoutBaseWithSidebar>
  )
}

export default PaymentConditionSelection
