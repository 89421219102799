import { useContext,useEffect } from 'react'
import { AuthContext } from '@tiny-fronts/basement'

export const Logout = () => {
  const { handleLogout } = useContext<any>(AuthContext)

  const redirect = () => {
    return window.location.href = process.env.REACT_APP_HOME + 'users/sign_out' || ''
  }

  useEffect(() => {
    handleLogout()
    redirect()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
