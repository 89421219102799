import React from 'react'
import {
  InvisibleButton,
  PrimaryButton,
  Spacer,
  Text,
  Title,
  View,
} from '@youse-seguradora/cargo-components'

import { Image } from './PixWarning'

import { useTheme } from '@material-ui/core/styles'

type PixQrCodeErrorProps = {
  createQrCode: () => void
  backToTutorial: () => void
}

const PixQrCodeError = ({ createQrCode, backToTutorial }: PixQrCodeErrorProps) => {
  const theme = useTheme()

  return (
    <>
      <View
        spacing={{ top: 4 }}
        style={{ padding: '26px' }}
        layout={{ alignHorizontalCenter: true }}
      >
        <Image src='https://www.datocms-assets.com/16903/1590782515-illus-characters-attention-03-4x3-light.png' />
        <Spacer top={3} />
        <Title size='xsmall' align='center' color={theme.colors.highlight}>
          Ops! Algo deu errado...
        </Title>
        <Spacer top={3} />
        <Text align='center' size='small' color={theme.colors.secondary}>
          A gente não conseguiu gerar seu código Pix. Mas fique numa boa: tente novamente em alguns
          instantes, ok? ;)
        </Text>
        <Spacer top={5} />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <PrimaryButton children='Tentar Novamente' onClick={createQrCode} />
        </div>
        <Spacer top={1} />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <InvisibleButton children='Voltar' onClick={backToTutorial} />
        </div>
        <Spacer top={4} />
      </View>
    </>
  )
}

export default PixQrCodeError
