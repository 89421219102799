import React from 'react'
import {
  Icon,
  Image,
  Spacer,
  Text,
  Title,
  View } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import { desktopContent, mobileContent } from 'styles/ContentStyle'
import { isMobile } from 'utils/IsMobile'

export const Redirect = ({ form }) => {

  setTimeout(() => {
    window.location.href = form.authorization_url
  }, 3000)

  return (
    <View
      style={isMobile() ? mobileContent : desktopContent}
    >
      <View>
        <View
          style={{ columnGap: '48px' }}
          layout={{ alignVerticalCenter: true, horizontal: true }}
        >
          <View style={{ minWidth: '100px' }} layout={{ alignHorizontalCenter: true }}>
            <Icon name='logo-youse-grey' size={6} />
          </View>

          <Title size={'xsmall'} weight={'bold'} align='left' color={defaultTheme.colors.primary}>
            Youse Seguros
          </Title>
        </View>

        <Spacer top={5} />

        <View
          style={{ columnGap: '48px' }}
          layout={{ alignVerticalCenter: true, horizontal: true }}
        >
          <View
            style={{ minWidth: '100px', height: '16px' }}
            layout={{ alignHorizontalCenter: true }}
          >
            <Image src='https://cdn.youse.com.br/opin/images/arrow.svg' alt='arrow' />
          </View>

          <Text
            size={'small'}
            weight={'regular'}
            align='left'
            color={defaultTheme.colors.secondary}
          >
            A gente vai direcionar você para a instituição escolhida.
          </Text>
        </View>

        <Spacer top={5} />

        <View
          style={{ columnGap: '48px' }}
          layout={{ alignVerticalCenter: true, horizontal: true }}
        >
          <View style={{ maxWidth: '100px' }} layout={{ alignHorizontalCenter: true }}>
            <Image src={form.brand.CustomerFriendlyLogoUri} alt={form.brand.CustomerFriendlyName} />
          </View>

          <Title size={'xsmall'} weight={'bold'} align='left' color={defaultTheme.colors.primary}>
            {form.brand.CustomerFriendlyName}
          </Title>
        </View>
      </View>
    </View>
  )
}
