import { makeStyles } from '@material-ui/core/styles'

const planSelectionStyled = makeStyles((theme) => ({
  progressBarWrapper: {
    [theme.breakpoints.down('md')]: {
      padding: '0',
    },
  },
  scrollArea: {
    overflowX: 'scroll',
    scrollSnapType: 'x proximity',
    display: 'grid',
    gridGap: '25px',
    gridTemplateColumns: 'repeat(6, minmax(260px, 1fr)) 8px',
    scrollbarWidth: 'none',
    padding: '15px',
    '&::-webkit-scrollbart': { display: 'none' },
    '&::after': { content: '""', width: '1px' },

    [theme.breakpoints.up('md')]: {
      overflowX: 'auto',
      gridTemplateColumns: (props: any) =>
        `repeat(${props.planSize > 3 ? '3' : props.planSize}, 1fr)`,
      gridGap: '24px',
      justifyContent: 'center',
      alignContent: 'center',
    },
  },

  centerColumn: {
    [theme.breakpoints.up('md')]: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridTemplateColumns: '[linename] 550px',
      justifyContent: 'center',
      alignContent: 'center',
    },
  },

  scrollAreaCustom: {
    overflowX: 'scroll',
    scrollSnapType: 'x proximity',
    display: 'grid',
    gridGap: '25px',
    gridTemplateColumns: 'repeat(6, minmax(260px, 1fr)) 8px',
    scrollbarWidth: 'none',
    padding: '15px',
    '&::-webkit-scrollbart': { display: 'none' },
    '&::after': { content: '""', width: '1px' },

    [theme.breakpoints.up('md')]: {
      overflowX: 'auto',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gridGap: '20px',
    },
  },
  progressBar: {
    backgroundColor: theme.backgrounds.primary,
    display: 'block',
    overflow: 'hidden',
    width: '100%',
    height: '4px',
    borderRadius: '8px',
    '&:before': {
      content: '""',
      backgroundColor: theme.backgrounds.highlight,
      display: 'block',
      width: (props: any) => `${props.progressValue}%`,
      height: '4px',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  planSelectedBox: {
    position: 'absolute',
    top: '-12px',
    right: '-2px',
    left: '-2px',
    display: 'flex',
    paddingLeft: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    height: '26px',
    fontSize: '0.75rem',
    color: 'rgb(255, 255, 255)',
    textAlign: 'center',
    backgroundColor: theme.colors.highlight,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.colors.highlight,
    borderImage: 'initial',
    borderRadius: '8px 8px 0px 0px',

    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
      paddingLeft: '0',
    },
  },

  planSelected: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.colors.highlight,
  },
}))

export { planSelectionStyled }
