import React from 'react'
import { Spacer, Text, Title, View } from '@youse-seguradora/cargo-components'

import Assistances from '../Assistances/Assistances'
import CheckoutResume from '../CheckoutResume/CheckoutResume'
import Coverages from '../Coverages/Coverages'

import { useTheme } from '@material-ui/core/styles'

type SummaryProps = {
  order: any
  data: any
}

const Summary = ({ data }: SummaryProps) => {
  const theme = useTheme()

  return (
    <>
      <View>
        <Title size='xsmall' align='center' color={theme.colors.primary}>
          Protocolo
        </Title>

        <Text align='center' color={theme.colors.highlight}>
          {data.protocolNumber}
        </Text>

        <Text size='xsmall' align='center' color={theme.colors.secondary}>
          {data.createdAt}
        </Text>
      </View>

      <Spacer top={6} />

      <View>
        <Title size='small' align='center' color={theme.colors.primary}>
          Revise seu seguro
        </Title>

        <Spacer top={6} />

        <Coverages data={data.coverages} />

        <Spacer top={2} />

        {data.assistances.length !== 0 && (
          <>
            <Assistances data={data.assistances} />
            <Spacer top={6} />
          </>
        )}

        <CheckoutResume data={data.resume} />
      </View>
    </>
  )
}

export default Summary
