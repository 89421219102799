import React from 'react'
import { Theme } from '@material-ui/core'
import { Dot, View } from '@youse-seguradora/cargo-components'

import { compareTranslation } from '../../helpers/functions'

const StatusComponent = (
  data: { status: string },
  translationStatus: {},
  // TODO remove any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  statusColor: any,
  theme: Theme,
) => {
  return (
    <View layout={{ horizontal: true, alignVerticalCenter: true, alignHorizontalCenter: true }}>
      <Dot statusColor={statusColor(data.status, theme)} />
      {compareTranslation(translationStatus, data.status)}
    </View>
  )
}

export default StatusComponent
