import React from 'react'
import { Text, Title, View } from '@youse-seguradora/cargo-components'

import { useTheme } from '@material-ui/core/styles'

const PricingMonthly = ({ data }: any) => {
  const theme = useTheme()

  const str = data.toFixed(2)
  const splitted = str.split('.')
  const int = splitted[0]
  const decimal = splitted[1]
  return (
    <>
      <View spacing={{ top: 4, right: 1, desktopTop: 5 }}>
        <Text size='small' weight='bold' color={theme.colors.primary}>
          R$
        </Text>
      </View>
      <View spacing={{ top: 2, desktopTop: 0 }}>
        <Title size='big' weight='bold' color={theme.colors.primary}>
          {int}
        </Title>
      </View>
      <View spacing={{ left: 1 }}>
        <View position={{ relative: true, top: '5px' }}>
          <Text size='default' weight='bold' color={theme.colors.primary}>
            ,{decimal}
          </Text>
        </View>
        <Text size='small' color={theme.colors.primary}>
          por mês
        </Text>
      </View>
    </>
  )
}

export default PricingMonthly
