import React, { useContext, useState } from 'react'
import Input from '@material-ui/core/Input'
import {
  InvisibleButton,
  Modal,
  PrimaryButton,
  Spacer,
  Text,
  Title,
  View,
} from '@youse-seguradora/cargo-components'

import BFFClient from '../../../../clients/BFFClient'
import { ResourceFlowContext } from '../../../../contexts/ResourceFlowContext'
import { pathName } from '../../../../helpers/PathName'
import { inputStyles } from '../../../styles/inputStyles'

import { useTheme } from '@material-ui/core/styles'

const bffClient = new BFFClient()

export default function NotifyOrder() {
  const theme = useTheme()
  const classes = inputStyles()
  const context = useContext<any>(ResourceFlowContext)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [modalSuccess, setModalSuccess] = React.useState<boolean>(false)
  const [orderByEmail, setOrderByEmail] = React.useState<string>('')
  const [errorInput, setErrorInput] = React.useState<boolean>(false)
  const [titleModal, setTitleModal] = React.useState('Enviar Cotação')
  const product = context.data.resource.product
  const resourceFlowId = context.data.id

  const applicationHost = `${window.location.origin}/${pathName(context)}`

  const modalSendOrder = {
    title: titleModal,
    content: modalSuccess ? (
      <>
        <View layout={{ alignHorizontalCenter: true }}>
          <Title size='xsmall' align='center' color={theme.colors.success}>
            Cotação enviada com sucesso!
          </Title>
        </View>
        <View spacing={{ top: 4 }} />
        <Text size='small' align='center'>
          A cotação foi enviada para <b>{orderByEmail}</b>.
        </Text>
      </>
    ) : (
      <>
        <Title size='xsmall' align='center' color={theme.colors.highlight}>
          Para qual email deseja enviar
          <br /> sua cotação?
        </Title>
        <Spacer top={4} />
        <Input
          style={{ width: '300px' }}
          className={classes.inputMaterial}
          id='insuredPersonEmail'
          autoComplete='nope'
          defaultValue={orderByEmail}
          onBlur={(e: any) => setOrderByEmail(e.target.value)}
          onChange={(e: any) => setOrderByEmail(e.target.value)}
          type='email'
          placeholder='Email'
          error={errorInput ? true : false}
        />
      </>
    ),
    footer: modalSuccess ? (
      <InvisibleButton onClick={() => handleSendAnotherOrder()}>Enviar outro</InvisibleButton>
    ) : (
      <PrimaryButton onClick={() => handleSubmit()}>Enviar</PrimaryButton>
    ),
  }

  const handleSendAnotherOrder = () => {
    setModalSuccess(false)
    setTitleModal('Enviar cotação')
    setErrorInput(false)
    setOrderByEmail('')
  }

  const handleSubmit = () => {
    if (orderByEmail !== '') {
      bffClient
        .sendOrderByEmail(product, resourceFlowId, applicationHost, orderByEmail)
        .then((response: any) => {
          if (response.status === 204) {
            setErrorInput(false)
            setTitleModal('Cotação enviada por email')
            setModalSuccess(true)
          } else {
            setErrorInput(true)
          }
        })
    } else {
      setErrorInput(true)
    }
  }

  return (
    <View spacing={{ top: 8 }} style={{ textAlign: 'center' }} block>
      <InvisibleButton light onClick={() => setIsOpen(true)}>
        Enviar cotação por email
      </InvisibleButton>
      <Modal {...modalSendOrder} isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </View>
  )
}
