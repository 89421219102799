export const cellPhoneMask = '(99) 99999-9999'
export const residentialPhoneMask = '(99) 9999-99999'

export const isResidentialPhoneNumber = (phone: string) => String(phone).length === 10

export const formatPhoneNumber = (phone: string) => {
  if (isResidentialPhoneNumber(phone)) {
    return `(${phone.substring(0, 2)}) ${phone.substring(2, 6)}-${phone.substring(6, 10)}`
  } else {
    return `(${phone.substring(0, 2)}) ${phone.substring(2, 7)}-${phone.substring(7, 11)}`
  }
}

export const removePhoneMask = (phone: string) => {
  return phone ? phone.replace(/[^\d]+/g, '') : ''
}
