import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Loading } from '@tiny-fronts/basement'
import {
  Container,
  Image,
  PrimaryButton,
  Spacer,
  Text,
  Title,
  View } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import useAuth from 'hooks/useAuth'

import { useTheme } from '@material-ui/core/styles'

class ResponseError extends Error {
  response!: Response
}

export const ConsentCallback = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const { colors } = useTheme()

  const history = useHistory()

  const { token } = useAuth()

  const redirect = () => {
    history.push('/')
  }

  const queryParams = new URLSearchParams(history.location.hash)

  const code = queryParams.get('#code')
  const id_token = queryParams.get('id_token')
  const state = queryParams.get('state')

  const payload = { code, id_token, state }

  useEffect(() => {
    setLoading(true)

    fetch(`${process.env.REACT_APP_OPIN_SERVICE_URL}/consents/data-reception/callback`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload)
    })
      .then((response) => {
        if (response.status === 200) {
          return response
        } else {
          const error = new ResponseError(response.statusText)
          error.response = response
          throw error
        }
      })
      .catch(() => history.push('/erro-desconhecido'))
      .finally(() => setLoading(false))

    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'Screen_Data',
        event_type: 'new',  
        screen: {
          page_title: 'tela-compartilhamento-de-dados-feito-com-sucesso',
          page_location: window.location.href,
        },
        product: {
          flow: 'OPIN Recepção'
        }
      })
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) return <Loading />

  return (
    <>
      <Container>
        <Spacer top={8} />

        <View layout={{ alignHorizontalCenter: true, alignVerticalCenter: true }} style={{ gap: '24px' }}>
          <Image src='https://www.datocms-assets.com/16903/1612473508-illus-objects-parcerias-experiencia-digital-4x3-light.png' height={19} alt={''} />

          <View style={{ maxWidth: '468px' }}>
            <Title
              size={'default'}
              weight={'bold'}
              color={defaultTheme.colors.highlight}
              align='center'
            >
              Compartilhamento de dados feito com sucesso
            </Title>
          </View>

          <View style={{ maxWidth: '472px' }}>
            <Text
              size={'default'}
              weight={'regular'}
              color={defaultTheme.colors.primary}
            >
              Já estamos recebendo os dados da sua instituição!
            </Text>
          </View>
        </View>
      </Container>

      <View
        as='div'
        background={{ color: colors.textSidebar }}
        style={{
          boxShadow: '0px 2px 16px rgba(47, 57, 65, 0.16)',
          bottom: 0,
          padding: '25px 0px'
        }}
        size={{ width: '100%' }}
        position={{ absolute: true }}
      >
        <Container>
          <View
            style={{ flexDirection: 'row', justifyContent: 'center' }}
            spacing={{ left: 0, desktopLeft: 20, right: 0, desktopRight: 20 }}
          > 
            <PrimaryButton full onClick={redirect}>
              VOLTAR PARA A PÁGINA ÍNICIAL
            </PrimaryButton>
          </View>
        </Container>
      </View>
    </>
  )
}
