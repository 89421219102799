import React, { useState } from 'react'
import {
  Checkbox,
  Column,
  ColumnsContainer,
  Link,
  Spacer,
  Text,
  View } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import { desktopContent, mobileContent } from 'styles/ContentStyle'
import { isMobile } from 'utils/IsMobile'

import { RequestSummary } from 'components/RequestSummary'

export const Terms = ({ form, setForm }) => {
  const [acept, setAcept] = useState(false)

  return (
    <View
      style={isMobile() ? mobileContent : desktopContent}
    >
      <ColumnsContainer>
        {window.innerWidth < 900 &&
          <Column tabletSize={6}>
            <RequestSummary segment={form.segment} brand={form.brand.CustomerFriendlyName} />

            <Spacer top={4} />
          </Column>
        }

        <Column tabletSize={6}>
          <View layout={{ horizontal: true }}>
            <View  style={{
              marginLeft: -16,
              marginRight: -32,
              marginBottom: -16,
              marginTop: -32
            }}>
              <Checkbox
                label=''
                value={acept}
                onChange={(e) => {
                  setForm({ ...form, acceptTerms: !form.acceptTerms })

                  setAcept(e.target.value)
                }}
              />
            </View>

            <View style={{
              borderBottom: '1px solid #EBEDEE'
            }}>
              <Text
                size={'small'}
                weight={'regular'}
                color={defaultTheme.colors.secondary}
              >
                Declaro que li e estou de acordo com os <Link targetBlank={true} size='default' href='/termos-e-condicoes' label='Termos e Condições'/> do compartilhamento de dados
              </Text>

              <Spacer top={4} />
            </View>
          </View>
        </Column>

        {window.innerWidth > 900 &&
          <Column tabletSize={6}>
            <RequestSummary segment={form.segment} brand={form.brand.CustomerFriendlyName} />
          </Column>
        }
      </ColumnsContainer>
    </View>
  )
}
