import React from 'react'
import { Card, Message, MessageType } from '@youse-seguradora/cargo-components'
import { Consent } from 'types/Consent'
import { formatHour } from 'utils/formatHour'

import ConsentCard from 'components/ConsentCard/ConsentCard'

export default function OverviewPage({ consent }: { consent: Consent }) {
  return (
    <>
      <Message
        icon='calendar'
        title='Prazo da solicitação'
        description={`Essa solicitação deve ser confirmada até ${formatHour(consent.creationDateTime)}. Após esse prazo você deve fazer uma nova`}
        type={MessageType.warning}
      />

      <Card
        style={{
          borderRadius: 8,
          padding: 34,
          marginTop: 16,
        }}
      >
        <ConsentCard consent={consent} />
      </Card>
    </>
  )
}
