import React from 'react'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '@tiny-fronts/basement'
import {
  Icon,
  Image,
  PrimaryButtonLink,
  Spacer,
  Title,
  View,
} from '@youse-seguradora/cargo-components'

import { AuthContextType } from '../../context/AuthContext'

import { useTheme } from '@material-ui/core/styles'

export default function LoginPartnerships() {
  const { authenticated, oAuthClient } = React.useContext<AuthContextType>(AuthContext)
  const theme = useTheme()
  const history = useHistory()

  React.useEffect(() => {
    if (authenticated) {
      history.push('/inicio')
    }
  }, [authenticated, history])

  return (
    <View
      layout={{ alignHorizontalCenter: true }}
      size={{ minHeight: '100vh' }}
      spacing={{ all: 8 }}
      background={{ color: theme.palette.background.default }}
    >
      <Icon name='youse' size={8} />
      <Spacer bottom={8} />
      <Image
        height={40}
        alt='produtos youse'
        src='https://www.datocms-assets.com/16903/1632860324-home-parceria-4x3-light.svg'
      />

      <View spacing={{ top: 6, bottom: 5 }}>
        <Title size='xsmall' align='center' weight='light' color={theme.colors.primaryInverse}>
          Um canal de vendas de seguros online.
          <br />
          Com a facilidade e agilidade que só a Youse tem.
        </Title>
      </View>
      <PrimaryButtonLink target='_self' css='' href={oAuthClient.token.getUri()}>
        ACESSE SUA CONTA
      </PrimaryButtonLink>
    </View>
  )
}
