import React, { useContext } from 'react'
import { ResourceFlowContext } from '@tiny-fronts/resource-flow-components'
import { getCargoSpacingPx, Icon, Text, View } from '@youse-seguradora/cargo-components'

import { useTheme } from '@material-ui/core/styles'

const HEIGHT = 10
const HEIGHT_PX = getCargoSpacingPx(HEIGHT)

type HeaderWithPricingProps = {
  productName?: string
}

const HeaderWithPricing = ({ productName }: HeaderWithPricingProps) => {
  const context = useContext<any>(ResourceFlowContext)
  const theme = useTheme()
  return (
    <View position={{ fixed: true, top: '0', left: '0', right: '0', zIndex: 100 }}>
      <View
        background={{ color: theme.backgrounds.inverse }}
        size={{ height: HEIGHT_PX }}
        style={{ boxShadow: theme.shadow.inverse.idle }}
      >
        <View size={{ height: HEIGHT_PX }} layout={{ horizontal: true, alignVerticalCenter: true }}>
          <View spacing={{ right: 2, left: 3 }}>
            <Icon name={`icon-${context?.data?.resource?.product}`} size={5} />
          </View>
          <Text size='default' color={theme.colors.textSidebar}>
            {productName}
          </Text>
          <View position={{ absolute: true, right: '50px' }}>
            <Text size='default' weight='bold' color={theme.colors.highlightInverse}>
              {context?.data?.resource?.pricing?.monthlyTotalCost}
            </Text>
          </View>
        </View>
      </View>
    </View>
  )
}

export default HeaderWithPricing
