/* eslint-disable linebreak-style */
import { Link } from '@youse-seguradora/cargo-components'
import styled from 'styled-components'

interface LinkProps {
  contextual: boolean
  light: boolean
  dark: boolean
  height: string
}

const TableActionLink = styled(Link)<LinkProps>`
  border-bottom: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: transparent;
  font-size: 12px;
  font-weight: bold;

  &:hover, &:focus {
    background-color: transparent;
    border-bottom: none;
  }

  display: inline-block;

  letter-spacing: ${(props) => (props.contextual ? '0px' : '1px')};
  text-transform: ${(props) => (props.contextual ? 'none' : 'uppercase')};
  padding: 0;
  color: ${(props) =>
    props.light
      ? props.theme.button.invisible.light
      : props.dark
      ? props.theme.button.invisible.dark
      : props.color
      ? props.color
      : props.theme.button.invisible.default};
  height: ${(props) => props.height};

  &:after {
    top: 4px;
    left: -6px;
    right: -6px;
    bottom: 4px;
    border: none;
  }

  &:hover,
  &:focus {
    color: ${(props) =>
      props.light
        ? props.theme.button.invisible.hoverLight
        : props.dark
        ? props.theme.button.invisible.hoverDark
        : props.color
        ? props.color
        : props.theme.button.invisible.hoverDefault};
  }
  &:active {
    color: ${(props) =>
      props.light
        ? props.theme.button.invisible.activeLight
        : props.dark
        ? props.theme.button.invisible.activeDark
        : props.color
        ? props.color
        : props.theme.button.invisible.activeDefault};    
  }
  &:focus:after {
   border-color: ${(props) =>
    props.light
      ? props.theme.button.invisible.focusAfterLight
      : props.theme.button.invisible.focusAfter};
   border: none;    
  }
  &[disabled] {
    pointer-events: none;
    color: ${(props) => props.theme.button.invisible.disabled};
  }
`

export default TableActionLink
