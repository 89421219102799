import React, { useContext } from 'react'
import { ResourceFlowContext } from '@tiny-fronts/resource-flow-components'
import { Divider, View } from '@youse-seguradora/cargo-components'

import SidebarCardFooter from './SidebarCardFooter'
import SidebarCardHeader from './SidebarCardHeader'
import SidebarCardItem from './SidebarCardItem'
import SidebarCardPrice from './SidebarCardPrice'

import { useTheme } from '@material-ui/core/styles'

export type SidebarCardProps = {
  productName: string
  footerText?: any
}

const SidebarCard = ({ productName, footerText }: SidebarCardProps) => {
  const context = useContext<any>(ResourceFlowContext)
  const theme = useTheme()
  const resource = context?.data?.resource
  const coveragesCount = (resource?.coverages || []).length
  const assistancesCount = (resource?.assistances || []).length

  return (
    <View
      style={{
        padding: '15px 16px',
        backgroundColor: theme.backgrounds.inverse,
        borderRadius: '8px',
      }}
    >
      <SidebarCardHeader product={resource?.product} productName={productName} />

      <SidebarCardItem
        spacing={{ bottom: 2 }}
        label={`${coveragesCount} ${coveragesCount === 1 ? 'Cobertura' : 'Coberturas'}`}
        value={resource?.pricing?.monthlyTotalPremium}
      />

      {assistancesCount > 0 && (
        <>
          <Divider top={1} color={theme.divisors.inverse} />

          <SidebarCardItem
            spacing={{ top: 2, bottom: 2 }}
            label={`${assistancesCount} ${assistancesCount === 1 ? 'Assistência' : 'Assistências'}`}
            value={resource?.pricing?.monthlyAssistancesCost}
          />
        </>
      )}

      <SidebarCardPrice label='Total' value={resource?.pricing?.monthlyTotalCost} period=' /mês' />

      <SidebarCardFooter
        text={footerText || `Valor anual de ${resource?.pricing?.annualTotalCost}`}
      />
    </View>
  )
}

export default SidebarCard
