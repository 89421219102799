import React from 'react'

import CreditCard from './CreditCard'
import Pix from './Pix'

const PaymentMethod = ({
  paymentMethod,
  onCreditCardFulfilled,
  setIsCreditCardValid,
}: any) => {
  const render = () => {
    switch (paymentMethod) {
    case 'credit_card':
      return (
        <CreditCard
          setIsCreditCardValid={setIsCreditCardValid}
          onFulfilled={onCreditCardFulfilled}
        />
      )
    case 'pix':
      return <Pix />
    default:
      return <></>
    }
  }

  return (
    <>{render()}</>
  )
}

export default PaymentMethod
