import React, { useEffect } from 'react'
import {
  Card,
  Icon,
  Image,
  Message,
  MessageType,
  Spacer,
  Text,
  Title,
  View,
} from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import { Consent } from 'types/Consent'

const redirectToOrganization = (consent: Consent) => () =>
  (window.location.href = consent.redirectURL)

export default function Redirect({ consent }: { consent: Consent }) {
  const { organizationLogo, organizationName } = consent

  useEffect(() => {
    const timeout = setTimeout(redirectToOrganization(consent), 3000)

    return () => {
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <View style={{ maxWidth: '170px' }}>
        <Image src='https://cdn.youse.com.br/opin/images/opin-logo.svg' alt='OpenInsurance' />
      </View>

      <Spacer top={2} />

      <Title size={'xsmall'} weight={'light'} color={defaultTheme.colors.secondary}>
        Fica numa boa que este compartilhamento de dados é seguro e realizado através do Open
        Insurance.
      </Title>

      <Spacer top={4} />

      <Message
        icon='calendar'
        title='Prazo da solicitação'
        description='O prazo para que o compartilhamento dos seus dados fique ativo é de até 60 minutos.'
        type={MessageType.warning}
      />

      <Card
        style={{
          borderRadius: 8,
          padding: 34,
          marginTop: 16,
        }}
      >
        <View>
          <View
            style={{ columnGap: '48px' }}
            layout={{ alignVerticalCenter: true, horizontal: true }}
          >
            <View style={{ minWidth: '100px' }} layout={{ alignHorizontalCenter: true }}>
              <Icon name='logo-youse-grey' size={6} />
            </View>

            <Title size={'xsmall'} weight={'bold'} align='left' color={defaultTheme.colors.primary}>
              Youse Seguros
            </Title>
          </View>

          <Spacer top={5} />

          <View
            style={{ columnGap: '48px' }}
            layout={{ alignVerticalCenter: true, horizontal: true }}
          >
            <View
              style={{ minWidth: '100px', height: '16px' }}
              layout={{ alignHorizontalCenter: true }}
            >
              <Image src='https://cdn.youse.com.br/opin/images/arrow.svg' alt='arrow' />
            </View>

            <Text
              size={'small'}
              weight={'regular'}
              align='left'
              color={defaultTheme.colors.secondary}
            >
              A gente vai direcionar você para a instituição escolhida.
            </Text>
          </View>

          <Spacer top={5} />

          <View
            style={{ columnGap: '48px' }}
            layout={{ alignVerticalCenter: true, horizontal: true }}
          >
            <View style={{ maxWidth: '100px' }} layout={{ alignHorizontalCenter: true }}>
              <Image src={organizationLogo} alt={organizationName} />
            </View>

            <Title size={'xsmall'} weight={'bold'} align='left' color={defaultTheme.colors.primary}>
              {organizationName}
            </Title>
          </View>
        </View>
      </Card>

      <Spacer top={8} />

      <View layout={{ alignHorizontalCenter: true, horizontal: true }}>
        <Title size={'xsmall'} weight={'light'} align='left' color={defaultTheme.colors.secondary}>
          Só mais um pouquinho, já estamos te direcionando...
        </Title>
      </View>
    </>
  )
}
