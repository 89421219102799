import { FAQ_DATA } from './faqData'

const currentUrl = window.location.href
const endorsementUrlRegex = /\/solicitacao\/endosso\/(\d+)/
const validateIfCurrentUrlMatchs  = currentUrl.match(endorsementUrlRegex)
let protocol = ''

if (validateIfCurrentUrlMatchs ) {
  protocol = validateIfCurrentUrlMatchs[1]
}

const faq = {
  title: 'Quais dados posso endossar?',
  data: 'É possível alterar seus dados cadastrais (nome, e-mail, telefone, endereço e CEP) e também os dados do seu contrato ' +
        '(coberturas e assistências).'
}

export const endorsement = {
  title: 'Recebemos a sua solicitação de alteração',
  description: 'A alteração no contrato será feita após a validação dos dados. Entraremos em contato por telefone para confirmar.',
  image: 'https://www.datocms-assets.com/16903/1590783393-illus-characters-success-04-4x3-light.png',
  textCard: 'Alteração no contrato',
  infoCard: [
    ['Prazo de resposta', '1 dia útil'],
    ['Protocolo:', protocol]
  ],
  titleFaq: 'Ficou com alguma dúvida?',
  faqData: [faq, ...FAQ_DATA]
}
