class Cache {
  static set(key, value, ttlInMinutes) {
    const ttl = 60000 * ttlInMinutes
    const now = new Date()

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl
    }

    localStorage.setItem(key, JSON.stringify(item))
  }

  static get(key) {
    const itemStr = localStorage.getItem(key)

    if (!itemStr) return

    const item = JSON.parse(itemStr)
    const now = new Date()

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key)
      return
    }

    return item.value
  }
}

export default Cache
