export const CPFValidator = (cpfString) => {
  const strCPF = cpfString.replace(/[^a-z0-9]/gi, '')
  if (strCPF === '00000000000') return false
  let sum = 0
  for (let i = 1; i <= 9; i++) {
    sum = sum + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
  }
  let rest = (sum * 10) % 11
  if (Number(rest) === 10 || Number(rest) === 11) rest = 0
  if (rest !== parseInt(strCPF.substring(9, 10))) return false
  sum = 0
  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
  }
  rest = (sum * 10) % 11
  if (Number(rest) === 10 || Number(rest) === 11) rest = 0
  if (rest !== parseInt(strCPF.substring(10, 11))) return false

  return true
}
