import React, { useState } from 'react'
import Card from '@material-ui/core/Card'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  Collapsable,
  Hidden,
  Spacer,
  Text,
  View,
  Visible,
} from '@youse-seguradora/cargo-components'

import { useTheme } from '@material-ui/core/styles'

const CoverageItem = ({ data }: any) => {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <>
      <button
        style={{
          border: 'none',
          textAlign: 'left',
          background: 'none',
          cursor: 'pointer',
          padding: '0',
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Card
          elevation={2}
          style={{
            borderRadius: '3px',
            boxShadow: 'none',
            border: '1px solid #E9EAEB',
          }}
        >
          <View
            layout={{ tabletHorizontal: true, tabletSpread: true }}
            spacing={{ vertical: 2, horizontal: 3 }}
          >
            <View layout={{ horizontal: true }}>
              <View spacing={{ left: 2, right: 4 }}>
                <img
                  src={`https://cdn.youse.com.br/app/images/${data.uid}.png`}
                  alt='cobertura'
                  width='80px'
                />
              </View>
              <View layout={{ horizontal: false }}>
                <Text size='big' weight='bold' color={theme.colors.primary}>
                  {data.name}
                </Text>
                <View layout={{ horizontal: true, alignVerticalCenter: true }}>
                  {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  <Text size='default' color={theme.colors.primary}>
                    Detalhes da cobertura
                  </Text>
                </View>
              </View>
            </View>
            <Visible desktop={true}>
              <Text size='xsmall' color={theme.colors.highlight}>
                {data.maximumIndemnityLimit}
              </Text>
            </Visible>
          </View>

          <Collapsable
            isOpen={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
          >
            <View
              layout={{ horizontal: false, tabletSpread: true }}
              spacing={{ bottom: 4, horizontal: 3 }}
            >
              <Hidden desktop={true}>
                <Text size='xsmall' color={theme.colors.highlight}>
                  {data.maximumIndemnityLimit}
                </Text>
              </Hidden>
              {data.deductibleValue !== null && (
                <View layout={{ horizontal: true }}>
                  <Text size='small' weight='bold' color={theme.colors.primary}>
                    Franquia:
                  </Text>
                  <View spacing={{ left: 2, bottom: 2 }}>
                    <Text size='small' weight='bold' color={theme.colors.highlight}>
                      {data.deductibleValue}
                    </Text>
                  </View>
                </View>
              )}
              <Text size='small' color={theme.colors.primary} markdown={data.description} />
            </View>
          </Collapsable>
        </Card>
      </button>
      <Spacer top={4} />
    </>
  )
}

export default CoverageItem
