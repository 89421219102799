import React from 'react'
import { useTheme } from '@material-ui/core'
import { EmptyState, Title, View } from '@youse-seguradora/cargo-components'

type WithoutPermissionProps = {
  titlePage: string
  titleMessage: string
  descriptionMessage: string
}

const WithoutPermission = ({ titlePage, titleMessage, descriptionMessage }: WithoutPermissionProps) => {
  const theme = useTheme()

  return (
    <>
      <View spacing={{ top: 6, bottom: 5 }}>
        <Title color={theme.colors.primary} size='small'>
          {titlePage}
        </Title>
      </View>
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <EmptyState
          icon='locked'
          title={titleMessage}
          description={descriptionMessage}
        />
      </View>
    </>
  )
}

export default WithoutPermission
