export const hasUpperAndLowerCase = (value = '') => {
  const upperCase = /[A-Z]/g
  const lowerCase = /[a-z]/g

  return upperCase.test(value) && lowerCase.test(value)
}

export const hasNumber = (value: string) => {
  const number = /[0-9]/g

  return number.test(value)
}

export const hasSpecialCharacter = (value: string) => {
  // eslint-disable-next-line no-useless-escape
  const specialCharacter = /[!@#\$%\^\&*\)\(+=._-]/g

  return specialCharacter.test(value)
}
