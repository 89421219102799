export const routePermissions = {
  cotacoes: {
    company: ['conversao_auto', 'conversao_home', 'conversao_life'],
    user: ['cotacoes_auto', 'cotacoes_vida', 'cotacoes_residencial'],
  },
  apolices: {
    company: ['pos_venda'],
    user: ['apolices'],
  },
  vistorias: {
    company: ['vistorias'],
    user: ['vistoria'],
  },
  sinistro: {
    company: ['sinistro'],
    user: ['sinistro'],
  },
  gestao: {
    company: ['dados_parceiro'],
    user: ['gestao', 'notas_fiscais'],
  },
  corretoras: {
    company: ['dados_parceiro'],
    user: ['gestao'],
  },
  dados_fiscais: {
    company: ['dados_parceiro'],
    user: ['notas_fiscais'],
  },
  notas_fiscais: {
    company: ['dados_parceiro'],
    user: ['notas_fiscais'],
  },
  relatorios: {
    company: ['dados_parceiro'],
    user: ['gestao'],
  },
  configuracoes: {
    company: ['dados_parceiro'],
    user: ['configuracoes', 'configuracoes_avancadas', 'tecnicas'],
  },
  configuracoes_avancadas: {
    company: ['dados_parceiro'],
    user: ['configuracoes_avancadas'],
  },
  cadastrar_usuario: {
    company: ['dados_parceiro'],
    user: ['configuracoes'],
  },
  usuarios_cadastrados: {
    company: ['dados_parceiro'],
    user: ['configuracoes'],
  },
  perfis_de_acesso: {
    company: ['dados_parceiro'],
    user: ['configuracoes_avancadas'],
  },
  tecnicas: {
    company: ['dados_parceiro'],
    user: ['tecnicas'],
  },
  endosso: {
    company: ['pos_venda'],
    user: ['endosso'],
  },
  assistencias: {
    company: ['pos_venda'],
    user: ['assistencias'],
  },
  cancelamento: {
    company: ['pos_venda'],
    user: ['cancelamento'],
  },
  organizacoes: {
    company: ['dados_parceiro'],
    user: ['configuracoes_avancadas', 'configuracoes'],
  }
}
