import React from 'react'
import {
  Divider,
  Message,
  MessageType,
  PrimaryButton,
  Spacer,
  Text,
  Title,
  View,
} from '@youse-seguradora/cargo-components'

import { useTheme } from '@material-ui/core/styles'

type PixTutorialProps = {
  setPixTutorialStep: React.Dispatch<React.SetStateAction<boolean>>
  setPixWarningStep: React.Dispatch<React.SetStateAction<boolean>>
}

const PixTutorial = ({ setPixTutorialStep, setPixWarningStep }: PixTutorialProps) => {
  const theme = useTheme()

  const handleClick = () => {
    setPixTutorialStep(false)
    setPixWarningStep(true)
  }
  return (
    <>
      <View
        spacing={{ top: 4 }}
        style={{ padding: '26px' }}
        layout={{ alignHorizontalCenter: true }}
      >
        <Title size='xsmall' align='center' color={theme.colors.highlight}>
          Como fazer o pagamento com o Pix?
        </Title>
        <Spacer top={3} />
        <Text align='center' weight='bold' size='small' color={theme.colors.secondary}>
          Se você tem uma chave do Pix cadastrada, confira o passo a passo:
        </Text>
        <Spacer top={3} />
      </View>
      <View spacing={{ top: 4 }} style={{ padding: '26px' }}>
        <Text align='left' size='small' color={theme.colors.secondary}>
          <Text as='span' weight='bold' size='small'>
            1.
          </Text>{' '}
          Gere seu código Pix aqui na Youse;
        </Text>
        <Text align='left' size='small' color={theme.colors.secondary}>
          <Text as='span' weight='bold' size='small'>
            2.
          </Text>{' '}
          Abra o aplicativo do seu banco e acesse a seção Pix;
        </Text>
        <Text align='left' size='small' color={theme.colors.secondary}>
          <Text as='span' weight='bold' size='small'>
            3.
          </Text>{' '}
          Selecione a opção para fazer o pagamento;
        </Text>
        <Text align='left' size='small' color={theme.colors.secondary}>
          <Text as='span' weight='bold' size='small'>
            4.
          </Text>{' '}
          No aplicativo do banco, leia o QR Code com a câmera ou use o código chave.
        </Text>
      </View>
      <Spacer top={4} />
      <View style={{ padding: '26px' }}>
        <Message
          icon='info'
          title='Importante:'
          description='Depois de pagar volte aqui para essa página, confirme o seu e-mail e finalize sua compra'
          type={MessageType.highlight}
        />
      </View>
      <Spacer top={4} />
      <Divider top={1} color={theme.divisors.standard} />
      <Spacer top={4} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <PrimaryButton children='Pagar com PIX' onClick={handleClick} />
      </div>
      <Spacer top={4} />
    </>
  )
}

export default PixTutorial
