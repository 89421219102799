import React from 'react'
import { SectionColor } from '@youse-seguradora/cargo-components'

import Hero from '../Hero/Hero'
import { AppYouse, Help, YouseFriends } from '../SectionsDefault'

type ProposalProps = {
  productName: string
  data?: any
}

export default function Proposal({ data, productName }: ProposalProps) {
  return (
    <>
      <Hero data={data} isProposal={true} productName={productName} />

      <AppYouse />

      {data.subproduct === 'default' && (
        <>
          <SectionColor
            title='Conheça benefícios que você já pode começar a usar'
            variant='offwhite [highlight]'
          />
          <YouseFriends />
        </>
      )}

      <SectionColor
        title='Pronto! Se estiver tudo certinho, vc já pode dirigir por aí numa boa ;)'
        variant='offwhite [highlight]'
        ctaLabel='[APPSTORES]'
        ctaLink='#'
      />

      <Help />
    </>
  )
}
