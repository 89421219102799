/* eslint-disable no-useless-escape */
export const replaceAllObjKeys = (objectData, setTransformKey) => {
  if (Array.isArray(objectData))
    objectData.forEach((value => replaceAllObjKeys(value, setTransformKey)))
  else if (typeof objectData === 'object' && objectData !== null) {
    Object.keys(objectData).forEach((key) => {
      const transformedKey = setTransformKey(key)

      objectData[transformedKey] = objectData[key]

      if (key !== transformedKey) delete objectData[key]

      replaceAllObjKeys(objectData[transformedKey], setTransformKey)
    })
  }
  return objectData
}

export const snakeToCamel = (str: string): string => str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase())

export const camelToSnake = (str: string): string => str.replace(/([A-Z])/g, '_$1').toLowerCase()

export const decamelizeKeys = <D>(obj): D => replaceAllObjKeys(obj, camelToSnake)

export const camelizeKeys = <D>(obj): D => replaceAllObjKeys(obj, snakeToCamel)

export const slugifyWithRange = (text: string, range = [0, 2]) => {
  return text
    .split(' ')
    .slice(range[0], range[1])
    .join(' ')
    .toString()
    .normalize('NFKD')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '_')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '_')
}

export const slugify = (text: string) => {
  return text.toString()
    .normalize('NFKD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')
}
