import React, { useContext } from 'react'
import InputMask from 'react-input-mask'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Input from '@material-ui/core/Input'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import {
  Container,
  getCargoSpacingPx,
  InvisibleButton,
  Modal,
  PrimaryButton,
  Spacer,
  Text,
  Title,
  View,
} from '@youse-seguradora/cargo-components'

import BFFClient from '../../clients/BFFClient'
import { ResourceFlowContext } from '../../contexts/ResourceFlowContext'
import { pathName } from '../../helpers/PathName'
import { inputStyles } from '../styles/inputStyles'

import { useTheme } from '@material-ui/core/styles'

const HEIGHT = 8
const HEIGHT_PX = getCargoSpacingPx(HEIGHT)
const TABLET_HEIGHT = 10
const TABLET_HEIGHT_PX = getCargoSpacingPx(TABLET_HEIGHT)

const bffClient = new BFFClient()

export function Impersonate() {
  const theme = useTheme()
  const classes = inputStyles()
  const context = useContext<any>(ResourceFlowContext)
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [orderByEmail, setOrderByEmail] = React.useState<string>('')
  const [orderBySMS, setOrderBySMS] = React.useState<string>('')
  const [notifyOrder, setNotifyOrder] = React.useState<string>('email')
  const [errorInput, setErrorInput] = React.useState<boolean>(false)
  const [titleModal, setTitleModal] = React.useState('Enviar Cotação')
  const [modalSuccess, setModalSuccess] = React.useState<boolean>(false)
  const product = context.data.resource.product
  const resourceFlowId = context.data.id

  const applicationHost = `${window.location.origin}/${pathName(context)}`

  const modalSendOrder = {
    title: titleModal,
    content: modalSuccess ? (
      <>
        <View layout={{ alignHorizontalCenter: true }}>
          <Title size='xsmall' align='center' color={theme.colors.success}>
            Cotação enviada com sucesso!
          </Title>
        </View>
        <View spacing={{ top: 4 }} />
        <Text size='small' align='center'>
          A cotação foi enviada para{' '}
          <b>
            {orderByEmail} {orderBySMS}
          </b>
        </Text>
      </>
    ) : (
      <>
        <Title size='xsmall' align='center' color={theme.colors.highlight}>
          Gostaria de enviar a cotação <br /> por qual canal?
        </Title>
        <View spacing={{ top: 4 }} />
        <RadioGroup
          row
          defaultValue={notifyOrder}
          onChange={(e: any) => setNotifyOrder(e.target.value)}
        >
          <FormControlLabel
            value='email'
            control={
              <Radio
                classes={{
                  root: classes.radio,
                  checked: classes.checked,
                }}
              />
            }
            label='Email'
          />
          <FormControlLabel
            value='sms'
            control={
              <Radio
                classes={{
                  root: classes.radio,
                  checked: classes.checked,
                }}
              />
            }
            label='SMS'
          />
        </RadioGroup>
        <Spacer top={4} />
        {notifyOrder === 'email' ? (
          <Input
            style={{ width: '300px' }}
            className={classes.inputMaterial}
            id='insuredPersonEmail'
            autoComplete='nope'
            defaultValue={orderByEmail}
            onBlur={(e: any) => setOrderByEmail(e.target.value)}
            onChange={(e: any) => setOrderByEmail(e.target.value)}
            type='email'
            placeholder='Email'
            error={errorInput ? true : false}
          />
        ) : (
          <InputMask
            style={{ width: '300px' }}
            className={classes.inputMaterial}
            id='insuredPersonPhone'
            autoComplete='nope'
            defaultValue={orderBySMS}
            onBlur={(e: any) => setOrderBySMS(e.target.value)}
            onChange={(e: any) => setOrderBySMS(e.target.value)}
            type='tel'
            mask='(99) 99999-9999'
            placeholder='SMS'
          >
            {(inputProps) => <Input error={errorInput ? true : false} {...inputProps} />}
          </InputMask>
        )}
      </>
    ),
    footer: modalSuccess ? (
      <InvisibleButton onClick={() => handleSendAnotherOrder()}>Enviar outro</InvisibleButton>
    ) : (
      <PrimaryButton onClick={() => handleSubmit()}>Enviar</PrimaryButton>
    ),
  }

  const handleSubmit = () => {
    if (orderByEmail !== '') {
      bffClient
        .sendOrderByEmail(product, resourceFlowId, applicationHost, orderByEmail)
        .then((response) => {
          if (response.status === 204) {
            setErrorInput(false)
            setTitleModal('Cotação enviada por email')
            setModalSuccess(true)
          } else {
            setErrorInput(true)
          }
        })
    } else if (orderBySMS !== '') {
      bffClient
        .sendOrderBySMS(product, resourceFlowId, applicationHost, orderBySMS)
        .then((response) => {
          if (response.status === 204) {
            setErrorInput(false)
            setTitleModal('Cotação enviada por SMS')
            setModalSuccess(true)
          } else {
            setErrorInput(true)
          }
        })
    } else {
      setErrorInput(true)
    }
  }

  const handleSendAnotherOrder = () => {
    setTitleModal('Enviar cotação')
    setModalSuccess(false)
    setErrorInput(false)
    setOrderByEmail('')
    setOrderBySMS('')
  }

  return (
    <View
      layout={{
        horizontal: true,
        alignHorizontalCenter: true,
        alignVerticalCenter: true,
      }}
    >
      <View size={{ width: '320px' }}>
        <PrimaryButton onClick={() => setIsOpen(true)}>
          Enviar cotação por email ou sms
        </PrimaryButton>
        <Modal {...modalSendOrder} isOpen={isOpen} onClose={() => setIsOpen(false)} />
      </View>
    </View>
  )
}

export function HeaderImpersonate({ attendant }) {
  const theme = useTheme()
  const context = useContext<any>(ResourceFlowContext)
  const currentSection = context.data.currentSection

  const DefaultHeader = () => {
    return (
      <Container>
        <View
          size={{ height: HEIGHT_PX, tabletHeight: TABLET_HEIGHT_PX }}
          layout={{
            alignVerticalCenter: true,
            alignHorizontalCenter: true,
          }}
        >
          <View layout={{ horizontal: true }}>
            <Text size='small'>
              Está cotação está registrada para <b>{attendant.name}</b> ({attendant.id})
            </Text>
          </View>
        </View>
      </Container>
    )
  }

  return currentSection === 'checkout' ||
    currentSection === 'assistances_selection' ||
    currentSection === 'coverages_selection' ? (
      <View position={{ relative: true, zIndex: 100 }}>
        <View
          background={{
            color: theme.colors.disable,
          }}
          size={{
            height: HEIGHT_PX,
            tabletHeight: TABLET_HEIGHT_PX,
            width: '66.5%',
          }}
          style={{ boxShadow: theme.shadow.inverse.idle }}
        >
          {DefaultHeader()}
        </View>
      </View>
  ) : (
    <View position={{ sticky: true, top: '0', zIndex: 100 }}>
      <View
        background={{
          color: theme.colors.disable,
        }}
        size={{ height: HEIGHT_PX, tabletHeight: TABLET_HEIGHT_PX }}
        style={{ boxShadow: theme.shadow.inverse.idle }}
      >
        {DefaultHeader()}
      </View>
    </View>
  )
}
