import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Container,
  Image,
  InvisibleButton,
  Spacer,
  Text,
  Title,
  View } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'

export const PoliciesNotFound = () => {
  const history = useHistory()

  const redirect = () => {
    history.push('/')
  }

  return (
    <>
      <Container>
        <Spacer top={8} />

        <View layout={{ alignHorizontalCenter: true, alignVerticalCenter: true }} style={{ gap: '24px' }}>
          <Image src='https://www.datocms-assets.com/16903/1590782515-illus-characters-attention-03-4x3-light.png' height={19} alt={''} />

          <Title
            size={'default'}
            weight={'bold'}
            align={window.screen.width > 551 ? 'center' : 'left'}
            color={defaultTheme.colors.primary}
          >
            Nenhuma apólice encontrada
          </Title>

          <View style={{ maxWidth: '472px' }}>
            <Text
              size={'default'}
              align={window.screen.width > 551 ? 'center' : 'left'}
              weight={'regular'}
              color={defaultTheme.colors.primary}
            >
              Parece que o CPF informado não possui nenhuma apólice cadastrada. Entre em contato com a sua seguradora para mais detalhes.
            </Text>
          </View>

          <InvisibleButton onClick={redirect}>
            Voltar para página inicial
          </InvisibleButton>
        </View>
      </Container>
    </>
  )
}
