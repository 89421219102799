import { gql } from '@apollo/client'

export const GET_ZIPCODE_ADDRESS = gql`
  query GetZipcodeAddress($zipcode: String!) {
    zipcodeAddress(zipcode: $zipcode) {
      street
      complement
      neighborhood
      city
      state
      longitude
      latitude
    }
  }
`
