import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { GenericError } from '@tiny-fronts/basement'
import { Container } from '@youse-seguradora/cargo-components'

type ErrorComponentProps = {
  // TODO remove any

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: any
  children: ReactElement
}

function GenericErrorHandler({ data, errors, children }: ErrorComponentProps) {
  const history = useHistory()

  const error = errors?.map((error) =>
    error.messages.map((message: { description: string }) => message.description),
  )
  const errorRequestAssistance = [].concat(...[error])
  const PREVIOUS_PAGE = -1
  const formattedError = () => {
    return errorRequestAssistance.join(' ').replace('Requisição não criada.', '')
  }

  if (data) {
    if (data.success === false) {
      return (
        <Container>
          <GenericError
            body={errorRequestAssistance.length > 0 && formattedError()}
            title='Opa!'
            ctaLabel='Voltar'
            ctaOnClick={() => history.go(PREVIOUS_PAGE)}
          />
        </Container>
      )
    } else {
      return children
    }
  } else
    return (
      <Container>
        <GenericError />
      </Container>
    )
}

export default GenericErrorHandler
