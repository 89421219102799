import React from 'react'
import { 
  Card, 
  Column, 
  ColumnsContainer, 
  Spacer, 
  Text, 
  Title } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import { Consent } from 'types/Consent'

export default function ConfirmEndorsementPage({ consent }: { consent: Consent }) {
  return (
    <>
      <Card
        style={{
          borderRadius: 8,
          padding: 34,
          marginTop: 16,
        }}
      >
        <ColumnsContainer style={{ rowGap: 21 }}>
          <Column tabletSize={5}>
            <Text size={'small'} weight={'regular'} color={defaultTheme.colors.secondary}>
              Sociedade receptora
            </Text>
            <Text size={'default'} weight={'regular'} color={defaultTheme.colors.primary}>
              {consent.organizationName}
            </Text>
          </Column>

          <Column tabletSize={5}>
            <Text size={'small'} weight={'regular'} color={defaultTheme.colors.secondary}>
              Identificação do cliente
            </Text>
            <Text size={'default'} weight={'regular'} color={defaultTheme.colors.primary}>
              {consent.user.document_number}
            </Text>
          </Column>

          <Column tabletSize={2}>
            <img style={{ width: '100px' }} src={consent.organizationLogo} alt={consent.organizationName} />
          </Column>
        </ColumnsContainer>
      </Card>

      <Spacer top={6} />

      <Card
        style={{
          borderRadius: 8,
          padding: 34,
          marginTop: 16,
        }}
      >
        <Title size={'xsmall'} weight={'bold'} color={defaultTheme.colors.primary}>
          Dados da solicitação
        </Title>       

        <Spacer top={4} />

        <ColumnsContainer style={{ rowGap: 21 }}>
          <Column tabletSize={5}>
            <Text size={'small'} weight={'regular'} color={defaultTheme.colors.secondary}>
              Número da apólice
            </Text>
            <Text size={'default'} weight={'regular'} color={defaultTheme.colors.primary}>
              {
                consent.segment === 'ENDOSSO' ?
                consent['endorsementInformation'].policyNumber :
                consent['claimNotificationInformation'].policyNumber
              }              
            </Text>            
          </Column>          

          <Column tabletSize={5}>
            <Text size={'small'} weight={'regular'} color={defaultTheme.colors.secondary}>
              {
                consent.segment === 'ENDOSSO' ?
                'Tipo de endosso' : 
                'Data e hora do sinistro'
              }              
            </Text>
            <Text size={'default'} weight={'regular'} color={defaultTheme.colors.primary}>
              {
                consent.segment === 'ENDOSSO' ?
                consent['endorsementInformation'].endorsementType :
                consent['claimNotificationInformation'].occurrenceDate
              }
            </Text>
          </Column>

          <Column tabletSize={2}>
            <Text size={'small'} weight={'regular'} color={defaultTheme.colors.secondary}>
              Descrição da solicitação              
            </Text>
            <Text size={'default'} weight={'regular'} color={defaultTheme.colors.primary}>
              {
                consent.segment === 'ENDOSSO' ?
                consent['endorsementInformation'].requestDescription :
                consent['claimNotificationInformation'].occurrenceDescription
              }
            </Text>
          </Column>
        </ColumnsContainer>
      </Card>
    </>
  )
}
