import React from 'react'
import { Text } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'

// eslint-disable-next-line react/prop-types
export default function PermissionItem({ item }) {
  return (
    <div style={{ paddingLeft: -16, marginLeft: -16 }}>
      <Text size={'small'} weight={'regular'} color={defaultTheme.colors.secondary}>
        &#8226; {item}
      </Text>
    </div>
  )
}
