export const formatHour = (timestamp: string): string => {
  const dateUTC = new Date(timestamp)
  dateUTC.setHours(dateUTC.getHours() + 1)

  const optionsDate: Intl.DateTimeFormatOptions = {
    timeZone: 'America/Sao_Paulo',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }

  const optionsHour: Intl.DateTimeFormatOptions = {
    timeZone: 'America/Sao_Paulo',
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  }

  const date = dateUTC.toLocaleDateString('pt-BR', optionsDate)
  const hour = dateUTC.toLocaleTimeString('pt-BR', optionsHour)

  return `${date} às ${hour}`
}
