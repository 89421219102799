import React from 'react'
import {
  Image,
  InvisibleButton,
  Modal,
  Spacer,
  Text,
  Title,
  View } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'

export const RegisterError = ({ open, setOpen, setStep, setError, error }) => {
  const content = (
    <>
      <View layout={{ alignHorizontalCenter: true, alignVerticalCenter: true }} spacing={{ bottom: 2 }}>
        <Image alt='cellphone' src='https://www.datocms-assets.com/16903/1590782860-illus-characters-confused-03-4x3-light.png' height={14} />

        <Title
          size={'small'}
          weight={'bold'}
          color={defaultTheme.colors.highlight}
          align={window.screen.width > 551 ? 'center' : 'left'}
        >
          E-mail já cadastrado
        </Title>

        <Spacer top={4} />

        <Text
          size={'default'}
          weight={'regular'}
          color={defaultTheme.colors.secondary}
          align={window.screen.width > 551 ? 'center' : 'left'}
        >
          Parece que já existe uma conta com esse e-mail. Se você não lembra da sua senha, você pode solicitar a recuperação, blz?
        </Text>

        <Spacer top={4} />

        <InvisibleButton contextual onClick={() => {
          setStep('1')

          setError({ ...error, email: { isError: true, message: 'Parece que esse e-mail já está cadastrado. Bora tentar entrar na sua conta?' } })

          setOpen(false)
        }}>
          OK, ENTENDI
        </InvisibleButton>
      </View>
    </>
  )

  const modalCancel = {
    content: content,
  }

  return (
    <Modal
      {...modalCancel}
      hiddenOverflow={false}
      isOpen={open}
      onClose={() => {
        setOpen(false)
      }}
    />
  )
} 
