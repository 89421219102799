import React, { useContext } from 'react'
import { Column, ColumnsContainer, PaymentSelector, View } from '@youse-seguradora/cargo-components'

import { ResourceFlowContext } from '../../../../contexts/ResourceFlowContext'

const PaymentConditionItem = ({
  item,
  changeSelectedCondition,
  isSelectionDisabled,
  dataTestId,
  productName
}: any) => {
  const context = useContext<any>(ResourceFlowContext)
  const variation = context.data.variation

  return (
    <ColumnsContainer>
      <Column desktopSize={8} desktopOffset={2}>
        <View spacing={{ bottom: 2 }} layout={{ alignHorizontalCenter: true }}>
          <PaymentSelector
            dataTestId={dataTestId}
            checked={item.selected}
            title={item.name}
            installmentsFrequency={item.installmentsFrequency}
            description={item.description}
            pricingCost={
              item.installmentsFrequency === 'monthly'
                ? item.pricing.monthlyTotalCost
                : item.pricing.annualTotalCost
            }
            totalCost={
              variation === 'mobile'
                ? `${item.pricing.annualTotalCost} /ano`
                : item.pricing.totalCost
            }
            discountInPercent={
              item.discountInPercent === 0
                ? 'Sem juros'
                : productName === 'Seguro Residencial'
                ? `Até ${item.discountInPercent}% OFF`
                : `${item.discountInPercent}% de desconto`
            }
            disabled={isSelectionDisabled}
            onChange={() => changeSelectedCondition(item)}
          />
        </View>
      </Column>
    </ColumnsContainer>
  )
}

export default PaymentConditionItem
