import React from 'react'
import { Image, View } from '@youse-seguradora/cargo-components'

import Loading from '../../image/generic-loading.gif'

export default function GenericLoading() {
  return (
    <View
      size={{ height: '100vh' }}
      layout={{ alignHorizontalCenter: true, alignVerticalCenter: true }}
    >
      <Image alt='Carregando...' src={Loading} height={16} />
    </View>
  )
}
