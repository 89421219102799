import React, { useState } from 'react'
import { Card, Column, ColumnsContainer, Spacer, Title } from '@youse-seguradora/cargo-components'

import PixQrCode from './PixSteps/PixQrCode'
import PixTutorial from './PixSteps/PixTutorial'
import PixWarning from './PixSteps/PixWarning'

import { useTheme } from '@material-ui/core/styles'

const Pix = () => {
  const theme = useTheme()

  const [pixTutorialStep, setPixTutorialStep] = useState<boolean>(true)
  const [pixWarningStep, setPixWarningStep] = useState<boolean>(false)
  const [pixQrCodeStep, setPixQrCodeStep] = useState<boolean>(false)

  return (
    <>
      <Title size='xsmall' align='center' color={theme.colors.primary}>
        Pague com o Pix
      </Title>

      <Spacer top={4} />

      <ColumnsContainer>
        <Column desktopSize={6} desktopOffset={3}>
          <Card
            style={{
              borderRadius: '12px',
              border: '4px solid #E9EAEB',
              boxShadow: 'none',
            }}
          >
            {pixTutorialStep && (
              <PixTutorial
                setPixTutorialStep={setPixTutorialStep}
                setPixWarningStep={setPixWarningStep}
              />
            )}
            {pixWarningStep && (
              <PixWarning
                setPixTutorialStep={setPixTutorialStep}
                setPixWarningStep={setPixWarningStep}
                setPixQrCodeStep={setPixQrCodeStep}
              />
            )}
            {pixQrCodeStep && (
              <PixQrCode
                setPixTutorialStep={setPixTutorialStep}
                setPixQrCodeStep={setPixQrCodeStep}
              />
            )}
          </Card>
        </Column>
      </ColumnsContainer>
    </>
  )
}

export default Pix
