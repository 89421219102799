import { decamelizeKeys as humpsDecamelizeKeys } from 'humps'
import mapValues from 'lodash/mapValues'

export const nullResolver = (object: object) => {
  return mapValues(object, (value) => (value === null ? '' : value))
}

export const undefinedResolver = (object: object) => {
  return JSON.parse(JSON.stringify(object))
}

export const decamelizeKeys = (object: object) => {
  return humpsDecamelizeKeys(object)
}
