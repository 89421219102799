import React, { useContext, useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { designTokens, View } from '@youse-seguradora/cargo-components'

import DataLayerClient from '../../clients/DataLayerClient'
import { ResourceFlowContext } from '../../contexts/ResourceFlowContext'
import SeoInterface from '../../interfaces/SeoInterface'
import Footer from '../Footer'
import Header from '../Header'

type LayoutBaseProps = {
  success?: boolean
  children?: React.ReactNode
  seo: SeoInterface
  fixed?: boolean
  icons?: string[] | any
  iconsActive?: boolean
  customFooter?: React.ReactNode
}

const LayoutBase = ({
  success,
  seo,
  children,
  fixed,
  icons,
  iconsActive,
  customFooter,
}: LayoutBaseProps) => {
  const { title } = seo
  const context = useContext<any>(ResourceFlowContext)

  useEffect(() => {
    new DataLayerClient().trigger(title)
    new DataLayerClient().triggerOrder(
      context?.data?.resourceId,
      context?.data?.resource?.salesChannel,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <View background={{ color: designTokens.colors.grey000 }} style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <Header icons={iconsActive ? icons : ['youse']} fixed={fixed} success={success} />

        <View style={{ flexGrow: 1 }}>
          {children}
        </View>
        
        {customFooter ? customFooter : <Footer />}
      </View>
    </HelmetProvider>
  )
}

export default LayoutBase
