import React from 'react'
import { Theme } from '@material-ui/core'
import { designTokens, Icon, Spacer, Table, Text, View } from '@youse-seguradora/cargo-components'

import { INSTALLMENTS_TABLE_HEAD, REFUND_INSTALLMENTS_TABLE_HEAD } from '../../helpers/constants'
import { formatDate } from '../../helpers/date'
import { compareTranslation } from '../../helpers/functions'
import { installmentStatuses } from '../../helpers/translationType'

export type PaymentAPIProps = {
  number: number | string
  dueAt: string
  amount: number
  status: string | React.ReactNode
  paymentMethodName?: string | null | React.ReactNode
}

type PaymentProps = {
  number: number | string
  dueAt: string
  amount: string
  status: string | React.ReactNode
  paymentMethodName?: string | null | React.ReactNode
}

export type InstallmentsProps = PaymentProps[]

const PaymentStatuses = (payment: PaymentAPIProps, theme: Theme) => {
  return (
    <View layout={{ horizontal: true, alignVerticalCenter: true }}>
      <Text size='small' color={theme.colors.primary}>
        {compareTranslation(installmentStatuses, payment.status)}
      </Text>
      <Spacer right={2} />
      {payment.status === 'PAID' ? (
        <Icon name='check-circle' size={4} color={theme.backgrounds.success} />
      ) : payment.status === 'BILLED' || payment.status === 'PENDING' ? (
        <Icon name='builder' size={4} color={theme.colors.secondary} />
      ) : (
        <Icon name='error' size={4} color={theme.backgrounds.error} />
      )}
    </View>
  )
}

export const installmentsAttributes = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paymentPlan: any,
  installments: PaymentProps[],
  theme: Theme,
) =>
  paymentPlan &&
  paymentPlan.installments.map((payment: PaymentAPIProps) =>
    installments.push({
      number: payment.number,
      dueAt: formatDate(payment.dueAt),
      amount: `R$ ${payment.amount.toFixed(2).toString().replace('.', ',')}`,
      status: PaymentStatuses(payment, theme),
      paymentMethodName: payment.paymentMethodName || <Text size='small' color={designTokens.colors.grey100}>Cobrança não realizada</Text>
    }),
  )

export const refundInstallmentsAttributes = (
  paymentPlan,
  refundInstallments: PaymentProps[],
  theme,
) =>
  paymentPlan &&
  paymentPlan.refundInstallments.length !== 0 &&
  paymentPlan.refundInstallments.map((payment: PaymentAPIProps) =>
    refundInstallments.push({
      number: payment.number,
      dueAt: formatDate(payment.dueAt),
      amount: `R$ ${payment.amount.toFixed(2).toString().replace('.', ',')}`,
      status: PaymentStatuses(payment, theme)
    }),
  )

export const PaymentTable = (payment: PaymentProps[]) => (
  <Table
    head={{
      row: { borderless: true },
      cellHead: payment[0].paymentMethodName ? INSTALLMENTS_TABLE_HEAD : REFUND_INSTALLMENTS_TABLE_HEAD,
    }}
    body={{ infoList: payment }}
  />
)
