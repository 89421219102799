export const screenPermissions = {
  cotacoes: {
    aba_auto: {
      company: ['conversao_auto'],
      user: ['cotacoes_auto'],
    },
    aba_residencial: {
      company: ['conversao_home'],
      user: ['cotacoes_residencial'],
    },
    aba_vida: {
      company: ['conversao_life'],
      user: ['cotacoes_vida'],
    },
  },
  apolices: {
    botao_cancelar_apolice: {
      company: ['pos_venda'],
      user: ['cancelamento'],
    },
    botao_editar_apolice: {
      company: ['pos_venda'],
      user: ['endosso'],
    },
    botao_abrir_sinistro: {
      company: ['pos_venda', 'sinistro'],
      user: ['sinistro'],
    },
    botao_acionar_assistencia: {
      company: ['pos_venda', 'assistencia'],
      user: ['assistencia'],
    }
  },
}
