import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { AuthContext, useLocalStorage } from '@tiny-fronts/basement'

import LayoutBase from 'components/LayoutBase'

export default function PrivateRoute({ children, title, ...rest }) {
  const { authenticated } = React.useContext<any>(AuthContext)
  const [, setCurrenLocation] = useLocalStorage('current_location')

  React.useEffect(() => {
    if (!authenticated) {
      setCurrenLocation(`${rest.location.pathname}${rest.location.search}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated])

  if (authenticated) {
    return (
      <Route {...rest}>
        <LayoutBase title={title} children={children} />
      </Route>
    )
  } else {
    return <Redirect to='/oauth' />
  }
}
