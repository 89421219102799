import { ViewerType } from '../../types/types'

export function policyDataLayer(policy: string, auth: boolean, viewer: ViewerType, flow?: string) {
  const location = 'portal-parceiros-apolices-historico'
  const product = {
    flow: flow?.toLowerCase(),
    subflow: 'web-portal-parceiros-apolices-historico'
  }
  const screen_name = 'apólices'

  const defaultData = {
    user: {
      client_id: process.env.REACT_APP_ACCOUNT_SERVICE_CLIENT_ID,
      order_id: viewer?.user?.id,
      sales_channel: 'digital',
      logged_user: Boolean(auth)
    },
    debug: {
      gtm_id: process.env.REACT_APP_GTM_ADM,
    }
  }


  return {
    pageView: {
      event: 'page_view',
      page: {
        page_location: window?.location?.href || '',
        page_title: `Detalhes da Apólice - Histórico da apólice - ${policy}`
      },
      location,
      ...product,
      ...defaultData
    },
    onFilter (filter: string) {
      return {
        event: 'event_data',
        action: `clique-chip-${filter?.toLowerCase()}`,
        location,
        screen_name,
        product,
        ...defaultData
      }
    },
    onSort (oldest: boolean) {
      return {
        event: 'event_data',
        action: `select-dropdown-${oldest ? 'oldest' : 'latest'}`,
        location,
        screen_name,
        product,
        ...defaultData
      }
    },
    moreDetails (ref?: string) {
      return { 
        event: 'event_data',
        action: `clique-more-details${ref ? `_${ref.toLowerCase()}` : ''}`,
        location,
        screen_name,
        product,
        ...defaultData
      }
    },
    paginateView: {
      event: 'event_data',
      action: 'view-pagination',
      location,
      screen_name,
      product,
      ...defaultData
    },
    onPaginate (page: number) {
      return {
        event: 'event_data',
        action: `clique-pagination-${page}`,
        location,
        screen_name,
        product,
        ...defaultData
      }
    }
  }
} 
