/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'

// TODO remove any

export type AuthContextType = {
  token: null | string
  authenticated: boolean
  authorized: null | boolean
  handleLogin: (data: any) => void
  handleLogout: () => void
  redirectLogout: () => void
  setAuthorizedRoles: (viewer?: any, companyTypes?: string[], routePermissionKey?: string, routePermissionKey?: boolean) => void
  oAuthClient: any
}

const AuthContext = React.createContext<AuthContextType>({
  token: null,
  authenticated: false,
  authorized: null,
  handleLogin: (data) => {},
  handleLogout: () => {},
  redirectLogout: () => {},
  setAuthorizedRoles: () => {},
  oAuthClient: null,
})

type AuthProviderProps = {
  children: React.ReactNode
  authentication: any
}

function AuthProvider({ authentication, children }: AuthProviderProps) {
  const {
    token,
    authenticated,
    authorized,
    handleLogin,
    handleLogout,
    redirectLogout,
    setAuthorizedRoles,
    oAuthClient,
  } = authentication

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        token,
        authorized,
        handleLogin,
        redirectLogout,
        handleLogout,
        setAuthorizedRoles,
        oAuthClient,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
