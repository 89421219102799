import React, { useContext } from 'react'

import { ResourceFlowContext } from '../../contexts/ResourceFlowContext'
import { HeaderImpersonate } from '../Impersonate/Impersonate'
import Loader from '../Loader'

const SalesJourney = () => {
  const context = useContext<any>(ResourceFlowContext)
  const { attendant } = context?.data?.resource || {}
  const impersonate = context?.data?.resource?.impersonate

  if (context.loading) {
    return <Loader data='Carregando...' />
  }
  return (
    <>
      {impersonate && <HeaderImpersonate attendant={attendant} />}
      {context.drawComponent()}
    </>
  )
}

export default SalesJourney
