import { DocumentNode, gql } from '@apollo/client'

export const GET_BANKS: DocumentNode = gql`
  query banks {
    banks {
      name
      code
      digit
    }
  }
`
