import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Text, View } from '@youse-seguradora/cargo-components'

import { useTheme } from '@material-ui/core/styles'

interface LoaderProps {
  data: String
}

const Loader = ({ data }: LoaderProps) => {
  const theme = useTheme()

  return (
    <View
      size={{ height: '100vh' }}
      layout={{ alignVerticalCenter: true, alignHorizontalCenter: true }}
      background={{ color: theme.palette.background.default }}
    >
      <CircularProgress style={{ color: theme.backgrounds.secondary }} />
      <View spacing={{ top: 2 }}>
        <Text align='center' color={theme.colors.primaryInverse}>
          {data}
        </Text>
      </View>
    </View>
  )
}

export default Loader
