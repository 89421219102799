import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Column,
  ColumnsContainer,
  Container,
  Icon,
  Image,
  InvisibleButton,
  Spacer,
  Text,
  Title,
  View,
} from '@youse-seguradora/cargo-components'

import { useTheme } from '@material-ui/core/styles'

type Column = { text: string; subText: string; size: number; highlight?: boolean, highlightCustomColor?: string }

type HeaderProps = {
  tag?: React.ReactNode
  title: string
  subtitle?: string
  columns?: Column[]
  buttons?: { position: 'right' | 'left', button: React.ReactNode }[]
  disableBackButton?: boolean
  disableBackground?: boolean
  imageSrc?: string
  heightImage?: number
  equalHorizontalPadding?: boolean
  ablePercentageWidth?: boolean
  defaultLayout?: boolean
  onBackButton?: () => void
}

export default function Header({
  tag,
  title,
  subtitle,
  columns,
  buttons,
  disableBackButton,
  disableBackground,
  imageSrc,
  heightImage,
  ablePercentageWidth,
  equalHorizontalPadding,
  defaultLayout = true,
  onBackButton
}: HeaderProps) {
  const theme = useTheme()
  const history = useHistory()

  const leftButtons = buttons?.filter((button) => button.button && button.position === 'left')
  const rightButtons = buttons?.filter((button) => button.button && button.position === 'right')
  const hasHighlight = (columnData: Column) => columnData?.highlight || columnData?.highlightCustomColor

  const highlightColor = (columnData: Column) => {
    if (columnData?.highlight) {
      return theme.colors.warning
    }

    if (columnData?.highlightCustomColor) {
      return columnData?.highlightCustomColor
    }

    return theme.colors.secondary
  }

  const handleBackButton = () => onBackButton ? onBackButton() : history.goBack()

  return (
    <View
      background={{
        color: disableBackground
          ? 'none'
          : theme.backgrounds.primary
      }}
      spacing={
        defaultLayout
          ? { left: 8, right: equalHorizontalPadding ? 8 : 12, top: 5, bottom: 5 }
          : { left: 0, right: 0, top: 5, bottom: 5 }
      }
    >
      <Container percentageWidth={ablePercentageWidth}>
        {imageSrc ? (
          <ColumnsContainer>
            <Column size={8}>
              <View block>
                {!disableBackButton && (
                  <InvisibleButton
                    children='Voltar'
                    onClick={handleBackButton}
                    leftIcon='arrow-left'
                  />
                )}
              </View>
              <View layout={{ horizontal: true, alignVerticalCenter: true }} spacing={{ top: 4 }}>
                <Title color={theme.colors.highlight} size='small'>
                  {title}
                </Title>
                {tag && (
                  <>
                    <Spacer right={6} />
                    {tag}
                  </>
                )}
              </View>
              {subtitle && (
                <>
                  <Spacer top={5} />
                  <Text color={theme.colors.secondary} markdown={subtitle} />
                </>
              )}
              {columns && (
                <>
                  <Spacer top={5} />
                  <ColumnsContainer>
                    {columns.map((column, i) => (
                      <Column key={i} size={column.size}>
                        <Text color={theme.colors.secondary}>{column.text}</Text>
                        <Spacer bottom={2} />

                        {column.highlight ? (
                          <View layout={{ horizontal: true, alignVerticalCenter: true }}>
                            <Title
                              color={
                                column.highlight ? theme.colors.warning : theme.colors.secondary
                              }
                              size='xsmall'
                            >
                              {column.subText}
                            </Title>
                            <Spacer left={2} />
                            <Icon name='check-circle' color={theme.colors.warning} />
                          </View>
                        ) : (
                          <Title color={theme.colors.secondary} size='xsmall'>
                            {column.subText}
                          </Title>
                        )}
                      </Column>
                    ))}
                  </ColumnsContainer>
                </>
              )}
            </Column>
            <Column size={4}>
              {columns ? (
                <Image src={imageSrc} alt={title} />
              ) : (
                <View
                  layout={{ alignHorizontalCenter: true, alignBottom: true }}
                  position={{ marginBottom: '-70px', marginTop: '20px' }}
                >
                  <Image height={heightImage} src={imageSrc} alt={title} />
                </View>
              )}
            </Column>
          </ColumnsContainer>
        ) : (
          <View style={
            defaultLayout
              ? { padding: '0' }
              : { padding: '16px 24px 24px', backgroundColor: theme.backgrounds.primary, borderRadius: '8px' }
          }>
            <View block>
              {!disableBackButton && (
                <InvisibleButton
                  children='Voltar'
                  onClick={handleBackButton}
                  leftIcon='arrow-left'
                />
              )}
            </View>
            <View layout={{ horizontal: true, alignVerticalCenter: true }} spacing={{ top: 4 }}>
              <Title color={defaultLayout ? theme.colors.highlight : theme.colors.primary} size='small'>
                {title}
              </Title>
              {tag && (
                <>
                  <Spacer right={6} />
                  {tag}
                </>
              )}
            </View>
            {subtitle && (
              <>
                <Spacer top={5} />
                <Text color={theme.colors.secondary} markdown={subtitle} />
              </>
            )}
            {columns && (
              <>
                <Spacer top={5} />
                <ColumnsContainer>
                  {columns.map((column, i) => (
                    <Column key={i} size={column.size}>
                      <Text color={theme.colors.secondary}>{column.text}</Text>
                      <Spacer bottom={2} />

                      {hasHighlight(column) ? (
                        <View layout={{ horizontal: true, alignVerticalCenter: true }}>
                          <Title
                            color={highlightColor(column)}
                            size='xsmall'
                          >
                            {column.subText}
                          </Title>
                          <Spacer left={2} />
                          <Icon name='check-circle' color={highlightColor(column)} />
                        </View>
                      ) : (
                        <Title color={theme.colors.secondary} size='xsmall'>
                          {column.subText}
                        </Title>
                      )}
                    </Column>
                  ))}
                </ColumnsContainer>
              </>
            )}
            {(leftButtons || rightButtons) && (
              <View layout={{ horizontal: true, spread: true  }} spacing={{ top: 5 }}>
                <View layout={{ horizontal: true, alignLeft: true }} style={{ gap: '16px' }}>
                  {leftButtons?.map(button => button.button)}
                </View>
                <View layout={{ horizontal: true, alignRight: true }} style={{ gap: '16px' }}>
                  {rightButtons?.map(button => button.button)}
                </View>
              </View>
            )}
          </View>
        )}
      </Container>
    </View>
  )
}
