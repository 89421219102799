import { gql } from '@apollo/client'

export const GET_PAYMENT_PLAN = gql`
  query GetPaymentPlan($policyNumber: String!) {
    paymentPlan(policyNumber: $policyNumber) {
      amount
      status
      installments {
        amount
        dueAt
        status
        number
        paymentMethodName
      }
      refundInstallments {
        amount
        dueAt
        status
        number
        paymentMethodName
      }
    }
  }
`
