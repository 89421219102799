import React from 'react'
import { FormControl } from '@tiny-fronts/resource-flow-components'
import {
  Column,
  ColumnsContainer,
  PrimaryButton,
  TrackingContainer,
  TrackingContext,
  View,
} from '@youse-seguradora/cargo-components'
import _ from 'lodash'

const FormSubmit = ({ handleSubmit, allowSubmit, label, action }) => (
  <View spacing={{ top: 8 }}>
    <ColumnsContainer>
      <Column desktopSize={3} desktopOffset={9}>
        <TrackingContext category='route-change'>
          <TrackingContainer action={action} label={label}>
            <FormControl fullWidth={true}>
              <PrimaryButton
                data-testid={`${action}-submit-button`}
                disabled={!allowSubmit}
                onClick={() => handleSubmit()}
              >
                {_.capitalize(label)}
              </PrimaryButton>
            </FormControl>
          </TrackingContainer>
        </TrackingContext>
      </Column>
    </ColumnsContainer>
  </View>
)

export default FormSubmit
