import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Container,
  InvisibleButton,
  LoadingBar,
  Spacer,
  Title,
  View } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import useAuth from 'hooks/useAuth'

import { DescriptionUsageData } from 'components/Modal/DescriptionUsageData'
import { RegisterError } from 'components/Modal/RegisterError'

import ActionButtons from '../../components/BottomBar/BottomBar'
import { FormErrorProps, UserProps } from '../../types/UserRegistrationFlow'

import { EmailForm } from './EmailForm'
import { LoginForm } from './LoginForm'
import { PasswordForm } from './PasswordForm'
import { UserForm } from './UserForm'

export const UserRegistrationFlow = () => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openError, setOpenError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [step, setStep] = useState<string>('1')
  const [form, setForm] = useState<UserProps>({
    cpf: '',
    name: '',
    password: '',
    email: ''
  })

  const [error, setError] = useState<FormErrorProps>({
    email: {
      isError: false,
      message: ''
    },
    name: {
      isError: false,
      message: ''
    },
    cpf: {
      isError: false,
      message: ''
    },
    password: {
      isError: false,
      message: ''
    }
  })

  const validEmptyInputs = () => {
    if (step === '1') {
      if (!form.email.length || error.email.isError) {
        return false
      }
    }

    if (step === '2') {
      if (!form.cpf.length || !form.name.length || error.name.isError || error.cpf.isError) {
        return false
      }
    }

    if (step === '3') {
      if (!form.password.length || error.password.isError) {
        return false
      }
    }

    return true
  }

  const stepStrategy = {
    '1': {
      children: <EmailForm
        form={form}
        setForm={setForm}
        error={error}
        setError={setError}
      />,
      title: 'Informe o seu e-mail para aproveitar os benefícios do Open Insurance'
    },
    '2': {
      children: <UserForm
        form={form}
        setForm={setForm}
        error={error}
        setError={setError}
      />,
      title: 'Precisamos de alguns dados para continuar seu cadastro'
    },
    '3': {
      children: <PasswordForm
        form={form}
        setForm={setForm}
        error={error}
        setError={setError}
      />,
      title: 'Falta pouco! Agora é só criar uma senha'
    },
    '4': {
      children: <LoginForm form={form} setForm={setForm}/>,
      title: 'Digite sua senha para continuar'
    }
  }

  const history = useHistory()

  const { token, handleLogin } = useAuth()

  const login = () => {
    const payload = {
      username: form.email,
      password: form.password
    }

    fetch(`${process.env.REACT_APP_OPIN_SERVICE_URL}/user/login`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        if (response.status === 200) {
          return response
        } else {
          throw error
        }
      })
      .then((response) => response.json())
      .then((response) => {
        handleLogin(response.access_token)
        return history.push(`/oauth_callback#access_token=${response.access_token}&token_type=Bearer&expires_in=7200`)
      })
      .catch(() => setOpenError(true))
  }

  const registerUser = () => {
    const payload = {
      name: form.name,
      email: form.email,
      document_number: form.cpf.replace(/[^a-zA-Z0-9]/g, ''),
      password: form.password
    }

    fetch(`${process.env.REACT_APP_OPIN_SERVICE_URL}/user`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then((response) => {
        if (response.status === 200) {
          return response
        } else {
          throw error
        }
      })
      .then((response) => response.json())
      .then(() => {
        return login()
      })
      .catch(() => setOpenError(true))
  }

  const actionButton = () => {
    if (step === '4') return login()

    return registerUser()
  }

  const verifyEmail = () => {
    setLoading(true)

    fetch(`${process.env.REACT_APP_OPIN_SERVICE_URL}/user/verify-email/?email=${form.email}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return '2'
        } else if (response.status === 409) {
          return '4'
        } else {
          return '1'
        }
      })
      .then((response) => {
        setStep(response.toString())
        setLoading(false)
      })
  }

  const nextStep = () => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'Event_Data',
        event_type: 'new',
        ga_event: {
          page_title: `tela-${formatPageTitle(stepStrategy[step].title)}`,
          page_location: window.location.href,
          action: 'click',
          element_name: `tela-${formatPageTitle(stepStrategy[step].title)}-botao: Botão Continuar`
        },
        product: {
          flow: 'OPIN Cadastro'
        }
      })
    }

    if (Number(step) === 1) {
      return verifyEmail()
    }

    if (Number(step) < 3) {
      const newStep = Number(step) + 1

      return setStep(newStep.toString())
    }

    actionButton()
  }

  const formatPageTitle = (title) => {
    const lowerTitle = title.toLowerCase()

    const formatedTitle = lowerTitle.replace(/ /g, '-')

    return formatedTitle
  }

  const prevStep = () => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'Event_Data',
        event_type: 'new',
        ga_event: {
          page_title: `tela-${formatPageTitle(stepStrategy[step].title)}`,
          page_location: window.location.href,
          action: 'click',
          element_name: `tela-${formatPageTitle(stepStrategy[step].title)}-botao: Voltar`
        },
        product: {
          flow: 'OPIN Cadastro'
        }
      })
    }

    if (Number(step) === 4) return setStep('1')

    setStep((Number(step) - 1).toString())
  }

  const nextStepText = () => {
    if (step === '3') return 'Criar conta'
    if (step === '4') return 'Entrar'
    return 'Continuar'
  }

  return (
    <>
      {loading && <LoadingBar />}

      <Container style={{ display: 'flex', flexDirection: 'column', minHeight: 'calc(100vh - 64px)' }}>
        <Spacer top={6} />

        {step !== '1' &&
          <InvisibleButton
            contextual
            onClick={prevStep}
            leftIcon='chevron-left'
          >
            Voltar
          </InvisibleButton>
        }

        <Spacer top={4} />

        <View
          style={{
            maxWidth: '624px',
            width: '100%',
            alignSelf: 'center'
          }}
        >
          <Title
            size={'small'}
            weight={'bold'}
            color={defaultTheme.colors.primary}
            align='left'
          >
            {stepStrategy[step].title}
          </Title>
        </View>

        <Spacer top={4} />

        <View
          style={{
            maxWidth: '528px',
            width: '100%',
            alignSelf: 'center'
          }}
        >
          {stepStrategy[step].children}
        </View>
      </Container>

      <ActionButtons
        nextStep={nextStep}
        previousStep={() => setOpenModal(true)}
        isDisabled={!validEmptyInputs()}
        nextStepText={nextStepText()}
        prevStepText={'Como meus dados são usados?'}
      />

      <DescriptionUsageData
        openModal={openModal}
        setOpenModal={setOpenModal}
      />

      <RegisterError
        open={openError}
        setOpen={setOpenError}
        setStep={setStep}
        setError={setError}
        error={error}
      />
    </>
  )
}
