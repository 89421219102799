import React, { Dispatch, SetStateAction, useContext } from 'react'
import { ResourceFlowContext } from '@tiny-fronts/resource-flow-components'
import { Modal, PrimaryButton, Text, Title, View } from '@youse-seguradora/cargo-components'

import { useTheme } from '@material-ui/core/styles'

interface ModalCarAcceptedProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  resource?: any
}

const ModalCarAccepted = ({ isOpen, setIsOpen }: ModalCarAcceptedProps) => {
  const theme = useTheme()
  const context = useContext<any>(ResourceFlowContext)
  const name = context?.data?.resource?.insuredPerson?.name

  const firstName = () => {
    return name?.split(' ')[0]
  }

  const data = {
    title: 'Sobre seu carro',
    content: (
      <>
        <Title align='center' size='small' color={theme.colors.highlight}>
          {firstName() ? `${firstName()}, fique de olho!` : 'Fique de olho!'}
        </Title>
        <View spacing={{ top: 4 }} />
        <Text color={theme.colors.secondary}>
          A gente identificou que o seu carro tem mais de 15 anos. Por isso, é importante lembrar
          que o prazo pra troca de peças em caso de reparos e acionamento de cobertura (sinistro)
          pode ser prolongado, ok?
        </Text>
      </>
    ),
    footer: <PrimaryButton onClick={() => setIsOpen(false)}>Eu Entendi</PrimaryButton>,
  }

  return <Modal {...data} isOpen={isOpen} onClose={() => setIsOpen(false)} />
}

export default ModalCarAccepted
