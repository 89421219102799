import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  AuthContext,
  GenericError,
  Loading,
  PageContext,
  useLocalStorage,
} from '@tiny-fronts/basement'
import { Container, View } from '@youse-seguradora/cargo-components'

export default function OAuthCallback() {
  const history = useHistory()
  const [currenLocation, setCurrenLocation] = useLocalStorage('current_location', '/')

  const { handleLogin, oAuthClient, authenticated } = React.useContext<any>(AuthContext)
  const { isLoading, setIsLoading } = React.useContext<any>(PageContext)
  const [hasError, setHasError] = React.useState(false)

  const handleError = () => {
    setIsLoading(false)
    setHasError(true)
  }

  React.useEffect(() => {
    if (authenticated) {
      history.push(currenLocation)
      setCurrenLocation('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, history])

  React.useEffect(() => {
    setIsLoading(true)

    oAuthClient.token.getToken(window.location.href).then((data) => {
      handleLogin(data.accessToken)
    }, handleError)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <View
        layout={{ alignHorizontalCenter: true, alignVerticalCenter: true }}
        spacing={{ vertical: 8 }}
      >
        {isLoading && <Loading />}
        {hasError && (
          <GenericError
            title='Opa! Ocorreu um erro no login.'
            ctaOnClick={() => history.push('/')}
          />
        )}
      </View>
    </Container>
  )
}
