import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { AuthProvider, PageProvider } from '@tiny-fronts/basement'
import { designTokens, GlobalStyles, standardTheme } from '@youse-seguradora/cargo-components'
import { createGlobalStyle, ThemeProvider as ThemeProviderStyled } from 'styled-components'

import defaultTheme from './config/defaultTheme'
import useAuth from './hooks/useAuth'
import Router from './router'

import { ThemeProvider } from '@material-ui/styles'

const GlobalStylesOverride = createGlobalStyle`
  body {
    background: ${designTokens.colors.grey000};
  }
`

const App = () => {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID || '',
  }

  TagManager.initialize(tagManagerArgs)
  const authentication = useAuth()

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS_OPIN,
    integrations: [new BrowserTracing()],
    enableTracing: false,
    tracesSampleRate: 0,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    beforeSend: (event) => {
      if (window.location.hostname === 'localhost') {
        return null
      }
      return event
    },
  })

  return (
    <PageProvider>
      <ThemeProvider theme={defaultTheme}>
        <ThemeProviderStyled theme={standardTheme}>
          <GlobalStyles />
          <GlobalStylesOverride />
          <AuthProvider authentication={authentication}>
            <Router />
          </AuthProvider>
        </ThemeProviderStyled>
      </ThemeProvider>
    </PageProvider>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))
