import React, { useEffect, useState } from 'react'
import MUIFormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { View } from '@youse-seguradora/cargo-components'

type FormControlProps = {
  children: React.ReactNode
  resourceerrors?: any
  errorfor?: any
  fullWidth?: boolean
}

const FormControl = (props: FormControlProps) => {
  const [errors, setErrors] = useState([])

  useEffect(() => {
    if (
      props.resourceerrors === null ||
      props.resourceerrors === undefined ||
      props.resourceerrors === {}
    ) {
      return
    }

    if (Array.isArray(props.errorfor)) {
      let errorsList: string[] = []
      props.errorfor.forEach((error) => {
        if (props.resourceerrors[error] !== undefined)
          errorsList = errorsList.concat(props.resourceerrors[error])
      })
      setErrors(errorsList)
    } else if (props.resourceerrors[props.errorfor] !== undefined) {
      setErrors(props.resourceerrors[props.errorfor])
    } else {
      setErrors([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.resourceerrors])

  return (
    <MUIFormControl {...props} error={errors.length > 0}>
      {props.children}
      {errors.map((message, index) => (
        <View
          key={`${props.errorfor}_${index}_error`}
          layout={{
            desktopAlignHorizontalCenter: true,
          }}
          spacing={{ top: 8, desktopTop: 4, tabletTop: 4 }}
        >
          <FormHelperText className='field-error'>{message}</FormHelperText>
        </View>
      ))}
    </MUIFormControl>
  )
}

export default FormControl
