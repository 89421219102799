import { routePermissions } from './routePermissions'
import { screenPermissions } from './screenPermissions'

export const checkPermission = (
  routePermissionKey: string,
  permissionsCompany: string,
  permissionsUser: string,
  screenPermissionKey = ''
) => {
  let isPermittedCompany = false, isPermittedUser = false

  if (screenPermissionKey) {
    isPermittedCompany = screenPermissions[routePermissionKey][screenPermissionKey].company.some(item => permissionsCompany?.includes(item))
    isPermittedUser = screenPermissions[routePermissionKey][screenPermissionKey].user.some(item => permissionsUser?.includes(item))
  } else {
    isPermittedCompany = routePermissions[routePermissionKey].company.some(item => permissionsCompany?.includes(item))
    isPermittedUser = routePermissions[routePermissionKey].user.some(item => permissionsUser?.includes(item))
  }

  return isPermittedCompany && isPermittedUser
}
