import React, { FC } from 'react'
import { Route } from 'react-router-dom'
import { claim } from 'helpers/claim'
import { endorsement } from 'helpers/endorsement'
import OAuthCallBack from 'pages/OAuthCallBack/OAuthCallBack'

import { InteractionNotFoundError, TransmitterNotFoundError, UnknownError } from 'components/Errors'

import { Logout } from '../pages/Logout'
import OAuthLogin from '../pages/OAuthLogin/OAuthLogin'
import { RequestConfirmation } from '../pages/RequestConfirmation'
import { UserRegistrationFlow } from '../pages/UserRegistrationFlow'

import { ConsentDetails } from './Management/ConsentDetails/ConsentDetails'
import { ConsentList } from './Management/ConsentList/ConsentList'
import { ConsentCallback } from './ConsentCallback'
import { Home } from './Home'
import LoginPage from './LoginPage'
import { PoliciesNotFound } from './PoliciesNotFound'
import { ReceptionFlow } from './ReceptionFlow'
import { SuccessRevoke } from './SuccessRevoke'
import { TermsDetails } from './TermsDetails'
import TransmissionFlow from './TransmissionFlow'

type PageProps = {
  title: string
  path: string
  children: FC
  to?: string
  exact: boolean
}[]

const pages: PageProps = [
  {
    title: 'Login',
    path: '/login/:loginInteractionId',
    exact: true,
    children: () => <LoginPage />,
  },
  {
    title: 'Open Insurance',
    path: '/transmissao/consentimento/:consentInteractionId',
    exact: false,
    children: () => <TransmissionFlow />,
  },

  {
    title: 'Apólices não encontradas',
    path: '/erro/apolices-nao-econtradas',
    exact: false,
    children: () => <PoliciesNotFound />,
  },
  {
    title: 'Home',
    path: '/',
    exact: true,
    children: () => <Home />,
  },
  {
    title: 'Consentimentos',
    path: '/gestao/consentimentos',
    exact: true,
    children: () => <ConsentList />,
  },
  {
    title: 'Detalhes do consentimento',
    path: '/gestao/consentimento-detalhes/:tab/:consentId',
    exact: true,
    children: () => <ConsentDetails />,
  },
  {
    title: 'Interação não encontrada',
    path: '/interacao-nao-encontrada',
    exact: true,
    children: () => <InteractionNotFoundError />,
  },
  {
    title: 'Revogado com sucesso',
    path: '/gestao/revogado-com-sucesso',
    exact: true,
    children: () => <SuccessRevoke />,
  },
  {
    title: 'Interação não encontrada',
    path: '/interacao-nao-encontrada',
    exact: true,
    children: () => <InteractionNotFoundError />,
  },
  {
    title: 'Revogado com sucesso',
    path: '/gestao/revogado-com-sucesso',
    exact: true,
    children: () => <SuccessRevoke />,
  },
  {
    title: 'Interação não encontrada',
    path: '/interacao-nao-encontrada',
    exact: true,
    children: () => <InteractionNotFoundError />,
  },
  {
    title: 'Recepção',
    path: '/recepcao',
    exact: true,
    children: () => <ReceptionFlow />,
  },
  {
    title: 'Renovação',
    path: '/renovacao/:consentId',
    exact: true,
    children: () => <ReceptionFlow />,
  },
  {
    title: 'Alteração',
    path: '/alteracao/:consentId',
    exact: true,
    children: () => <ReceptionFlow />,
  },
  {
    title: 'Termos e condições',
    path: '/termos-e-condicoes',
    exact: true,
    children: () => <TermsDetails />,
  },
  {
    title: 'Confirmação',
    path: '/callback',
    exact: true,
    children: () => <ConsentCallback />,
  },
]

const publicPages: PageProps = [
  {
    title: 'Oauth',
    path: '/oauth',
    exact: true,
    children: () => <OAuthLogin />,
  },
  {
    title: 'Callback Oauth',
    path: '/oauth_callback',
    exact: true,
    children: () => <OAuthCallBack />,
  },
  {
    title: 'Logout',
    path: '/logout',
    exact: true,
    children: () => <Logout />,
  },
  {
    title: 'Cadastro de Usuário',
    path: '/cadastre-se',
    exact: true,
    children: () => <UserRegistrationFlow />,
  },
  {
    title: 'Erro Desconhecido',
    path: '/erro-desconhecido',
    exact: true,
    children: () => <UnknownError />,
  },
  {
    title: 'Confirmação de Sinistro',
    path: '/solicitacao/sinistro/:protocol',
    exact: true,
    children: () => <RequestConfirmation {...claim} />,
  },
  {
    title: 'Erro Transmissora',
    path: '/erro-transmissora/:nameUser/:documentUser/:emailUser/:nameTransmitter',
    exact: true,
    children: () => (
      <Route
        exact
        path='/erro-transmissora/:nameUser/:documentUser/:emailUser/:nameTransmitter'
        render={(routeProps) => (
          <TransmitterNotFoundError
            {...routeProps}
            messageProps={'A seguradora que você escolheu não está disponível para o compartilhamento de dados no momento.'}
          />
        )}
      />
    ),
  },
  {
    title: 'Confirmação de Endosso',
    path: '/solicitacao/endosso/:protocol',
    exact: true,
    children: () => <RequestConfirmation {...endorsement} />,
  },
]

export default { pages, publicPages }
