import React, { Key, useState } from 'react'
import { DefaultComponents, FormFields, SocialProvider } from '@aws-amplify/ui/dist/aws-amplify-ui'
import { Authenticator, translations, useAuthenticator } from '@aws-amplify/ui-react'
import { designTokens, InvisibleButton, Text, View } from '@youse-seguradora/cargo-components'
import { Auth, I18n } from 'aws-amplify'

import { translationCognitoMessages } from '../../helpers/translationType'

import PasswordValidation from './PasswordValidation/PasswordValidation'
import ChallengeCode from './ChallengeCode'
import HeaderLogin from './HeaderLogin'

import '@aws-amplify/ui-react/styles.css'
import './amplify.css'

type CognitoLoginType = {
  children?: React.ReactNode | React.ReactNode[]
  socialProviders?: SocialProvider[]
}

const dictionary = {
  pt: {
    ...translations.pt,
    ...translationCognitoMessages
  },
}

I18n.putVocabularies(dictionary)
I18n.setLanguage('pt')

export default function CognitoLogin({ children, socialProviders = [] }: CognitoLoginType) {
  const [showChallengeCode, setShowChallengeCode] = useState(false)
  const [user, setUser] = useState<Record<Key, string>>()
  const [password, setPassword] = useState('s')
  const [currentEmail, setCurrentEmail] = useState('')

  const components: DefaultComponents = {
    SignIn: {
      Header() {
        return (
          <HeaderLogin title='Acessar o Portal de Parceiros'>
            <Text color={designTokens.colors.grey500} align='center'>
              Insira seu e-mail e senha para entrar no Portal.
            </Text>
          </HeaderLogin>
        )
      },

      Footer() {
        const { toResetPassword } = useAuthenticator()

        return (
          <View layout={{ alignHorizontalCenter: true }} spacing={{ top: 5 }}>
            <InvisibleButton onClick={toResetPassword}>Esqueci minha senha</InvisibleButton>
          </View>
        )
      }
    },

    ForceNewPassword: {
      FormFields() {
        return (
          <>
            <HeaderLogin title='Alterar senha' />

            <PasswordValidation
              password={password}
              label='Agora crie uma senha segura seguindo as regras abaixo:'
            />

            <Authenticator.ForceNewPassword.FormFields />
          </>
        )
      }
    },

    ResetPassword: {
      Header() {
        return (
          <HeaderLogin title='Recuperação de senha'>
            <Text color={designTokens.colors.grey500} align='center'>
              Informe seu e-mail e senha para receber um código para recuperar sua senha.
            </Text>
          </HeaderLogin>
        )
      },

      Footer() {
        const { toSignIn } = useAuthenticator()

        return (
          <View layout={{ alignHorizontalCenter: true }}>
            <InvisibleButton onClick={toSignIn} leftIcon='chevron-left'>
              voltar para o login
            </InvisibleButton>
          </View>
        )
      }
    },

    ConfirmResetPassword: {
      Header() {
        return (
          <>
            <HeaderLogin title='Recuperação de senha'>
              <Text color={designTokens.colors.grey500} align='center'>
                Você deve ter recebido um e-mail contendo um código de cinco dígitos no
                endereço <strong>{currentEmail}</strong> para alterar sua senha.
                Copie esse código no campo abaixo:
              </Text>
            </HeaderLogin>

            <PasswordValidation
              password={password}
              label='Agora crie uma senha segura seguindo as regras abaixo:'
            />
          </>
        )
      },

      Footer() {
        const { toSignIn } = useAuthenticator()

        return (
          <View layout={{ alignHorizontalCenter: true }}>
            <InvisibleButton onClick={toSignIn} leftIcon='chevron-left'>
              voltar para o login
            </InvisibleButton>
          </View>
        )
      }
    }
  }

  const formFields: FormFields = {
    signIn: {
      username: {
        labelHidden: true,
        placeholder: 'E-mail*',
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => setCurrentEmail(event.target.value)
      },
      password: {
        placeholder: 'Senha*',
      },
    },

    forceNewPassword: {
      password: {
        labelHidden: true,
        placeholder: 'Digite sua nova senha*',
      },
      confirm_password: {
        labelHidden: true,
        placeholder: 'Confirme sua nova senha*',
      },
    },

    resetPassword: {
      username: {
        labelHidden: true,
        placeholder: 'E-mail*',
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => setCurrentEmail(event.target.value)
      },
    },

    confirmResetPassword: {
      confirmation_code: {
        labelHidden: true,
        placeholder: 'Código recebido no e-mail*',
        isRequired: true,
      },
      password: {
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value),
        labelHidden: true,
        placeholder: 'Digite sua nova senha*'
      },
      confirm_password: {
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value),
        labelHidden: true,
        placeholder: 'Confirme sua nova senha*',
      },
    },
  }

  const services = {
    async handleSignIn(formData) {
      const { username, password } = formData
      const signInResponse = await Auth.signIn({ username, password })

      setUser(signInResponse)

      if (signInResponse?.challengeName === 'CUSTOM_CHALLENGE') {
        setShowChallengeCode(true)
      } else
        return signInResponse
    }
  }

  return (
    <>
      <Authenticator.Provider>
        {showChallengeCode && (
          <ChallengeCode
            user={user}
            currentEmail={currentEmail}
            setShowChallengeCode={setShowChallengeCode}
          />
        )}

        {!showChallengeCode && (
          <Authenticator
            services={services}
            socialProviders={socialProviders}
            components={components}
            formFields={formFields}
          >
            {children}
          </Authenticator>
        )}
      </Authenticator.Provider>
    </>
  )
}


