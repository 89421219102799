import React, { useState } from 'react'
import {
  ChipList,
  Pagination,
  Spacer,
  Text,
  View } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import { isMobile } from 'utils/IsMobile'

import { ConsentCardDesktop } from 'components/ConsentCardDesktop/ConsentCardDesktop'
import { ConsentCardMobile } from 'components/ConsentCardMobile/ConsentCardMobile'

import { EmptyStateReception } from './EmptyStateReception'
import { EmptyStateTransmission } from './EmptyStateTransmission'
import { NotFoundFilter } from './NotFoundFilter'

const chips = [
  {
    name: 'Pendente',
  },
  {
    name: 'Ativo',
  },
  {
    name: 'Vencido',
  },
  {
    name: 'Encerrado',
  },
]

export const TabContent = ({ consentList, tab }) => {
  const [itemsList, setItemsList] = useState<string[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)

  const removeItem = (element: string) => {
    setItemsList(itemsList.filter((item) => item !== element))
  }

  const selectChip = (selected: boolean, name: string) => {
    if (selected) {
      setItemsList([...itemsList, name])
    } else {
      removeItem(name)
    }
  }

  const handlePagination = (list) => {
    const newList = list.reduce((result, _obj, index) => {
      if (index % 4 === 0) {
        result.push(list.slice(index, index + 4))
      }

      return result
    }, [])

    return newList
  }

  const filteredList = () => {
    if (!itemsList.length) return handlePagination(consentList)

    const newList = consentList.filter((item) => itemsList.includes(item.status))

    return handlePagination(newList)
  }

  return (
    <View style={{ marginTop: '24px' }}>
      <View
        layout={{ desktopAlignVerticalCenter: true, desktopHorizontal: true }}
        style={{ columnGap: '32px', rowGap: '8px' }}
      >
        <Text size={'default'} weight={'regular'} color={defaultTheme.colors.secondary}>
          Filtrar por:
        </Text>

        <ChipList chips={chips} handleSelect={selectChip} />
      </View>

      {(!filteredList()?.length && !!itemsList.length) &&
        <NotFoundFilter />
      }

      {(!filteredList()?.length && tab === 'Recebidos' && !itemsList.length) &&
        <EmptyStateReception />
      }

      {(!filteredList()?.length && tab === 'Enviados' && !itemsList.length) &&
        <EmptyStateTransmission />
      }

      {!!filteredList().length && filteredList()[currentPage - 1].map((consent, index) => (
        isMobile()
          ? <ConsentCardMobile key={index} consent={consent} tab={tab}/>
          : <ConsentCardDesktop key={index} consent={consent} tab={tab}/>
      ))}

      <Spacer top={4} />

      <Pagination current={currentPage} total={filteredList()?.length} onClick={(e) => setCurrentPage(e)}/>
    </View>
  )
}
