import React from 'react'
import {
  Container,
  InvisibleButton,
  PrimaryButton,
  View,
} from '@youse-seguradora/cargo-components'
import { Consent } from 'types/Consent'

import { useTheme } from '@material-ui/core/styles'

type ActionButtonsProps = {
  isDisabled?: boolean
  nextStepText?: string
  prevStepText?: string
  previousStep: () => void
  nextStep: () => void
  consent?: Consent
} 

const ActionButtons = ({
  isDisabled = true,
  nextStepText = 'continuar',
  nextStep,
  previousStep,
  prevStepText,
  consent
}: ActionButtonsProps) => {

  const { colors } = useTheme()

  return (
    <View
      as='div'
      background={{ color: colors.textSidebar }}
      spacing={{ top: 4, bottom: 4 }}
      style={{
        boxShadow: '0px 2px 16px rgba(47, 57, 65, 0.16)',
        bottom: 0,
        marginTop: 32,
        zIndex: 900
      }}
      size={{ width: '100%' }}
      position={{ sticky: true }}
    >
      <Container>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            flexWrap: 'wrap-reverse',
            columnGap: '80px',
            rowGap: '24px'
          }}
          spacing={{ left: 0, desktopLeft: 20, right: 0, desktopRight: 20 }}
        >
          {
            consent?.kind === 'DATA' && (
              <View layout={{ alignHorizontalCenter: true }} style={{ width: 'fit-content' }}>
                <InvisibleButton onClick={previousStep}>
                  {prevStepText || 'Cancelar solicitação'}
                </InvisibleButton>
              </View>
            )
          }          

          <PrimaryButton onClick={nextStep} style={{ width: '288px' }} disabled={isDisabled} type='submit' form='hook-form'>
            {              
              consent?.kind === 'SERVICE' ? 'Confirmar' : nextStepText
            }
          </PrimaryButton>
        </View>
      </Container>
    </View>
  )
}

export default ActionButtons
