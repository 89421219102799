import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { checkPermission, ErrorComponent, Policy, RoutePermissionsKeys, useViewer } from '@tiny-fronts/basement'
import {
  Column,
  ColumnsContainer,
  FeatureCardLargeList,
  Image,
  Modal,
  Text,
  Title,
  View,
} from '@youse-seguradora/cargo-components'

import ModalService from './ModalServices'

import { useTheme } from '@material-ui/core/styles'

type PolicyProps = {
  policy: Policy
  activateAssistance?: boolean
}

export default function Assistances({ policy, activateAssistance }: PolicyProps) {
  const theme = useTheme()
  const history = useHistory()
  const { product } = useParams<{ product: string }>()
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [isOpenService, setIsOpenService] = React.useState<boolean>(false)

  const { viewer } = useViewer()
  let permissionsUser: boolean | Permissions = false
  let isPermitted = false

  if (viewer) {
    permissionsUser = viewer?.user?.permissions
    const permissionsCompany = viewer?.company?.permissions

    isPermitted = permissionsCompany && permissionsUser && checkPermission(RoutePermissionsKeys.ASSISTENCIAS, permissionsCompany, permissionsUser)
  }

  const [assistanceDetail, setAssistanceDetail] = React.useState<{
    iconUrl: string
    name: string
    description: string
    services: {}[]
  }>({
    iconUrl: '',
    name: '',
    description: '',
    services: [{}],
  })

  const isServicesToSelect = (assistance) =>
    assistance.services
      .filter((service: { canRequestAssistance: boolean }) => service.canRequestAssistance)
      .length > 0


  const getProps = (item) => ({
    title: item.name,
    subtitle: '',
    imageComponent: <Image alt={item.name} src={item.iconUrl} height={20} />,
    ctaLabel: 'Ver detalhes',
    onClick: () => {
      setIsOpen(true), setAssistanceDetail(item)
    },
    buttonAsLink: false,
  })

  const getPropsActivateAssistance = (assistance) => {
    // eslint-disable-next-line max-len
    const subtitleChat = 'Para acionar essa assistência, você pode entrar em contato pelo chat do aplicativo ou liguar para o nosso Time de Ajuda nos números 3003 5770 (capitais e regiões metropolitanas) ou 0800 730 9901 (demais localidades).'
    const isServicesAvaibleToSelect = isServicesToSelect(assistance)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let properties: any = {
      title: assistance.name,
      imageComponent: <Image alt={assistance.name} src={assistance.iconUrl} height={20} />,
      ctaLabel: 'Ver detalhes',
      onClick: () => {
        setIsOpen(true), setAssistanceDetail(assistance)
      },
      buttonAsLink: false,
      subtitle: assistance.providerChatAvailable ? subtitleChat : '',
    }

    if (isServicesAvaibleToSelect && !assistance.providerChatAvailable)
      properties = {
        ...properties,
        secondaryButtonAsLink: assistance.provider === 'autoglass' ? true : false,
        ctaLinkSecondaryButton: process.env.REACT_APP_AUTO_GLASS,
        ctaLabelSecondaryButton: isPermitted && 'Acionar assistência',
        onClickSecondaryButton: () =>
          isServicesAvaibleToSelect ?
            (setIsOpenService(true), setAssistanceDetail(assistance)) :
            history.push(`/apolices/${product}/${policy.number}/acionamento/${assistance.assistanceId}`)
      }

    return properties
  }

  const content = (
    <>
      <View layout={{ alignHorizontalCenter: true }} spacing={{ bottom: 2 }}>
        <Image alt='documentos' src={assistanceDetail.iconUrl} height={16} />
      </View>
      <View spacing={{ top: 2 }} />
      <Title align='center' size='small' color={theme.colors.highlight}>
        {assistanceDetail.name}
      </Title>
      <View spacing={{ top: 4 }} />
      <Text color={theme.colors.secondary}>{assistanceDetail.description}</Text>
    </>
  )

  const modalAvailableDocs = {
    title: 'Assistências',
    content: content,
  }

  return (
    <ErrorComponent policy={policy}>
      <View>
        {policy?.assistances.length !== 0 ? (
          <>
            <View spacing={{ vertical: 6 }}>
              <Title color={theme.colors.primary} size='xsmall' dataTestId='assistances-title'>
                Assistências
              </Title>
            </View>
            {activateAssistance && policy?.number !== null ? (
              <FeatureCardLargeList
                items={policy?.assistances.map((assistance) =>
                  getPropsActivateAssistance(assistance)
                )}
              />
            ) : (
              <FeatureCardLargeList items={policy?.assistances.map((i) => getProps(i))} />
            )}
            <Modal hiddenOverflow {...modalAvailableDocs} isOpen={isOpen} onClose={() => setIsOpen(false)} />
            <ModalService
              isOpen={isOpenService}
              setIsOpenService={setIsOpenService}
              assistance={assistanceDetail}
              policy={policy}
            />
          </>
        ) : (
          <ColumnsContainer>
            <Column size={6}>
              <Title size='small' color={theme.colors.highlight}>
                Essa apólice não possui assistências
              </Title>
            </Column>
            <Column size={6}>
              <Image
                src='https://www.datocms-assets.com/16903/1590783167-illus-characters-error-03-4x3-light.png'
                alt='caracter de erro'
                height={38}
              />
            </Column>
          </ColumnsContainer>
        )}
      </View>
    </ErrorComponent>
  )
}
