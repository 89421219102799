import { designTokens } from '@youse-seguradora/cargo-components'
import styled from 'styled-components'

type Step = {
  isValid: boolean
}

export const ValidationStatus = styled.ul`
  margin-top: 8px;
  list-style: none;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
`

export const Step = styled.li<Step>`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ isValid }) => isValid ? designTokens.colors.green600 : designTokens.colors.grey600};

  > svg {
    visibility: ${({ isValid }) => isValid ? 'visible' : 'hidden'};
  }
`
