import React, { useEffect } from 'react'
import { Image, Title, View } from '@youse-seguradora/cargo-components'

import DataLayerClient from '../../../clients/DataLayerClient'
import OhNo from '../../images/oh-no.svg'

import { useTheme } from '@material-ui/core/styles'

const NotFound = () => {
  const theme = useTheme()

  useEffect(() => {
    new DataLayerClient().trigger('Página não encontrada')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <View
        size={{ minHeight: '100vh' }}
        style={{ background: theme.backgrounds.gradient, overflow: 'scroll' }}
        spacing={{ vertical: 6 }}
        layout={{ desktopHorizontal: true }}
      >
        <View
          spacing={{
            desktopLeft: 21,
            desktopTop: 14,
            desktopRight: 0,
            left: 6,
            top: 8,
            right: 6,
          }}
          size={{ desktopWidth: '580px', width: 'auto' }}
        >
          <Title size='big' color={theme.colors.highlightInverse}>
            Oh no!
          </Title>

          <View spacing={{ top: 5 }}>
            <Title size='small' weight='light' color={theme.colors.primaryInverse}>
              A página que você tentou acessar não existe :(
            </Title>
          </View>
        </View>

        <View
          layout={{ alignRight: true }}
          spacing={{ horizontal: 5 }}
          position={{ desktopAbsolute: true, right: '30px' }}
        >
          <View size={{ maxWidth: '600px', minWidth: '380px' }}>
            <Image
              alt='avatar saindo de porta'
              src={OhNo}
              height={52}
              tabletHeight={66}
              desktopHeight={80}
            />
          </View>
        </View>
      </View>
    </>
  )
}

export default NotFound
