import moment from 'moment'

export const formatDate = (date: string | Date | undefined) => (date ? moment(date).format('DD/MM/YYYY') : '-')

export const formatMonthDate = (date: string | Date | undefined) => (date ? moment(date).format('DD/MM') : '-')

export const currentDate = moment()

export const revertFormatDate = (date: string | Date) =>
  moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')

export const formatHourDate = (date: string | Date | undefined, text?: string) => {
  if (!date) return false
  const textFormat = text ? `DD/MM/YYYY [${text}] HH:mm[h]` : 'DD/MM/YYYY HH:mm'
  return moment(date).format(textFormat)
}

export const formatHour = (date: string | Date | undefined) => {
  if (!date) return false
  const textFormat = 'HH:mm'
  return moment(date).format(textFormat).replace(':', 'h')
}

export const formatMonthHourDate = (date: string | Date, text: string) =>
  text ? moment(date).format(`DD/MM [${text}] HH:mm`) : moment(date).format('DD/MM HH:mm')

export const monthsDictionary = {
  '01': 'Janeiro',
  '02': 'Fevereiro',
  '03': 'Março',
  '04': 'Abril',
  '05': 'Maio',
  '06': 'Junho',
  '07': 'Julho',
  '08': 'Agosto',
  '09': 'Setembro',
  '10': 'Outubro',
  '11': 'Novembro',
  '12': 'Dezembro',
}

export const isWeekend = (date: string) => {
  const parts = date.split('-').map((part) => Number(part))
  const dateObject = new Date(parts[0], parts[1] - 1, parts[2])
  const dayOfWeek = dateObject.getDay()

  return dayOfWeek === 0 || dayOfWeek === 6
}
