/* eslint-disable no-unused-vars */
import React from 'react'
import ClientOAuth2 from 'client-oauth2'

export default function useAuth() {
  const [token, setToken] = React.useState<string | null>(localStorage.getItem('@opin_token'))

  function handleLogin(newToken: string): void {
    localStorage.setItem('@opin_token', newToken)
    setToken(newToken)
  }

  function handleLogout(): void {
    setToken(null)
    localStorage.removeItem('@opin_token')
  }

  const oAuthClient = new ClientOAuth2({
    clientId: process.env.REACT_APP_ACCOUNT_SERVICE_CLIENT_ID,
    accessTokenUri: `${process.env.REACT_APP_ACCOUNT_SERVICE_URL}/oauth/token`,
    authorizationUri: `${process.env.REACT_APP_ACCOUNT_SERVICE_URL}/oauth/authorize`,
    redirectUri: process.env.REACT_APP_OPIN_REDIRECT_URI,
    scopes: ['public'],
  })

  const authenticated = !!token

  return {
    token,
    authenticated,
    handleLogin,
    handleLogout,
    oAuthClient,
  }
}
