import React, { useEffect, useState } from 'react'
import { BFFClient, FormRadio } from '@tiny-fronts/resource-flow-components'
import {
  Card,
  Image,
  InvisibleButton,
  InvisibleButtonLink,
  Message,
  MessageType,
  Modal,
  Spacer,
  Text,
  Title,
  View,
} from '@youse-seguradora/cargo-components'

import FormInput from '../../FormInput'
import FormInputError from '../../FormInputError'
import FormSelect from '../../FormSelect'
import PaymentTagMoney from '../../images/payment-tag-money-4x3-light.svg'
import { bonusClassStyles } from '../../styles/bonusClassStyles'

import { useTheme } from '@material-ui/core/styles'

const bffClient = new BFFClient()

type BonusClassProps = {
  errors: string
  driver: {
    userBonusesClass: number
    ciNumber: string
  }
  onChange: any
}

function BonusClass({ errors, driver, onChange }: BonusClassProps) {
  const theme = useTheme()
  const classes = bonusClassStyles()

  const isValidCiNumber =
    driver.ciNumber !== null && driver.ciNumber !== '' && driver.ciNumber !== undefined
  const isValidUserBonusesClass =
    driver.userBonusesClass !== null && driver.userBonusesClass !== undefined

  const [hasInsuranceInTheLastYear, setHasInsuranceInTheLastYear] = React.useState(
    isValidCiNumber || isValidUserBonusesClass,
  )
  const [hasBonusesClass, setHasBonusesClass] = React.useState(hasInsuranceInTheLastYear)
  const [validCiNumber, setValidCiNumber] = React.useState(true)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [userBonusesClass, setUserBonusesClass] = React.useState<[]>([])

  useEffect(() => {
    bffClient.fetchList('classes-de-bonus').then((response: any) => {
      if (response.status === 200) return setUserBonusesClass(response.data)
    })
  }, [])

  const updateHasInsuranceInTheLastYear = (_: any, value: React.SetStateAction<boolean>) => {
    setHasInsuranceInTheLastYear(value)
    clearBonusClass(value)
  }

  const updateHasBonusesClass = (_: any, value: React.SetStateAction<boolean>) => {
    setHasBonusesClass(value)
    clearBonusClass(value)
  }

  const clearBonusClass = (value: React.SetStateAction<boolean>) => {
    if (value === false) {
      updateDriver('userBonusesClass', '')
      updateDriver('ciNumber', '')
    }
  }

  const updateDriver = (field: string, value: any) =>
    onChange(`driver.${field}`, value === '0' ? parseInt(value) : value)

  const updateCiNumber = (field: string, value: any) => {
    const valid = value.length === 16 && !value.match(/(\d)\1{9,}/)
    const firstThreeCiNumbers = value.substring(0, 3)

    bffClient.fetchList('seguradoras').then((response: any) => {
      if (response.status === 200) {
        const ciNumber = response.data
        const issuerNumber = ciNumber.map((issuerNumber: any) => {
          return issuerNumber.value
        })

        const isInsurerValid = valid && issuerNumber.includes(firstThreeCiNumbers)

        if (isInsurerValid) {
          setValidCiNumber(true)
          return updateDriver(field, value)
        }
        setValidCiNumber(false)
      }

      if (response.status === 500) {
        setValidCiNumber(false)
      }
    })
  }

  const bonusesClass = (userBonusesClass: string) => {
    const parsedUserBonusClass = parseInt(userBonusesClass)

    switch (parsedUserBonusClass) {
    case 1:
      return '24'
    case 2:
    case 3:
      return '27'
    case 4:
      return '35'
    case 5:
      return '38'
    case 6:
      return '45'
    case 7:
      return '48'
    case 8:
    case 9:
    case 10:
      return '50'
    }
  }

  const modalFindCiNumber = {
    title: 'Código de Identificação - CI',
    content: (
      <>
        <Title align='center' size='xsmall' color={theme.colors.highlight}>
          Encontre sua Classe de Bônus e Código de Identificação - CI
        </Title>
        <Spacer top={4} />
        <Text color={theme.colors.secondary}>
          Pegue a apólice do seu seguro e procure pela classe de bônus e CI nos campos de: <br />
          - Dados do veículo ou produto; <br />
          - Dados gerais;
          <br />
          - Dados da sua apólice; <br />- Dados do segurado.
        </Text>
        <Spacer top={2} />
        <Text color={theme.colors.secondary}>
          Caso não encontre, entre em contato com a sua seguradora.
        </Text>
      </>
    ),
    footer: <InvisibleButton onClick={() => setIsOpen(false)}>entendi</InvisibleButton>,
  }

  return (
    <>
      <Spacer top={6} />
      <Card color={theme.colors.highlight}>
        <View
          position={{
            desktopAbsolute: true,
            desktopTop: '-50px',
            desktopRight: '0',
          }}
          spacing={{ top: 4, desktopTop: 0 }}
        >
          <Image src={PaymentTagMoney} alt='etiqueta de dinheiro' height={16} />
        </View>
        <View
          spacing={{
            desktopHorizontal: 16,
            tabletHorizontal: 8,
            horizontal: 4,
            top: 6,
            bottom: 4,
          }}
          layout={{ alignHorizontalCenter: true }}
        >
          <Title align='center' size='default' color={theme.colors.highlightInverse}>
            Quer ganhar até 50% de desconto?
          </Title>
          <Spacer top={4} />
          <Text align='center' color={theme.colors.primaryInverse}>
            Se você teve Seguro Auto no último ano, mesmo em outra seguradora, pode ser que tenha
            Classe de Bônus, que é um desconto especial na cotação.
          </Text>
          <FormRadio
            errors={errors}
            classes={classes}
            field={hasInsuranceInTheLastYear}
            name='hasInsuranceInTheLastYear'
            label='Você tem ou teve seguro no último ano?'
            obj='hasInsuranceInTheLastYear'
            onChange={updateHasInsuranceInTheLastYear}
            spacing={{ top: 2 }}
            horizontal={false}
            color={theme.colors.primaryInverse}
            weight={true}
            alignHorizontalCenter={true}
            align='center'
          />
          {hasInsuranceInTheLastYear === true && (
            <FormRadio
              errors={errors}
              classes={classes}
              field={hasBonusesClass}
              name='hasBonusesClass'
              label='Você tem Classe de Bônus?'
              obj='hasBonusesClass'
              onChange={updateHasBonusesClass}
              spacing={{ top: 2 }}
              horizontal={false}
              color={theme.colors.primaryInverse}
              weight={true}
              alignHorizontalCenter={true}
              align='center'
            />
          )}
          {hasBonusesClass === true && hasInsuranceInTheLastYear === true && (
            <>
              <Spacer top={4} />
              <Card>
                <View
                  spacing={{
                    desktopHorizontal: 12,
                    tabletHorizontal: 8,
                    horizontal: 4,
                    top: 6,
                    bottom: 4,
                  }}
                >
                  <Title align='center' size='xsmall'>
                    Vamos conferir sua Classe de Bônus?
                  </Title>
                  <Spacer top={2} />
                  <Text align='center'>
                    Insira as informações do seu último contrato de Seguro Auto e veja se você tem
                    desconto.
                  </Text>
                  <FormSelect
                    classes={classes}
                    options={userBonusesClass}
                    errors={errors}
                    name='userBonusesClass'
                    obj='driver'
                    value={driver.userBonusesClass?.toString()}
                    label='Qual o número da minha classe de bônus?'
                    onChange={updateDriver}
                    horizontal={false}
                    weight={true}
                    align='center'
                  />
                  {driver.userBonusesClass !== 0 &&
                    driver.userBonusesClass !== undefined &&
                    // @ts-ignore
                    driver.userBonusesClass !== '' &&
                    // @ts-ignore
                    driver.userBonusesClass !== 'Selecione' &&
                    driver.userBonusesClass !== null && (
                    <View
                      spacing={{ top: 6 }}
                      size={{ width: 'auto', desktopWidth: '350px' }}
                      style={{ alignSelf: 'center' }}
                    >
                      <Message
                        icon='info'
                        title={`Ganhe até ${bonusesClass(
                          driver.userBonusesClass.toString(),
                        )}% de desconto.`}
                        description='Esse desconto precisa ser confirmado pela sua seguradora anterior.'
                        type={MessageType.success}
                      />
                    </View>
                  )}
                  <FormInput
                    classes={classes}
                    errors={errors}
                    label='Qual o número do código de identificação (CI)?'
                    name='ciNumber'
                    obj='driver'
                    field={driver.ciNumber}
                    onChange={updateCiNumber}
                    weight={true}
                    desktopHorizontal={false}
                    mask='999 **********-*'
                    maskChar={null}
                    align='center'
                    valid={validCiNumber}
                  />
                  {!validCiNumber && <FormInputError message='Insira um CI válido' />}
                  <Spacer top={2} />
                  <View style={{ alignSelf: 'center' }}>
                    <InvisibleButton onClick={() => setIsOpen(true)}>
                      ONDE ENCONTRO ESSE NÚMERO?
                    </InvisibleButton>
                  </View>
                  <Modal {...modalFindCiNumber} isOpen={isOpen} onClose={() => setIsOpen(false)} />
                </View>
              </Card>
            </>
          )}
          <Spacer top={2} />
          <InvisibleButtonLink
            css
            color={theme.colors.primaryInverse}
            href='https://www.youse.com.br/seguro-auto/classe-de-bonus/?_ga=2.114415808.691958999.1620051296-542773130.1616591114'
          >
            LEIA MAIS SOBRE CLASSE DE BÔNUS
          </InvisibleButtonLink>
        </View>
      </Card>
    </>
  )
}

export default BonusClass
