/* eslint-disable import/prefer-default-export */

// Exposed clients
export { default as DataLayerClient } from "./clients/DataLayerClient"
export { default as BFFClient } from "./clients/BFFClient"
export { default as ResourceFlowClient } from "./clients/ResourceFlowClient"

// Exposed helpers
export { default as noDelaySetInterval } from "./helpers/noDelaySetInterval"
export { default as formFieldName } from "./helpers/formFieldName"
export * as licensePlateHelpers from "./helpers/licensePlate"
export * as vinHelpers from "./helpers/vinHelper"
export * as phoneHelpers from "./helpers/phone"

// Exposed contexts
export { ResourceFlowContext, ResourceFlowContextProvider } from "./contexts/ResourceFlowContext"

// Exposed interfaces
export type { default as SeoInterface } from "./interfaces/SeoInterface"

// Exposed components
export { default as SalesJourney } from "./components/SalesJourney"

export { default as FormControl } from "./components/FormControl"
export { default as Loader } from "./components/Loader"
export { default as Header } from "./components/Header"
export { default as Footer } from "./components/Footer"

export { default as LayoutBase } from "./components/LayoutBase"
export { default as LayoutBaseWithSidebar } from "./components/LayoutBaseWithSidebar"
export { default as SidebarIcons } from "./components/LayoutBaseWithSidebar/Sidebar/SidebarIcons"
export { default as SidebarCard } from "./components/LayoutBaseWithSidebar/Sidebar/SidebarCard"
export { default as SidebarCardHeader } from "./components/LayoutBaseWithSidebar/Sidebar/SidebarCard/SidebarCardHeader"
export { default as SidebarCardFooter } from "./components/LayoutBaseWithSidebar/Sidebar/SidebarCard/SidebarCardFooter"
export { default as SidebarCardItem } from "./components/LayoutBaseWithSidebar/Sidebar/SidebarCard/SidebarCardItem"
export { default as SidebarCardPrice } from "./components/LayoutBaseWithSidebar/Sidebar/SidebarCard/SidebarCardPrice"
export { default as AppStoreCtas } from "./components/AppStoreCtas"
export { default as ModalCarNotAccepted } from "./components/ModalCarNotAccepted/ModalCarNotAccepted"
export { default as FormInput } from "./components/FormInput"
export { default as FormInputError } from "./components/FormInputError"
export { default as FormSelect } from "./components/FormSelect"
export { default as FormRadio } from "./components/FormRadio"
export { default as FormSubmit } from "./components/FormSubmit"
export { default as FormHeader } from "./components/FormHeader"
export { default as BonusClass } from "./components/sections/BonusClassSection/BonusClassSection"
export { default as PrivacyDisclaimer } from "./components/PrivacyDisclaimer"
export { default as VehicleVersionSelector } from "./components/VehicleVersionSelector/VehicleVersionSelector"
export { default as VehicleWithoutPlateOrVin } from "./components/VehicleWithoutPlateOrVin"
export { default as PlateInput } from "./components/PlateInput/PlateInput"
export { default as VinInput } from "./components/VinInput/VinInput"
export { default as ModalCarAccepted } from "./components/ModalCardAccepted/ModalCarAccepted"

export { default as LgpdConsent } from "./components/LgpdConsent"

// Exposed pages
export { default as NotFound } from "./components/pages/NotFound"
export { default as SomethingWrong } from "./components/pages/SomethingWrong"
export { default as FinishedOrder } from "./components/pages/FinishedOrder"
export { default as InsuranceNotAccepted } from "./components/pages/InsuranceNotAccepted"

// Exposed sections
export { default as CoveragesSelectionSection } from "./components/sections/CoveragesSelectionSection"
export { default as AssistancesSelectionSection } from "./components/sections/AssistancesSelectionSection"
export { default as RiskAcceptanceSection } from "./components/sections/RiskAcceptanceSection"
export { default as CheckoutSection } from "./components/sections/CheckoutSection"
export { default as PlanSelectionSection } from "./components/sections/PlanSelectionSection"
export { default as IssuanceSection } from "./components/sections/IssuanceSection"
export { default as SuccessSection } from "./components/sections/SuccessSection"
export { default as PaymentConditionSelection } from "./components/sections/PaymentConditionSelection"

// Exposed styles
export { inputStyles } from "./components/styles/inputStyles"
