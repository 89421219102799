import React, { useContext, useEffect, useState } from 'react'
import {
  Card,
  Container,
  designTokens,
  InvisibleButton,
  Message,
  MessageType,
  PrimaryButton,
  RangeSlider,
  Spacer,
  Switch,
  Tag,
  Text,
  Title,
  View,
} from '@youse-seguradora/cargo-components'

import BFFClient from '../../../clients/BFFClient'
import { ResourceFlowContext } from '../../../contexts/ResourceFlowContext'
import SeoInterface from '../../../interfaces/SeoInterface'
import LayoutBaseWithSidebar from '../../LayoutBaseWithSidebar'
import Loader from '../../Loader'

import '../../styles/RangeSliderCustom.css'
import { useTheme } from '@material-ui/core/styles'

const bffClient = new BFFClient()

const CoveragesSelectionSection = (props: any) => {
  const { productName, header } = props

  const theme = useTheme()
  const context = useContext<any>(ResourceFlowContext)
  const product = context.data.resource.product

  // This const represents a coverage that is not selected by the user, this is the basic coverage and always fixed as TRUE
  const REQUIRED_COVERAGE = `bra/${product}/coverage/1`
  const TOTAL_LOSS = 'bra/auto/coverage/2'
  const THEFT_LOSS = 'bra/auto/coverage/3'
  const NATURE_EVENTS_LOSS = 'bra/auto/coverage/4'

  const [firstRender, setFirstRender] = useState<boolean>(true)
  const [interactionEnabled, setInteractionEnabled] = useState<boolean>(false)
  const [hasPricing, setHasPricing] = useState<boolean>(
    context.data.resource.pricing.monthlyTotalCost !== null,
  )
  // const [isHomeBasicCoverageChange, setIsBasicCoverageChange] = useState<boolean>(false)

  const [coverages, setCoverages] = useState<any>([])
  const [coverageSelectedList, setCoverageSelectedList] = useState<any>({})
  const [uidCoverageList, setUidCoverageList] = useState<any>({})
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true)
  const [selectedCoveragesContext, setSelectedCoveragesContext] = useState<any>([])
  const [warningMessage, setWarningMessage] = useState<string>()
  const [isOpen, setIsOpen] = React.useState(true)

  const seo: SeoInterface = {
    title: `${productName} | Youse`,
  }

  useEffect(() => setFirstRender(false), [])

  useEffect(() => {
    if (!firstRender) return

    if (
      product === 'home' &&
      context.status === 422 &&
      context.data.resource.errors?.pricingBase[0]
    ) {
      setWarningMessage(context.data.resource.errors.pricingBase[0])
    }

    bffClient.fetchCoverages(product, context.data.id).then((response: any) => {
      if (response.status !== 200) return

      response.data.forEach((coverage) => {
        setCoverageSelectedList((prevItemSelected) => ({
          ...prevItemSelected,
          [coverage.id]: coverage,
        }))
        setUidCoverageList((prevItemSelected) => ({
          ...prevItemSelected,
          [coverage.uid]: coverage,
        }))
      })

      if (context.data.resource.coverages.length === 0) {
        setCoverages(response.data)
        updateResource(response.data)

        if (product === 'home') {
          enableSubmitButton(response.data)
        }
      } else {
        const coveragesSelected: any[] = []
        response.data.forEach((coverage) => {
          coveragesSelected.push(coverage)
          if (context.data.resource.coverages.includes(coverage.id)) {
            Object.assign(coverage, { selected: true })
          } else {
            Object.assign(coverage, { selected: false })
          }
        })
        setCoverages(coveragesSelected)
        enableSubmitButton(coveragesSelected)
        if (selectedCoveragesContext.length === 0) {
          updateResource(coveragesSelected)
        }
      }
      setInteractionEnabled(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkAutoSpecificCoveragesSelected = () => {
    if (uidCoverageList[TOTAL_LOSS].selected && uidCoverageList[THEFT_LOSS].selected) {
      uidCoverageList[NATURE_EVENTS_LOSS].selected = true
    }
  }

  const updateCoverageSelection = (updatedCoverage) => {
    const updatedCoverageList: any[] = []

    coverages.forEach((coverage) => {
      if (coverage === updatedCoverage) {
        coverage.selected = !coverage.selected

        // Select all coverages when select one with dependsOn
        if (updatedCoverage.dependsOn.length > 0) {
          updatedCoverage.dependsOn.map((updateDependency) => {
            if (coverage.selected) {
              coverageSelectedList[updateDependency].selected = true
            }
          })
        }
      }

      // Deselect all coverages when deselect one with dependsOn
      if (coverage.dependsOn.includes(updatedCoverage.id)) {
        coverageSelectedList[coverage.id].selected = false
      }

      if (product === 'auto') {
        checkAutoSpecificCoveragesSelected()
      }

      if (coverage.autoSelectedBy.includes(updatedCoverage.id)) {
        // When not exists in the list, we remove the selection by auto_selected_by
        coverageSelectedList[coverage.id].selected = false
      }

      validateCopyFromLmi(updatedCoverage, coverage)

      updatedCoverageList.push(coverage)
    })

    enableSubmitButton(coverages)
    setCoverages(updatedCoverageList)
    updateResource(updatedCoverageList)
  }

  const validateCopyFromLmi = (updatedCoverage, coverage) => {
    const copyId = coverage.maximumIndemnityLimit.copiesFrom

    // Select LMI validating all coverages
    if (copyId !== null) {
      coverageSelectedList[coverage.id].maximumIndemnityLimit.selected =
        coverageSelectedList[copyId].maximumIndemnityLimit.selected
    }
    // Select LMI when current coverage with 'copiesFrom' exists
    if (updatedCoverage.maximumIndemnityLimit.copiesFrom !== null) {
      updatedCoverage.maximumIndemnityLimit.selected =
        coverageSelectedList[updatedCoverage.maximumIndemnityLimit.copiesFrom].maximumIndemnityLimit
          .selected ||
        coverageSelectedList[updatedCoverage.maximumIndemnityLimit.copiesFrom].maximumIndemnityLimit
          .range.minimum
    }
  }

  const updateCoverageByRangeSlider = (selectedValue, sliderType, updatedCoverage) => {
    const updatedCoverageList: any[] = []
    let isHomeBasicCoverageChange = false

    coverages.forEach((coverage) => {
      if (coverage === updatedCoverage) {
        if (sliderType === 'deductible') {
          coverage.deductible.selected = selectedValue
        } else {
          coverage.maximumIndemnityLimit.selected = selectedValue

          if(product === 'home' && coverage.uid === REQUIRED_COVERAGE) {
            isHomeBasicCoverageChange = true
          }
        }
      }
      validateCopyFromLmi(updatedCoverage, coverage)

      updatedCoverageList.push(coverage)
    })

    setCoverages(updatedCoverageList)
    updateResource(updatedCoverageList, isHomeBasicCoverageChange)
  }

  const updateLmiBasedOnBasicCoverage = (updatedCoverages) => {
    const updatedCoverageList: any[] = []

    coverages.forEach((coverage) => {
      if(coverage.uid !== REQUIRED_COVERAGE) {
        updatedCoverages.forEach((updatedCoverage) => {
          if(updatedCoverage.uid === coverage.uid) {
            coverage.maximumIndemnityLimit = updatedCoverage.maximumIndemnityLimit
          }
        })
      }
      updatedCoverageList.push(coverage)
    })

    setCoverages(updatedCoverageList)
  }

  const updateResource = (updatedCoverageList, isHomeBasicCoverageChange = false) => {
    const selectedCoverages: any[] = []
    updatedCoverageList.forEach((coverage) => {
      if (coverage.selected || coverage.uid === REQUIRED_COVERAGE) {
        selectedCoverages.push({
          coverageId: coverage.id,
          uid: coverage.uid,
          name: coverage.name,
          description: coverage.description,
          maximumIndemnityLimit:
            coverage.maximumIndemnityLimit.selected ||
            coverage.maximumIndemnityLimit.range?.default ||
            coverage.maximumIndemnityLimit.range?.minimum,
          deductible: {
            type: null,
            value: coverage.deductible.selected || coverage.deductible.range?.default,
          },
        })
      }
    })

    setInteractionEnabled(false)
    setSelectedCoveragesContext(selectedCoverages)

    context.updateResource({ coverages: selectedCoverages }).then(() => {
      if(!firstRender && isHomeBasicCoverageChange) {
        bffClient.fetchCoverages(product, context.data.id).then((response: any) => {
          if (response.status !== 200) return

          updateLmiBasedOnBasicCoverage(response.data)
          setHasPricing(true)
          setInteractionEnabled(true)
        })
      }
      else {
        setInteractionEnabled(true)
        setHasPricing(context.data.resource.pricing.monthlyTotalCost !== null)
      }
    })
  }

  const enableSubmitButton = (allCoverages) => {
    if (product === 'home') {
      setIsButtonDisabled(false)
      return
    }

    const selectedCoverages: boolean[] = []

    allCoverages.filter((coverage) =>
      coverage.selected && coverage.uid !== REQUIRED_COVERAGE && coverage.basic
        ? selectedCoverages.push(coverage.selected)
        : null,
    )

    const selectedCoverageChecker =
      selectedCoverages.length > 0 ? selectedCoverages.every((coverage) => coverage) : false

    if (selectedCoverageChecker) {
      setIsButtonDisabled(false)
    } else {
      setIsButtonDisabled(true)
    }
  }

  if (!hasPricing || coverages.length === 0) {
    return <Loader data='Carregando...' />
  }

  const inclusiveTag = (name: string, backgroundColor: string, color: string) => {
    return (
      <View as='div' style={{ alignItems: 'flex-start' }} spacing={{ top: 2 }}>
        <Tag name={name} backgroundColor={backgroundColor} color={color} isUppercase={false} />
      </View>
    )
  }

  return (
    <LayoutBaseWithSidebar seo={seo} productName={productName}>
      <View
        spacing={{
          bottom: 6,
          right: 4,
          left: 4,
        }}
      >
        {header}
      </View>
      <Container>
        {warningMessage && (
          <Container>
            <Message
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              hasCloseButton
              icon='info'
              title='Se liga aqui'
              description={warningMessage}
              type={MessageType.warning}
            />
            <Spacer bottom={5} />
          </Container>
        )}
        <Title size='small'>
          Olha só as coberturas do {productName} disponíveis pra você escolher.
        </Title>
        <Spacer bottom={10} />
        {coverages.map((coverage) => (
          <View key={`coverage_${coverage.id}`}>
            <Card>
              <View
                spacing={{
                  top: 5,
                  bottom: 5,
                  right: 5,
                  left: 5,
                }}
              >
                <View
                  as='div'
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    flexDirection: 'row',
                  }}
                >
                  <Title align='left' size='xsmall'>
                    {coverage.name}
                  </Title>
                  {!coverage.immutable && (
                    <Switch
                      dataTestId={`coverage-${coverage.id}-switch`}
                      checked={coverage.selected}
                      disabled={!interactionEnabled || coverage.immutable}
                      onChange={() => updateCoverageSelection(coverage)}
                      key={`coverage_${coverage.id}`}
                    />
                  )}
                </View>
                {coverage.required &&
                  inclusiveTag(
                    'Incluso',
                    designTokens.colors.green300,
                    designTokens.colors.green800,
                  )}

                {/* Validate auto_selected_by tags */}
                {!coverage.required && coverage.immutable && coverage.selected
                  ? inclusiveTag(
                    'Incluso',
                    designTokens.colors.green300,
                    designTokens.colors.green800,
                  )
                  : !coverage.required &&
                    coverage.immutable &&
                    !coverage.selected &&
                    inclusiveTag(
                      'Não incluso',
                      designTokens.colors.grey200,
                      designTokens.colors.grey000,
                    )}

                <Spacer all={4} />

                <Text
                  align='left'
                  color={theme.colors.secondary}
                  markdown={coverage.description.selected}
                />
                {coverage.maximumIndemnityLimit.description && (
                  <Text as='span' color={theme.colors.highlight}>
                    {' '}
                    {coverage.maximumIndemnityLimit.description}
                  </Text>
                )}

                {coverage?.deductible?.description && (
                  <>
                    <Spacer all={2} />
                    <Message
                      icon='info'
                      title={coverage.deductible.description}
                      type={MessageType.warning}
                    />
                  </>
                )
                }

                {coverage.selected && coverage.deductible?.range && (
                  <>
                    <Spacer all={4} />
                    <RangeSlider
                      dataTestId={`coverage-${coverage.id}-deductible-slider`}
                      disabled={!interactionEnabled}
                      min={coverage.deductible.range.minimum}
                      max={coverage.deductible.range.maximum}
                      value={
                        coverage.deductible.selected ||
                        coverage.deductible.range.default ||
                        coverage.deductible.range.minimum
                      }
                      title='Valor da Franquia'
                      onChangeComplete={(value) =>
                        updateCoverageByRangeSlider(value, 'deductible', coverage)
                      }
                    />
                  </>
                )}
                {(coverage.selected || coverage.uid === REQUIRED_COVERAGE) && coverage.maximumIndemnityLimit?.range && (
                  <>
                    <Spacer all={4} />
                    <RangeSlider
                      key={
                        `coverage_${coverage.id}_${coverage.maximumIndemnityLimit.selected ||
                        coverage.maximumIndemnityLimit.range.default ||
                        coverage.maximumIndemnityLimit.range.minimum}`
                      }
                      dataTestId={`coverage-${coverage.id}-lmi-slider`}
                      disabled={!interactionEnabled}
                      min={coverage.maximumIndemnityLimit.range.minimum}
                      max={coverage.maximumIndemnityLimit.range.maximum}
                      value={
                        coverage.maximumIndemnityLimit.selected ||
                        coverage.maximumIndemnityLimit.range.default ||
                        coverage.maximumIndemnityLimit.range.minimum
                      }
                      title='Valor da Indenização'
                      onChangeComplete={(value) =>
                        updateCoverageByRangeSlider(value, 'lmi', coverage)
                      }
                    />
                  </>
                )}
              </View>
            </Card>
            <Spacer all={2} />
          </View>
        ))}
        <Spacer top={8} />
        <View layout={{ alignHorizontalCenter: true }}>
          <PrimaryButton
            data-testid='coverages-selection-submit-button'
            disabled={isButtonDisabled}
            onClick={() => context.submit({ coverages: selectedCoveragesContext })}
          >
            Continuar
          </PrimaryButton>
          <Spacer top={3} />
          <InvisibleButton
            data-testid='coverages-selection-back-button'
            onClick={context.navigateBack}
          >
            Voltar
          </InvisibleButton>
        </View>
      </Container>
    </LayoutBaseWithSidebar>
  )
}

export default CoveragesSelectionSection
