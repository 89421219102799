import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PageContext } from '@tiny-fronts/basement'
import { BreadcrumbsRouter, Footer, Header } from '@youse-seguradora/cargo-components'
import { HeaderDataType } from '@youse-seguradora/cargo-components/dist/components/dato/cms/data/header'
import { footerData } from 'helpers/footerProps'
import useAuth from 'hooks/useAuth'
import { UserResponse } from 'pages/ReceptionFlow/interfaces'

import { LayoutBaseProps } from '../types/LayoutBaseProps'

class ResponseError extends Error {
  response!: Response
}

export default function LayoutBase({ title, children }: LayoutBaseProps) {
  const [headerOptions, setHeaderOptions] = useState<HeaderDataType | any>(
    {
      mainNav: {
        home: {
          label: 'Open Insurance',
          href: '/',
        },
      },
      adminNav: {
        title: 'Carregando...',
        items: [
          {
            label: 'MEUS SEGUROS',
            onClick: () => {
              window.open(`${process.env.REACT_APP_HOME}account/`, '_blank')
            }
          },
          {
            label: 'YOUSE FRIENDS',
            onClick: () => {
              window.open(`${process.env.REACT_APP_HOME}seguro-auto/friends/`, '_blank')
            }
          },
          {
            label: 'CLUBE DE BENEFÍCIOS',
            onClick: () => {
              window.open(`${process.env.REACT_APP_HOME}account/partnerships`, '_blank')
            }
          }
        ],
        subNavItems: [
          {
            title: 'MEUS SEGUROS',
            onClick: () => {
              window.open(`${process.env.REACT_APP_HOME}account/`, '_blank')
            }
          },
          {
            title: 'YOUSE FRIENDS',
            onClick: () => {
              window.open(`${process.env.REACT_APP_HOME}seguro-auto/friends/`, '_blank')
            }
          },
          {
            title: 'CLUBE DE BENEFÍCIOS',
            onClick: () => {
              window.open(`${process.env.REACT_APP_HOME}account/partnerships`, '_blank')
            }
          },
          {
            title: 'Sair',
            onClick: () => {
              history.push('/logout')
            },
          },
        ],
        button: {
          label: 'sair',
          icon: 'close',
          onClick: () => {
            history.push('/logout')
          },
        },
      },
    }
  )
  const { setTitle } = React.useContext(PageContext)
  const { token } = useAuth()
  const history = useHistory()

  useEffect(() => {
    fetch(`${process.env.REACT_APP_OPIN_SERVICE_URL}/user/me`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response
        } else {
          const error = new ResponseError(response.statusText)
          error.response = response
          throw error
        }
      })
      .then<UserResponse>((response) => response.json())
      .then((response) => {
        const name = `Olá, ${response.name.trim().split(' ').shift()?.toString()}`

        localStorage.setItem('userName', name)

        return setHeaderOptions({
          ...headerOptions,
          adminNav: {
            ...headerOptions.adminNav,
            title: localStorage.getItem('userName')?.toString() || name
          }
        })
      })
      .catch((err) => {
        if (err.response.status === 401) return history.push('/logout')
        return history.push('/erro-desconhecido')
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setTitle(title)
  }, [title, setTitle])

  document.title = `${title} | Youse`

  const breadcrumbs = {
    items: [],
  }

  return (
    <>
      <Header {...headerOptions as HeaderDataType} admin />

      {title !== 'Home' && <BreadcrumbsRouter currentTitle={title} {...breadcrumbs} />}

      {children}

      <Footer {...footerData} />
    </>
  )
}
