import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  LayoutBase,
  ResourceFlowContext,
  SeoInterface,
} from '@tiny-fronts/resource-flow-components'
import {
  Container,
  Message,
  MessageType,
  SecondaryButton,
  Title,
  TrackingContainer,
  TrackingContext,
  View } from '@youse-seguradora/cargo-components'

import { PlanData } from '../../../contexts/ResourceFlowContext/types'
import ModalPlanSelectionNotAllowed from '../../ModalPlanSelectionNotAllowed/ModalPlanSelectionNotAllowed'

import CustomCard from './PlanCard/CustomCard'
import PlanCard from './PlanCard/PlanCard'
import { planSelectionStyled } from './PlanSelectionStyled'

import { useTheme } from '@material-ui/core/styles'

type PlanSelectionProps = {
  productName?: string
  priceSubtitle?: any
  message?: any
  mainTitle?: string
  mainMessage?: string
  mainMessageDescription?: string
  isCustomized?: boolean
  desktopWidthMessage?: string
  icons?: string[] | any
  iconsActive?: boolean
  withoutPartnershipHighlight?: boolean
  triggerGTMEvent?: (planId, plans) => void
}

const PlanSelection = ({
  productName,
  priceSubtitle,
  message,
  mainTitle,
  mainMessage,
  mainMessageDescription,
  isCustomized,
  desktopWidthMessage,
  icons,
  iconsActive = false,
  withoutPartnershipHighlight = false,
  triggerGTMEvent
}: PlanSelectionProps) => {
  const seo: SeoInterface = {
    title: `${productName} | Youse`,
  }

  const theme = useTheme()
  const context = useContext<any>(ResourceFlowContext)
  const [plans] = useState<PlanData[]>(context.data.extra.plans)
  const [plansItems] = useState(context.data.extra.plansItems)
  const [blockPlanSelection] = useState(context.data.extra.blockPlanSelection)
  const [assistances, setAssistances] = useState<[]>([])
  const [coverages, setCoverages] = useState<[]>([])
  const [progressValue, setProgressValue] = useState<any>(0)
  const planSize = plans.length

  const [openModal, setOpenModal] = useState(false)

  const selectPlan = (planId: string) => {
    if (blockPlanSelection) {
      setOpenModal(true)
    } else {
      context.updateResource({ pricing: { planId: planId } }, false).then(response => {
        const success = response.status === 200
        const currentPlan = plans.filter(plan => plan.id === planId)[0]

        context.setCurrentPlan(currentPlan)

        if (success && typeof triggerGTMEvent === 'function') {
          triggerGTMEvent(planId, plans)
        }
      })
    }
  }

  const setCustomCoveragesSection = () => {
    /* When selected `planId: ""` we redirect currentSection to `coverages_selection_section` */
    context.updateResource({ pricing: { planId: '' } }, false)
  }

  const refScrollArea = useRef<any>()
  const styles = { progressValue, planSize }
  const classes = planSelectionStyled(styles)

  useEffect(() => {
    function progressHandler() {
      const scrolled =
        (refScrollArea?.current?.scrollLeft /
          (refScrollArea?.current?.scrollWidth - refScrollArea?.current?.clientWidth)) *
        100

      setProgressValue(scrolled)
    }
    refScrollArea?.current?.addEventListener('scroll', progressHandler)

    return function cleanup() {
      refScrollArea?.current.removeEventListener('scroll', progressHandler)
    }
  }, [])

  useEffect(() => {
    function handleCoverageAndAssistance() {
      const coverages = plansItems.filter((item: any) => {
        return item.type === 'coverage'
      })
      const assistances = plansItems.filter((item: any) => {
        return item.type === 'assistance'
      })
      setAssistances(assistances)
      setCoverages(coverages)
    }

    handleCoverageAndAssistance()
  }, [plansItems])

  const defaultCardPlans = () => {
    return (
      <>
        <ModalPlanSelectionNotAllowed
          isOpen={openModal}
          setIsOpen={setOpenModal}
          contextNavigation={context.navigateBack}
          buttonText='Informar placa ou chassi'
          description='Precisamos que vc nos informe a placa ou o chassi do veículo. Volte a tela anterior e nos
          informe :)'
          title='Hey, falta pouco para você escolher seu plano!'
        />
        <PlanCard
          plans={plans}
          priceSubtitle={priceSubtitle}
          message={message}
          coverages={coverages}
          assistances={assistances}
          theme={theme}
          selectPlan={selectPlan}
          withoutPartnershipHighlight={withoutPartnershipHighlight}
        />
        {isCustomized && (
          <CustomCard theme={theme} buttonAction={() => setCustomCoveragesSection()} />
        )}
      </>
    )
  }

  return (
    <LayoutBase iconsActive={iconsActive} icons={icons} seo={seo}>
      <View background={{ color: theme.backgrounds.secondary }} spacing={{ bottom: 10 }}>
        {plans.length === 0 ? (
          <View spacing={{ top: 10, bottom: 6, right: 4, left: 4 }}>
            <Title size='small' align='center' color={theme.colors.primary}>
              No momento não temos planos disponível pra vc :(
            </Title>
          </View>
        ) : (
          <>
            <View
              spacing={{
                tabletVertical: 10,
                top: 10,
                bottom: 6,
                right: 4,
                left: 4,
              }}
              layout={{
                tabletAlignHorizontalCenter: true,
                alignVerticalCenter: true,
              }}
            >
              <Title align='center' color={theme.colors.primary}>
                {plans.length <= 1 ? 'Este é o plano ideal pra você!' : mainTitle}
              </Title>

              {mainMessage && (
                <View
                  size={{
                    desktopWidth: desktopWidthMessage ? desktopWidthMessage : '100%',
                  }}
                  spacing={{ top: 6 }}
                >
                  <Message
                    icon='info'
                    title={mainMessage}
                    type={MessageType.highlight}
                    description={mainMessageDescription}
                  />
                </View>
              )}
            </View>
            <Container>
              <div className={classes.progressBar}></div>
            </Container>
            {plans.length > 1 ? (
              <Container className={classes.progressBarWrapper}>
                <div
                  className={isCustomized ? classes.scrollAreaCustom : classes.scrollArea}
                  ref={refScrollArea}
                >
                  {defaultCardPlans()}
                </div>
              </Container>
            ) : (
              <Container className={isCustomized && classes.progressBarWrapper}>
                <div
                  className={isCustomized ? classes.scrollAreaCustom : classes.centerColumn}
                  ref={refScrollArea}
                >
                  {defaultCardPlans()}
                </div>
              </Container>
            )}
          </>
        )}

        <Container>
          <View spacing={{ top: 6 }} />
          <TrackingContext category='route-change'>
            <TrackingContainer action='info' label='voltar'>
              <SecondaryButton
                onClick={context.navigateBack}
                data-testid='plan-selection-back-button'
              >
                VOLTAR
              </SecondaryButton>
            </TrackingContainer>
          </TrackingContext>
        </Container>
      </View>
    </LayoutBase>
  )
}

export default PlanSelection
