import React, { useContext, useEffect, useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  InvisibleButton,
  Message,
  MessageType,
  Spacer,
  Text,
  Title,
  View,
} from '@youse-seguradora/cargo-components'
import copy from 'copy-to-clipboard'
import QRCode from 'qrcode.react'

import BFFClient from '../../../../../../clients/BFFClient'
import PixContext from '../../../../../../contexts/PixContext/PixContext'
import { ResourceFlowContext } from '../../../../../../contexts/ResourceFlowContext'

import PixQrCodeError from './PixQrCodeError'

import { useTheme } from '@material-ui/core/styles'

const bffClient = new BFFClient()

type PixQrCodeProps = {
  setPixTutorialStep: React.Dispatch<React.SetStateAction<boolean>>
  setPixQrCodeStep: React.Dispatch<React.SetStateAction<boolean>>
}

const PixQrCode = ({ setPixTutorialStep, setPixQrCodeStep }: PixQrCodeProps) => {
  const RESET_TIMEOUT_MILLISECONDS = 2000
  const theme = useTheme()
  const context = useContext<any>(ResourceFlowContext)
  const pix = useContext(PixContext)

  const [qrCodeValue, setQrCodeValue] = useState<string>('')
  const [showQrCodeError, setShowQrCodeError] = useState<boolean>(false)
  const [labelButton, setLabelButton] = useState<string>('COPIAR CÓDIGO')

  useEffect(() => {
    createQrCode()
  }, [])

  const copyQrCode = () => {
    copy(qrCodeValue)
    setLabelButton('COPIADO')

    setTimeout(() => {
      setLabelButton('COPIAR CÓDIGO')
    }, RESET_TIMEOUT_MILLISECONDS)
  }

  const getInvoiceId = (invoiceId: string) => {
    pix.setInvoiceId(invoiceId)
  }

  const createQrCode = () => {
    setQrCodeValue('')
    setShowQrCodeError(false)

    bffClient
      .fetchPaymentConditionQRCode(context.data.resource.product, context.data.id, 'pix')
      .then((response: any) => {
        if (response.status === 200) {
          setQrCodeValue(response.data.brcode)
          getInvoiceId(response.data.invoiceId)
        } else {
          setShowQrCodeError(true)
        }
      })
  }

  const backToTutorial = () => {
    setPixTutorialStep(true)
    setPixQrCodeStep(false)
  }

  return (
    <>
      <View
        spacing={{ top: 4 }}
        style={{ padding: '26px' }}
        layout={{ alignHorizontalCenter: true }}
      >
        {!showQrCodeError && (
          <>
            <Title size='xsmall' align='center' color={theme.colors.highlight}>
              Pague via QR Code ou código
            </Title>
            <Spacer top={3} />
            <Message
              icon='info'
              title='Importante:'
              description='Nem todas as instituições financeiras estão oferecendo essa modalidade de pagamento.'
              type={MessageType.highlight}
            />
            <View spacing={{ top: 4 }} layout={{ alignHorizontalCenter: true }}>
              {qrCodeValue ? (
                <>
                  <QRCode value={qrCodeValue} size={200} />

                  <Spacer top={4} />

                  <Text size='xsmall' align='center' color={theme.colors.secondary}>
                    Vc também pode usar o Pix Copia e Cola.
                  </Text>

                  <Spacer top={2} />

                  <InvisibleButton
                    children={labelButton}
                    color={theme.colors.highlight}
                    onClick={copyQrCode}
                  />
                </>
              ) : (
                <View spacing={{ top: 4 }}>
                  <CircularProgress style={{ color: theme.colors.highlightInverse }} />
                </View>
              )}
            </View>
          </>
        )}
        {showQrCodeError && (
          <PixQrCodeError createQrCode={createQrCode} backToTutorial={backToTutorial} />
        )}
      </View>
    </>
  )
}

export default PixQrCode
