import React from 'react'
import InputMask from 'react-input-mask'
import CircularProgress from '@material-ui/core/CircularProgress'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'

type MaskProps = {
  classes: any
  value: string
  setValue: any
  name?: string
  field?: string
  onChange?: any
  inputType?: string
  formField?: any
  mask?: any
  valid?: boolean
  loader?: boolean
  maskChar?: string | null
}

const Mask = ({
  classes,
  value,
  setValue,
  name,
  onChange,
  inputType,
  formField,
  mask,
  maskChar,
  valid = true,
  loader = false,
}: MaskProps) => {
  return (
    <InputMask
      className={classes.inputMaterial}
      id={formField}
      autoComplete='nope'
      defaultValue={value}
      onBlur={(e) => onChange(name, e.target.value)}
      onChange={(e) => setValue(e.target.value)}
      type={inputType}
      mask={mask}
      maskChar={maskChar}
    >
      {(inputProps) => (
        <Input
          {...inputProps}
          inputProps={{ 'data-testid': `${name}-input` }}
          error={valid ? false : true}
          fullWidth={true}
          endAdornment={
            loader && (
              <InputAdornment position='end'>
                <CircularProgress size={20} color='secondary' />
              </InputAdornment>
            )
          }
        />
      )}
    </InputMask>
  )
}

export default Mask
