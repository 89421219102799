import { FAQ_DATA } from './faqData'

const currentUrl = window.location.href
const claimsUrlRegex = /\/solicitacao\/sinistro\/(\d+)/
const validateIfCurrentUrlMatchs  = currentUrl.match(claimsUrlRegex)
let protocol = ''

if (validateIfCurrentUrlMatchs ) {
  protocol = validateIfCurrentUrlMatchs[1]
}

export const claim = {
  title: 'Recebemos sua solicitação de aviso de sinistro',
  description: 'A alteração no contrato será feita após a validação dos dados. Entraremos em contato por telefone para confirmar.',
  image: 'https://www.datocms-assets.com/16903/1590783393-illus-characters-success-04-4x3-light.png',
  textCard: 'Aviso de sinistro',
  infoCard: [
    ['Prazo de resposta', '1 dia útil'],
    ['Protocolo:', protocol]
  ],
  titleFaq: 'Ficou com alguma dúvida?',
  faqData: FAQ_DATA
}
