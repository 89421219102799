import React from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Divider, InvisibleButton, Spacer, Text,Title, View } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import useAuth from 'hooks/useAuth'
import { isMobile } from 'utils/IsMobile'

class ResponseError extends Error {
  response!: Response
}

export const ConsentActions = ({ isReception, setModal, consentId }) => {
  const history = useHistory()
  const { token } = useAuth()

  const revokeConsent = () => {
    const consentType = isReception ? 'data-reception' : 'data-transmission'

    fetch(`${process.env.REACT_APP_OPIN_SERVICE_URL}/consents/${consentType}/${consentId}/revoke`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 204) {
          return history.push('/gestao/revogado-com-sucesso')
        } else {
          const error = new ResponseError(response.statusText)
          error.response = response
          throw error
        }
      })
      .catch(() => history.push('/erro-desconhecido'))
  }

  return (
    <Card
      style={{
        borderRadius: 8,
        padding: isMobile() ? 16 : 34,
        columnGap: '56px',
        rowGap: '32px',
        display: 'flex',
        flexDirection: isMobile() ? 'column' : 'initial'
      }}
    >
      {isReception &&
        <>
          <View>
            <Title
              size={'xsmall'}
              weight={'bold'}
              color={defaultTheme.colors.primary}
            >
              Renovar compartilhamento
            </Title>

            <Spacer top={2} />

            <Text
              size={'default'}
              weight={'regular'}
              color={defaultTheme.colors.secondary}
            >
              Na renovação, dá pra aumentar ou diminuir o limite do prazo que você quer compartilhar os seus dados.
            </Text>

            <InvisibleButton
              children='Renovar'
              rightIcon='chevron-right'
              contextual
              onClick={() => setModal({
                open: true,
                image: 'https://www.datocms-assets.com/16903/1612473607-illus-objects-parcerias-personalizado-4x3-light.png',
                title: 'Renovação de compartilhamento',
                subTitle: 'O processo de renovação utiliza dados já preenchidos para iniciar um novo processo de compartilhamento de dados',
                prevButton: {
                  name: 'Cancelar renovação',
                },
                nextButton: {
                  name: 'Quero renovar',
                  nextMethod: () => history.push(`/renovacao/${consentId}`)
                }
              })}
            />

            {isMobile() && <Divider bottom={1} />}
          </View>

          <View>
            <Title
              size={'xsmall'}
              weight={'bold'}
              color={defaultTheme.colors.primary}
            >
              Alterar compartilhamento
            </Title>

            <Spacer top={2} />

            <Text
              size={'default'}
              weight={'regular'}
              color={defaultTheme.colors.secondary}
            >
              Na alteração, você cria um novo compartilhamento, reaproveitando seus dados. Ah, o atual é encerrado, blz? 
            </Text>

            <InvisibleButton
              children='Alterar'
              rightIcon='chevron-right'
              contextual
              onClick={() => setModal({
                open: true,
                image: 'https://www.datocms-assets.com/16903/1587668953-illus-objects-cellphone-choose-add-remove-4x3-light-2x.png',
                title: 'Alteração de compartilhamento',
                subTitle: 'O processo de alteração é irreversível e encerra o compartilhamento atual',
                prevButton: {
                  name: 'Cancelar alteração',
                },
                nextButton: {
                  name: 'Quero alterar',
                  nextMethod: () => history.push(`/alteracao/${consentId}`)
                }
              })}
            />

            {isMobile() && <Divider bottom={1} />}
          </View>
        </>
      }

      <View style={{ maxWidth: '320px' }}>
        <Title
          size={'xsmall'}
          weight={'bold'}
          color={defaultTheme.colors.primary}
        >
          Encerrar compartilhamento
        </Title>

        <Spacer top={2} />

        <Text
          size={'default'}
          weight={'regular'}
          color={defaultTheme.colors.secondary}
        >
          No encerramento, você para de compartilhar os dados dessa instituição com a Youse.
        </Text>

        <InvisibleButton
          children='Encerrar'
          rightIcon='chevron-right'
          contextual
          onClick={() => setModal({
            open: true,
            image: 'https://www.datocms-assets.com/16903/1587671029-illus-objects-cellphone-attention-4x3-light-2x.png',
            title: 'Você quer mesmo cancelar essa solicitação?',
            subTitle: 'Se você cancelar, vai ter que começar todo o processo desde o início.',
            prevButton: {
              name: 'Cancelar solicitação',
            },
            nextButton: {
              name: 'Continuar solicitação',
              nextMethod: () => revokeConsent()
            }
          })}
        />
      </View>
    </Card>
  )
}
