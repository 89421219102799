export enum OrdersScreenPermissionsKeys {
  ABA_AUTO = 'aba_auto',
  ABA_RESIDENCIAL = 'aba_residencial',
  ABA_VIDA = 'aba_vida',
}

export enum PolicyScreenPermissionsKeys {
  BOTAO_CANCELAR_APOLICE = 'botao_cancelar_apolice',
  BOTAO_EDITAR_APOLICE = 'botao_editar_apolice',
  BOTAO_ABRIR_SINISTRO = 'botao_abrir_sinistro',
  BOTAO_ACIONAR_ASSISTENCIA = 'botao_acionar_assistencia',
}
