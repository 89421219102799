import React from 'react'
import { Accordion, Spacer, Text,Title, View } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'

export const PermissionsList = ({ permissions }) => {
  const filteredPermissions = permissions.reduce((currentPermission, obj) => {
    const sectionIndex = currentPermission.findIndex((item) => item.section === obj.section)

    if (sectionIndex === -1) {
      currentPermission.push({
        section: obj.section,
        content_section: [{
          title: obj.title,
          permissions: obj.permissions
        }]
      })
    } else {
      currentPermission[sectionIndex].content_section.push({
        title: obj.title,
        permissions: obj.permissions
      })
    }

    return currentPermission
  }, [])

  return (
    filteredPermissions.map((section, index) => (
      <View key={index}>
        {index > 0 && <Spacer top={8} />}

        <Title
          size={'xsmall'}
          weight={'bold'}
          color={defaultTheme.colors.primary}
        >
          {section.section}
        </Title>

        <Spacer top={4} />

        {section.content_section.map((permission, indexPermission) => (
          <Accordion
            key={indexPermission}
            items={[
              {
                title: permission.title,
                children: <ul>
                  {permission.permissions.map((opt, index) => (
                    <li key={index} style={{ paddingLeft: -16, marginLeft: -16 }}>
                      <Text
                        size={'small'}
                        weight={'regular'}
                        color={defaultTheme.colors.secondary}
                      >
                        {opt}
                      </Text>
                    </li>
                  ))}
                </ul>,
                dataTestId: permission.title
              }
            ]}
          />
        ))}
      </View>
    ))
  )
}
