import React, { Key, useState } from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import {
  designTokens,
  Divider,
  Input,
  InvisibleButton,
  Message,
  MessageType,
  PrimaryButton,
  Spacer,
  Text,
  View } from '@youse-seguradora/cargo-components'
import { Auth } from 'aws-amplify'

import HeaderLogin from './HeaderLogin'


type ChallengeCodeProps = {
  user: Record<Key, string> | undefined
  currentEmail: string
  setShowChallengeCode: React.Dispatch<React.SetStateAction<boolean>>
}

const fiveDigitsRegex = /^\d{5}$/

const ChallengeCode = ({ user, currentEmail, setShowChallengeCode }: ChallengeCodeProps ) => {
  const { toResetPassword, toSignIn } = useAuthenticator()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [challengeCode, setChallengeCode] = useState('')
  const isValidChallengeCode = fiveDigitsRegex.test(challengeCode)

  const backSignIn = () => {
    toResetPassword()
    toSignIn()
    setShowChallengeCode(false)
  }


  const handleChallengeCode = (value) => {
    const codeWithoutSpace = value.replace(/\D/g, '')
    setChallengeCode(codeWithoutSpace)
  }

  const signInByCode = async () => {
    setLoading(true)

    try {
      await Auth.sendCustomChallengeAnswer(user, challengeCode)

      window.location.href = '/oauth_callback'
    } catch (error) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  return (
    <View
      layout={{ alignHorizontalCenter: true }}
      background={{ color: '#fff' }}
      style={{ maxWidth: '464px' }}
    >
      <HeaderLogin title='Digite o código de acesso'>
        <Text color={designTokens.colors.grey500} align='center'>
          Enviamos um código no e-mail <strong>{currentEmail}</strong>.<br />
          Digite abaixo o código de cinco dígitos que recebeu.
        </Text>
      </HeaderLogin>

      <View as='form'>
        <Input
          type='text'
          name='challengeCode'
          label='Código recebido no e-mail'
          onChange={handleChallengeCode}
        />

        <Spacer bottom={4} />

        <PrimaryButton onClick={signInByCode} disabled={!isValidChallengeCode || loading || error}>
          acessar o portal de parceiros youse
        </PrimaryButton>

        {error && (
          <>
            <View spacing={{ top: 5, bottom: 6 }}>
              <Message
                icon='info-filled'
                title='Código inválido. Clique no botão abaixo e entre com as informações de login novamente para receber outro código de acesso.'
                type={MessageType.error}
              />
            </View>

            <InvisibleButton center onClick={backSignIn} leftIcon='chevron-left'>
              voltar para o login e tentar novamente
            </InvisibleButton>
          </>
        )}

        <div style={{ margin: '32px 0' }}>
          <Divider bottom={1} />
        </div>

        <Text align='center'>
          Não recebeu o e-mail? Volte ao login, insira e-mail e senha novamente para receber outro código.
        </Text>

        <View layout={{ alignHorizontalCenter: true }} spacing={{ top: 5 }}>
          <InvisibleButton center onClick={backSignIn} leftIcon='chevron-left'>
            voltar para o login
          </InvisibleButton>
        </View>
      </View>
    </View>
  )
}

export default ChallengeCode
