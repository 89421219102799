import React from 'react'
import { DataPrivacyDisclaimer } from '@youse-seguradora/cargo-components'

const PrivacyDisclaimer = () => (
  <DataPrivacyDisclaimer title='Como meus dados são usados?'>
    <DataPrivacyDisclaimer.Description>
      As informações do seu carro ajudam a gente a calcular o seu seguro. Usamos os seus dados
      pessoais para ativar, simular e cotar produtos que você tem interesse.
    </DataPrivacyDisclaimer.Description>
  </DataPrivacyDisclaimer>
)

export default PrivacyDisclaimer
