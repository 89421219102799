import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Column,
  ColumnsContainer,
  Divider,
  IconButton,
  Input,
  RadioInput,
  Spacer,
  Text,
  View,
} from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import useAuth from 'hooks/useAuth'
import { desktopContent, mobileContent } from 'styles/ContentStyle'
import { isMobile } from 'utils/IsMobile'

import { InstituitionDetails } from 'components/Modal/InstituitionDetails'

class ResponseError extends Error {
  response!: Response
}

interface InstituitionProps {
  AuthorisationServers: BrandProps[]
  OrganisationName: string
  RegistrationNumber: string
  TotalAuthorisationServer: number
}

interface BrandProps {
  AuthorisationServerId: string
  CustomerFriendlyDescription: string
  CustomerFriendlyLogoUri: string
  CustomerFriendlyName: string
  RegistrationNumber: string
  TotalAuthorisationServer: number
  brands: BrandProps[]
}

export const ChooseInstitution = ({ form, setForm }) => {
  const [searchText, setSearchText] = useState('')
  const [delayTimer, setDelayTimer] = useState<NodeJS.Timeout | null>(null)
  const [selectedInstitution, setSelectedInstitution] = useState<BrandProps>({
    AuthorisationServerId: '',
    CustomerFriendlyDescription: '',
    CustomerFriendlyLogoUri: '',
    CustomerFriendlyName: '',
    RegistrationNumber: '',
    TotalAuthorisationServer: 0,
    brands: []
  })
  const [openDetails, setOpenDetails] = useState(false)
  const [institutionList, setInstitutionList] = useState<BrandProps[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const history = useHistory()

  const { token } = useAuth()

  const handleInstitution = (item) => {
    const radio = document.querySelector(`input[value="${item.CustomerFriendlyName}"]`) as HTMLInputElement | null
    radio?.click()
    setForm({ ...form, brand: item })
  }

  useEffect(() => {
    if (delayTimer) {
      clearTimeout(delayTimer)
    }
  }, [delayTimer])

  const fetchParticipants = () => {
    if (searchText.length < 3) return []

    setLoading(true)

    fetch(`${process.env.REACT_APP_OPIN_SERVICE_URL}/participants?name=${searchText}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response
        } else {
          const error = new ResponseError(response.statusText)
          error.response = response
          throw error
        }
      })
      .then<InstituitionProps[]>((response) => response.json())
      .then((response) => {
        const newResponse = response.reduce((acc, curr) => {
          curr.AuthorisationServers.map((item) => {
            item = {
              ...item,
              TotalAuthorisationServer: curr.TotalAuthorisationServer,
              RegistrationNumber: curr.RegistrationNumber,
              brands: curr.AuthorisationServers
            } as any

            return acc.push(item)
          })

          return acc
        }, [] as any)

        setInstitutionList(newResponse)
      })
      .catch(() => history.push('/erro-desconhecido'))
      .finally(() => setLoading(false))

    return null
  }

  const handleSearchInput = (value) => {
    setSearchText(value)

    if (delayTimer) {
      clearTimeout(delayTimer)
    }

    const newDelayTimer = setTimeout(() => {
      fetchParticipants()
    }, 1500)

    return setDelayTimer(newDelayTimer)
  }

  const openInstitutionDetails = (item) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'Event_Data',
        event_type: 'new',
        ga_event: {
          page_title: 'tela-instituição-que-você-quer-trazer-seus-dados',
          page_location: window.location.href,
          action: 'click',
          element_name: 'tela-instituição-que-você-quer-trazer-seus-dados: Detalhes da instituição'
        },
        product: {
          flow: 'OPIN Recepção'
        }
      })
    }

    setSelectedInstitution(item as any)
    setOpenDetails(true)
  }

  useEffect(() => {
    fetchParticipants()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  return (
    <>
      <View
        style={isMobile() ? mobileContent : desktopContent}
      >
        <ColumnsContainer>
          <Column tabletSize={12}>
            <View style={{ width: window.innerWidth < 900 ? '100%' : '50%' }}>
              <Input
                name='search'
                type={'text'}
                value={searchText}
                label='Buscar instituição'
                icon={{
                  name: !searchText.length ? 'search' : 'close',
                  color: defaultTheme.colors.secondary,
                  children: !searchText.length ? null : (
                    <IconButton
                      onClick={() => setSearchText('')}
                      icon='close'
                      colorIcon={defaultTheme.colors.primaryInverse}
                      backgroundColor={defaultTheme.colors.highlight}
                    />
                  ),
                }}
                onChange={(e) => handleSearchInput(e)}
                color={defaultTheme.colors.secondary}
              />

              {searchText.length > 2 && !institutionList.length && !loading && (
                <Text size={'small'} weight={'regular'} color={defaultTheme.colors.primary}>
                  Não encontramos nenhuma intituição com esses caracteres. Tente novamente.
                </Text>
              )}
            </View>
          </Column>

          {searchText.length > 2 && !!institutionList.length && (
            <Column tabletSize={12}>
              <Text size={'default'} weight={'bold'} color={defaultTheme.colors.primary}>
                {institutionList.length} resultados
              </Text>

              <Spacer bottom={2} />

              <Divider bottom={1} />

              <Spacer bottom={7} />
            </Column>
          )}

          {searchText.length > 2 &&
            <>
              {!loading
                ? institutionList.map((item, index) => (
                  <Column key={index} tabletSize={4}>
                    <View
                      layout={{ horizontal: true }}
                      style={{
                        width: '100%',
                        justifyContent: 'space-between'
                      }}
                    >
                      <View onClick={() => handleInstitution(item)} layout={{ horizontal: true }}>
                        <View style={{ width: '16px' }}>
                          <RadioInput
                            options={[{ label: '', name: 'participant', value: item.CustomerFriendlyName }]}
                          />
                        </View>

                        <Spacer right={4} />

                        <View>
                          <Text
                            size={'default'}
                            weight={'regular'}
                            color={defaultTheme.colors.primary}
                          >
                            {item.CustomerFriendlyName}
                          </Text>

                          <Text
                            size={'small'}
                            weight={'regular'}
                            color={defaultTheme.colors.secondary}
                          >
                            {item.TotalAuthorisationServer} instituições
                          </Text>
                        </View>
                      </View>

                      <IconButton
                        onClick={() => openInstitutionDetails(item)}
                        icon='chevron-right'
                        colorIcon='#F9660B'
                        backgroundColor='transparent'
                      />
                    </View>
                  </Column>
                ))
                : <>Carregando...</>
              }
            </>}
        </ColumnsContainer>
      </View>

      {openDetails &&
        <InstituitionDetails
          instituition={selectedInstitution}
          openDetails={openDetails}
          setOpenDetails={setOpenDetails}
        />
      }
    </>
  )
}
