import React from 'react'
import {
  Input,
  View
} from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import { desktopContent, mobileContent } from 'styles/ContentStyle'
import { CPFValidator } from 'utils/CPFValidator'
import { isMobile } from 'utils/IsMobile'

export const UserForm = ({ form, setForm, error, setError }) => {
  const validName = () => {
    if (!form.name.length) {
      setError({
        ...error,
        name: {
          isError: true,
          message: 'Preencha seu nome.'
        }
      })
    } else {
      setError({
        ...error,
        name: {
          isError: false,
          message: ''
        }
      })
    }
  }

  const validCPF = () => {
    if (!form.cpf.length) {
      return setError({
        ...error,
        cpf: {
          isError: true,
          message: 'Preencha seu CPF.'
        }
      })
    }

    if (!CPFValidator(form.cpf)) {
      return setError({
        ...error,
        cpf: {
          isError: true,
          message: 'Preencha um CPF válido.'
        }
      })
    }

    return setError({
      ...error,
      cpf: {
        isError: false,
        message: ''
      }
    })
  }

  return (
    <View
      style={isMobile() ? mobileContent : desktopContent}
    >
      <Input
        name='name'
        type={'text'}
        value={form.name}
        label='Nome completo*'
        onChange={(e) => setForm({ ...form, name: e })}
        color={defaultTheme.colors.secondary}
        onBlur={validName}
        messageError={{
          isError: error.name.isError,
          message: error.name.message
        }}
      />

      <Input
        name='cpf'
        type={'text'}
        value={form.cpf}
        mask={'999.999.999-99'}
        label='CPF*'
        onChange={(e) => setForm({ ...form, cpf: e })}
        color={defaultTheme.colors.secondary}
        onBlur={validCPF}
        messageError={{
          isError: error.cpf.isError,
          message: error.cpf.message
        }}
      />
    </View>
  )
}
