import { DocumentNode, gql } from '@apollo/client'

const QUERY_NOTIFICATIONS_DETAILS = gql`
  query Notifications(
    $limit: Int
    $page: String
    $messageSubtype: String
    $messageType: String
  ) {
    notifications(
      limit: $limit
      nextPage: $page
      messageSubtype: $messageSubtype
      messageType: $messageType
    ) {
      nodes {
        id
        expiresAt
        messageRead
        messageType
        messageSubtype
        description
        createdAt
      }
      pageInfo {
        nextPage
      }
    }
  }
`

const QUERY_NOTIFICATIONS_TOTAL = gql`
  query NotificationsTotal {
    notificationsTotal {
      total
      unread
      items {
        messageType
        messageSubtype
        total
        unread
        lastMsg
      }
    }
  }
`

const UPDATE_NOTIFICATIONS: DocumentNode = gql`
    mutation UpdateNotification(
        $messageType: String,
        $messageSubtype: String
    ) {
        updateNotification(input: {
            attributes: {
                messageType: $messageType
                messageSubtype: $messageSubtype
            }
        }) {
            success
        }
    }
`


export {
  QUERY_NOTIFICATIONS_DETAILS,
  QUERY_NOTIFICATIONS_TOTAL,
  UPDATE_NOTIFICATIONS
}
