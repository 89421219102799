import React from 'react'
import { Accordion, Spacer, Title } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import { PermissionWithSection } from 'types/Consent'

import PermissionItem from './PermissionItem'

interface PermissionsProps {
  permissions: PermissionWithSection[]
}

const groupBy = <K extends string | number | symbol, S extends Record<K, any>>(
  arr: S[],
  by: K,
): Record<K, S[]> => {
  return arr.reduce<Record<K, S[]>>((acc: Record<K, S[]>, current: S) => {
    if (!acc[current[by]]) {
      acc[current[by]] = []
    }
    acc[current[by]].push(current)
    return acc
  }, {} as Record<K, S[]>)
}

export default function Permissions({ permissions }: PermissionsProps) {
  const groupedPermissions: Record<string, PermissionWithSection[]> = groupBy<
    string,
    PermissionWithSection
  >(permissions, 'section')

  return (
    <>
      {Object.keys(groupedPermissions).map((section, index) => {
        const permissionsSection = groupedPermissions[section]

        return (
          <div key={index}>
            {index > 0 && <Spacer top={8} />}
            <Title size={'xsmall'} weight={'bold'} color={defaultTheme.colors.primary}>
              {section}
            </Title>
            <Spacer top={4} />
            <Accordion
              items={permissionsSection.map((section) => ({
                title: section.title,
                children: (
                  <>
                    {section.permissions.map((item, i) => {
                      return <PermissionItem key={i} item={item} />
                    })}
                  </>
                ),
              }))}
            />
          </div>
        )
      })}
    </>
  )
}
