import React, { useContext, useEffect, useState } from 'react'
import {
  DataPrivacyDisclaimer,
  InvisibleButton,
  PrimaryButton,
  Spacer,
  TrackingContainer,
  TrackingContext,
  View,
  Visible } from '@youse-seguradora/cargo-components'

import PixContext from '../../../contexts/PixContext/PixContext'
import { ResourceFlowContext } from '../../../contexts/ResourceFlowContext'
import LayoutBaseWithSidebar from '../../LayoutBaseWithSidebar'
import { SidebarCardProps } from '../../LayoutBaseWithSidebar/Sidebar/SidebarCard'
import { SidebarIconsProps } from '../../LayoutBaseWithSidebar/Sidebar/SidebarIcons'

import AcceptTermsConditions from './AcceptTermsConditions/AcceptTermsConditions'
import ConfirmEmail from './ConfirmEmail/ConfirmEmail'
import Message from './Message/Message'
import ModalBonusesClass from './ModalBonusesClass/ModalBonusesClass'
import ModalLGPDContent from './ModalLGPDContent/ModalLGPDContent'
import PaymentConditions from './PaymentConditions/PaymentConditions'
import Summary from './Summary/Summary'

type CustomerCheckoutProps = {
  productName: string
  seo: any
  sidebarIcons?: SidebarIconsProps
  sidebarCard?: SidebarCardProps
  customFooter?: React.ReactNode
  iconHeaderPartner?: string[]
  message?: React.ReactNode
  isLGPGEnabled?: boolean
  isSidebarNotifyOrder?: boolean
  isMobileBackgroundColorAside?: boolean
  backButton?: boolean
  restartQuotation?: boolean
  triggerGTMEvent?: (paymentMethod: string, installmentsFrequency: string) => void
}

const CustomerCheckout = ({
  productName,
  seo,
  sidebarIcons,
  sidebarCard,
  customFooter,
  iconHeaderPartner,
  message,
  isLGPGEnabled,
  isSidebarNotifyOrder,
  isMobileBackgroundColorAside,
  backButton,
  restartQuotation,
  triggerGTMEvent
}: CustomerCheckoutProps) => {
  const context = useContext<any>(ResourceFlowContext)
  const showBonusesClass = context.data.resource.bonusesClass?.visible

  const [hiddenSubmit, setHiddenSubmit] = useState<boolean>(false)
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true)
  const [hasError, setHasError] = useState<boolean>(false)
  const [insuredPersonEmail, setInsuredPersonEmail] = useState<string>(
    context.data.resource.insuredPerson.email,
  )
  const [insuredPersonEmailConfirm, setInsuredPersonEmailConfirm] = useState<string>()
  const [isInsuredPersonEmailNotEqual, setIsInsuredPersonEmailNotEqual] = useState<boolean>(true)
  const [creditCard, setCreditCard] = useState<any>({})
  const [isCreditCardValid, setIsCreditCardValid] = useState<boolean>(false)
  const [isPaymentConditionEnabled, setIsPaymentConditionEnabled] = useState<boolean>(false)

  const pix = useContext(PixContext)

  useEffect(() => {
    setHasError(context.status !== 200)
    setHiddenSubmit(false)

    const paymentMethod = context.data.resource.paymentCondition.paymentMethod
    if (paymentMethod === 'credit_card' && !isCreditCardValid) {
      setDisabledSubmit(true)
    } else if (paymentMethod === 'pix' && pix.invoiceId === '') {
      setDisabledSubmit(true)
    } else {
      setDisabledSubmit(isInsuredPersonEmailNotEqual || !isPaymentConditionEnabled)
    }
  }, [isInsuredPersonEmailNotEqual, isCreditCardValid, isPaymentConditionEnabled, pix.invoiceId])

  const submit = () => {
    const paymentMethod = context.data.resource.paymentCondition.paymentMethod

    const attributes = {
      insuredPerson: {
        email: insuredPersonEmail,
      },
    }

    if (paymentMethod === 'credit_card' && isCreditCardValid) {
      Object.assign(attributes, {
        payment: {
          creditCard: {
            number: creditCard.encryptedCardNumber,
            holder: creditCard.holderName,
            due_date_month: creditCard.encryptedExpiryMonth,
            due_date_year: creditCard.encryptedExpiryYear,
            security_code: creditCard.encryptedSecurityCode,
            brand: creditCard.brand,
          },
        },
      })
    } else if (paymentMethod === 'pix') {
      Object.assign(attributes, {
        payment: {
          invoice_id: pix.invoiceId,
        },
      })
    }

    context.updateResource(attributes, false).then(response => {
      const success = response.status === 200

      if (success && typeof triggerGTMEvent === 'function') {
        const installmentsFrequency = context.data.resource.paymentCondition.installmentsFrequency

        triggerGTMEvent(paymentMethod, installmentsFrequency)
      }
    })
  }

  const renderFooterActions = () => {
    if (hiddenSubmit) {
      return
    }

    return (
      <>
        <TrackingContext category='route-change'>
          <TrackingContainer action='buy' label='finalizar compra'>
            <PrimaryButton
              disabled={disabledSubmit}
              onClick={submit}
              style={{ height: '50px' }}
              data-testid={'checkout-submit-button'}
            >
              Finalizar compra
            </PrimaryButton>
          </TrackingContainer>
        </TrackingContext>

        <Visible mobile tablet>
          <Spacer top={4} />
        </Visible>

        {backButton && (
          <TrackingContext category='route-change'>
            <TrackingContainer action='back_to_plans' label='voltar para plans'>
              <InvisibleButton
                onClick={context.navigateToPlans}
                data-testid={'checkout-back-button'}
              >
                Voltar
              </InvisibleButton>
            </TrackingContainer>
          </TrackingContext>
        )}
        {restartQuotation && (
          <TrackingContext category='route-change'>
            <TrackingContainer action='back_to_lead_info' label='refazer cotação'>
              <InvisibleButton onClick={context.restart} data-testid={'checkout-restart-button'}>
                Refazer Cotação
              </InvisibleButton>
            </TrackingContainer>
          </TrackingContext>
        )}
      </>
    )
  }

  return (
    <LayoutBaseWithSidebar
      productName={productName}
      seo={seo}
      iconHeaderPartner={iconHeaderPartner}
      sidebarIcons={sidebarIcons}
      sidebarCard={sidebarCard}
      customFooter={customFooter}
      isSidebarNotifyOrder={isSidebarNotifyOrder}
      isMobileBackgroundColorAside={isMobileBackgroundColorAside}
      footerButton={renderFooterActions()}
      backButton={backButton}
    >
      <Message hasError={hasError} message={message} />

      <PaymentConditions
        onCreditCardFulfilled={(data) => {
          setCreditCard(data)
        }}
        isCreditCardValid={isCreditCardValid}
        setIsCreditCardValid={setIsCreditCardValid}
        isPaymentConditionEnabled={isPaymentConditionEnabled}
        setIsPaymentConditionEnabled={setIsPaymentConditionEnabled}
        productName={productName}
      />

      {isLGPGEnabled && (
        <View as='div' layout={{ alignHorizontalCenter: true }} spacing={{ vertical: 3 }}>
          <DataPrivacyDisclaimer
            title='Como meus dados são usados?'
            children={<ModalLGPDContent />}
          />
        </View>
      )}

      <Spacer top={6} />

      <AcceptTermsConditions resource={context.data.resource} productName={productName} />

      <Spacer top={6} />

      <ConfirmEmail
        insuredPersonEmail={insuredPersonEmail}
        setInsuredPersonEmail={setInsuredPersonEmail}
        insuredPersonEmailConfirm={insuredPersonEmailConfirm}
        setInsuredPersonEmailConfirm={setInsuredPersonEmailConfirm}
        isInsuredPersonEmailNotEqual={isInsuredPersonEmailNotEqual}
        setIsInsuredPersonEmailNotEqual={setIsInsuredPersonEmailNotEqual}
      />

      {showBonusesClass && (
        <>
          <Spacer top={4} />
          <ModalBonusesClass />
        </>
      )}

      <Spacer top={6} />

      <Summary data={context.data.resource} />

      <Spacer top={2} />
    </LayoutBaseWithSidebar>
  )
}

export default CustomerCheckout
