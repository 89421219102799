import React, { FC, PropsWithChildren } from 'react'
import {
  FlagProvider,
  IConfig,
  IFlagProvider,
  IVariant,
  UnleashClient,
  useFlag,
  useUnleashClient as useFeatureToggleClient,
  useVariant,
} from '@unleash/proxy-client-react'

import { UNLEASH_REFRESH_INTERVAL_IN_SECONDS } from '../helpers/constants'
import { ProjectNameType } from '../types/types'

interface IFeatureToggle {
  configFeatureToggle: IConfig;
  FlagProvider: FC<PropsWithChildren<IFlagProvider>>;
  useFlag: (toggle: string) => boolean;
  useVariant: (variant: string) => Partial<IVariant>;
  useFeatureToggleClient: () => UnleashClient;
}

export default function useFeatureToggle(projectName: ProjectNameType): IFeatureToggle {
  const [configFeatureToggle] = React.useState<IConfig>({
    url: String(process.env.REACT_APP_UNLEASH_URL),
    clientKey: String(process.env.REACT_APP_UNLEASH_CLIENTKEY),
    refreshInterval: UNLEASH_REFRESH_INTERVAL_IN_SECONDS,
    appName: projectName,
  })

  return {
    configFeatureToggle,
    FlagProvider,
    useVariant,
    useFeatureToggleClient,
    useFlag,
  }
}
