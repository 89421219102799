import React from 'react'
import {
  Column,
  ColumnsContainer,
  Icon,
  InvisibleButton,
  Modal,
  Spacer,
  Text,
  Title,
  View } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'

export const DataDetail = ({ openDataDetail, setOpenDataDetail, modalData }) => {
  const redirect = () => {
    window.open('https://opinbrasil.com.br/', '_blank')
  }

  const content = (
    <>
      <View layout={{ alignHorizontalCenter: false, alignVerticalCenter: true }} spacing={{ bottom: 2 }}>
        <Title
          size={'small'}
          weight={'bold'}
          color={defaultTheme.colors.highlight}
          align={window.screen.width > 551 ? 'center' : 'left'}
        >
          {modalData.title}
        </Title>

        <Spacer top={4} />

        <Text
          size={'default'}
          weight={'regular'}
          color={defaultTheme.colors.secondary}
          align='left'
        >
          {modalData.title === 'Dados cadastrais'
            ? <>Essa é a lista completa de todos os dados obrigatórios que iremos trazer ao realizar o compartilhamento.</>
            : <>Essa é a lista completa de todos os dados não obrigatórios que você pode trazer.</>
          }
        </Text>

        <Spacer top={2} />

        {modalData.permissions.map((item, index) => (
          <Text
            key={index}
            size={'default'}
            weight={'regular'}
            color={defaultTheme.colors.secondary}
            align='left'
          >
            &#8226; {item}
          </Text>
        ))}

        <Spacer top={5} />

        <View
          style={{
            border: '1px solid #CED1D4',
            borderRadius: '8px',
            padding: '12px'
          }}
        >
          <ColumnsContainer>
            <View layout={{ horizontal: true }} style={{ width: 'max-content' }}>
              <Column tabletSize={1}>
                <Icon name='info' size={5} color='#CED1D4' />
              </Column>

              <Spacer right={3} />

              <Column tabletSize={11}>
                <Text
                  size={'small'}
                  weight={'regular'}
                  color={defaultTheme.colors.primary}
                  align='left'
                >
                  Para mais detalhes acesse o
                </Text>

                <Spacer top={1} />

                <InvisibleButton color={defaultTheme.colors.primary} contextual onClick={redirect} rightIcon='chevron-right'>
                  Portal do Open Insurance
                </InvisibleButton>
              </Column>
            </View>
          </ColumnsContainer>
        </View>

        <Spacer top={5} />

        <View
          layout={{ horizontal: true }}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            flexWrap: 'wrap-reverse',
            gap: '24px'
          }}
        >
          <InvisibleButton onClick={() => setOpenDataDetail(false)}>
            Fechar
          </InvisibleButton>
        </View>
      </View>
    </>
  )

  const modalCancel = {
    content: content,
  }

  return (
    <Modal
      {...modalCancel}
      hiddenOverflow={false}
      isOpen={openDataDetail}
      onClose={() => {
        setOpenDataDetail(false)
      }}
    />
  )
}
