/* eslint-disable no-script-url */
import React, { useContext, useState } from 'react'
import { ResourceFlowContext } from '@tiny-fronts/resource-flow-components'
import { InvisibleButton, Modal, Text, Title, View } from '@youse-seguradora/cargo-components'

import { useTheme } from '@material-ui/core/styles'

const ModalBonusesClass = () => {
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const theme = useTheme()

  const context = useContext<any>(ResourceFlowContext)
  const bonusesClass = context.data.resource.bonusesClass

  const modalBonusesClass = {
    title: 'Classe de bônus',
    content: (
      <>
        <Title align='center' size='small' color={theme.colors.highlight}>
          {bonusesClass.message.title}
        </Title>
        <View spacing={{ top: 4 }} />
        <Text color={theme.colors.secondary}>{bonusesClass.message.description}</Text>
      </>
    ),
    footer: <InvisibleButton onClick={() => setIsOpen(false)}>IR PARA PAGAMENTO</InvisibleButton>,
  }

  return (
    <View layout={{ alignHorizontalCenter: true }}>
      <Modal {...modalBonusesClass} isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </View>
  )
}

export default ModalBonusesClass
