import React from 'react'
import {
  Column,
  ColumnsContainer,
  Icon,
  Spacer,
  TagType,
  Text,
  Tooltip,
  View,
} from '@youse-seguradora/cargo-components'

import { useTheme } from '@material-ui/core/styles'

type BaseDetailsProps = {
  columns: {
    title?: string
    text?: string
    size: number
    highlight?: boolean
    tooltipDescription?: string
    children?: React.ReactNode
  }[]
}

const BaseDetails = ({ columns }: BaseDetailsProps) => {
  const theme = useTheme()

  const displayTitle = (column) => (
    <View layout={{ horizontal: true }}>
      <Text color={theme.colors.secondary}>{column.title}</Text>
      {column.tooltipDescription && (
        <View spacing={{ left: 2 }}>
          <Tooltip
            title=''
            description={column.tooltipDescription}
            placement='right'
            icon='question'
            trigger='hover'
            hideTrigger='mouseleave'
            hideMessageIcon
            type={TagType.highlight}
          />
        </View>
      )}
    </View>)

  const displayTitleAndText = (column) => {
    return (
      <>
        { displayTitle(column) }
        {column.highlight ? (
          <View layout={{ horizontal: true, alignVerticalCenter: true }}>
            <Text
              size='big'
              weight='bold'
              color={column.highlight ? theme.colors.warning : theme.colors.secondary}
            >
              {column.text}
            </Text>
            <Spacer left={2} />
            <Icon name='check-circle' color={theme.colors.warning} />
          </View>
        ) : (
          <Text
            size='big'
            weight='bold'
            color={theme.colors.secondary}
          >
            {column.text}
          </Text>
        )}
      </>)
  }

  const displayTitleAndChildren = (column) => {
    return (
      <>
        { displayTitle(column) }
        { column.children }
      </>)
  }

  const displayColumn = (column) => {
    if (column.title && column.text)
      return displayTitleAndText(column)
    else if (column.title !== undefined)
      return displayTitleAndChildren(column)
    else
      return column.children
  }

  return (
    <View spacing={{ vertical: 4 }} position={{ relative: true }} size={{ width: '100%' }}>
      <ColumnsContainer>
        {columns.map(
          (
            column: {
              title?: string
              text?: string
              size: number
              highlight?: boolean
              tooltipDescription?: string
              children?: React.ReactNode
            },
            id: number,
          ) => (
            <Column desktopSize={column.size} key={id}>
              { displayColumn(column) }
            </Column>
          ),
        )}
      </ColumnsContainer>
    </View>
  )
}

export default BaseDetails
