export const FAQ_DATA = [  
  {
    title: 'Quais as formas de entrar em contato com a Youse?',
    data: 'Você pode falar com a gente quando e onde quiser, através do chat no nosso app ou por telefone. Nosso número é 3003 5770 ' +
          '(capitais e regiões metropolitanas) ou 0800 730 9901 (demais localidades).'
  },
  {
    title: 'Qual prazo pra finalizar minha solicitação?',
    data: 'O prazo para a sua solicitação ser atendida é de 1 dia útil.'
  },
  {
    title: 'O que acontece se eu não finalizar minha solicitação?',
    data: 'Se a sua solicitação não for finalizada, seu contrato permanecerá o mesmo, sem alterações.'
  }
]
