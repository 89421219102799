import React from 'react'
import { Spacer, Title, View } from '@youse-seguradora/cargo-components'

type HeaderLoginProps = {
  title?: string
  children?: React.ReactNode
}

const HeaderLogin = ({ title, children }: HeaderLoginProps) => {
  return (
    <View as='header' spacing={{ bottom: 5 }} layout={{ alignHorizontalCenter: true }}>
      <Title size='xsmall'>{title}</Title>

      {children && (
        <>
          <Spacer bottom={5} />
          {children}
        </>
      )}
    </View>
  )
}

export default HeaderLogin
