import React from 'react'
import { designTokens, Icon, Spacer, Text, Title, View } from '@youse-seguradora/cargo-components'

import { useTheme } from '@material-ui/core/styles'

export const Success = () => {
  const theme = useTheme()

  return (
    <View
      spacing={{ top: 16, bottom: 30 }}
      layout={{ alignVerticalCenter: true, alignHorizontalCenter: true }}
    >
      <Icon name='check-circle' size={8} color={designTokens.colors.green700} />
      <Spacer top={2} />
      <Title
        align='center'
        size='xsmall'
        color={theme.colors.highlightInverse}
        htmlChildren='Cartão registrado com sucesso :)'
      />
      <Spacer top={2} />
      <Text
        align='center'
        color={theme.colors.secondary}
        markdown='Fique tranquilo, só iremos fazer a cobrança na data de <br/> vencimento da sua apólice para garantir a renovação'
      />
      <Spacer top={2} />
    </View>
  )
}
