import React from 'react'
import { Image, Spacer, Title, View } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import { isMobile } from 'utils/IsMobile'

export const NotFoundFilter = () => {
  return (
    <View layout={{ alignVerticalCenter: true, alignHorizontalCenter: true }}>
      <Spacer top={4} />

      <View
        layout={{ alignVerticalCenter: true }}
        style={{ width: '300px', height: '225px' }}
      >
        <Image
          alt='cellphone'
          src='https://www.datocms-assets.com/16903/1590783111-illus-characters-empty-state-4x3-light.png'
        />
      </View>

      <Spacer top={4} />

      <View
        layout={{ alignVerticalCenter: true, alignHorizontalCenter: true }}
        style={{ width: isMobile() ? '288px' : '400px' }}
      >
        <Title
          size={'xsmall'}
          weight={'bold'}
          color={defaultTheme.colors.primary}
          align='center'
        >
          Não encontramos nenhum dado compartilhado com este filtro
        </Title>
      </View>

      <Spacer top={4} />

      <View
        layout={{ alignVerticalCenter: true, alignHorizontalCenter: true }}
        style={{ width: isMobile() ? '288px' : '400px' }}
      >
        <Title
          size={'xsmall'}
          weight={'light'}
          color={defaultTheme.colors.primary}
          align='center'
        >
          Selecione outro filtro para verificar os seus dados compartilhados com a gente
        </Title>
      </View>
    </View>
  )
}
