const regexCarMercosul = /^[a-zA-Z]{3}(-?)[0-9]{1}[a-zA-Z]{1}[0-9]{2}$/

export const brazilLicensePlateMask = 'aaa-9*99'
export const mercosulLicensePlateMask = 'aaa9*99'
export const licensePlateMasks = [brazilLicensePlateMask, mercosulLicensePlateMask]

export const isMercosulLicensePlate = (plate: string) => regexCarMercosul.test(plate)

export const fixLicensePlate = (plate: string) => {
  const licensePlate = plate.replace('-', '')

  return isMercosulLicensePlate(licensePlate)
    ? licensePlate
    : licensePlate.substring(0, 3) + '-' + licensePlate.substring(3)
}

export const isInvalidPlate = (plate: string) => {
  if (!plate) return false

  const licensePlate = plate.replace(/-|_/g, '')

  return licensePlate.length !== 7 || licensePlateMasks.includes(plate)
}
