import React from 'react'
import { View } from '@youse-seguradora/cargo-components'

export const SkeletonConsentList = () => {
  return (
    <View style={{ gap: '16px', paddingTop: '24px' }}>
      <View style={{
        width: '100%',
        height: '112px',
        backgroundColor: '#EBEDEE',
        borderRadius: '8px'
      }}
      ></View>
      <View style={{
        width: '100%',
        height: '112px',
        backgroundColor: '#EBEDEE',
        borderRadius: '8px'
      }}
      ></View>
    </View>
  )
}
