import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Image,
  InvisibleButton,
  Modal,
  Spacer,
  Text,
  Title,
  View } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'

export const LoginError = () => {
  const [isOpenError, setIsOpenError] = useState(true)

  const history = useHistory()

  const redirect = () => {
    history.push('/')
  }

  const content = (
    <>
      <View layout={{ alignHorizontalCenter: true, alignVerticalCenter: true }} spacing={{ bottom: 2 }}>
        <Image alt='cellphone' src='https://www.datocms-assets.com/16903/1590782860-illus-characters-confused-03-4x3-light.png' height={14} />

        <Title
          size={'small'}
          weight={'bold'}
          color={defaultTheme.colors.highlight}
          align={window.screen.width > 551 ? 'center' : 'left'}
        >
          Algo inesperado aconteceu
        </Title>

        <Spacer top={4} />

        <Text
          size={'default'}
          weight={'regular'}
          color={defaultTheme.colors.secondary}
          align={window.screen.width > 551 ? 'center' : 'left'}
        >
          Você será direcionado para a seguradora que vai enviar os seus dados. Tente de novo em alguns minutinhos!
        </Text>

        <Spacer top={4} />

        <InvisibleButton contextual onClick={redirect}>
          OK, ENTENDI
        </InvisibleButton>
      </View>
    </>
  )

  const modalCancel = {
    content: content,
  }

  return (
    <Modal
      {...modalCancel}
      hiddenOverflow={false}
      isOpen={isOpenError}
      onClose={() => {
        setIsOpenError(false)
      }}
    />
  )
}
