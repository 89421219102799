import React from 'react'
import { Table } from '@youse-seguradora/cargo-components'

export default function TableStructure({ inspectionsList }) {
  const autoTableHeaders = [
    { title: 'TIPO' },
    { title: 'CRIADO EM' },
    { title: 'EMPRESA' },
    { title: 'ATUALIZADO EM' },
    { title: 'STATUS' },
    { title: 'AÇÕES' },
  ]

  return (
    <Table
      head={{
        row: { borderless: true },
        cellHead: autoTableHeaders,
      }}
      body={{ infoList: inspectionsList }}
    />
  )
}
