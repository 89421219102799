import React from 'react'
import { SectionSuccess, Text, View } from '@youse-seguradora/cargo-components'

import { useTheme } from '@material-ui/core/styles'

type HeroProps = {
  productName: string
  data?: any
  titleHeader?: string
  isProposal?: boolean
}

const Hero = ({ data, productName, titleHeader, isProposal }: HeroProps) => {
  const { insuredPerson, product, policy, document } = data
  const theme = useTheme()

  function proposalIssued() {
    return (
      <SectionSuccess
        title='Suas informações já estão em análise!'
        subtitle='Se estiver tudo certinho, em até 24hrs a gente te manda um e-mail sobre a análise do seu
      seguro. OK?'
        backgroundColor={theme.backgrounds.success}
      />
    )
  }

  const policyIssued = (titleHeader?: string) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const theme = useTheme()

    const description = () => {
      return (
        <>
          <Text weight='bold' size='small' color={theme.colors.secondary}>
            Segurado
          </Text>
          <View spacing={{ top: 2, bottom: 1 }}>
            <Text color={theme.colors.secondary} size='small'>
              {insuredPerson.name}
            </Text>
          </View>
          <View spacing={{ bottom: 1 }}>
            <Text color={theme.colors.secondary} size='small'>
              {insuredPerson.document.number}
            </Text>
          </View>
          <View spacing={{ bottom: 4 }}>
            <Text color={theme.colors.secondary} size='small'>
              Apólice: {policy.number}
            </Text>
          </View>
          <Text color={theme.colors.secondary} weight='bold' size='small'>
            Dados de contato
          </Text>
          <View spacing={{ top: 2, bottom: 1 }}>
            <Text color={theme.colors.secondary} size='small'>
              {insuredPerson.phone}
            </Text>
          </View>
          <Text color={theme.colors.secondary} size='small'>
            {insuredPerson.email}
          </Text>
        </>
      )
    }

    return (
      <>
        <SectionSuccess
          title='Pagamento confirmado'
          subtitle={titleHeader}
          ctaCardList={{
            icon: `icon-${product}`,
            titleInsurance: productName,
            descriptionChildren: description(),
            policyDownloadUrl: document.publicUrl,
          }}
          backgroundColor={theme.backgrounds.success}
        />
      </>
    )
  }

  return isProposal ? proposalIssued() : policyIssued(titleHeader)
}

export default Hero
