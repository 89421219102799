import { IconName } from '../types/IconNames'

export const footerData = {
  services: {
    content: {
      title: 'Central de Serviços',
      subtitle: 'Assistência 24h, Sinistro e Vistoria',
    },
    primary: {
      title: '3003 5770',
      subtitle: 'Capitais e regiões metropolitanas',
    },
    secondary: {
      title: '0800 730 9901',
      subtitle: 'Demais localidades',
    },
  },
  sales: {
    content: {
      title: 'Vendas',
      subtitle: 'Contratar seguros',
    },
    primary: {
      title: '0800 730 9902',
      subtitle: 'Contrate pelo telefone de seg a sáb, das 9h às 21h.',
    },
  },
  app: {
    title: 'Aplicativo',
    subtitle: 'Faça tudo isso pelo Youse App!',
  },
  stores: {
    googleplay: 'https://play.google.com/store/apps/details?id=br.com.youse',
    appstore:
      'https://itunes.apple.com/br/app/youse-seguro-tipo-vc/id1371689994',
  },
  help: {
    content: {
      title: 'Time de ajuda',
      subtitle: 'Dúvidas, Reclamações e Cancelamento',
    },
    customerService: {
      header: 'SAC',
      title: '0800 730 9903',
      subtitle:
        'Nosso time de ajuda está sempre disponível para te atender 24h por dia, 7 dias na semana.',
    },
    hearingAid: {
      header: 'Deficiente Auditivo',
      title: '0800 730 9904',
      subtitle: 'Somos inclusivos e aqui todo mundo tem voz.',
    },
    support: {
      header: 'Ouvidoria',
      title: '0800 730 9991',
      subtitle:
        'Seu problema não foi resolvido nos canais de atendimento? Ligue para a Ouvidoria: segunda a sexta, de 8h as 18h, exceto em feriados.​',
    },
  },
  disclaimer:
    'Youse é uma plataforma de seguros 100% digital. Os produtos comercializados Seguro Auto ' +
    'Youse, Seguro Residencial Youse e Seguro Vida Youse são garantidos pela empresa Caixa ' +
    'Seguradora S.A., inscrita no CNPJ nº 34.020.354/0001-10, sediada no SHN, Quadra 01, ' +
    'Conjunto A, Bloco E, CEP 70701 050 – Brasília DF, sendo intermediados pela Wiz Soluções' +
    'e Corretagem de Seguros S.A. O registro deste plano na SUSEP não implica, por parte da ' +
    'Autarquia, incentivo ou recomendação à sua comercialização. Processo SUSEP Seguro Auto ' +
    'nº 15414.900039/2016-18; Processo SUSEP Seguro Residencial nº 15.414.900040/2016-34 e ' +
    'Processo SUSEP Seguro Vida nº 15.414.900041/2016-89. As condições contratuais/regulamento ' +
    'deste produto protocolizadas pela sociedade/entidade junto à SUSEP poderão ser consultadas ' +
    'no endereço eletrônico www.susep.gov.br, de acordo com o número de processo constante da ' +
    'apólice/proposta. Ouvidoria 0800.730.9991 / Atendimento ao Público Susep: 0800.021.8484',
  nav: {
    items: [
      {
        href: 'https://portalprivacidade.cnpbrasil.com.br/s/',
        label: 'Política de Privacidade',
      },
      {
        href: '/?cookies',
        label: 'Cookies',
        id: 'cookies-consent-link',
      },
      {
        href:
          'https://www.caixaseguradora.com.br/atendimento/Paginas/converse-com-a-gente.aspx',
        label: 'Canal de Denúncias',
      },
      {
        href: '/duvidas/',
        label: 'Central de Ajuda',
      },
    ],
  },
  socialLinks: {
    instagram: 'https://www.instagram.com/yousebrasil/',
    facebook: 'https://www.facebook.com/yousebrasil/',
    twitter: 'https://twitter.com/yousebrasil/',
    linkedin: 'https://www.linkedin.com/company/yousebrasil/',
    youtube: 'https://www.youtube.com/youse-brasil',
  },
  paymentOptions: [
    'visa',
    'mastercard',
    'amex',
    'visaelectron',
    'elo',
    'maestro',
    'diners',
    'discover',
    'caixa',
    'hipercard',
    'jcb',
    'aura',
    'pix',
  ] as IconName[],
  sitemap: {
    title: 'Mapa do site',
    products: {
      title: 'Seguros',
      items: [
        {
          href: '/seguro-auto/',
          label: 'Seguro Auto',
        },
        {
          href: '/seguro-auto/seguro-para-terceiros/',
          label: 'Seguro Auto para Terceiros',
        },
        {
          href: '/seguro-auto/seguro-de-carro-por-marcas/',
          label: 'Seguro por Marcas de Carro',
        },
        {
          href: '/seguro-residencial/',
          label: 'Seguro Residencial',
        },
        {
          href: '/seguro-de-vida/',
          label: 'Seguro de Vida',
        },
        {
          href: 'https://cdn.youse.com.br/docs/manual-assistencia.pdf',
          label: 'Manual de Assistências',
        },
        {
          href: '/termos-e-condicoes-gerais/',
          label: 'Condições Gerais',
        },
      ],
    },
    otherProducts: {
      title: 'Outros Serviços',
      items: [
        {
          href: '/seguro-auto/friends/',
          label: 'Youse Friends',
        },
        {
          href: '/clube-de-beneficios/',
          label: 'Clube de Benefícios',
        },
        {
          href: '/seguro-auto/clube-de-oficinas/',
          label: 'Clube de Oficinas',
        },
        {
          href: '/clube-youser/convide-amigos-e-ganhe',
          label: 'Convide e ganhe',
        },
        {
          href: '/parceiro-youse',
          label: 'Seja um parceiro Youse',
        },
        {
          href: '/black-friday/',
          label: 'Black Friday',
        },
      ],
    },
    about: {
      title: 'Sobre a Youse',
      items: [
        { href: '/quem-somos/', label: 'Quem Somos' },
        {
          href: '/vem-pra-youse/',
          label: 'Vem Pra Youse',
        },
        {
          href: '/seguro-online/',
          label: 'Seguro Online',
        },
        {
          href: '/formas-de-pagamento/',
          label: 'Formas de Pagamento',
        },
        {
          href: '/a-youse-e-confiavel/',
          label: 'A Youse é Confiável',
        },
        { href: 'https://blog.youse.com.br/imprensa', label: 'Imprensa' },
      ],
    },
    help: {
      title: 'Ajuda',
      items: [
        {
          href: '/duvidas/',
          label: 'Central de Ajuda',
        },
        {
          href: '/ouvidoria/',
          label: 'Ouvidoria',
        },
        {
          href: 'https://portalprivacidade.cnpbrasil.com.br/s/',
          label: 'Política de Privacidade',
        },
        {
          href: '/mapa-do-site/',
          label: 'Mapa do Site',
        },
      ],
    },
    blogs: {
      title: 'Blogs',
      items: [{ href: 'https://start.youse.com.br/', label: 'Blog Start' }],
    },
  },
}
