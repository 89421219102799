import React, { useContext } from 'react'

import { ViewerContextType } from '../types/types'

const ViewerContext = React.createContext<ViewerContextType>({
  viewer: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  allowPermission: () => {},
})

type ViewerProviderProps = {
  children: React.ReactNode,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
}

export function ViewerProvider({ children, data }: ViewerProviderProps) {
  const { viewer } = data || {}
  const allowPermission = (permission: string) => {
    const permissionList = viewer?.company?.permissions || []
    return permissionList.includes(permission)
  }

  return (
    <ViewerContext.Provider value={{ viewer, allowPermission }}>
      {children}
    </ViewerContext.Provider>
  )
}

export const useViewer = () => useContext<ViewerContextType>(ViewerContext)
