import React from 'react'
import {
  Image,
  InvisibleButton,
  Modal,
  PrimaryButton,
  Spacer,
  Text,
  Title,
  View,
} from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import { isMobile } from 'utils/IsMobile'

export interface ModalProps {
  open: boolean
  closeModal: () => void
  image: string
  title: string
  subTitle: string
  prevButton: {
    name: string
  }
  nextButton: {
    name: string
    nextMethod: () => void
  }
}

export const ConsentUpdate = ({ open, closeModal, image, title, subTitle, prevButton, nextButton }: ModalProps) => {
  const content = (
    <>
      <View
        layout={{ alignHorizontalCenter: true, alignVerticalCenter: true }}
        spacing={{ bottom: 2 }}
      >
        <Image
          alt='cellphone'
          src={image}
          height={14}
        />

        <Title
          size={'small'}
          weight={'bold'}
          color={defaultTheme.colors.highlight}
          align={isMobile() ? 'left' : 'center'}
        >
          {title}
        </Title>

        <Spacer top={4} />

        <Text
          size={'default'}
          weight={'regular'}
          color={defaultTheme.colors.secondary}
          align={isMobile() ? 'left' : 'center'}
        >
          {subTitle}
        </Text>

        <Spacer top={6} />

        <View
          layout={{ horizontal: true }}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            flexWrap: 'wrap-reverse',
            gap: '24px',
          }}
        >
          <InvisibleButton onClick={closeModal}>
            {prevButton.name}
          </InvisibleButton>

          <PrimaryButton form='hook-form' onClick={nextButton.nextMethod}>
            {nextButton.name}
          </PrimaryButton>
        </View>
      </View>
    </>
  )

  const modalCancel = {
    content: content,
  }

  return (
    <Modal
      {...modalCancel}
      hiddenOverflow={false}
      isOpen={open}
      onClose={closeModal}
    />
  )
}
