import React from 'react'
import { Column, ColumnsContainer, Spacer, Text,Title } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import { formatDistanceToNow, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Consent } from 'types/Consent'

import FriendlyDate from '../FriendlyDate'

import { formatCPF, formatSegment } from './format'
import Permissions from './Permissions'

export default function ConsentCard({ consent }: { consent: Consent }) {
  return (
    <>
      <Title size={'xsmall'} weight={'bold'} color={defaultTheme.colors.primary}>
        Dados de compartilhamento
      </Title>

      <Spacer top={4} />

      <ColumnsContainer style={{ rowGap: 21 }}>
        <Column tabletSize={6}>
          <Text size={'small'} weight={'regular'} color={defaultTheme.colors.secondary}>
            Solicitação criada em
          </Text>
          <Text size={'default'} weight={'regular'} color={defaultTheme.colors.primary}>
            <FriendlyDate date={consent.creationDateTime} />
          </Text>
        </Column>

        <Column tabletSize={6}>
          <Text size={'small'} weight={'regular'} color={defaultTheme.colors.secondary}>
            Realizada por
          </Text>
          <Text size={'default'} weight={'regular'} color={defaultTheme.colors.primary}>
            {consent.user.name}
          </Text>
        </Column>

        <Column tabletSize={6}>
          <Text size={'small'} weight={'regular'} color={defaultTheme.colors.secondary}>
            CPF
          </Text>
          <Text size={'default'} weight={'regular'} color={defaultTheme.colors.primary}>
            {formatCPF(consent.documentNumber)}
          </Text>
        </Column>

        <Column tabletSize={6}>
          <Text size={'small'} weight={'regular'} color={defaultTheme.colors.secondary}>
            Instituição que irá receber os dados
          </Text>
          <Text size={'default'} weight={'regular'} color={defaultTheme.colors.primary}>
            {consent.organizationName}
          </Text>
        </Column>

        <Column tabletSize={6}>
          <Text size={'small'} weight={'regular'} color={defaultTheme.colors.secondary}>
            Período de compartilhamento
          </Text>
          <Text size={'default'} weight={'regular'} color={defaultTheme.colors.primary}>
            {formatDistanceToNow(parseISO(consent.expirationDateTime), { locale: ptBR })} - encerra
            em <FriendlyDate date={consent.expirationDateTime} />
          </Text>
        </Column>

        <Column tabletSize={6}>
          <Text size={'small'} weight={'regular'} color={defaultTheme.colors.secondary}>
            Segmento
          </Text>
          <Text size={'default'} weight={'regular'} color={defaultTheme.colors.primary}>
            {formatSegment(consent.product.toUpperCase())}
          </Text>
        </Column>
      </ColumnsContainer>

      <Spacer top={8} />

      <Permissions permissions={consent.permissions_sections} />
    </>
  )
}
