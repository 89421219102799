import React from 'react'
import {
  Column,
  ColumnsContainer,
  Message as CargoMessage,
  MessageType,
  View,
} from '@youse-seguradora/cargo-components'

type MessageProps = {
  hasError?: boolean
  message?: React.ReactNode
}

const Message = ({ hasError, message }: MessageProps) => {
  return (
    <ColumnsContainer>
      <Column desktopSize={6} desktopOffset={3}>
        <View
          spacing={{ bottom: 6 }}
          layout={{ alignHorizontalCenter: true }}
        >
          {hasError ? (
            <CargoMessage
              icon='info'
              title='Alguma coisa deu errado! Você pode esperar alguns segundos e tentar novamente.'
              type={MessageType.error}
            />
          ) : (
            message
          )}
        </View>
      </Column>
    </ColumnsContainer>
  )
}

export default Message
