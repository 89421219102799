import React from 'react'
import {
  Image,
  InvisibleButton,
  Modal,
  Spacer,
  Text,
  Title,
  View,
} from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'

export default function CancellationModalError({
  close,
  isOpen,
}: {
  isOpen: boolean
  close: () => void
}) {
  return (
    <Modal
      hiddenOverflow={false}
      isOpen={isOpen}
      onClose={close}
      content={
        <View
          layout={{ alignHorizontalCenter: true, alignVerticalCenter: true }}
          spacing={{ bottom: 2 }}
        >
          <Image
            alt='cellphone'
            src='https://www.datocms-assets.com/16903/1590782860-illus-characters-confused-03-4x3-light.png'
            height={14}
          />

          <Title
            size={'small'}
            weight={'bold'}
            color={defaultTheme.colors.highlight}
            align={window.screen.width > 551 ? 'center' : 'left'}
          >
            Algo inesperado aconteceu
          </Title>

          <Spacer top={4} />

          <Text
            size={'default'}
            weight={'regular'}
            color={defaultTheme.colors.secondary}
            align={window.screen.width > 551 ? 'center' : 'left'}
          >
            Que pena. Algo inesperado aconteceu, mas fica numa boa: você pode voltar para o início
            fechando essa janela ou clicando no botão abaixo, ok?
          </Text>

          <Spacer top={4} />

          <InvisibleButton contextual onClick={close}>
            VOLTAR PARA O INÍCIO
          </InvisibleButton>
        </View>
      }
    />
  )
}
