/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react'
import { useHistory,useParams } from 'react-router-dom'
import { Loading } from '@tiny-fronts/basement'
import {
  Card,
  Column,
  ColumnsContainer,
  Container,
  InvisibleButton,
  Spacer,
  Tag,
  Text,
  Title,
  View
} from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import { format, parseISO } from 'date-fns'
import useAuth from 'hooks/useAuth'
import { isMobile } from 'utils/IsMobile'
import { statusStrategy } from 'utils/StatusStrategy'

import { formatCPF } from 'components/ConsentCard/format'
import { ConsentUpdate, ModalProps } from 'components/Modal/ConsentUpdate'

import { ConsentParams, ConsentProps, ResponseError } from '../Interfaces'

import { ConsentActions } from './ConsentActions'
import { PermissionsList } from './PermissionsList'

export const ConsentDetails = () => {
  const [modal, setModal] = useState<ModalProps>({
    open: false,
    image: '',
    title: '',
    subTitle: '',
    closeModal: () => {},
    prevButton: {
      name: ''
    },
    nextButton: {
      name: '',
      nextMethod: () => {}
    }
  })
  const [loading, setLoading] = useState<boolean>(true)
  const [consentDetails, setConsentDetails] = useState<ConsentProps>({
    organizationName: '',
    status: '',
    creationDateTime: '',
    expirationDateTime: '',
    consentId: '',
    permissions_sections: [],
    segmentDescription: '',
    statusUpdateDateTime: '',
    user: {
      name: '',
      cpf: ''
    }
  })

  const history = useHistory()
  const { consentId, tab } = useParams<ConsentParams>()
  const { token } = useAuth()

  const tabStrategy = {
    recebidos: 'data-reception',
    enviados: 'data-transmission'
  }

  const formatDate = (value) => {
    if (!value) return 'Não informado'

    return format(parseISO(value), 'dd/MM/yyyy',)
  }

  useEffect(() => {
    setLoading(true)

    fetch(`${process.env.REACT_APP_OPIN_SERVICE_URL}/consents/${tabStrategy[tab]}/${consentId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response
        } else {
          const error = new ResponseError(response.statusText)
          error.response = response
          throw error
        }
      })
      .then<ConsentProps>((response) => response.json())
      .then((response) => {
        const newResponse = {
          ...response,
          formatedExpirationDateTime: formatDate(response?.expirationDateTime), 
          formatedCreationDataTime: formatDate(response?.creationDateTime),
          formatedCPF: formatCPF(response?.user.cpf),
          formatedstatusUpdateDateTime: format( parseISO(response?.statusUpdateDateTime), 'dd/MM/yyyy, à\'s\' HH\'h\'mm\'min\'')
        }

        setConsentDetails(newResponse)
      })
      .catch(() => history.push('/erro-desconhecido'))
      .finally(() => setLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) return <Loading />

  return (
    <Container>
      <Spacer top={3} />

      <InvisibleButton
        contextual
        onClick={() => history.push('/gestao/consentimentos')}
        leftIcon='chevron-left'
      >
        Voltar
      </InvisibleButton>

      <Spacer top={2} />

      <View layout={{ horizontal: true, alignVerticalCenter: true }} style={{ gap: '24px' }}>
        <Title
          size={'small'}
          weight={'bold'}
          color={defaultTheme.colors.primary}
        >
          {consentDetails?.organizationName}
        </Title>

        <Tag name={consentDetails.status} backgroundColor={defaultTheme.backgrounds.disable} color={statusStrategy(consentDetails.status)} isUppercase={false} />
      </View>

      <Spacer top={1} />

      <ColumnsContainer style={{ rowGap: 4 }}>
        <Column tabletSize={2}>
          <Text
            size={'small'}
            weight={'regular'}
            color={defaultTheme.colors.secondary}
          >
            Início
          </Text>

          <Text
            size={'default'}
            weight={'regular'}
            color={defaultTheme.colors.primary}
          >
            {consentDetails?.formatedCreationDataTime}
          </Text>
        </Column>

        <Column tabletSize={2}>
          <Text
            size={'small'}
            weight={'regular'}
            color={defaultTheme.colors.secondary}
          >
            Encerramento
          </Text>

          <Text
            size={'default'}
            weight={'regular'}
            color={defaultTheme.colors.primary}
          >
            {consentDetails?.formatedExpirationDateTime}
          </Text>
        </Column>
      </ColumnsContainer>

      <Spacer top={2} />

      <Card
        style={{
          borderRadius: 8,
          padding: isMobile() ? 34 : 16,
          marginTop: 16
        }}
      >
        <Title
          size={'xsmall'}
          weight={'bold'}
          color={defaultTheme.colors.primary}
        >
          Dados de compartilhamento
        </Title>

        <Spacer top={4} />

        <ColumnsContainer style={{ rowGap: 8 }}>
          <Column tabletSize={6}>
            <Text
              size={'small'}
              weight={'regular'}
              color={defaultTheme.colors.secondary}
            >
              Realizada por
            </Text>
            <Text
              size={'default'}
              weight={'regular'}
              color={defaultTheme.colors.primary}
            >
              {consentDetails?.user.name}
            </Text>
          </Column>

          <Column tabletSize={6}>
            <Text
              size={'small'}
              weight={'regular'}
              color={defaultTheme.colors.secondary}
            >
              CPF
            </Text>
            <Text
              size={'default'}
              weight={'regular'}
              color={defaultTheme.colors.primary}
            >
              {consentDetails?.formatedCPF}
            </Text>
          </Column>

          <Column tabletSize={6}>
            <Text
              size={'small'}
              weight={'regular'}
              color={defaultTheme.colors.secondary}
            >
              Finalidade
            </Text>
            <Text
              size={'default'}
              weight={'regular'}
              color={defaultTheme.colors.primary}
            >
              Ofertar serviços personalizados
            </Text>
          </Column>

          <Column tabletSize={6}>
            <Text
              size={'small'}
              weight={'regular'}
              color={defaultTheme.colors.secondary}
            >
              Segmento
            </Text>
            <Text
              size={'default'}
              weight={'regular'}
              color={defaultTheme.colors.primary}
            >
              {consentDetails?.segmentDescription}
            </Text>
          </Column>

          <Column tabletSize={6}>
            <Text
              size={'small'}
              weight={'regular'}
              color={defaultTheme.colors.secondary}
            >
              Data da confirmação
            </Text>
            <Text
              size={'default'}
              weight={'regular'}
              color={defaultTheme.colors.primary}
            >
              {consentDetails?.formatedstatusUpdateDateTime}
            </Text>
          </Column>
        </ColumnsContainer>

        <Spacer top={8} />

        {!!consentDetails.permissions_sections.length &&
          <PermissionsList permissions={consentDetails.permissions_sections} />
        }
      </Card>

      <Spacer top={6} />

      {consentDetails.status === 'Ativo' && <ConsentActions consentId={consentId} isReception={tab === 'recebidos'} setModal={setModal} />}

      <ConsentUpdate
        image={modal.image}
        open={modal.open}
        closeModal={() => setModal({ ...modal, open: false })}
        subTitle={modal.subTitle}
        title={modal.title}
        nextButton={{
          name: modal.nextButton.name,
          nextMethod: modal.nextButton.nextMethod
        }}
        prevButton={{
          name: modal.prevButton.name
        }}
      />
    </Container>
  )
}
