import { makeStyles } from '@material-ui/core/styles'

const bonusClassStyles = makeStyles((theme) => ({
  radio: {
    color: theme.colors.primaryInverse,
    '&$checked': {
      color: theme.colors.primaryInverse,
    },
  },
  checked: {},
  label: {
    color: theme.colors.primaryInverse,
  },
}))

export { bonusClassStyles }
