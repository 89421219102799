/* eslint-disable @typescript-eslint/no-var-requires */
import Fingerprint from '../../helpers/Fingerprint/Fingerprint'

const qs = require('qs')
const humps = require('humps')
const axios = require('axios').default

class ResourceFlowClient {
  product: string
  variation: string
  resourceType: string
  baseUrl: string

  constructor(product: string, variation: string) {
    this.product = product
    this.variation = variation

    this.resourceType = `bra/${this.product}/order`
    this.baseUrl = process.env.REACT_APP_MICROFRONTEND_BFF_BASE_URL + '/v1/cotacao/'+ this.product
  }

  new() {
    const permittedParams = {
      resourceType: this.resourceType,
      variation: this.variation,
    }
    const url = this._buildUrl(`${this.baseUrl}/novo`, permittedParams)
    return this._request('GET', url).then(humps.camelizeKeys)
  }

  get(id, params = {}) {
    const permittedParams = { currentSection: params.currentSection }
    const url = this._buildUrl(`${this.baseUrl}/${id}`, permittedParams)
    return this._request('GET', url).then(humps.camelizeKeys)
  }

  create(params = {}) {
    const permittedParams = {
      resourceType: this.resourceType,
      variation: this.variation,
      currentSection: params.currentSection,
      partialSection: params.partialSection,
      resourceAttributes: params.resourceAttributes,
    }
    this._removeEmpty(permittedParams)
    const url = this._buildUrl(this.baseUrl)
    return this._request('POST', url, permittedParams).then(humps.camelizeKeys)
  }

  update(id, params = {}) {
    const permittedParams = {
      variation: this.variation,
      currentSection: params.currentSection,
      partialSection: params.partialSection,
      resourceAttributes: params.resourceAttributes,
    }
    this._removeEmpty(permittedParams)
    const url = this._buildUrl(`${this.baseUrl}/${id}`)
    return this._request('PUT', url, permittedParams).then(humps.camelizeKeys)
  }

  /**
   * Calls the HTTP request methods
   * @private
   */
  async _request(method, url, body = null) {
    const snakeKeysBody = humps.decamelizeKeys(body)
    const stringifiedBody = body ? JSON.stringify(snakeKeysBody) : null
    const visitorId = await Fingerprint.getVisitorId()

    return axios(url, {
      method: method,
      data: stringifiedBody,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Visitor-Id': visitorId,
      },
      validateStatus: function (status) {
        return (status >= 200 && status < 300) || status === 422
      },
    })
      .then((response) => {
        return response
      })
      .catch((error) => {
        return {
          error: true,
          status: error.response?.status,
          data: error.response?.data,
        }
      })
  }

  /**
   * Builds an URL transforming params into query string.
   * @private
   */
  _buildUrl(path, params = {}) {
    if (params === null || Object.keys(params).length === 0) {
      return path
    } else {
      const convertedParams = humps.decamelizeKeys(params)
      return path + '?' + qs.stringify(convertedParams)
    }
  }

  _removeEmpty(obj) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === 'object') this._removeEmpty(obj[key])
      else if (obj[key] === null) {
        const description = Object.getOwnPropertyDescriptor(obj, key)
        if (description?.configurable) delete obj[key]
      }
    })
  }
}

export default ResourceFlowClient
