import React, { createRef, useContext, useEffect, useState } from 'react'
import Input from '@material-ui/core/Input'
import {
  FormControl,
  inputStyles,
  ResourceFlowContext,
} from '@tiny-fronts/resource-flow-components'
import { Text, Title, View } from '@youse-seguradora/cargo-components'

import { useTheme } from '@material-ui/core/styles'

type ConfirmEmailProps = {
  insuredPersonEmail?: string
  setInsuredPersonEmail?: any
  insuredPersonEmailConfirm?: string
  setInsuredPersonEmailConfirm?: any
  isInsuredPersonEmailNotEqual?: boolean
  setIsInsuredPersonEmailNotEqual?: any
}

const ConfirmEmail = ({
  insuredPersonEmail,
  setInsuredPersonEmail,
  insuredPersonEmailConfirm,
  setInsuredPersonEmailConfirm,
  isInsuredPersonEmailNotEqual,
  setIsInsuredPersonEmailNotEqual,
}: ConfirmEmailProps) => {
  const textEmailConfirm = createRef<any>()
  const [textEmailConfirmVisited, setTextEmailConfirmVisited] = useState<boolean>(false)
  const theme = useTheme()
  const validationEmail = (data: string) => {
    setIsInsuredPersonEmailNotEqual(insuredPersonEmail !== data)
  }
  const context = useContext<any>(ResourceFlowContext)
  const classes = inputStyles()

  useEffect(() => {
    const EmailConfirmValue = textEmailConfirm.current.value
    if (EmailConfirmValue.length > 0) {
      setTextEmailConfirmVisited(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insuredPersonEmailConfirm])

  const handleChange = (e: any) => {
    e.preventDefault()
  }

  return (
    <>
      <Title size='xsmall' align='center' color={theme.colors.primary}>
        Confirme seu email pra gente ;)
      </Title>

      <FormControl
        resourceerrors={context.data.resource.errors}
        errorfor='insuredPersonEmail'
        fullWidth={true}
      >
        <View
          layout={{
            desktopHorizontal: true,
            desktopAlignHorizontalCenter: true,
          }}
          spacing={{ top: 4 }}
        >
          <View size={{ desktopWidth: '40%' }}>
            <Input
              data-testid='insuredPersonEmail-input'
              inputProps={{ 'data-testid': 'insuredPersonEmail-input' }}
              className={classes.inputMaterial}
              id='insuredPersonEmail'
              autoComplete='nope'
              defaultValue={insuredPersonEmail || ''}
              onBlur={(e: any) => setInsuredPersonEmail(e.target.value)}
              type='email'
              fullWidth={true}
            />
          </View>
        </View>
      </FormControl>
      <FormControl
        resourceerrors={context.data.resource.errors}
        errorfor='insuredPersonEmailConfirm'
        fullWidth={true}
      >
        <View
          layout={{
            desktopHorizontal: true,
            desktopAlignHorizontalCenter: true,
          }}
          spacing={{ top: 2 }}
        >
          <View size={{ desktopWidth: '40%' }} spacing={{ bottom: 2 }}>
            <Input
              inputProps={{ 'data-testid': 'insuredPersonEmailConfirm-input' }}
              inputRef={textEmailConfirm}
              className={classes.inputMaterial}
              id='insuredPersonEmailConfirm'
              autoComplete='nope'
              defaultValue={insuredPersonEmailConfirm || ''}
              onBlur={(e: any) => setInsuredPersonEmailConfirm(e.target.value)}
              type='email'
              placeholder='repita seu e-mail'
              fullWidth={true}
              onKeyUp={(e: any) => validationEmail(e.target.value)}
              onCut={handleChange}
              onCopy={handleChange}
              onPaste={handleChange}
            />
          </View>
        </View>
        {isInsuredPersonEmailNotEqual && textEmailConfirmVisited && (
          <Text align='center' size='xsmall' color={theme.colors.error}>
            Os e-mails não estão iguais
          </Text>
        )}
      </FormControl>
    </>
  )
}

export default ConfirmEmail
