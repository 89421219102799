import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Image, SecondaryButton, Title, View } from '@youse-seguradora/cargo-components'

import DataLayerClient from '../../../clients/DataLayerClient'
import OhNo from '../../images/oh-no.svg'

import { useTheme } from '@material-ui/core/styles'

type FinishedOrderProps = {
  redirectToHome?: boolean
}

const FinishedOrder = ({ redirectToHome }: FinishedOrderProps) => {
  const theme = useTheme()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const location = useLocation()

  useEffect(() => {
    new DataLayerClient().trigger('Esse link não é mais válido / Cotação expirada')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <View
        size={{ minHeight: '100vh' }}
        style={{ background: theme.backgrounds.gradient, overflow: 'scroll' }}
        spacing={{ vertical: 6 }}
        layout={{ desktopHorizontal: true }}
      >
        <View
          spacing={{
            desktopLeft: 21,
            desktopTop: 14,
            desktopRight: 0,
            left: 6,
            top: 8,
            right: 6,
          }}
          size={{ desktopWidth: '580px', width: 'auto' }}
        >
          <Title size='big' color={theme.colors.highlightInverse}>
            Oh no!
          </Title>
          <Title size='big' color={theme.colors.primaryInverse}>
            Esse link não é mais válido :(
          </Title>
          {redirectToHome && (
            <View spacing={{ top: 6 }} size={{ width: '170px' }}>
              <a href='https://www.youse.com.br/'>
                <SecondaryButton light>ir para a youse</SecondaryButton>
              </a>
            </View>
          )}
        </View>

        <View
          layout={{ alignRight: true }}
          spacing={{ horizontal: 5 }}
          position={{ desktopAbsolute: true, right: '30px' }}
        >
          <View size={{ maxWidth: '600px', minWidth: '380px' }}>
            <Image
              alt='avatar saindo de porta'
              src={OhNo}
              height={52}
              tabletHeight={66}
              desktopHeight={80}
            />
          </View>
        </View>
      </View>
    </>
  )
}

export default FinishedOrder
