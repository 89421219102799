import { CompanyType } from './enums'

export const UNLEASH_REFRESH_INTERVAL_IN_SECONDS = 15
export const PARTNERS_WITH_BROKER_ALLOWED = [CompanyType.representative, CompanyType.advisory, CompanyType.marketplace]
export const INSTALLMENTS_TABLE_HEAD = [
  { title: 'Parcela' },
  { title: 'Data da cobrança' },
  { title: 'Valor' },
  { title: 'Status' },
  { title: 'Método de Pagamento' },
]
export const REFUND_INSTALLMENTS_TABLE_HEAD = [
  { title: 'Parcela' },
  { title: 'Data da cobrança' },
  { title: 'Valor' },
  { title: 'Status' },
]
