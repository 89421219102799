import React from 'react'
import { Column, ColumnsContainer, Text, Title, View } from '@youse-seguradora/cargo-components'

type FormHeaderProps = {
  title: string
  subtitle?: string
  spacing?: number
}

const FormHeader = ({ title, subtitle = '', spacing = 0 }: FormHeaderProps) => (
  <ColumnsContainer>
    <Column>
      <View spacing={{ top: spacing }}>
        <Title align='center'>{title}</Title>
        {subtitle && <Text align='center'>{subtitle}</Text>}
      </View>
    </Column>
  </ColumnsContainer>
)

export default FormHeader
