import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { FormControl, formFieldName } from '@tiny-fronts/resource-flow-components'
import { Text, View } from '@youse-seguradora/cargo-components'

type FormRadioProps = {
  errors?: any
  field: string
  name: string
  label?: string
  classes: any
  obj: string
  onChange?: any
  size?: 'big' | 'default' | 'small' | 'xsmall'
  spacing?: {}
  trueLabel: string
  falseLabel: string
  horizontal?: boolean
  tooltip?: React.ReactNode
  color?: string
  weight?: any
  align?: 'inherit' | 'left' | 'right' | 'center'
  alignHorizontalCenter?: boolean
}

const FormRadio = ({
  errors,
  field = '',
  name,
  label,
  classes,
  obj,
  onChange,
  size = 'big',
  spacing = {},
  trueLabel = 'Sim',
  falseLabel = 'Não',
  horizontal = true,
  tooltip,
  ...props
}: FormRadioProps) => {
  const formField = formFieldName(obj, name)

  return (
    <FormControl fullWidth={true} resourceerrors={errors} errorfor={formField}>
      <View
        layout={{
          desktopHorizontal: horizontal,
          desktopAlignHorizontalCenter: true,
          desktopAlignVerticalCenter: true,
          alignHorizontalCenter: props.alignHorizontalCenter,
        }}
        spacing={{ top: 6 }}
      >
        <View layout={{ desktopHorizontal: true }}>
          {tooltip && (
            <View
              spacing={{ right: 3 }}
              position={{ relative: true, top: '3px' }}
              layout={{
                alignHorizontalCenter: true,
                desktopAlignHorizontalCenter: false,
              }}
            >
              {tooltip}
            </View>
          )}
          <Text
            color={props.color}
            weight={props.weight && 'bold'}
            size={size}
            as='label'
            htmlFor={formField}
            align={props.align}
          >
            {label}
          </Text>
        </View>
        <View spacing={{ ...spacing, desktopLeft: 2 }}>
          <RadioGroup
            row
            defaultValue={field?.toString()}
            onChange={(e: any) => onChange(name, e.target.value === 'true')}
          >
            <FormControlLabel
              value='true'
              control={
                <Radio
                  inputProps={{
                    // @ts-ignore
                    'data-testid': `${name}-radio-input-true`,
                  }}
                  classes={{
                    root: classes.radio,
                    checked: classes.checked,
                  }}
                />
              }
              classes={{ label: classes.label }}
              label={trueLabel}
            />
            <FormControlLabel
              value='false'
              control={
                <Radio
                  inputProps={{
                    // @ts-ignore
                    'data-testid': `${name}-radio-input-false`,
                  }}
                  classes={{
                    root: classes.radio,
                    checked: classes.checked,
                  }}
                />
              }
              classes={{ label: classes.label }}
              label={falseLabel}
            />
          </RadioGroup>
        </View>
      </View>
    </FormControl>
  )
}

export default React.memo(FormRadio, (p, n) => p.field === n.field)
