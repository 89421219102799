import React from 'react'
import {
  Column,
  ColumnsContainer,
  Divider,
  Spacer,
  Text,  View } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import { desktopContent, mobileContent } from 'styles/ContentStyle'
import { isMobile } from 'utils/IsMobile'

import { formatCPF } from '../../components/ConsentCard/format'

export const ClientIdentification = ({ form }) => {

  return (
    <View
      style={isMobile() ? mobileContent : desktopContent}
    >
      <ColumnsContainer>
        <Column tabletSize={6}>
          <Text
            size={'small'}
            weight={'regular'}
            color={defaultTheme.colors.secondary}
          >
            Nome
          </Text>

          <Text
            size={'default'}
            weight={'regular'}
            color={defaultTheme.colors.primary}
          >
            {form.name}
          </Text>

          {window.innerWidth < 900 &&
            <>
              <Spacer top={3} />
              <Divider bottom={1} />
            </>
          }
        </Column>

        <Column tabletSize={6}>
          <Text
            size={'small'}
            weight={'regular'}
            color={defaultTheme.colors.secondary}
          >
            CPF
          </Text>

          <Text
            size={'default'}
            weight={'regular'}
            color={defaultTheme.colors.primary}
          >
            {form.document_number?.length ? formatCPF(form.document_number) : ''}
          </Text>
        </Column>
      </ColumnsContainer>
    </View>
  )
}
