import React, { createContext, useState } from 'react'

type PixInvoiceContextType = {
  invoiceId: string
  setInvoiceId: React.Dispatch<React.SetStateAction<string>>
}

// Context default value
const DEFAULT_VALUE = {
  invoiceId: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setInvoiceId: () => {}, // initialization function
}

const PixContext = createContext<PixInvoiceContextType>(DEFAULT_VALUE)

// Show correct name in devtools
PixContext.displayName = 'PixContext'

// This function is used to change state providing the context for the children
const PixContextProvider: React.FC = ({ children }) => {
  const [invoiceId, setInvoiceId] = useState(DEFAULT_VALUE.invoiceId)
  return <PixContext.Provider value={{ invoiceId, setInvoiceId }}>{children}</PixContext.Provider>
}

export { PixContextProvider }
export default PixContext
