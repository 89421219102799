import React from 'react'
import InputMask from 'react-input-mask'
import CircularProgress from '@material-ui/core/CircularProgress'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import { FormControl } from '@tiny-fronts/resource-flow-components'
import { Text, View } from '@youse-seguradora/cargo-components'

type VinInputProps = {
  vehicle: {
    vin?: string
  }
  errors?: string
  classes?: any
  setVin?: any
  maskVin?: any
  vinLoader?: boolean
}

const VinInput = ({ vehicle, errors, classes, setVin, maskVin, vinLoader }: VinInputProps) => {
  return (
    <FormControl fullWidth={true} resourceerrors={errors} errorfor='vehicleVin'>
      <View
        layout={{
          desktopHorizontal: true,
          desktopAlignHorizontalCenter: true,
        }}
        spacing={{ top: 6 }}
      >
        <Text size='big' as={'label'} htmlFor='vehicleVin'>
          O chassi do meu carro é
        </Text>

        <View spacing={{ desktopLeft: 2 }}>
          <InputMask
            id='vehicleVin'
            autoComplete='nope'
            defaultValue={vehicle.vin || ''}
            onBlur={(e) => setVin(e.target.value)}
            mask={maskVin}
            maskChar={null}
          >
            {(inputProps) => (
              <Input
                className={`${classes.uppercase} ${classes.inputMaterial}`}
                {...inputProps}
                inputProps={{ 'data-testid': 'vehicleVin-input' }}
                fullWidth={true}
                endAdornment={
                  <InputAdornment position='end'>
                    {vinLoader && <CircularProgress size={20} color='secondary' />}
                  </InputAdornment>
                }
              />
            )}
          </InputMask>
        </View>
      </View>
    </FormControl>
  )
}

export default VinInput
