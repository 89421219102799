import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Checkbox,  Container,
  Image,
  PrimaryButton,
  Spacer,
  Text,
  Title,
  View,
} from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import useAuth from 'hooks/useAuth'
import { ResponseError } from 'pages/Management/Interfaces'
import { FormProps } from 'pages/ReceptionFlow/interfaces'

import { useTheme } from '@material-ui/core/styles'

interface TranmitterProps{
  messageProps: string
  match: {
    params:{
      nameUser: string
      documentUser: string
      emailUser: string
      nameTransmitter: string
    }
  }
}
export const TransmitterNotFoundError = (props: TranmitterProps) => {
  const { nameUser, documentUser, emailUser, nameTransmitter } = props?.match.params
  const { messageProps } = props
  const { colors } = useTheme()
  const history = useHistory()
  const { token } = useAuth()
  const [saveNotification, setSaveNotification] = React.useState(false)
  const NOTIFICATION_CONGENERS_OFFLINE = '1'
  const [form, setForm] = React.useState<FormProps>({
    name: nameUser,
    document_number: documentUser,
    email: emailUser,
    brand: {
      CustomerFriendlyName: nameTransmitter.replace('|','/')
    }
  })
  let payload: { name: string,
               e_mail: string,
               transmitter_name: string,
               notification_type: string,
            }

  const createPayload = () => {
    payload = {
      name: nameUser,
      e_mail: emailUser,
      transmitter_name: nameTransmitter.replace('|','/'),
      notification_type: NOTIFICATION_CONGENERS_OFFLINE,
    }
  }

  const defaultRequest = (path, method, payload) => {
    fetch(`${process.env.REACT_APP_OPIN_SERVICE_URL}${path}`, {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.status === 200) {
          return response
        } else {
          const error = new ResponseError(response.statusText)
          error.response = response
          throw error
        }
      })
      .then((response) => response.json())
      .then((response) => {
        setForm({ ...form, authorization_url: response.authorization_url })
      })
      .catch(() => {
        history.push('/erro-desconhecido')
      })
  }

  const saveAndRedirect = () => {
    if(saveNotification){
      createPayload()
      defaultRequest('/notifications', 'POST', payload)
    }
    history.push('/')
  }

  return (
    <>
      <Container>
        <Spacer top={8} />

        <View layout={{ alignHorizontalCenter: true, alignVerticalCenter: true }} style={{ height: 'calc(100vh - 110px)' }}>
          <Image src='https://www.datocms-assets.com/16903/1590783190-illus-characters-error-04-4x3-light.png' height={19} alt={''} />

          <Title
            size={'default'}
            weight={'bold'}
            align={window.screen.width > 551 ? 'center' : 'left'}
            color={defaultTheme.colors.highlight}
          >
            Instituição fora do ar
          </Title>

          <View style={{ maxWidth: '472px' }}>
            <Text
              size={'default'}
              align={window.screen.width > 551 ? 'center' : 'left'}
              weight={'regular'}
              color={defaultTheme.colors.primary}
            >
              { messageProps }

            </Text>
          </View>
          <View>
            <View  style={{
              marginLeft: -16,
              marginBottom: -16,
              marginTop: -32
            }}>
              <Checkbox
                label='Quero receber uma notificação quando estiver disponível'
                onChange={() => setSaveNotification((state) => !state)}
              />
            </View>
          </View>
        </View>
      </Container>

      <View
        as='div'
        background={{ color: colors.textSidebar }}
        style={{
          boxShadow: '0px 2px 16px rgba(47, 57, 65, 0.16)',
          bottom: 0,
          padding: '25px 0px'
        }}
        size={{ width: '100%' }}
        position={{ absolute: true }}
      >
        <Container>
          <View
            style={{ flexDirection: 'row', justifyContent: 'center' }}
            spacing={{ left: 0, desktopLeft: 20, right: 0, desktopRight: 20 }}
          >
            <PrimaryButton full onClick={saveAndRedirect}>
              VOLTAR PARA A PÁGINA INICIAL
            </PrimaryButton>
          </View>
        </Container>
      </View>
    </>
  )
}
