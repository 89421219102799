import React, { useEffect, useState } from 'react'
import AdyenCheckout from '@adyen/adyen-web'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import {
  Column,
  ColumnsContainer,
  Spacer,
  Title,
} from '@youse-seguradora/cargo-components'

import '@adyen/adyen-web/dist/adyen.css'
import { useTheme } from '@material-ui/core/styles'

const CreditCard = ({
  onFulfilled,
  setIsCreditCardValid,
}: any) => {
  const theme = useTheme()

  const [, setBrowserInfo] = useState<any>()
  const [, setRiskData] = useState<any>()

  useEffect(() => {
    const adyenEnvironment = process.env.REACT_APP_LIVE_ADYEN === 'true' ? 'live' : 'test'
    const translations = {
      pt_BR: { 'creditCard.holderName.placeholder': 'Nome como no cartão' },
    }
    const originKey = process.env.REACT_APP_ADYEN_CLIENT_KEY
    const configuration = {
      locale: 'pt_BR',
      translations: translations,
      environment: adyenEnvironment,
      originKey: originKey,
      hasHolderName: true,
      holderNameRequired: true,
      paymentMethodsResponse: {
        paymentMethods: [
          {
            brands: [
              'mc',
              'visa',
              'elo',
              'amex',
              'hipercard',
              'maestro',
              'diners',
              'discover',
              'hiper',
              'jcb',
            ],
            details: [
              {
                key: 'number',
                type: 'text',
              },
              {
                key: 'expiryMonth',
                type: 'text',
              },
              {
                key: 'expiryYear',
                type: 'text',
              },
              {
                key: 'cvc',
                type: 'text',
              },
              {
                key: 'holderName',
                optional: true,
                type: 'text',
              },
            ],
            name: 'Credit Card',
            type: 'scheme',
          },
        ],
      },
      onChange: (state, component) => {
        setBrowserInfo(state.data.browserInfo)
        setRiskData(state.data.riskData)
        if (!state.isValid) {
          setIsCreditCardValid(false)
          return
        }
        const brand = component.state.brand === 'mc' ? 'MasterCard' : component.state.brand
        setIsCreditCardValid(true)
        onFulfilled(Object.assign({}, state.data.paymentMethod, { brand: brand }))
      },
    }

    const checkout = new AdyenCheckout(configuration)
    // eslint-disable-next-line no-lone-blocks
    checkout.create('card').mount('#component-container')
  }, [])

  return (
    <>
      <Title size='xsmall' align='center' color={theme.colors.primary}>
        Informe os dados de pagamento
      </Title>

      <Spacer top={4} />

      <ColumnsContainer>
        <Column desktopSize={6} desktopOffset={3}>
          <Card
            elevation={2}
            style={{
              borderRadius: '12px',
              border: '4px solid #E9EAEB',
              padding: '10px',
              boxShadow: 'none',
            }}
          >
            <CardContent>
              <div id='component-container'></div>
            </CardContent>
          </Card>
        </Column>
      </ColumnsContainer>
    </>
  )
}

export default CreditCard
