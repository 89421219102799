import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  InvisibleButton,
  Text,
  TrackingContainer,
  View,
} from '@youse-seguradora/cargo-components'

import { useTheme } from '@material-ui/core/styles'

export type ServiceProps = {
  serviceId: string
  name: string
}

type OptionsProps = {
  isColorful?: boolean
  title: string
  onClick: () => void
}

const Options = ({ isColorful, title, onClick }: OptionsProps) => {
  const theme = useTheme()

  return (
    <View
      layout={{ horizontal: true, alignVerticalCenter: true, spread: true }}
      spacing={{ horizontal: 5, vertical: 3 }}
      background={{ color: isColorful ? theme.backgrounds.primary : theme.backgrounds.secondary }}
    >
      <View spacing={{ right: 4 }}>
        <Text size='xsmall' weight='bold'>
          {title}
        </Text>
      </View>
      <TrackingContainer action='hit_admin_request_service' label='Acionar Serviço'>
        <InvisibleButton dataTestId='active-assistance-button' onClick={onClick}>Acionar Serviço</InvisibleButton>
      </TrackingContainer>
    </View>
  )
}

export const Services = ({ service, i, assistance, policy }) => {
  const history = useHistory()
  const { product } = useParams<{
    product: string
  }>()

  function isOdd(num) {
    return num % 2 !== 1
  }

  return (
    <Options
      isColorful={isOdd(i)}
      title={service.name || assistance.name}
      onClick={() => {
        history.push(
          `/apolices/${product}/${policy.number}/acionamento/${assistance.assistanceId}/${service.serviceId}`,
        )
      }}
    />
  )
}
