/* eslint-disable max-len */
import React, { useState } from 'react'
import {
  Accordion,
  Card,
  Container,
  Divider,
  Icon,
  Image,
  Link,
  Message,
  MessageType,
  Spacer,
  Text,
  Title,
  View } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import * as S from 'styles/Global'

export const RequestConfirmation = (props) => {

  const {
    title,
    description,
    image,
    textCard,
    infoCard,
    faqData,
    titleFaq
  } = props

  const [message, setMessage] = useState(false)

  const copyProtocol = (e: React.MouseEvent) => {
    e.preventDefault()
    navigator.clipboard.writeText(infoCard[1][1])
    setMessage(true)
  }

  return (
    <View>
      <Container style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: '1.6rem',
        paddingBottom: '1.6rem',
      }}>

        <View layout={{ desktopHorizontal: true }}>
          {
            window.innerWidth <= 551 &&
            <Image
              alt='characters'
              src={image}
              height={ window.innerWidth < 551 ? 35.2 : 45.2 }
            />
          }

          <View layout={{ alignVerticalCenter: true }} style={{ maxWidth: '464px', gap: '32px' }}>
            <Title
              size={ 'default' }
              weight={ 'bold' }
              align={ window.innerWidth < 551 ? 'center' : 'left' }
              color={ defaultTheme.colors.primary }
            >
              {title}
            </Title>
            <Text
              size={ 'big' }
              weight={ 'regular' }
              color={ defaultTheme.colors.primary }
              align={ window.innerWidth < 551 ? 'center' : 'left' }
            >
              { description }
            </Text>

            <S.ContentCardList>
              <Card style={{ padding: '24px', boxShadow: 'none', border: `1px solid ${defaultTheme.divisors.standard}` }}>
                <View style={{ flexDirection: 'row', marginBottom: '16px' }}>
                  <Text
                    as='h2'
                    size='big'
                    weight='bold'
                  >
                    { textCard }
                  </Text>

                </View>

                <View layout={{ desktopHorizontal: true }} style={{ justifyContent: 'space-between' }}>
                  {infoCard.map((item: string, index: number) => {
                    return (
                      <S.InfoBox key={index}>
                        <p>{item[0]}</p>
                        <p>{item[1]}</p>
                      </S.InfoBox>
                    )
                  })}
                </View>

                <Divider />
                {
                  !message ?
                    <Link href='' color={defaultTheme.colors.primary} onClick={copyProtocol}>
                      <Icon name='copy' /> Copiar protocolo
                    </Link>
                  :
                    <Message
                      icon='copy'
                      title=''
                      description='Protocolo copiado para sua área de transferência'
                      type={ MessageType.success }
                    />
                }
              </Card>
            </S.ContentCardList>

            <Text
              as='h2'
              size='big'
              weight='bold'
            >
              { titleFaq }
            </Text>

            <S.ContentSectionFAQ>
              {
                faqData.map((item, index) => (
                  <Accordion
                    key={ index }
                    items={[
                      {
                        title: item.title,
                        children: <>
                          <Text
                            key={ index}
                            size={ 'default' }
                            weight={ 'regular' }
                            color={ defaultTheme.colors.secondary }
                          >
                            {item.data}
                          </Text>
                        </>,
                        dataTestId: item.title
                      }
                    ]}
                  />)
                )
              }
            </S.ContentSectionFAQ>

            { window.innerWidth < 551 && <Spacer bottom={ 4 } /> }
          </View>

          {
            window.innerWidth > 551 &&
            <Image
              alt='characters'
              src='https://www.datocms-assets.com/16903/1590783393-illus-characters-success-04-4x3-light.png'
              height={window.innerWidth < 551 ? 35.2 : 45.2}
            />
          }
        </View>
      </Container>
    </View>
  )
}
