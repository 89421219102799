import React from 'react'

import FinishedOrder from '../../components/pages/FinishedOrder'
import InsuranceNotAccepted from '../../components/pages/InsuranceNotAccepted'
import NotFound from '../../components/pages/NotFound'
import SomethingWrong from '../../components/pages/SomethingWrong'
import UnauthorizedOrder from '../../components/pages/UnauthorizedOrder'

type HandleHTTPErrorsProps = {
  status: number
  data?: any
  redirectToHome?: boolean
}

const HandleHTTPErrors = ({ status, data, redirectToHome }: HandleHTTPErrorsProps) => {
  switch (status) {
  case 401:
    return <UnauthorizedOrder />
  case 403:
    if (data.error === 'Resource status is "expired"')
      return <SomethingWrong expiredOrder redirectToHome={redirectToHome} />
    if (data.error === 'Resource status is "denied"') return <InsuranceNotAccepted />
    if (data.error === 'Resource status is "finished"') return <FinishedOrder redirectToHome />
    break
  case 410:
    return <FinishedOrder redirectToHome />
  case 404:
    return <NotFound />
  case 500:
  default:
    return <SomethingWrong />
  }
}

export default HandleHTTPErrors
