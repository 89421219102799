import defaultTheme from "config/defaultTheme"

export const statusStrategy = (status) => {
  const strategy = {
    Ativo: defaultTheme.palette.success.main,
    Pendente: defaultTheme.colors.warning,
    Vencido: defaultTheme.colors.secondary,
    Encerrado: defaultTheme.palette.error.main,
  }

  return strategy[status]
}
