/* eslint-disable max-len */
import React from 'react'
import { SectionBulletPoints, SectionColor, SectionImage } from '@youse-seguradora/cargo-components'

import Hero from '../Hero/Hero'
import { AppYouse, Help, YouseFriends } from '../SectionsDefault'

type InspectionProps = {
  productName: string
  data?: any
}

const inspectionList = [
  {
    content:
      'Se é a primeira vez que você contratou um Seguro Youse com o e-mail cadastrado, é necessário criar uma senha de acesso. Se já possui conta, faça login.',
  },
  {
    content: 'Clique no link de agendamento e selecione dia e local para realizar a vistoria.',
  },
]

export default function OnlineInspection({ data, productName }: InspectionProps) {
  const AUTO_FLOW = data.salesChannel === 'mobile'
  return (
    <>
      <Hero
        data={data}
        titleHeader={`Pro seguro começar a valer, vc precisa fazer sua vistoria física em até ${
          AUTO_FLOW ? '7' : '3'
        } dias.`}
        productName={productName}
      />

      <SectionBulletPoints
        title='Verifique sua caixa de e-mail, enviamos o link para o agendamento da vistoria'
        listTitle='Como fazer isso:'
        listItems={inspectionList}
        disclaimerTitle='Atenção'
        disclaimerBody='Se você não fizer a vistoria, o seu seguro pode ser cancelado. Caso isso aconteça, vamos estornar automaticamente o seu pagamento de acordo com o fechamento da fatura do cartão.'
        image='https://www.datocms-assets.com/16903/1587751980-illus-emails-letter-success02-4x3-light-2x.png'
      />

      <SectionColor
        title='Saiba como se preparar para a Vistoria Física'
        variant='offwhite [highlight]'
      />

      <SectionImage
        title='Separe o documento atualizado do carro'
        bodyNode={{
          childMarkdownRemark: {
            html: 'Nossos vistoriadores precisarão conferir se o documento do carro está em dia. Não se esqueça de levá-lo.',
          },
        }}
        image='https://www.datocms-assets.com/16903/1587671351-illus-objects-others-car-ducument-4x3-light-2x.png'
        variant='white'
      />

      <SectionImage
        title='Dirija-se com o carro até o posto de inspeção'
        bodyNode={{
          childMarkdownRemark: {
            html: 'Os vistoriadores irão conferir se há danos no veículo. Após o processo, o resultado é entregue em até 2 dias úteis. Depois disso, é só ficar numa boa com seu Seguro Auto! ;)',
          },
        }}
        image='https://www.datocms-assets.com/16903/1588083912-illus-vehicles-car-onix-front-02-4x3-light-2x.png'
        variant='offwhite'
        placeImageAtTheLeftSide
      />

      <AppYouse />

      {data.subproduct === 'default' && (
        <>
          <SectionColor
            title='Conheça benefícios que você já pode começar a usar'
            variant='offwhite [highlight]'
          />

          <YouseFriends />
        </>
      )}

      <SectionColor
        title='Vamos lá? Baixe o app e comece já! ;)'
        variant='offwhite [highlight]'
        ctaLabel='[APPSTORES]'
        ctaLink='#'
      />

      <Help />
    </>
  )
}
