import React from 'react'
import { useHistory } from 'react-router-dom'
import { useLazyQuery, useQuery } from '@apollo/client'
import Grid from '@material-ui/core/Grid'
import {
  BaseDetails,
  compareTranslation,
  createEvent,
  formatDate,
  formatHour,
  GET_POLICY_TIME_LINE_EVENTS,
  Loading,
  Policy,
  policyTagColor,
  translationStatusPolicy,
  useAuth,
  useViewer,
} from '@tiny-fronts/basement'
import {
  ChipList,
  Column,
  ColumnsContainer,
  EmptyState,
  EmptyStateProps,
  HistoryLine,
  HistorylineItemProps,
  InvisibleButton,
  Modal,
  Pagination,
  Select,
  Tag,
  Text,
  View } from '@youse-seguradora/cargo-components'

import { GET_HISTORY_EMAIL_EVENT } from '../../queries/HistoryEmailEventQuery'

import { policyDataLayer } from './datalayer.payload'

import { useTheme } from '@material-ui/core/styles'

type PolicyProps = {
  policy: Policy
  product: string
  currentProject: string
}

type HistoryProps = {
  title: string
  occurredAt: string
  description: string
  referenceId: string | undefined
  referenceType: string | undefined
  tags: [string]
}

export type FiltersProps = {
  name: string
  isSelected: boolean
}

const historyDeliveryRef = (ref = ''): boolean => ref?.toLowerCase() === 'delivery'

const EmptyStateEmailModal = (props:Partial<EmptyStateProps>) => (
  <EmptyState
    title='E-mail indisponível.'
    description='Alguns e-mails podem não estar disponível no momento.'
    buttonLabel='Voltar'
    {...props}
  />
)

function PolicyHistory({ policy, product, currentProject }: PolicyProps) {
  const theme = useTheme()
  const history = useHistory()

  const [modalHTML, setModalHTML] = React.useState('')
  const [openModal, setOpenModal] = React.useState(false)

  const [oldest, setOldest] = React.useState(false)
  const [page, setPage] = React.useState(1)
  const [itemsList, setItemsList] = React.useState<string[]>([])

  const { authenticated } = useAuth()
  const { viewer } = useViewer()

  const PolicyDatalayer = React.useMemo(() => (
    policyDataLayer(policy?.number, authenticated, viewer, policy?.product)
  ), [policy, authenticated, viewer])
  
  const tagsList = React.useMemo(() => [
    {
      name: 'Pagamento',
      id: 'billing',
      isSelected: itemsList.includes('billing')
    },
    {
      name: 'Assistência',
      id: 'assist',
      isSelected: itemsList.includes('assist')
    },
    {
      name: 'Sinistro',
      id: 'claim',
      isSelected: itemsList.includes('claim')
    },
    {
      name: 'Vistorias',
      id: 'inspection',
      isSelected: itemsList.includes('inspection')
    },
    {
      name: 'Endosso',
      id: 'endorsement',
      isSelected: itemsList.includes('endorsement')
    }
  ], [itemsList])

  const selectChip = (selected: boolean, name: string, id?: string) => {
    if (id) {
      if (!selected) {
        setItemsList([id])
        createEvent(PolicyDatalayer.onFilter(id))
      } else {
        setItemsList([])
      }
      setPage(1)
    }
  }

  const handleSort = React.useCallback(event => {
    const _sort = JSON.parse(event.target.value)

    if (_sort !== oldest) {
      setPage(1)
      setOldest(_sort)
      createEvent(PolicyDatalayer.onSort(_sort))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldest])

  const {
    data,
    error,
    refetch,
    loading: loadingEvents,
  } = useQuery(GET_POLICY_TIME_LINE_EVENTS, {
    variables: {
      size: 10,
      number: page,
      oldest: oldest,
      tags: itemsList,
      product: policy.product.toUpperCase(),
      policyNumber: policy.number,
    }
  })

  React.useEffect(() => {
    refetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsList])

  function detailLinkRedirect(tags, referenceID) {
    const codePrefixMapping = {
      claim: `sinistros/${policy?.product?.toLowerCase()}`,
      inspection: 'vistorias',
    }

    const foundTag = tags.find((tag: { code: string }) => codePrefixMapping[tag.code])

    if (currentProject !== 'partner-portal' && foundTag?.code === 'claim') {
      return undefined
    }

    if (policy.product !== 'AUTO') {
      return undefined
    }

    if (!referenceID) return undefined

    return foundTag
      ? `${window.location.origin}/${codePrefixMapping[foundTag.code]}/${policy.number}/${referenceID}`
      : undefined
  }

  const [
    getHistoryEmailEvent,
    {
      loading: loadingEmail,
      error: errorEmail
    }
  ] = useLazyQuery(GET_HISTORY_EMAIL_EVENT)

  const handleDetailClick = (deliveryId?:string) => {
    if (deliveryId) {
      setOpenModal(true)
      getHistoryEmailEvent({
        variables: {
          deliveryId,
        },
        onCompleted: res => {
          setModalHTML(res?.historyEmailEvent?.body || '')
        }
      })
    }
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setModalHTML('')
  }

  const contentModal = React.useMemo(() => {
    return (
      <>
        {loadingEmail ? (
          <View size={{ width: '100%' }}>
            <Loading/>
          </View>
        ) : (
          errorEmail || !modalHTML ? (
            <EmptyStateEmailModal
              bgColor={'#FFFFFF'}
              buttonOnClick={() => {
                setOpenModal(false)
              }}
            />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: modalHTML }}/>
          )
        )}
      </>
    )
  }, [
    loadingEmail,
    errorEmail,
    modalHTML
  ])

  const historyLineItems: HistorylineItemProps[] = React.useMemo(() => {
    const histories = data?.policyTimelineEvents.nodes || []
    return histories.map((history: HistoryProps): HistorylineItemProps => {
      const historyRefs = !historyDeliveryRef(history?.referenceType) && history?.referenceId && history?.tags
      const hasDetail = historyRefs ||  historyDeliveryRef(history?.referenceType)
      return {
        title: history.title,
        content: history.description,
        date: formatDate(history.occurredAt.split('T', 1)[0]),
        time: formatHour(history.occurredAt) as string,
        detailLink: historyRefs ? 
          detailLinkRedirect(history.tags, history.referenceId) :
          '',
        onDetailClick: hasDetail ? () => {
          createEvent(PolicyDatalayer.moreDetails(history?.referenceType))
          if (historyDeliveryRef(history?.referenceType))
            handleDetailClick(history.referenceId)
        } : undefined
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const emptyStateData: EmptyStateProps = React.useMemo(() => {
    if (!loadingEvents) {      
      if (error || data?.error)
        return {
          description: 'Tivemos um problema para carregar as informações, tente novamente mais tarde.',
          buttonLabel: 'Recarregar',
          buttonOnClick() {
            if (window !== undefined) window.location.reload()
          },
        }

      if (itemsList?.length && !historyLineItems?.length)
        return {
          description: 'Não encontramos resultados para esse filtro.',
          buttonLabel: 'Limpar',
          buttonOnClick() {
            setItemsList([])
          },
        }
    }

    return {
      description: '',
      buttonLabel: '',
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    itemsList,
    loadingEvents,
    historyLineItems
  ])

  React.useEffect(() => {
    createEvent(PolicyDatalayer.pageView)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onPaginate = (pageNumber: number) =>  {
    setPage(pageNumber)
    createEvent(PolicyDatalayer.onPaginate(pageNumber))
  }

  return (
    <View spacing={{ bottom: 6 }}>
      <Modal
        isOpen={openModal}
        onClose={handleCloseModal}
        content={contentModal}
        isAutoWidth={true}
        customHeight='80vh'
      />
      <View spacing={{ top: 5, bottom: 8 }}>
        <ColumnsContainer>
          <Column size={12}>
            <View
              background={{
                color: theme.backgrounds.primary,
              }}
              spacing={{ vertical: 5, tabletHorizontal: 4, horizontal: 2 }}
            >
              <BaseDetails
                columns={[
                  {
                    size: 4,
                    title: 'Cliente:',
                    children: (
                      <Text
                        as='span'
                        weight='bold'
                        size='big'
                        color={theme.colors.secondary}
                      >
                        {policy?.insuredPerson?.name || '-'}
                      </Text>
                    ),
                  },
                  {
                    size: 4,
                    title: 'Apólice:',
                    children: (<>
                      <Text
                        as='span'
                        weight='bold'
                        size='big'
                        color={theme.colors.highlight}
                      >
                        {policy?.number || '-'}
                      </Text>
                      <View inline size={{ width: 'fit-content' }} spacing={{ left: 2 }}>
                        <Tag
                          name={compareTranslation(translationStatusPolicy, policy?.status)}
                          type={policyTagColor(policy?.status)}
                        />
                      </View>
                    </>),
                  },
                  {
                    size: 4,
                    title: 'Data Emissão:',
                    text: formatDate(policy?.issuedAt) || '-',
                  },
                ]}
              />
              <BaseDetails
                columns={[
                  {
                    size: 4,
                    title: 'Subproduto:',
                    text: policy?.subproduct && policy?.subproduct !== 'default'
                      ? policy.subproduct.toUpperCase()
                      : '-'
                  },
                  {
                    size: 4,
                    title: 'Vigência:',
                    text:
                      `${formatDate(policy?.effectiveFrom)} a ${formatDate(policy?.effectiveUntil)}` ||
                      '-',
                  },
                  {
                    size: 4,
                    children: (
                      <View spacing={{ top: 4 }} size={{ width: 'fit-content' }} >
                        <InvisibleButton
                          children='Resumo da apólice'
                          onClick={() =>
                            history.push(
                              `/apolices/${product}/${policy?.number}/resumo-da-apolice`
                            )
                          }
                          rightIcon='arrow-right'
                        />
                      </View>
                    )
                  }
                ]}
              />
            </View>
          </Column>
        </ColumnsContainer>
      </View>

      {(!error && !data?.error) && (
        <View spacing={{ top: 5, bottom: 5 }}>
          <ColumnsContainer>
            <Column size={12}>
              <View layout={{ horizontal: true, spread: true }}>
                <View size={{ width: 'auto' }}>
                  <Text color={theme.colors.secondary}>Filtro por tipo de evento:</Text>
                  <View spacing={{ top: 2 }}>
                    <ChipList chips={tagsList} handleSelect={selectChip} />
                  </View>
                </View>
                <View size={{ width: '288px' }} spacing={{ left: 6 }}>
                  <Select
                    name='oldest'
                    label='Ordenar por data'
                    onChange={handleSort}
                    value={JSON.stringify(oldest)}
                    options={[
                      {
                        value: 'false',
                        text: 'Mais recentes',
                      },
                      {
                        value: 'true',
                        text: 'Mais antigos',
                      }
                    ]}
                  />
                </View>
              </View>
            </Column>
          </ColumnsContainer>
        </View>
      )}

      <View spacing={{ top: 3 }} >
        <View layout={{ horizontal: true, spread: true }}>
          {loadingEvents ? (
            <View size={{ width: '100%' }}>
              <Loading/>
            </View>
          ) : emptyStateData?.description ? (        
            <EmptyState {...emptyStateData} style={{ marginBottom: '32px' }}/>
          ) : (
            <Grid
              container
              justifyContent='flex-start'
              alignContent='flex-start'
              spacing={2}
            >
              <Grid item md={6} sm={10}>
                <HistoryLine items={historyLineItems} />
              </Grid>
            </Grid>
          )}
        </View>
      </View>

      {!emptyStateData?.description && data?.policyTimelineEvents?.pageInfo?.totalPages > 1 && (
        <Pagination
          current={page}
          total={data.policyTimelineEvents.pageInfo.totalPages}
          onClick={onPaginate}
        />
      )}
    </View>
  )
}

export default PolicyHistory
