import { designTokens } from '@youse-seguradora/cargo-components'

import { createTheme } from '@material-ui/core/styles'

const theme = {
  colors: {
    highlight: designTokens.colors.purple500,
    highlightInverse: designTokens.colors.orange300,
    primary: designTokens.colors.grey900,
    primaryInverse: designTokens.colors.grey000,
    secondary: designTokens.colors.grey600,
    secondaryInverse: designTokens.colors.purple100,
    success: designTokens.colors.green800,
    warning: designTokens.colors.yellow900,
    error: designTokens.colors.red700,
    disable: designTokens.colors.grey100,
    textSidebar: designTokens.colors.grey000,
    sidebarPrice: designTokens.colors.orange300,
    linkSidebar: designTokens.colors.orange300,
    expandIcon: designTokens.colors.grey000,
  },
  backgrounds: {
    primary: designTokens.colors.grey025,
    secondary: designTokens.colors.grey000,
    inverse: designTokens.colors.purple500,
    highlightDark: designTokens.colors.purple600,
    highlight: designTokens.colors.purple400,
    highlightLight: designTokens.colors.purple400,
    success: designTokens.colors.green700,
    warning: designTokens.colors.yellow700,
    error: designTokens.colors.red700,
    successLight: designTokens.colors.green300,
    warningLight: designTokens.colors.yellow300,
    errorLight: designTokens.colors.red300,
    disable: designTokens.colors.grey050,
    header: designTokens.colors.purple600,
    focus: '#48218F',
    gradient: designTokens.colors.gradient,
  },
  divisors: {
    standard: designTokens.colors.grey050,
    inverse: designTokens.colors.purple050,
  },
  buttons: {
    primary: designTokens.colors.orange600,
    outlineHover: designTokens.colors.grey100,
    outlineHoverInverse: designTokens.colors.transparentLight008,
    outline: designTokens.colors.grey900,
    outlineInverse: designTokens.colors.grey000,
    labelHighlight: designTokens.colors.purple600,
  },
  shadow: {
    standard: {
      idle: designTokens.shadows.default.idle,
      active: designTokens.shadows.default.active,
      hover: designTokens.shadows.default.hover,
    },
    inverse: {
      idle: designTokens.shadows.dark.idle,
      active: designTokens.shadows.dark.active,
      hover: designTokens.shadows.dark.hover,
    },
  },
  palette: {
    primary: {
      main: designTokens.colors.grey900,
    },
    secondary: {
      main: designTokens.colors.grey600,
    },
    error: {
      main: designTokens.colors.red700,
    },
    background: {
      default: designTokens.colors.purple700,
    },
  },
  typography: {
    h1: { fontFamily: designTokens.typography.title.family.bold },
    h2: { fontFamily: designTokens.typography.title.family.bold },
    h3: { fontFamily: designTokens.typography.title.family.bold },
    h4: { fontFamily: designTokens.typography.title.family.bold },
    h5: { fontFamily: designTokens.typography.title.family.bold },
    h6: { fontFamily: designTokens.typography.title.family.bold },
    subtitle1: { fontFamily: designTokens.typography.title.family.light },
    subtitle2: { fontFamily: designTokens.typography.title.family.light },
  },
}
const defaultTheme = createTheme(theme)

export type ThemeType = typeof theme
export default defaultTheme
