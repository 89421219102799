import React, { useEffect, useState } from 'react'
import AutosizeInput from 'react-input-autosize'
import { FormControl, formFieldName } from '@tiny-fronts/resource-flow-components'
import { Text, View } from '@youse-seguradora/cargo-components'

import InputMask from './InputMask'

const FormInput = (props) => {
  const {
    errors,
    classes,
    inputType,
    label,
    name,
    obj,
    field,
    onChange,
    mask,
    desktopHorizontal,
    size,
    spacing,
    disabled,
    fullWidth,
  } = props

  const [value, setValue] = useState(field)

  useEffect(() => {
    setValue(() => field)
  }, [field])

  const formField = formFieldName(obj, name)

  return (
    <FormControl resourceerrors={errors} errorfor={formField} fullWidth={fullWidth}>
      <View
        layout={{
          desktopHorizontal,
          desktopAlignHorizontalCenter: true,
        }}
        spacing={spacing}
      >
        <View layout={{ desktopHorizontal: true }}>
          {props.tooltip && (
            <View
              spacing={{ right: 3 }}
              position={{ relative: true, top: '3px' }}
              layout={{
                alignHorizontalCenter: true,
                desktopAlignHorizontalCenter: false,
              }}
            >
              {props.tooltip}
            </View>
          )}
          <Text
            size='big'
            as={'label'}
            htmlFor={formField}
            weight={props.weight && 'bold'}
            align={props.align}
          >
            {label}
          </Text>
        </View>
        <View spacing={{ desktopLeft: 2 }} size={size}>
          {mask ? (
            <InputMask value={value || ''} setValue={setValue} {...props} />
          ) : (
            <AutosizeInput
              data-testid={`${name}-input`}
              inputClassName={classes.input}
              id={formField}
              type={inputType}
              autoComplete='nope'
              value={value || ''}
              onChange={(e) => setValue(e.target.value)}
              onBlur={(e: any) => onChange(name, e.target.value)}
              disabled={disabled}
            />
          )}
        </View>
      </View>
    </FormControl>
  )
}

FormInput.defaultProps = {
  inputType: '',
  mask: '',
  loader: false,
  desktopHorizontal: true,
  size: {},
  spacing: { top: 6 },
  disabled: false,
  fullWidth: true,
  field: '',
}

export default React.memo(
  FormInput,
  (p, n) =>
    (!n.field && !p.field) ||
    (p.field === n.field &&
      p.mask === n.mask &&
      p.loader === n.loader &&
      p.disabled === n.disabled),
)
