import React, { useState } from 'react'
import {
  Image,
  InvisibleButton,
  Modal,
  Spacer,
  Text,
  Title,
  View } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'

export const AuthenticationError = () => {
  const [isOpenError, setIsOpenError] = useState(true)

  const redirect = () => {
    window.location.href = ('https://www.youse.com.br/open-insurance')
  }

  const content = (
    <>
      <View layout={{ alignHorizontalCenter: true, alignVerticalCenter: true }} spacing={{ bottom: 2 }}>
        <Image alt='cellphone' src='https://www.datocms-assets.com/16903/1590783167-illus-characters-error-03-4x3-light.png' height={14} />

        <Title
          size={'small'}
          weight={'bold'}
          color={defaultTheme.colors.highlight}
          align={window.screen.width > 551 ? 'center' : 'left'}
        >
          A autenticação da sua solicitação expirou
        </Title>

        <Spacer top={4} />

        <Text
          size={'default'}
          weight={'regular'}
          color={defaultTheme.colors.secondary}
          align={window.screen.width > 551 ? 'center' : 'left'}
        >
          Você precisa voltar pra seguradora que vai enviar os seus dados e fazer uma nova solicitação.
        </Text>

        <Spacer top={4} />

        <InvisibleButton contextual onClick={redirect}>
          OK, ENTENDI
        </InvisibleButton>
      </View>
    </>
  )

  const modalCancel = {
    content: content,
  }

  return (
    <Modal
      {...modalCancel}
      hiddenOverflow={false}
      isOpen={isOpenError}
      onClose={() => {
        setIsOpenError(false)
      }}
    />
  )
}
