import * as React from 'react'
import { Icon, LinkContainer, View } from '@youse-seguradora/cargo-components'

const AppStoreCtas = () => {
  return (
    <View
      layout={{
        horizontal: true,
        alignHorizontalCenter: true,
      }}
    >
      <LinkContainer href='https://play.google.com/store/apps/details?id=br.com.youse'>
        <Icon name='googleplay' size={7} />
      </LinkContainer>
      <View inline spacing={{ left: 4 }}>
        <LinkContainer href='https://itunes.apple.com/br/app/youse-seguro-tipo-vc/id1371689994'>
          <Icon name='appstore' size={7} />
        </LinkContainer>
      </View>
    </View>
  )
}

export default AppStoreCtas
