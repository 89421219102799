export enum RoutePermissionsKeys {
  COTACOES = 'cotacoes',
  APOLICES = 'apolices',
  VISTORIAS = 'vistorias',
  SINISTRO = 'sinistro',
  GESTAO = 'gestao',
  CORRETORAS = 'corretoras',
  DADOS_FISCAIS = 'dados_fiscais',
  NOTAS_FISCAIS = 'notas_fiscais',
  RELATORIOS = 'relatorios',
  CONFIGURACOES = 'configuracoes',
  CONFIGURACOES_AVANCADAS = 'configuracoes_avancadas',
  CADASTRAR_USUARIO = 'cadastrar_usuario',
  USUARIOS_CADASTRADOS = 'usuarios_cadastrados',
  PERFIS_DE_ACESSO = 'perfis_de_acesso',
  TECNICAS = 'tecnicas',
  ENDOSSO = 'endosso',
  ASSISTENCIAS = 'assistencias',
  CANCELAMENTO = 'cancelamento',
  ORGANIZACOES = 'organizacoes',
}
