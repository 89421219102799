import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { PageContext } from '@tiny-fronts/basement'
import { BreadcrumbsRouter, Footer, Header } from '@youse-seguradora/cargo-components'
import { HeaderDataType } from '@youse-seguradora/cargo-components/dist/components/dato/cms/data/header'
import { footerData } from 'helpers/footerProps'

import { LayoutBaseProps } from '../types/LayoutBaseProps'

export default function PublicLayoutBase({ title, children }: LayoutBaseProps) {
  const history = useHistory()
  const headerOptions =
    {
      mainNav: {
        home: {
          label: 'Open Insurance',
          href: '/',
        },
      },
      adminNav: {
        items: [
          {
            label: 'YOUSE FRIENDS',
            onClick: () => {
              window.open(`${process.env.REACT_APP_HOME}seguro-auto/friends/`, '_blank')
            }
          },
          {
            label: 'CLUBE DE BENEFÍCIOS',
            onClick: () => {
              window.open(`${process.env.REACT_APP_HOME}account/partnerships`, '_blank')
            }
          }
        ],
        subNavItems: [
          {
            title: 'YOUSE FRIENDS',
            onClick: () => {
              window.open(`${process.env.REACT_APP_HOME}seguro-auto/friends/`, '_blank')
            }
          },
          {
            title: 'CLUBE DE BENEFÍCIOS',
            onClick: () => {
              window.open(`${process.env.REACT_APP_HOME}account/partnerships`, '_blank')
            }
          },
        ],
        button: {
          label: 'Login',
          icon: 'account',
          onClick: () => {
            history.push('/oauth')
          },
        },
      },
    }
  const { setTitle } = React.useContext(PageContext)

  useEffect(() => {
    setTitle(title)
  }, [title, setTitle])

  document.title = `${title} | Youse`

  const breadcrumbs = {
    items: [],
  }

  return (
    <>
      <Header {...headerOptions as HeaderDataType} admin />
      { title !== 'Home' && <BreadcrumbsRouter currentTitle={title} {...breadcrumbs} /> }
      { children }
      <Footer {...footerData} />
    </>
  )
}
