import React from 'react'
import {
  Card,
  Image,
  PrimaryButton,
  Text,
  TrackingContainer,
  TrackingContext,
  View,
} from '@youse-seguradora/cargo-components'

import CustomPlan from '../../../images/custom-plan.png'

const CustomCard = (props: any) => {
  const { theme, buttonAction } = props
  return (
    <Card style={{ border: 'dashed 1px #C3A6DA' }}>
      <View spacing={{ horizontal: 4 }} size={{ height: 'auto' }}>
        <View
          layout={{
            horizontal: true,
            alignHorizontalCenter: true,
          }}
          spacing={{ top: 14 }}
        >
          <Text align='center' color={theme.colors.highlight} size='big' weight='bold'>
            Quero pagar pelo que eu escolher ;)
          </Text>
        </View>
        <View spacing={{ top: 5 }}>
          <TrackingContext category='route-change'>
            <TrackingContainer action='buy' label='personalizar'>
              <PrimaryButton full onClick={buttonAction} data-testid={'plan-card-button-custom'}>
                PERSONALIZAR
              </PrimaryButton>
            </TrackingContainer>
          </TrackingContext>
        </View>
        <View spacing={{ top: 8 }}>
          <Image src={CustomPlan} alt='personalize seu seguro' />
        </View>
      </View>
    </Card>
  )
}

export default CustomCard
