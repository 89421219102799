import React, { useEffect, useState } from 'react'
import {
  BFFClient,
  inputStyles,
  noDelaySetInterval,
  ResourceFlowContext,
} from '@tiny-fronts/resource-flow-components'
import { InvisibleButton, View } from '@youse-seguradora/cargo-components'

import CarDetails from './CarDetails/CarDetails'

const bffClient = new BFFClient()

const POOLING_RETRY_MILLISECONDS = 1000

type VehicleWithoutPlateOrVinProps = {
  carDetails: boolean
  setCarDetails: React.Dispatch<React.SetStateAction<boolean>>
  setShowDefaultOptions: React.Dispatch<React.SetStateAction<boolean>>
  showDefaultOptions: boolean
  setOptions: (
    options: {
      fipeCode: string
      accepted: any
      year: string
      auction?: boolean
      acceptance?: any
      message?: string
    }[],
  ) => void

  errors?: string
  vehicle: {
    acceptanceType: string
    vin: string
    licensePlate: string
    fipeCode?: string
    usage?: string
    ownershipStatus?: string
    bulletProof?: boolean
    brandNew?: boolean
    address: {
      zipCode?: string
    }
    make?: string
    model?: string
    year?: string
    version?: string
  }
  usages?: string
  ownershipStatuses?: string
  setRCFPage?: any
  rcfPage?: boolean
  onChange?: any
}

const VehicleWithoutPlateOrVin = ({
  carDetails,
  setCarDetails,
  errors,
  vehicle,
  onChange,
  setOptions,
  setShowDefaultOptions,
}: VehicleWithoutPlateOrVinProps) => {
  const [vehicleMakes, setVehicleMakes] = useState<{ value: string; text: string }[]>([])
  const [vehicleModels, setVehicleModels] = useState<{ value: string; text: string }[]>([])
  const [vehicleYears, setVehicleYears] = useState<{ value: string; text: string }[]>([])
  const [vehicleVersions, setVehicleVersions] = useState<{ value: string; text: string }[]>([])

  const context = React.useContext<any>(ResourceFlowContext)

  const VEHICLE_MAKE = context.data.resource.vehicle.make
  const VEHICLE_MODEL = context.data.resource.vehicle.model
  const VEHICLE_YEAR = context.data.resource.vehicle.year
  const VEHICLE_VERSION = context.data.resource.vehicle.version
  const LICENSE_PLATE = context.data.resource.vehicle.licensePlate
  const VIN = context.data.resource.vehicle.vin

  useEffect(() => {
    if (VEHICLE_MAKE !== null && LICENSE_PLATE === null && VIN === null) {
      requestVehicleMakes()
      requestVehicleModels(VEHICLE_MAKE)
      requestVehicleYears(VEHICLE_MAKE, VEHICLE_MODEL)
      requestVehicleVersions(VEHICLE_MAKE, VEHICLE_MODEL, VEHICLE_YEAR)
      fetchVehicleByVersion(VEHICLE_MAKE, VEHICLE_MODEL, VEHICLE_YEAR, VEHICLE_VERSION)
      setCarDetails(true)
    } else {
      cleanVehicleFields(['make', 'model', 'year', 'version'])
      setCarDetails(false)
      setShowDefaultOptions(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      (LICENSE_PLATE !== null && LICENSE_PLATE !== undefined) ||
      (VIN !== null && VIN !== undefined)
    ) {
      setCarDetails(false)
    }
  }, [LICENSE_PLATE, VIN])

  const classes = inputStyles()

  const vehicleChange = (field: string, value: any) => {
    onChange(`vehicle.${field}`, value)
  }

  const setOnlyFipe = (fipeCodeValue: string) => {
    vehicleChange('fipeCode', fipeCodeValue)
  }

  const handleVehicleAttributeSelector = (field: string, value: any) => {
    vehicleChange(field, value)

    switch (field) {
    case 'make':
      requestVehicleModels(value)
      cleanVehicleFields(['model', 'year', 'version'])
      break
    case 'model':
      requestVehicleYears(vehicle.make, value)
      cleanVehicleFields(['year', 'version'])
      break
    case 'year':
      requestVehicleVersions(vehicle.make, vehicle.model, value)
      cleanVehicleFields(['version'])
      break
    case 'version':
      fetchVehicleByVersion(vehicle.make, vehicle.model, vehicle.year, value)
      break
    default:
      break
    }
  }

  const cleanVehicleFields = (values: any) => {
    values.forEach((value: string) => {
      vehicleChange(value, null)
    })
  }

  const fetchVehicleByVersion = (make, model, year, version) => {
    setOptions([])

    const interval = noDelaySetInterval(() => {
      bffClient.fetchVehicleByVersion(make, model, year, version).then((resp) => {
        const data = resp.data
        if (resp.status === 202) return
        clearInterval(interval)

        if (resp.status === 404 || resp.status === 500) return
        const dataOptions = [data]
        setOptions(dataOptions)

        if (data && data.accepted) {
          setOnlyFipe(data.externalCode)
        }
      })
    }, POOLING_RETRY_MILLISECONDS)
  }

  const requestVehicleMakes = () => {
    const interval = noDelaySetInterval(() => {
      bffClient.fetchVehicleMakes().then((resp) => {
        const data = resp.data
        if (resp.status === 202) return
        clearInterval(interval)

        if (resp.status === 404 || resp.status === 500) return
        const makes = data.map((make) => ({ value: make.text, text: make.text }))
        setVehicleMakes(makes)
      })
    }, POOLING_RETRY_MILLISECONDS)
  }

  const requestVehicleModels = (make) => {
    const interval = noDelaySetInterval(() => {
      bffClient.fetchVehicleModels(make).then((resp) => {
        const data = resp.data

        if (resp.status === 202) return
        clearInterval(interval)

        if (resp.status === 404 || resp.status === 500) return
        setVehicleModels(data)
      })
    }, POOLING_RETRY_MILLISECONDS)
  }

  const requestVehicleYears = (make, model) => {
    const interval = noDelaySetInterval(() => {
      bffClient.fetchVehicleYears(make, model).then((resp) => {
        const data = resp.data
        if (resp.status === 202) return
        clearInterval(interval)

        if (resp.status === 404 || resp.status === 500) return

        setVehicleYears(data)
      })
    }, POOLING_RETRY_MILLISECONDS)
  }

  const requestVehicleVersions = (make, model, year) => {
    const interval = noDelaySetInterval(() => {
      bffClient.fetchVehicleVersions(make, model, year).then((resp) => {
        const data = resp.data

        if (resp.status === 202) return
        clearInterval(interval)
        if (resp.status === 404 || resp.status === 500) return
        const versions = data.map((version) => ({ value: version.text, text: version.text }))
        setVehicleVersions(versions)
      })
    }, POOLING_RETRY_MILLISECONDS)
  }

  const handleVehicleDetailsSelector = () => {
    setCarDetails(!carDetails)
    setShowDefaultOptions(false)
    requestVehicleMakes()
  }
  return (
    <>
      {!carDetails && LICENSE_PLATE === null && VIN === null && (
        <View as='div' layout={{ alignHorizontalCenter: true }} spacing={{ top: 5 }}>
          <InvisibleButton onClick={() => handleVehicleDetailsSelector()}>
            Não sei a placa ou chassi
          </InvisibleButton>
        </View>
      )}
      {carDetails && (
        <CarDetails
          errors={errors}
          classes={classes}
          vehicle={vehicle}
          vehicleMakes={vehicleMakes}
          vehicleModels={vehicleModels}
          vehicleYears={vehicleYears}
          vehicleVersions={vehicleVersions}
          onChange={handleVehicleAttributeSelector}
        />
      )}
    </>
  )
}

export default VehicleWithoutPlateOrVin
