import React, { useEffect, useState } from 'react'
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch
} from 'react-router-dom'
import { Loading, useLocalStorage } from '@tiny-fronts/basement'
import { Container, Spacer, Text, Title } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import {
  nextPath,
  Steps,
  subtitle,
  title } from 'helpers/stepsUtils'
import useRequest from 'hooks/useRequest'
import { Consent, ConsentApproveResponseBody } from 'types/Consent'
import { Policy } from 'types/Policy'

import BottomBar from 'components/BottomBar'
import {
  CancellationModal,
  CancellationModalError
} from 'components/ConsentCancellation'
import {
  AuthenticationError,
  InteractionNotFoundError,
  LoginError,
  UnknownError,
} from 'components/Errors'

import ConfirmationPage from './ConfirmationPage'
import ConfirmEndorsementPage from './ConfirmEndorsementPage'
import DataSourcePage from './DataSourcePage'
import OverviewPage from './OverviewPage'
import RedirectPage from './RedirectPage'

interface ConsentParams {
  consentInteractionId: string
}

export default function TransmissionFlow() {
  const { consentInteractionId } = useParams<ConsentParams>()
  const [consent, setConsent] = useLocalStorage<Consent>(`consent:${consentInteractionId}`)
  const [loading, setLoading] = useState(false)
  const [cancellationModal, setCancellationModal] = useState(false)
  const [viewBtnBar, setViewBtnBar] = useState(true)
  const [step, setStep] = useState<Steps>(Steps.overview)
  const history = useHistory()
  const { path, url } = useRouteMatch()
  const location = useLocation()
  const [errorType, setErrorType] = useState<
    null | 'expired_error' | 'interaction_not_found' | 'auth_error' | 'unknown_error'
  >(null)

  const setPolicies = (policies: Policy[]) => {
    setConsent({
      ...consent,
      policies,
    })
  }

  const {
    execute: cancel,
    body: cancellationBody,
    loading: cancellationLoading,
    error: cancellationError,
  } = useRequest('POST', `consents/${consentInteractionId}/cancel`, false)

  const redirectToPublicHome = () =>
    (window.location.href = cancellationBody.url)

  const handleCancellation = () => cancel(true)

  useEffect(() => {
    if (cancellationLoading) {
      return
    }

    if (cancellationBody) {
      setCancellationModal(false)
      redirectToPublicHome()
    }

    if (cancellationError) {
      setCancellationModal(false)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancellationBody, cancellationLoading, cancellationError])

  const {
    execute: approve,
    body: approveBody,
    loading: approveLoading,
    error: approveError,
  } = useRequest<ConsentApproveResponseBody>(
    'POST',
    `consents/${consentInteractionId}/approve`,
    false,
    consent.policies
  )

  useEffect(() => {
    if (approveLoading) {
      setLoading(true)
      return
    }

    if (approveBody) {
      setConsent({ ...consent, ...{ redirectURL: approveBody.url } })

      history.push(`${url.replace(/\/$/, '')}/redirecionamento`)
      setLoading(false)
    }

    if (approveError) {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approveBody, approveLoading, approveError, history, step, url])

  useEffect(() => {
    if (consent.kind === 'DATA') {
      setStep(Steps[location.pathname.split(`${consentInteractionId}/`)[1] || 'overview'])
    } else {
      if (consent.segment === 'ENDOSSO') {
        setStep(Steps['endorsement-confirm'])
      } else {
        setStep(Steps['claim-confirm'])
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, consentInteractionId])

  const [tick, setTick] = useState(0)

  useEffect(() => {
    const timeout = setTimeout(() => {
      const now = new Date()
      const at = new Date(consent.finishTransmissionUntil)

      if (now > at) {
        setErrorType('expired_error')
      } else {
        setTick((t) => t + 1)
      }
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [consent, tick])

  if (errorType) {
    switch (errorType) {
    case 'interaction_not_found':
      return <InteractionNotFoundError />
    case 'auth_error':
      return <AuthenticationError />
    case 'unknown_error':
      return <UnknownError />
    case 'expired_error':
      return <CancellationModalError isOpen={true} close={() => redirectToPublicHome()} />
    default:
      return <LoginError />
    }
  }

  if (loading) return <Loading />
  if (approveError) return <UnknownError />

  return (
    <>
      <Container>
        <Spacer top={6} />

        <Title size={'small'} weight={'bold'} color={defaultTheme.colors.primary}>
          {title(step)}
        </Title>

        <Spacer top={2} />

        <Title size={'xsmall'} weight={'light'} color={defaultTheme.colors.secondary}>
          {subtitle(step)} {consent.kind === 'SERVICE' && consent.organizationName}
        </Title>

        <Spacer top={4} />

        <Switch>
          <Route
            exact={true}
            path={`${path}/`}
            render={() => {
              return consent.kind === 'DATA' ?
                <OverviewPage consent={consent} /> :
                <ConfirmEndorsementPage consent={consent} />
            }}
          />

          <Route
            exact={true}
            path={`${path}/origem-dos-dados`}
            render={() => {
              return (
                <DataSourcePage
                  consent={consent}
                  selectedPolicies={consent.policies || []}
                  setPolicies={setPolicies}
                  url={url}
                />
              )
            }}
          />

          <Route
            exact={true}
            path={`${path}/confirmacao`}
            render={() => {
              return <ConfirmationPage consent={consent} />
            }}
          />

          <Route
            exact={true}
            path={`${path}/redirecionamento`}
            render={() => {
              return <RedirectPage consent={consent} />
            }}
          />

        </Switch>

        {
          consent.kind === 'SERVICE' && (
            <>
              <Spacer top={6} />
              <Text align='center'>
                Ao clicar em confirmar você manifesta sua intenção em realizar um endosso na sua apólice
              </Text>
            </>
          )
        }
      </Container>

      {(step !== Steps.redirecionamento && viewBtnBar) && (
        <>
          <BottomBar
            previousStep={() => {
              setCancellationModal(true)
            }}
            isDisabled={false}
            consent={consent}
            nextStep={() => {
              if (step === Steps.confirmacao || consent.kind === 'SERVICE') {
                approve(true)
                setViewBtnBar(false)
              } else {
                history.push(`${url.replace(/\/$/, '')}/${nextPath(step)}`)
              }
            }}
          />

          <CancellationModal
            isOpen={cancellationModal}
            close={() => {
              setCancellationModal(false)
            }}
            handleCancellation={handleCancellation}
          />

          <CancellationModalError
            isOpen={!!cancellationError}
            close={() => redirectToPublicHome()}
          />
        </>
      )}
    </>
  )
}
