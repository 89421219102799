import React, { ReactElement } from 'react'
import { GenericError, PoliciesNotFound, Policy } from '@tiny-fronts/basement'

type ErrorComponentProps = {
  errorSearch?: boolean
  policy: Policy | undefined
  children: ReactElement
}

export default function ErrorComponent({ errorSearch, policy, children }: ErrorComponentProps) {
  const errorCondition = policy !== undefined && policy === null
  const noError = policy

  if (errorCondition || errorSearch)
    return <PoliciesNotFound listTitle='Ah! Mas pode fazer sua busca novamente ;)' />
  else if (noError) return children
  else return <GenericError />
}
