import React, { useEffect } from 'react'

import DataLayerClient from '../../../clients/DataLayerClient'
import LayoutBase from '../../LayoutBase'
import FinishedOrder from '../../pages/FinishedOrder'

import SuccessDefault from './SuccessDefault/SuccessDefault'
import Inspection from './Inspection'
import InspectionMonitoringDefault from './InspectionMonitoringDefault'
import OnlineInspection from './OnlineInspection'
import Proposal from './Proposal'

type SuccessSectionProps = {
  resource?: any
  seo?: any
  productName: string
  dataLayerParams?: string
  iconsActive?: boolean
  icons?: string[] | any
  success?: boolean
  customFooter?: React.ReactNode
}

const SuccessSection = ({
  resource,
  seo,
  productName,
  dataLayerParams,
  iconsActive,
  icons,
  customFooter,
  success = true,
}: SuccessSectionProps) => {
  useEffect(() => {
    new DataLayerClient('route-change.policy.created').trigger('Sucesso', dataLayerParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!resource) {
    return <FinishedOrder />
  }

  const caseInspectionMonitoring = () => {
    switch (resource.inspectionMonitoring) {
    case 'inspection':
      return <Inspection data={resource} productName={productName} />
    case 'online_inspection':
      return <OnlineInspection data={resource} productName={productName} />
    default:
      return <InspectionMonitoringDefault data={resource} productName={productName} />
    }
  }

  return (
    <LayoutBase
      iconsActive={iconsActive}
      icons={iconsActive === true && icons}
      success={success}
      seo={seo}
      customFooter={customFooter}
    >
      {!resource.policy.number ? (
        <Proposal data={resource} productName={productName} />
      ) : resource.inspectionMonitoring === undefined ? (
        <SuccessDefault data={resource} productName={productName} />
      ) : (
        caseInspectionMonitoring()
      )}
    </LayoutBase>
  )
}

export default SuccessSection
