import React, { useContext, useEffect } from 'react'
import {
  Image,
  PrimaryButtonLink,
  SecondaryButton,
  Title,
  TrackingContainer,
  View,
} from '@youse-seguradora/cargo-components'

import DataLayerClient from '../../../clients/DataLayerClient'
import { ResourceFlowContext } from '../../../contexts/ResourceFlowContext'
import OhNo from '../../images/oh-no.svg'

import { useTheme } from '@material-ui/core/styles'

type SomethingWrongProps = {
  expiredOrder?: boolean
  redirectToHome?: boolean
  title?: string
  body?: string
  href?: string
  withoutPlateOrVin?: boolean
}

const SomethingWrong = ({
  expiredOrder,
  redirectToHome = true,
  body,
  href,
  title,
  withoutPlateOrVin,
}: SomethingWrongProps) => {
  const theme = useTheme()
  const context = useContext<any>(ResourceFlowContext)

  useEffect(() => {
    const title = expiredOrder ? 'A cotação expirou' : 'Alguma coisa deu errada.'

    new DataLayerClient().triggerOrder(
      context?.data?.resourceId,
      context?.data?.resource?.salesChannel,
    )
    new DataLayerClient().trigger(title)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <View
        size={{ minHeight: '100vh' }}
        style={{ background: theme.backgrounds.gradient, overflow: 'scroll' }}
        spacing={{ vertical: 6 }}
        layout={{ desktopHorizontal: true }}
      >
        <View
          spacing={{
            desktopLeft: 21,
            desktopTop: 14,
            desktopRight: 0,
            left: 6,
            top: 8,
            right: 6,
          }}
          size={{ desktopWidth: '580px', width: 'auto' }}
        >
          <Title size='big' color={theme.colors.highlightInverse}>
            {title || 'Oh no!'}
          </Title>
          {expiredOrder ? (
            <>
              <Title size='big' color={theme.colors.primaryInverse}>
                A cotação expirou :(
              </Title>
              <View spacing={{ top: 5 }}>
                <Title size='small' weight='light' color={theme.colors.primaryInverse}>
                  A gente indica que você faça uma nova cotação para descobrir o valor do seu
                  seguro.
                </Title>
              </View>

              <View spacing={{ top: 6 }} size={{ width: '170px' }}>
                {redirectToHome && (
                  <TrackingContainer action='buy' label='Restart Quote Insurance'>
                    <SecondaryButton light onClick={() => context.restart()}>
                      cotar de novo
                    </SecondaryButton>
                  </TrackingContainer>
                )}
              </View>
            </>
          ) : (
            <View spacing={{ top: 5 }}>
              <Title size='small' weight='light' color={theme.colors.primaryInverse}>
                {body ||
                  'Alguma coisa deu errada. A gente tá tentando ver o que aconteceu pra resolver o mais rápido possível, tá?'}
              </Title>
              {withoutPlateOrVin && (
                <View spacing={{ top: 6 }}>
                  <TrackingContainer
                    action='vehicle_without_plate_or_vin'
                    label='Redirect to Vehicle Details'
                  >
                    <PrimaryButtonLink css='' onClick={context.navigateToVehicleDetails}>
                      INFORMAR PLACA OU CHASSI
                    </PrimaryButtonLink>
                  </TrackingContainer>
                </View>
              )}
              {href && (
                <View spacing={{ top: 6 }}>
                  <TrackingContainer action='rcf' label='Redirect to RCF Lading Page'>
                    <PrimaryButtonLink target='_self' css='' href={href}>
                      COTAR ESSA OPÇÃO
                    </PrimaryButtonLink>
                  </TrackingContainer>
                </View>
              )}
            </View>
          )}
        </View>

        <View
          layout={{ alignRight: true }}
          spacing={{ horizontal: 5 }}
          position={{ desktopAbsolute: true, right: '30px' }}
        >
          <View size={{ maxWidth: '600px', minWidth: '380px' }}>
            <Image
              alt='avatar saindo de porta'
              src={OhNo}
              height={52}
              tabletHeight={66}
              desktopHeight={80}
            />
          </View>
        </View>
      </View>
    </>
  )
}

export default SomethingWrong
