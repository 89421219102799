/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import HandleHTTPErrors from './HandleHTTPErrors'
import { PlanData } from './types'

/*
Check this example passing undefined for React.createContext()
https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/context/#extended-example
https://reactjs.org/docs/context.html#reactcreatecontext
*/
const ResourceFlowContext = React.createContext(undefined)

const ResourceFlowContextProvider = (props: any) => {
  const { children, match, history, client, sections } = props

  const [loading, setLoading] = useState(true)
  const [allowSubmit, setAllowSubmit] = useState(true)
  const [resourceFlow, setResourceFlow] = useState<any>()
  const [lgpdConsent, setLgpdConsent] = useState(false)
  const [currentPlan, setCurrentPlan] = useState<PlanData>()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    _load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Public methods
  const drawComponent = () => {
    if (resourceFlow.error) {
      //@ts-ignore
      return <HandleHTTPErrors redirectToHome={props.isRedirectNewOrder} {...resourceFlow} />
    }

    return sections[resourceFlow.data.currentSection].component
  }

  const updateResource = (attributes, partialSection = true) => {
    const sectionsAttributes = ['coverages', 'assistances', 'driver', 'vehicle', 'insuredPerson']

    function customizer(oldValue, newValue, key) {
      if (sectionsAttributes.includes(key)) return newValue
    }

    _.mergeWith(resourceFlow.data.resource, attributes, customizer)
    delete resourceFlow.data.resource.errors
    setAllowSubmit(false)

    if (resourceFlow.data.id === null || resourceFlow.data.id === undefined) {
      if (!partialSection) setLoading(true)

      return client
        .create({
          currentSection: resourceFlow.data.currentSection,
          partialSection: partialSection,
          resourceAttributes: resourceFlow.data.resource,
        })
        .then((response) => {
          const lgpdConsent = resourceFlow.data.resource?.lgpdConsent

          _updateResourceFlowLocalState(response)
          setLoading(false)
          setAllowSubmit(true)
          lgpdConsent && setLgpdConsent(lgpdConsent)

          return response
        })
    } else {
      if (!partialSection) setLoading(true)
      return client
        .update(resourceFlow.data.id, {
          currentSection: resourceFlow.data.currentSection,
          partialSection: partialSection,
          resourceAttributes: resourceFlow.data.resource,
        })
        .then((response) => {
          const lgpdConsent = resourceFlow.data.resource?.lgpdConsent

          _updateResourceFlowLocalState(response)
          setLoading(false)
          setAllowSubmit(true)
          lgpdConsent && setLgpdConsent(lgpdConsent)

          return response
        })
    }
  }

  const submit = (attributes) => updateResource(attributes || {}, false)

  const navigateBack = (_event) => {
    setLoading(true)
    return client
      .get(resourceFlow.data.id, {
        currentSection: resourceFlow.data.previousSection,
      })
      .then((response) => {
        _updateResourceFlowLocalState(response)
        setLoading(false)

        return response.data
      })
  }

  const navigateToHome = (_event) => {
    setLoading(true)
    return client
      .get(resourceFlow.data.id, { currentSection: 'pricing_requirements' })
      .then((response) => {
        _updateResourceFlowLocalState(response)
        setLoading(false)

        return response.data
      })
  }

  const navigateToPlans = (_event) => {
    setLoading(true)
    return client
      .get(resourceFlow.data.id, { currentSection: 'plan_selection' })
      .then((response) => {
        _updateResourceFlowLocalState(response)
        setLoading(false)

        return response.data
      })
  }

  const navigateToVehicleDetails = (_event) => {
    setLoading(true)
    return client
      .get(resourceFlow.data.id, { currentSection: 'vehicle_details' })
      .then((response) => {
        _updateResourceFlowLocalState(response)
        setLoading(false)

        return response.data
      })
  }

  const navigateToLeadInfo = (_event) => {
    setLoading(true)
    return client.get(resourceFlow.data.id, { currentSection: 'lead_info' }).then((response) => {
      _updateResourceFlowLocalState(response)
      setLoading(false)

      return response.data
    })
  }

  const navigateToAssistancesSelection = () => {
    setLoading(true)
    return client
      .get(resourceFlow.data.id, {
        currentSection: 'assistances_selection',
      })
      .then((response) => {
        _updateResourceFlowLocalState(response)
        setLoading(false)

        return response.data
      })
  }

  const restart = () => {
    setLoading(true)

    return client.new().then((response) => {
      _updateResourceFlowLocalState(response)
      setLoading(false)
      history.push('/')
      return response
    })
  }

  const reload = () => _load()

  const getResourceFlowData = (currentSection: string) => {
    return client.get(match.params.resourceFlowId, { currentSection }).then(response => response)
  }

  // Private methods
  const _load = () => {
    if (match.params.resourceFlowId) {
      return client
        .get(match.params.resourceFlowId, {
          currentSection: _currentSectionByParam(),
        })
        .then((response) => {
          _updateResourceFlowLocalState(response)
          setLoading(false)
          return response
        })
    } else {
      return client.new().then((response) => {
        _updateResourceFlowLocalState(response)
        setLoading(false)
        return response
      })
    }
  }

  const _updateResourceFlowLocalState = (response) => {
    setResourceFlow(response)
    if (response.status === undefined) return
    _updateBrowserUrl(response.data.id, response.data.currentSection)
  }

  const _currentSectionByParam = () => {
    if (!match.params.section) return

    let name

    Object.keys(sections).forEach((sectionName) => {
      if (match.params.section === sections[sectionName].slug) {
        name = sectionName
        return
      }
    })

    return name
  }

  const _updateBrowserUrl = (id, section) => {
    if (!id) return

    const url =
      match.path
        .replace(':resourceFlowId?', id)
        .replace(':section?', sections[section].slug || '') + window.location.search
    history.push(url)
  }

  return (
    <ResourceFlowContext.Provider
      value={{
        ...resourceFlow,
        drawComponent,
        updateResource,
        submit,
        navigateBack,
        navigateToHome,
        navigateToPlans,
        navigateToVehicleDetails,
        navigateToLeadInfo,
        navigateToAssistancesSelection,
        restart,
        loading,
        reload,
        allowSubmit,
        setResourceFlow,
        getResourceFlowData,
        lgpdConsent,
        setCurrentPlan,
        currentPlan
      }}
    >
      {children}
    </ResourceFlowContext.Provider>
  )
}

export { ResourceFlowContext, ResourceFlowContextProvider }
