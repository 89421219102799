import React, { useContext, useEffect } from 'react'

import DataLayerClient from '../../../clients/DataLayerClient'
import { PixContextProvider } from '../../../contexts/PixContext/PixContext'
import { ResourceFlowContext } from '../../../contexts/ResourceFlowContext'
import SeoInterface from '../../../interfaces/SeoInterface'
import { SidebarCardProps } from '../../LayoutBaseWithSidebar/Sidebar/SidebarCard'
import { SidebarIconsProps } from '../../LayoutBaseWithSidebar/Sidebar/SidebarIcons'

import CustomerCheckout from './CustomerCheckout'
import ImpersonateCheckout from './ImpersonateCheckout'

type CheckoutProps = {
  productName: string
  message?: React.ReactNode
  sidebarIcons?: SidebarIconsProps
  sidebarCard?: SidebarCardProps
  customFooter?: React.ReactNode
  iconHeaderPartner?: string[]
  isLGPGEnabled?: boolean
  isSidebarNotifyOrder?: boolean
  isMobileBackgroundColorAside?: boolean
  backButton?: boolean
  restartQuotation?: boolean
  triggerGTMEvent?: (paymentMethod: string, installmentsFrequency: string) => void
}

const Checkout = ({
  message,
  productName,
  sidebarIcons,
  sidebarCard,
  customFooter,
  iconHeaderPartner,
  isLGPGEnabled,
  isSidebarNotifyOrder,
  isMobileBackgroundColorAside,
  backButton,
  restartQuotation,
  triggerGTMEvent
}: CheckoutProps) => {
  const context = useContext<any>(ResourceFlowContext)
  const impersonate = context?.data?.resource?.impersonate

  const seo: SeoInterface = { title: `${productName} | Youse` }

  useEffect(() => {
    new DataLayerClient().trigger('Page Checkout')
    // eslint-disable-next-line no-lone-blocks
  }, [])

  return impersonate ? (
    <ImpersonateCheckout
      productName={productName}
      seo={seo}
      sidebarIcons={sidebarIcons}
      sidebarCard={sidebarCard}
      iconHeaderPartner={iconHeaderPartner}
    />
  ) : (
    <PixContextProvider>
      <CustomerCheckout
        productName={productName}
        seo={seo}
        sidebarIcons={sidebarIcons}
        sidebarCard={sidebarCard}
        customFooter={customFooter}
        iconHeaderPartner={iconHeaderPartner}
        message={message}
        isLGPGEnabled={isLGPGEnabled}
        isSidebarNotifyOrder={isSidebarNotifyOrder}
        isMobileBackgroundColorAside={isMobileBackgroundColorAside}
        backButton={backButton}
        restartQuotation={restartQuotation}
        triggerGTMEvent={triggerGTMEvent}
      />
    </PixContextProvider>
  )
}

export default Checkout
