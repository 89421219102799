export type DataError =  {
    attribute: string
    messages: [
        {
            code: string
            description: string
        }
    ]
}

export function mapFormFieldErrors(dataError: DataError[], setError) {
  if (dataError?.length)
        dataError?.forEach(err => {
          const { attribute, messages } = err

          if (attribute)
            setError(attribute, {
              type: attribute,
              message: messages?.[0]?.description,
            })
        })
}

export default mapFormFieldErrors
