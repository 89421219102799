export const formatCPF = (document: string) =>
  document.slice(0, 3) +
  '.' +
  document.slice(3, 6) +
  '.' +
  document.slice(6, 9) +
  '-' +
  document.slice(9, 11)

export const formatSegment = (segment: string) =>
  ({
    AUTO: 'Automóvel',
    HOME: 'Residencial',
    LIFE: 'Vida',
  }[segment])
