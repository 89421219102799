import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Container,
  Image,
  PrimaryButton,
  Spacer,
  Text,
  Title,
  View } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'

import { useTheme } from '@material-ui/core/styles'

export const InteractionNotFoundError = () => {
  const { colors } = useTheme()

  const history = useHistory()

  const redirect = () => {
    history.goBack()
  }

  return (
    <>
      <Container>
        <Spacer top={8} />

        <View layout={{ alignHorizontalCenter: true, alignVerticalCenter: true }} style={{ gap: '24px', minHeight: '100vh' }}>
          <Image src='https://www.datocms-assets.com/16903/1590782846-illus-characters-confused-02-4x3-light.png' height={19} alt={''} />

          <Title
            size={'default'}
            weight={'bold'}
            align={window.screen.width > 551 ? 'center' : 'left'}
            color={defaultTheme.colors.primary}
          >
            Não conseguimos encontrar essa página.
          </Title>

          <View style={{ maxWidth: '472px' }}>
            <Text
              size={'default'}
              align={window.screen.width > 551 ? 'center' : 'left'}
              weight={'regular'}
              color={defaultTheme.colors.primary}
            >
              Que tal voltar, ver se está tudo certo e tentar novamente?
            </Text>
          </View>
        </View>
      </Container>

      <View
        as='div'
        background={{ color: colors.textSidebar }}
        style={{
          boxShadow: '0px 2px 16px rgba(47, 57, 65, 0.16)',
          bottom: 0,
          padding: '25px 0px'
        }}
        size={{ width: '100%' }}
        position={{ absolute: true }}
      >
        <Container>
          <View
            style={{ flexDirection: 'row', justifyContent: 'center' }}
            spacing={{ left: 0, desktopLeft: 20, right: 0, desktopRight: 20 }}
          >
            <PrimaryButton full onClick={redirect}>
              VOLTAR PARA A PÁGINA ANTERIOR
            </PrimaryButton>
          </View>
        </Container>
      </View>
    </>
  )
}
