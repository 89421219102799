import React from 'react'
import { Spacer, Title, View } from '@youse-seguradora/cargo-components'

import CheckoutResumeItem from './CheckoutResumeItem'

import { useTheme } from '@material-ui/core/styles'

const CheckoutResume = ({ data }: any) => {
  const theme = useTheme()

  return (
    <>
      <View spacing={{ left: 4, right: 4 }}>
        <Title size='small' align='center' color={theme.colors.primary}>
          Suas informações
        </Title>
      </View>

      <Spacer top={4} />

      <View spacing={{ left: 4, right: 4 }} background={{ color: theme.backgrounds.secondary }}>
        {data.map((item: any, index: number) => {
          return <CheckoutResumeItem key={index} item={item} />
        })}
      </View>
    </>
  )
}

export default CheckoutResume
