/* eslint-disable max-len */
import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Accordion,
  Card,
  Container,
  Icon,
  Image,
  PrimaryButton,
  SecondaryButton,
  Spacer,
  Text,
  Title,
  View } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import { FAQ_DATA_HOME } from 'helpers/faqDataHome'

export const Home = () => {
  const history = useHistory()

  const fetchMyData = () => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'Event_Data',
        event_type: 'new',  
        ga_event: {
          page_title: 'tela-home',
          page_location: window.location.href,
          action: 'click',
          element_name: 'tela-home-botao: Trazer meus dados'
        },
        product: {
          flow: 'OPIN'
        }
      })
    }

    history.push('/recepcao')
  }

  return (
    <>
      <View style={{ backgroundColor: defaultTheme.colors.highlight, padding: '104px 0' }}>
        <Container style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <View>
            <View layout={{ desktopHorizontal: true }}>
              <View layout={{ alignVerticalCenter: true }} style={{ maxWidth: '464px', gap: '32px' }}>
                <Title
                  size={'big'}
                  weight={'bold'}
                  align={window.innerWidth < 551 ? 'center' : 'left'}
                  color={defaultTheme.colors.primaryInverse}
                >
                  Open Insurance
                </Title>

                <Text
                  size={'big'}
                  weight={'regular'}
                  color={defaultTheme.colors.primaryInverse}
                  align={window.innerWidth < 551 ? 'center' : 'left'}
                >
                  Gerencie seus dados em um só lugar de um jeito rápido e seguro.
                </Text>

                {window.innerWidth < 551 &&
                  <Spacer bottom={4} />
                }
              </View>

              <Image
                alt='notebook'
                src='https://www.datocms-assets.com/16903/1587671297-illus-objects-computer-youse-4x3-light-2x.png'
                height={window.innerWidth < 551 ? 35.2 : 45.2}
              />
            </View>
          </View>
        </Container>
      </View>

      <View style={{ backgroundColor: defaultTheme.backgrounds.secondary }}>
        <Container>
          <View 
            layout={{ desktopHorizontal: true }}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
              marginTop: '-58px'
            }}
          >
            <Card
              style={{
                borderRadius: 8,
                padding: 32,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <View
                layout={{ alignVerticalCenter: true }}
                style={{ gap: '12px', display: 'flex', flexDirection: 'row' }}
              >
                <View style={{ paddingBottom: '8px', gap: '8px' }}>
                  <Text
                    size={'big'}
                    weight={'bold'}
                    color={defaultTheme.colors.primary}
                  >
                    Trazer dados pra Youse
                  </Text>

                  <Text
                    size={'default'}
                    weight={'regular'}
                    color={defaultTheme.colors.primary}
                  >
                    Compartilhe seus dados com a Youse pra ter ofertas ainda mais personalizadas.
                  </Text>
                </View>

                <Image
                  alt='notebook'
                  src='https://www.datocms-assets.com/16903/1587668682-illus-objects-cellphone-youse-4x3-light-2x.png'
                  height={window.innerWidth < 551 ? 8 : 12}
                />
              </View>

              <PrimaryButton style={{ width: window.innerWidth < 551 ? '100%' : '70%' }} onClick={fetchMyData} form='hook-form'>
                trazer meus dados
              </PrimaryButton>
            </Card>

            <Card
              style={{
                borderRadius: 8,
                padding: 32,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <View
                layout={{ alignVerticalCenter: true }}
                style={{ gap: '12px', display: 'flex', flexDirection: 'row' }}
              >
                <View style={{ paddingBottom: '8px', gap: '8px' }}>
                  <Text
                    size={'big'}
                    weight={'bold'}
                    color={defaultTheme.colors.primary}
                  >
                    Dados compartilhados
                  </Text>

                  <Text
                    size={'default'}
                    weight={'regular'}
                    color={defaultTheme.colors.primary}
                  >
                    Gerencie dados que foram compartilhados de outras instituições com a Youse.
                  </Text>
                </View>

                <Image alt='notebook' src='https://www.datocms-assets.com/16903/1587668953-illus-objects-cellphone-choose-add-remove-4x3-light-2x.png' height={window.innerWidth < 551 ? 8 : 12}/>
              </View>

              <PrimaryButton style={{ width: window.innerWidth < 551 ? '100%' : '70%' }} onClick={() => history.push('/gestao/consentimentos')} form='hook-form'>
                gerenciar dados
              </PrimaryButton>
            </Card>
          </View>
        </Container>
      </View>

      <View style={{ backgroundColor: defaultTheme.backgrounds.secondary, padding: '80px 0' }}>
        <Container style={{ display: 'flex', flexDirection: window.innerWidth < 551 ? 'column-reverse' : 'row', alignItems: 'center', gap: '16px' }}>
          <View layout={{ alignVerticalCenter: true }} style={{ gap: '32px' }}>
            <Title
              size={'big'}
              weight={'bold'}
              color={defaultTheme.colors.highlight}
            >
              O que é o Open Insurance?
            </Title>

            <Title
              size={'xsmall'}
              weight={'light'}
              color={defaultTheme.colors.secondary}
            >
              O Open Insurance, ou Sistema de Seguros Aberto, permite que você tenha a liberdade de compartilhar os seus dados com as empresas de seguro que escolher. A vantagem dessa iniciativa é poder receber ofertas ainda mais personalizadas.
            </Title>
          </View>

          <Image alt='notebook' src='https://www.datocms-assets.com/16903/1590783427-illus-characters-youser-ipad-01-4x3-light.png' height={window.innerWidth < 551 ? 20 : 38}/>
        </Container>
      </View>

      <View style={{ backgroundColor: defaultTheme.backgrounds.success, padding: '80px 0' }}>
        <Container style={{ display: 'flex', flexDirection: 'column', gap: '48px' }}>
          <Title
            size={'small'}
            weight={'bold'}
            color={defaultTheme.colors.primaryInverse}
          >
            Acesse o Portal do Open Insurance pra você ficar por dentro de tudo
          </Title>

          <SecondaryButton style={{ alignSelf: window.innerWidth < 551 ? 'center' : 'start', width: '188px', color: defaultTheme.colors.primaryInverse }} onClick={() => window.open('https://opinbrasil.com.br/', '_blank')} light>
            ir para o portal
          </SecondaryButton>
        </Container>
      </View>

      <View style={{ backgroundColor: defaultTheme.backgrounds.secondary, padding: '80px 0' }}>
        <Container style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Title
            size={'small'}
            weight={'bold'}
            color={defaultTheme.colors.primary}
          >
            Dúvidas sobre o Open Insurance?
          </Title>

          <Title
            size={'xsmall'}
            weight={'light'}
            color={defaultTheme.colors.primary}
          >
            A gente te explica tudo direitinho
          </Title>

          {FAQ_DATA_HOME.map((item, index) => (
            <Accordion
              key={index}
              items={[
                {
                  title: item.title,
                  children: <>
                    {item.data.map((opt, index) => (
                      <div key={index} style={{ paddingLeft: -16, marginLeft: -16, paddingBottom: 16 }}>
                        <Text
                          key={index}
                          size={'small'}
                          weight={'regular'}
                          color={defaultTheme.colors.secondary}
                        >
                          {opt}
                        </Text>
                      </div>
                    ))}
                  </>,
                  dataTestId: item.title
                }
              ]}
            />
          ))}

          <SecondaryButton
            onClick={() => window.open(
              'https://www.youse.com.br/open-insurance/duvidas/',
              '_blank'
            )}
            style={{
              width: window.innerWidth < 551 ? '100%' : '217px',
              color: defaultTheme.colors.primary,
              alignSelf: 'center'
            }}
          >
            dúvidas frequentes
          </SecondaryButton>
        </Container>
      </View>
      
      <View style={{ backgroundColor: defaultTheme.backgrounds.highlight, padding: '30px 0px' }}>
        <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Icon name='youse' size={5} />

            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '8px'
              }}
            >
              <View
                onClick={() => window.open('https://www.facebook.com/yousebrasil', '_blank')}
                style={{
                  border: '1px solid white',
                  borderRadius: '100px',
                  width: '25px',
                  height: '25px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer'
                }}
              >
                <Icon name='facebook' size={3} color='white'/>
              </View>

              <View
                onClick={() => window.open('https://twitter.com/yousebrasil', '_blank')}
                style={{
                  border: '1px solid white',
                  borderRadius: '100px',
                  width: '25px',
                  height: '25px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer'
                }}
              >
                <Icon name='twitter' size={3} color='white'/>
              </View>

              <View
                onClick={() => window.open('https://www.linkedin.com/company/yousebrasil/', '_blank')}
                style={{
                  border: '1px solid white',
                  borderRadius: '100px',
                  width: '25px',
                  height: '25px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer'
                }}
              >
                <Icon name='linkedin' size={3} color='white'/>
              </View>
            </View>
          </View>

          <Spacer bottom={6} />

          <Text
            size={'small'}
            weight={'regular'}
            color={defaultTheme.colors.primaryInverse}
            align={'center'}
          >
            Os produtos comercializados neste site são garantidos pela Caixa Seguradora S.A., inscrita no CNPJ nº 34.020.354/0001-10, sediada no SHN, Quadra 01. Conjunto A. Bloco E. CEP 70701 050 - Brasília DF.
          </Text>

          <Spacer bottom={3} />

          <Text
            size={'small'}
            weight={'regular'}
            color={defaultTheme.colors.primaryInverse}
            align={'center'}
          >
            Youse é uma plataforma de seguros 100% digital.
          </Text>

          <Spacer bottom={3} />

          <Text
            size={'small'}
            weight={'regular'}
            color={defaultTheme.colors.primaryInverse}
            align={'center'}
          >
            © 2023 Youse - Todos os direitos reservados.
          </Text>

          <Spacer bottom={3} />

          <View style={{ cursor: 'pointer' }} onClick={() => window.open('https://portalprivacidade.cnpbrasil.com.br/s/', '_blank')}>
            <Text
              size={'small'}
              weight={'bold'}
              color={defaultTheme.colors.primaryInverse}
              align={'center'}
            >
              Política de Privacidade
            </Text>
          </View>
        </Container>
      </View>
    </>
  )
}
