import React, { PropsWithChildren } from 'react'

export type AppContextType = {
  app?: 'partner-portal'
}

type AppProviderProps = {
  app?: 'partner-portal'
}

const AppContext = React.createContext<AppContextType>({})

function AppProvider({ app, children }: PropsWithChildren<AppProviderProps>) {
  return <AppContext.Provider value={{ app }}>{children}</AppContext.Provider>
}

export { AppContext, AppProvider }
