import React, { useState } from 'react'
import {
  Card,
  IconButton,
  Spacer,
  Text,
  Title,
  View } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'

export const RequestSummary = ({ segment, brand }) => {
  const [moreInfo, setMoreInfo] = useState(false)

  const segmentStrategy = {
    Home: 'Residencial',
    Housing: 'Residencial',
    Auto: 'Auto',
    Life: 'Vida',
    residencial: 'Residencial'
  }

  return (
    <Card
      style={{
        borderRadius: 8,
        padding: 16,
      }}
    >
      <View layout={{ horizontal: true }} style={{ justifyContent: 'space-between' }}>
        <View>
          <Title
            size={'xsmall'}
            weight={'light'}
            color={defaultTheme.colors.primary}
          >
            Resumo da solicitação
          </Title>

          <Spacer top={4} />

          <Text
            size={'small'}
            weight={'regular'}
            color={defaultTheme.colors.secondary}
          >
            Finalidade do uso dos dados
          </Text>

          <Spacer top={1} />

          <Text
            size={'default'}
            weight={'regular'}
            color={defaultTheme.colors.primary}
          >
            Ofertar serviços personalizados
          </Text>

          {moreInfo &&
            <View layout={{ horizontal: false }}>
              <Spacer top={5} />

              <Text
                size={'small'}
                weight={'regular'}
                color={defaultTheme.colors.secondary}
              >
                Segmento
              </Text>

              <Spacer top={1} />

              <Text
                size={'default'}
                weight={'regular'}
                color={defaultTheme.colors.primary}
              >
                {segmentStrategy[segment]}
              </Text>

              <Spacer top={5} />

              <Text
                size={'small'}
                weight={'regular'}
                color={defaultTheme.colors.secondary}
              >
                Instituição escolhida
              </Text>

              <Spacer top={1} />

              <Text
                size={'default'}
                weight={'regular'}
                color={defaultTheme.colors.primary}
              >
                {brand}
              </Text>

              <Spacer top={3} />
            </View>
          }
        </View>

        <View>
          <View style={{ transform: !moreInfo ? 'rotate(90deg)' : 'rotate(270deg)' }}>
            <IconButton
              onClick={() => setMoreInfo(!moreInfo)}
              icon='chevron-right'
              colorIcon='#F9660B'
              backgroundColor='transparent'
            />
          </View>
        </View>
      </View>
    </Card>
  )
}
