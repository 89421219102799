import { useEffect, useState } from 'react'

import useAuth from '../useAuth'

import ResponseError from './ResponseError'

type RequestMethod = 'GET' | 'POST' | 'DELETE' | 'PATCH'

export default function useRequest<S>(
  method: RequestMethod,
  path: string,
  shouldExecute = true,
  data: any = null
) {
  const [loading, setLoading] = useState(false)
  const [body, setBody] = useState<S | null | any>(null)
  const [error, setError] = useState<ResponseError | null>(null)
  const [_shouldExecute, execute] = useState<boolean>(shouldExecute)

  const { token } = useAuth()

  useEffect(() => {
    if (!_shouldExecute) {
      return
    }

    setLoading(true)

    fetch(`${process.env.REACT_APP_OPIN_SERVICE_URL}/${path}`, {
      method,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: data && JSON.stringify(data)
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response
        } else {
          const error = new ResponseError(response.statusText)
          error.response = response
          throw error
        }
      })
      .then<S>((response) => response.json())
      .then((response) => {
        setBody(response)
      })
      .catch(setError)
      .finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, token, _shouldExecute])

  return { loading, body, error, execute }
}
