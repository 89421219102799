import { gql } from '@apollo/client'

export const GET_INSPECTIONS = gql`
  query GetInspections($policyNumber: String!) {
    policyInspections(policyNumber: $policyNumber) {
      id
      createdAt
      updatedAt
      deadline
      inspectionType
      status
      rejectReasons
      bookings {
        protocol
        address {
          street
          neighborhood
          city
          state
        }
        date
        status
        updatedAt
        placeType
      }
      refusedReasons {
        code
        description
      }
      provider {
        name
      }
      requester { type }
      onlineInspectionLink
    }
  }
`
