import React, { createContext, PropsWithChildren, ReactNode, useEffect, useMemo, useState } from 'react'  
import { useLocation } from 'react-router-dom'
import { Message, MessageType, View } from '@youse-seguradora/cargo-components'

interface ErrorType {
    title?: string
    description?: string
}
export type ErrorContextType = {
    hasError: boolean
    errorMessage?: JSX.Element
    dispatchError?: (err: ErrorType) => void
  }
  

const ErrorContext = createContext<ErrorContextType>({
  hasError: false,
  errorMessage: <></>
})

const defaultError: ErrorType = {
  title: '',
  description: ''
}

function ErrorProvider({ children }: PropsWithChildren<ReactNode>) {
  const [error, setError] = useState(defaultError)
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()

  const cleanError = () => {
    setIsOpen(false)
    setError(defaultError)
  }

  const dispatchError = ({ description, title }: ErrorType) => {
    if (title !== error?.title || description !== error?.description)
      setTimeout(() => {
        setError({
          ...(title ? { title } : {}),
          ...(description ? { description } : {})
        })
      }, 1000)            
  }

  const errorMessage = useMemo(() => isOpen && (
    <View spacing={{ vertical: 4 }}>
      <Message
        icon={'error'}
        isOpen={true}
        onClose={cleanError}
        hasCloseButton
        title={error?.title || ''}
        description={error?.description}
        type={MessageType.error}
      />
    </View>
  ), [isOpen, error])
    
  useEffect(() => {
    // execute on location change
    cleanError()
  }, [location])

  useEffect(() => {
    if (error?.title || error?.description)
      setIsOpen(true)
  }, [error])


  return <ErrorContext.Provider value={{ hasError: isOpen, dispatchError, errorMessage }}>{children}</ErrorContext.Provider>
}

export { ErrorContext, ErrorProvider }
