import React from 'react'
import { Text, View } from '@youse-seguradora/cargo-components'

import { useTheme } from '@material-ui/core/styles'

type RefusedReasonType = {
  code: string;
  description: string;
}

export default function InspectionRefusedReason({ refusedReasons }) {
  const theme = useTheme()

  if (!refusedReasons || refusedReasons.length === 0) return <></>

  return (
    <View spacing={{ bottom: 6 }}>
      <Text color={theme.colors.secondary}>Motivo de Recusa</Text>

      {refusedReasons.map((reason: RefusedReasonType) => (
        <ul key={reason.code}>
          <li
            style={{
              listStyle: 'inside',
              marginLeft: '15px',
              fontSize: '15px',
              fontWeight: 500,
            }}
          >
            {reason.description}
          </li>
        </ul>
      ))}
    </View>
  )
}
