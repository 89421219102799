import React from 'react'
import { Text, View } from '@youse-seguradora/cargo-components'

import { useTheme } from '@material-ui/core/styles'

type SidebarCardItemProps = {
  spacing: any
  label: string
  value: string
}

const SidebarCardItem = ({ spacing, label, value }: SidebarCardItemProps) => {
  const theme = useTheme()

  return (
    <View spacing={spacing} layout={{ spread: true, horizontal: true }}>
      <Text color={theme.colors.textSidebar}>{label}</Text>
      <Text weight='bold' color={theme.colors.textSidebar}>
        {value}
      </Text>
    </View>
  )
}

export default SidebarCardItem
