import React from 'react'
import {
  Image,
  InvisibleButton,
  Modal,
  PrimaryButton,
  Spacer,
  Text,
  Title,
  View,
} from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'

export default function CancellationModal({
  close,
  handleCancellation,
  isOpen,
}: {
  isOpen: boolean
  close: () => void
  handleCancellation: () => void
}) {
  return (
    <Modal
      hiddenOverflow={false}
      isOpen={isOpen}
      onClose={close}
      content={
        <View
          layout={{ alignHorizontalCenter: true, alignVerticalCenter: true }}
          spacing={{ bottom: 2 }}
        >
          <Image
            alt='cellphone'
            src='https://www.datocms-assets.com/16903/1587671029-illus-objects-cellphone-attention-4x3-light-2x.png'
            height={14}
          />

          <Title
            size={'small'}
            weight={'bold'}
            color={defaultTheme.colors.highlight}
            align={window.screen.width > 551 ? 'center' : 'left'}
          >
            Você quer mesmo cancelar essa solicitação?
          </Title>

          <Spacer top={4} />

          <Text
            size={'default'}
            weight={'regular'}
            color={defaultTheme.colors.secondary}
            align={window.screen.width > 551 ? 'center' : 'left'}
          >
            Se você cancelar, vai ter que começar todo o processo desde o início.
          </Text>

          <Spacer top={6} />

          <View
            layout={{ horizontal: true }}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              flexWrap: 'wrap-reverse',
              gap: '24px',
            }}
          >
            <InvisibleButton contextual onClick={close}>
              CONTINUAR A SOLICITAÇÃO
            </InvisibleButton>

            <PrimaryButton form='hook-form' onClick={handleCancellation}>
              CANCELAR SOLICITAÇÃO
            </PrimaryButton>
          </View>
        </View>
      }
    />
  )
}
