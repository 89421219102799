import React, { useState } from 'react'
import {
  Checkbox,
  Column,
  ColumnsContainer,
  InvisibleButton,
  Spacer,
  Text,
  View } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import { desktopContent, mobileContent } from 'styles/ContentStyle'
import { isMobile } from 'utils/IsMobile'

import { DataDetail } from 'components/Modal/DataDetail'
import { RequestSummary } from 'components/RequestSummary'

const optionalData = [
  {
    title: 'Dados qualitativos',
    dataDatail: 'E-mail, estado civil, filiação, sexo, renda, profissão, identificação, entre outros',
    checked: false
  },
  {
    title: 'Dados opcionais 2',
    dataDatail: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
    checked: false
  },
  {
    title: 'Dados opcionais 3',
    dataDatail: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
    checked: false
  },
]

export const ChooseOptionalData = ({ form, setForm, permissions }) => {
  const [options, setOptions] = useState(optionalData)
  const [defaultChecked, setDefaultChecked] = useState()
  const [openDetailsOptionalData, setOpenDetailsOptionalData] = useState(false)
  const [selectedData, setSelectedData] = useState()

  const onChange = (e, title) => {
    const newOptions = options.map((item) => {
      if (item.title === title) {
        return item = {
          ...item,
          checked: e.target.checked
        }
      }

      return item
    })

    setForm({ ...form, optionalData: !form.optionalData })
    setOptions(newOptions)
  }

  const onChangeAll = (e) => {
    const newOptions = options.map((item) => {
      return item = {
        ...item,
        checked: e.target.checked
      }
    })

    setOptions(newOptions)
    setForm({ ...form, optionalDataAll: !form.optionalDataAll })
    setDefaultChecked(e.target.checked)
  }

  return (
    <>
      <View
        style={isMobile() ? mobileContent : desktopContent}
      >
        <ColumnsContainer>
          {window.innerWidth < 900 &&
            <Column tabletSize={6}>
              <RequestSummary segment={form.segment} brand={form.brand.CustomerFriendlyName} />

              <Spacer top={4} />
            </Column>
          }

          <Column tabletSize={6}>
            <View layout={{ horizontal: false }}>
              <Text
                size={'default'}
                weight={'bold'}
                color={defaultTheme.colors.primary}
              >
                Quais dados você gostaria de trazer?
              </Text>

              <Spacer top={4} />

              <View style={{
                borderBottom: '1px solid #EBEDEE'
              }}>
                <View  style={{
                  marginLeft: -16,
                  marginBottom: -16,
                  marginTop: -32
                }}>
                  <Checkbox
                    label='Selecionar todos'
                    onChange={(e) => onChangeAll(e)}
                  />
                </View>
              </View>

              <Spacer top={5} />

              {permissions.permissions_sections.map((data, index) => {
                if (data.title !== 'Dados cadastrais' && data.title !== 'Dados complementares') {
                  return <div key={index}>
                    <View
                      key={index}
                      style={{
                        borderBottom: '1px solid #EBEDEE'
                      }}
                      layout={{ horizontal: true }}
                    >
                      <View  style={{
                        marginLeft: -16,
                        marginRight: -32,
                        marginBottom: -16,
                        marginTop: -32
                      }}>
                        <Checkbox
                          label=''
                          defaultChecked={defaultChecked}
                          onChange={(e) => onChange(e, data.title)}
                        />
                      </View>

                      <View layout={{ horizontal: false }}>
                        <Text
                          size={'default'}
                          weight={'bold'}
                          color={defaultTheme.colors.primary}
                        >
                          {data.title}
                        </Text>

                        <Spacer top={2} />

                        <Text
                          size={'small'}
                          weight={'regular'}
                          color={defaultTheme.colors.secondary}
                        >
                          {data.permissions.join(', ')}
                        </Text>

                        <Spacer top={2} />

                        <InvisibleButton
                          contextual
                          onClick={() => {
                            setSelectedData(data)

                            setOpenDetailsOptionalData(true)
                          }}
                          rightIcon='chevron-right'
                        >
                          Exibir lista completa
                        </InvisibleButton>
                      </View>
                    </View>

                    <Spacer top={4} />
                  </div>
                }

                return <div key={index}></div>
              })}
            </View>
          </Column>

          {window.innerWidth > 900 &&
            <Column tabletSize={6}>
              <RequestSummary segment={form.segment} brand={form.brand.CustomerFriendlyName} />
            </Column>
          }
        </ColumnsContainer>
      </View>

      {!!selectedData &&
        <DataDetail
          modalData={selectedData}
          openDataDetail={openDetailsOptionalData}
          setOpenDataDetail={setOpenDetailsOptionalData}
        />
      }
    </>
  )
}
