/* eslint-disable @typescript-eslint/no-empty-function */
import React, { Dispatch, SetStateAction } from 'react'

export type PageContextType = {
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  displayHeader: boolean;
  setDisplayHeader: Dispatch<SetStateAction<boolean>>;
};

const PageContext = React.createContext<PageContextType>({
  title: '',
  setTitle: () => {},
  isLoading: true,
  setIsLoading: () => {},
  displayHeader: true,
  setDisplayHeader: () => {},
})

type PageProviderProps = {
  children: React.ReactNode;
};

function PageProvider({ children }: PageProviderProps) {
  const [title, setTitle] = React.useState<string>('')
  const [displayHeader, setDisplayHeader] = React.useState<boolean>(true)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  return (
    <PageContext.Provider value={{ title, setTitle, isLoading, setIsLoading, displayHeader, setDisplayHeader }}>
      {children}
    </PageContext.Provider>
  )
}

export { PageContext, PageProvider }
