import React from 'react'
import InputMask from 'react-input-mask'
import CircularProgress from '@material-ui/core/CircularProgress'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import { FormControl } from '@tiny-fronts/resource-flow-components'
import { Text, View } from '@youse-seguradora/cargo-components'

type PlateInputProps = {
  vehicle: {
    licensePlate?: string
  }
  errors?: string
  classes?: any
  setLicensePlate?: any
  maskPlate?: any
  licensePlateLoader?: boolean
}

const PlateInput = ({
  vehicle,
  errors,
  classes,
  setLicensePlate,
  maskPlate,
  licensePlateLoader,
}: PlateInputProps) => {
  return (
    <FormControl fullWidth={true} resourceerrors={errors} errorfor='vehicleLicensePlate'>
      <View
        layout={{
          desktopHorizontal: true,
          desktopAlignHorizontalCenter: true,
        }}
        spacing={{ top: 6 }}
      >
        <Text size='big' as={'label'} htmlFor='vehicleLicensePlate'>
          A placa do meu carro é
        </Text>

        <View spacing={{ desktopLeft: 2 }}>
          <InputMask
            id='vehicleLicensePlate'
            autoComplete='nope'
            defaultValue={vehicle.licensePlate || ''}
            onBlur={(e) => setLicensePlate(e.target.value)}
            mask={maskPlate}
            maskChar={null}
          >
            {(inputProps) => (
              <Input
                className={`${classes.uppercase} ${classes.inputMaterial}`}
                {...inputProps}
                fullWidth={true}
                endAdornment={
                  <InputAdornment position='end'>
                    {licensePlateLoader && <CircularProgress size={20} color='secondary' />}
                  </InputAdornment>
                }
              />
            )}
          </InputMask>
        </View>
      </View>
    </FormControl>
  )
}

export default PlateInput
