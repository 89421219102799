import React from 'react'
import {
  InvisibleButton,
  Link,
  Modal,
  Spacer,
  Text,
  Title,
  View } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'

export const DescriptionUsageData = ({ openModal, setOpenModal }) => {
  const content = (
    <>
      <View layout={{ alignHorizontalCenter: true, alignVerticalCenter: true }} spacing={{ bottom: 2 }}>
        <Title
          size={'small'}
          weight={'bold'}
          color={defaultTheme.colors.highlight}
          align={window.screen.width > 551 ? 'center' : 'left'}
        >
          Como seus dados são usados?
        </Title>

        <Spacer top={4} />

        <Text
          size={'default'}
          weight={'regular'}
          color={defaultTheme.colors.secondary}
          align={window.screen.width > 551 ? 'center' : 'left'}
        >
          A gente usa estas informações pra fins de identificação e ativar, simular ou cotar os produtos Youse que você tem interesse.
        </Text>

        <Text
          size={'default'}
          weight={'regular'}
          color={defaultTheme.colors.secondary}
          align={window.screen.width > 551 ? 'center' : 'left'}
        >
          Nestes casos, também podemos usar os seus dados pra esclarecer dúvidas e até mesmo oferecer oportunidades que combinem com o seu perfil.
        </Text>

        <Text
          size={'default'}
          weight={'regular'}
          color={defaultTheme.colors.secondary}
          align={window.screen.width > 551 ? 'center' : 'left'}
        >
          Pra saber mais detalhes, é só conferir a nossa <Link targetBlank={true} size='default' href='https://portalprivacidade.cnpbrasil.com.br/s/' label='Política de Privacidade'/>
        </Text>

        <Spacer top={6} />

        <InvisibleButton onClick={() => setOpenModal(false)}>
          ok, entendi
        </InvisibleButton>
      </View>
    </>
  )

  const modalCancel = {
    content: content,
  }

  return (
    <Modal
      {...modalCancel}
      hiddenOverflow={false}
      isOpen={openModal}
      onClose={() => {
        setOpenModal(false)
      }}
    />
  )
}
