import React from 'react'
import { Text, Title, View } from '@youse-seguradora/cargo-components'

import { useTheme } from '@material-ui/core/styles'

type SidebarCardPriceProps = {
  label: string
  value: string
  period: string
}

const SidebarCardPrice = ({ label, value, period }: SidebarCardPriceProps) => {
  const theme = useTheme()

  return (
    <View
      background={{ color: theme.backgrounds.highlightDark }}
      spacing={{ vertical: 5, horizontal: 3 }}
      style={{ borderRadius: '8px' }}
      layout={{
        horizontal: true,
        spread: true,
        alignVerticalCenter: true,
      }}
    >
      <View style={{ maxWidth: '125px' }}>
        <Text size='big' weight='bold' align='left' color={theme.colors.primaryInverse}>
          {label}
        </Text>
      </View>

      <Text as='span' align='right' color={theme.colors.sidebarPrice}>
        <Title size='xsmall' as='span'>
          {value}
          <Text size='default' as='span' weight='bold' color={theme.colors.primaryInverse}>
            {period}
          </Text>
        </Title>
      </Text>
    </View>
  )
}

export default SidebarCardPrice
