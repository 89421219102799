import React from 'react'
import { Text, View } from '@youse-seguradora/cargo-components'

import { useTheme } from '@material-ui/core/styles'

const FormInputError = ({ message }) => {
  const theme = useTheme()

  return (
    <View
      layout={{
        desktopAlignHorizontalCenter: true,
      }}
      spacing={{ top: 2 }}
    >
      <Text align='left' tabletAlign='center' size='small' color={theme.colors.error}>
        {message}
      </Text>
    </View>
  )
}

export default FormInputError
