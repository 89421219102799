import React from 'react'
import { SectionColor } from '@youse-seguradora/cargo-components'

import Hero from '../Hero/Hero'
import { AppYouse, Help, YouseFriends } from '../SectionsDefault'

type InspectionMonitoringDefaultProps = {
  productName: string
  data?: any
}

export default function InspectionMonitoringDefault({
  data,
  productName,
}: InspectionMonitoringDefaultProps) {
  return (
    <>
      <Hero
        data={data}
        titleHeader='Seja bem-vindo, Youser! Seu carro já está segurado e vc já pode dirigir por aí numa boa! ;)'
        productName={productName}
      />

      <AppYouse />

      {data.subproduct === 'default' && (
        <>
          <SectionColor
            title='Conheça benefícios que você já pode começar a usar'
            variant='offwhite [highlight]'
          />
          <YouseFriends />
        </>
      )}

      <SectionColor
        title='Pronto! Se estiver tudo certinho, vc já pode dirigir por aí numa boa ;)'
        variant='offwhite [highlight]'
        ctaLabel='[APPSTORES]'
        ctaLink='#'
      />

      <Help />
    </>
  )
}
