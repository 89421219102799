/* eslint-disable max-len */
import React from 'react'
import {
  Container,
  Spacer,
  Text,
  Title,
  View } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'

export const TermsDetails = () => {
  return (
    <Container>
      <Spacer top={8} />

      <View layout={{ alignHorizontalCenter: true }}>
        <View style={{ maxWidth: '50%' }}>
          <Title
            size={'xsmall'}
            weight={'bold'}
            color={defaultTheme.colors.primary}
            align='center'
          >
            Termos e Condições - Open Insurance CNP Seguros Holding Brasil
          </Title>
        </View>
      </View>

      <Spacer top={2} />

      <Title
        size={'xsmall'}
        weight={'bold'}
        color={defaultTheme.colors.primary}
      >
        1. O que é o Open insurance
      </Title>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        O Open Insurance é um ecossistema regulamentado pelo Conselho Nacional de Seguros Privados (CNSP) e pela SUSEP (Superintendência de Seguros Privados) que permite que pessoas que possuem produtos de seguros, previdência e capitalização compartilhem os seus dados pessoais e contratuais com outras seguradoras, a partir do consentimento prévio para esse compartilhamento.
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        Essa facilidade permite que outras seguradoras recebam seus dados e iniciem o relacionamento com você a partir das informações do relacionamento que você já <b>possui</b> com outras seguradoras, facilitando a oferta e aquisição de produtos e serviços personalizados ao seu perfil.
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        A implementação do Open Insurance obedece ao cronograma estabelecido pelo CNSP e pela SUSEP e, com sua evolução e futura integração com o Open Finance será possível compartilhar informações sobre seu relacionamento com bancos, corretoras de investimento e outros participantes do mercado financeiro nacional.
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        O sistema está sendo construído com a diretriz de manter o cliente no centro, ou seja, toda a jornada no Open Insurance está sendo desenvolvida com o objetivo de facilitar o seu relacionamento com o mercado segurador, seja para aquisição de produtos e serviços, seja para a manutenção dos produtos que você já possui.
      </Text>

      <Spacer top={4} />

      <Title
        size={'xsmall'}
        weight={'bold'}
        color={defaultTheme.colors.primary}
      >
        2. Quem participa do Open Insurance
      </Title>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        Participam do Open Insurance apenas seguradoras previamente autorizadas a funcionar pela Superintendência de Seguros Privados - SUSEP, órgão que regula o mercado segurador no Brasil. Algumas instituições participam de forma obrigatória e outras de forma voluntária. Para ver a relação completa das instituições participantes, acesse: http://www.opinbrasil.com.br/
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        Conforme esse documento de Termos e Condições, o grupo CNP Seguros Holding Brasil e as empresas coligadas serão os receptores dos dados que você escolher compartilhar conosco pelo Open Insurance, e poderão usar esses dados conforme as finalidades a serem atendidas com esse compartilhamento ou descritas nesse documento.
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        É importante destacar que nós temos uma Política de Segurança de Informação rígida que prevê todos os cuidados para que suas informações estejam seguras, pelo período que for necessário mantê-las. Trabalhamos para proteger os seus dados contra destruição ou alteração acidental ou ilegal, perda acidental, divulgação ou acesso não autorizado e contra outras formas ilegais de tratamento de dados pessoais.
      </Text>

      <Spacer top={4} />

      <Title
        size={'xsmall'}
        weight={'bold'}
        color={defaultTheme.colors.primary}
      >
        3. Quais informações você pode compartilhar?
      </Title>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        Conforme esse documento de Termos e Condições, o grupo CNP Seguros Holding Brasil e as empresas coligadas serão os receptores dos dados que você escolher compartilhar conosco pelo Open Insurance, e poderão usar esses dados conforme as finalidades a serem atendidas com esse compartilhamento ou descritas nesse documento. 
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        É importante destacar que nós temos uma Política de Segurança de Informação rígida que prevê todos os cuidados para que suas informações estejam seguras, pelo período que for necessário mantê-las.
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        Trabalhamos para proteger os seus dados contra destruição ou alteração acidental ou ilegal, perda acidental, divulgação ou acesso não autorizado e contra outras formas ilegais de tratamento de dados pessoais. <b>(Os três parágrafos acima estão repetidos. Como citamos anteriormente, entendo que não há a necessidade deles aqui.)</b>
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        Abaixo, listamos todos os tipos de dados que você poderá compartilhar conosco:
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        - Dados Cadastrais (Pessoas Físicas): nome completo; documento de identificação (CPF e outros); endereço residencial completo; número de telefone; e-mail(s); nome social; tipo de filiação; filiação (nome completo das pessoas relativas à filiação); data de nascimento; estado civil; sexo; nacionalidade; nome e CPF do cônjuge, renda; patrimônio; ocupação; data de início de relacionamento; tipos de produtos e serviços com contratos vigentes; nome e CPF do representante legal ou procurador (se houver). 
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        - <b>Dados Cadastrais (Pessoas Jurídicas):</b> razão social; nome fantasia (se houver); CNPJ ou número de registro no exterior (se houver); data da constituição; endereço completo; latitude e longitude; telefone; e-mail(s); nome, nome social e documento de identificação dos sócios, representantes ou administradores; participação societária de sócios; data de início de vínculo; participação societária; ramo de atuação principal e secundário (se houver); valor e frequência de faturamento; patrimônio; data de início de relacionamento da pessoa jurídica; tipos de produtos e serviços com contratos vigentes; nome, CPF ou CNPJ do representante legal ou procurador (se houver).
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'bold'}
        color={defaultTheme.colors.primary}
      >
        Dados transacionais: 
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        - <b>Informações sobre sua apólice de seguro:</b> incluindo tipo de documento emitido; tipo de emissão; data de emissão; data de início e fim de vigência; limite máximo de garantia; nome, tipo de documento e número de documento do segurado; nome, tipo de documento e número de documento do beneficiário (se houver); nome, tipo de documento e número de documento do tomador/ garantidor (se aplicável); identificação, tipo, tipo de documento, número de documento e endereço do intermediador; identificação, tipo, descrição e valor do objeto segurado; grupo e ramo de coberturas; limite máximo de indenização; tipo de coberturas; carência; franquias; participação obrigatória do segurado; cosseguro; cessionárias; histórico de movimentação de prêmios; valores pagos; assistências contratadas; benefícios ou serviços adicionais; sinistros avisados;  pontuação; classe de bônus.
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        - <b>Informações sobre seu título de capitalização:</b> dados de pagamento; valores dos títulos; prazo de vigência; datas de compra e resgate; dados de sorteio; índice de atualização.
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'bold'}
        color={defaultTheme.colors.primary}
      >
        Importante saber:
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        As informações que você compartilhar conosco ou com outras instituições no Open Insurance podem também conter informações relacionadas a terceiros.
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        Por exemplo: a filiação de pessoa física, dados de identificação de sócios e representantes de pessoas jurídicas e dados sobre pagamentos recebidos e realizados por terceiros, beneficiários e dependentes. Estes dados também poderão ser usados para que possamos cumprir os objetivos do compartilhamento das informações conosco.
      </Text>

      <Spacer top={4} />

      <Title
        size={'xsmall'}
        weight={'bold'}
        color={defaultTheme.colors.primary}
      >
        4. Quais são os passos para compartilhar seus dados
      </Title>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        O compartilhamento dos seus dados pelo Open Insurance só ocorre após a sua autorização expressa, em um processo gratuito a ser realizado pelos canais digitais das seguradoras e empresas participantes, com segurança, privacidade, agilidade, conveniência e transparência para que você tenha controle sobre os seus dados.
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'bold'}
        color={defaultTheme.colors.primary}
      >
        Caso você não aceite estes termos, seus dados não serão compartilhados com as empresas do grupo CNP Seguros Holding Brasil e não será realizada nenhuma ação descrita nesse documento com seus dados pessoais ou transacionais.
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        Saiba abaixo como funciona as etapas da solicitação de compartilhamento de dados:
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        1. Você entra em nossos canais digitais, seja website, aplicativo de celular ou outros, e se identifica. Caso seja nosso cliente, usando seu usuário e senha. Caso não seja nosso cliente, realizando um rápido cadastro.
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        2. São apresentadas as finalidades pelas quais precisamos de seus dados: pode ser uma oferta de seguro, um benefício adicional a uma apólice que você possui conosco ou outro objetivo. Caso concorde com a finalidade e com o compartilhamento dos dados necessários, você aceita o compartilhamento.
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        3. Depois desse passo, precisamos saber em qual instituição <b>devemos</b> buscar esses dados. Você então escolhe a instituição e será direcionado para a interface dessa instituição, visando garantir que é realmente você que está solicitando as informações.
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        4. Após autorizar o compartilhamento, receberemos os dados que precisamos para cumprir com a finalidade apresentada no início do processo. <b>Importante informar que podemos usar esses dados por até 12 meses.</b>
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        5. Após finalizar as etapas de autorização do compartilhamento de seus dados poderemos solicitar esses dados atualizados para a instituição autorizada durante todo o período em que você consentiu.
      </Text>

      <Spacer top={4} />

      <Title
        size={'xsmall'}
        weight={'bold'}
        color={defaultTheme.colors.primary}
      >
        5. Como você gerencia seus compartilhamentos e quando eles serão encerrados
      </Title>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        No Open Insurance seus dados estão sob o seu controle e a autorização que você nos forneceu para receber seus dados pode ser revogada a qualquer momento, seja na instituição que transmitiu seus dados para nós como em nossas interfaces.
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        O compartilhamento de seus dados tem a duração prevista durante o seu processo de autorização/consentimento, com prazo máximo de 12 meses.
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        Ao fim do prazo de compartilhamento, a sua autorização será revogada caso você não a renove. Para que você não perca nenhuma oportunidade ou facilidade fornecida a você a partir dos seus dados, notificaremos previamente sobre a proximidade do fim do período de seu consentimento, sendo possível renová-lo caso seja de seu interesse.
      </Text>

      <Spacer top={4} />

      <Title
        size={'xsmall'}
        weight={'bold'}
        color={defaultTheme.colors.primary}
      >
        6. Como seus dados podem ser utilizados
      </Title>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        Com os dados que você vai compartilhar conosco, vamos poder conhecer melhor seu perfil para te oferecer o melhor produto, serviço, vantagens, benefícios ou as melhores condições de contratação que entendermos que façam sentido para seu momento de vida e para sua necessidade.
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        Para isso, os dados que você compartilhar conosco podem ser utilizados por qualquer empresa de nosso grupo, incluindo nossas empresas de seguros, capitalização ou consórcio.
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        Esses dados compartilhados serão utilizados em linha com a autorização que você nos forneceu ou usando outras hipóteses legais previstas na regulamentação de proteção de dados pessoais e nas regulamentações do mercado segurador. <b>Nesse contexto podemos usar seus dados em nossos processos de prospecção, venda, cadastro, aceitação, avaliações de risco de subscrição e crédito, pós-venda, sinistro, endosso, resgate, financeiro e contábil, prevenção a fraudes, cumprimento de obrigações legais ou regulatórias e para nos defendermos eventualmente em processos judiciais, arbitrais ou administrativos.</b>
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        Os dados também serão utilizados para criação e melhoria de nossos serviços, processos e produtos.
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        Para saber mais sobre a política de privacidade do grupo CNP Seguros Holding Brasil, acesse <b>https://portalprivacidade.cnpbrasil.com.br</b> 
      </Text>

      <Spacer top={4} />

      <Title
        size={'xsmall'}
        weight={'bold'}
        color={defaultTheme.colors.primary}
      >
        7. Alteração destes Termos e Condições
      </Title>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        A qualquer momento podemos alterar esses termos e condições em razão de novas exigências legais ou regulatórias, mudanças no ambiente do Open Insurance, incluindo evoluções e correções de erros ou caso a CNP Seguros Holding Brasil entenda ser necessária alguma mudança.
      </Text>

      <Spacer top={1} />

      <Text
        size={'default'}
        weight={'regular'}
        color={defaultTheme.colors.primary}
      >
        Nesse caso, se você continuar compartilhando seus dados, entenderemos que você aceitou os novos termos e condições, incluindo as alterações realizadas.
      </Text>

      <Spacer top={8} />
    </Container>
  )
}
