import { makeStyles } from '@material-ui/core/styles'

const inputStyles = makeStyles((theme) => ({
  radio: {
    '&$checked': {
      color: theme.colors.highlight,
    },
  },
  checked: {},
  select: {
    color: theme.colors.highlight,
    '&:before': {
      color: theme.colors.disable,
    },
    '&:after': {
      borderColor: theme.colors.highlight,
    },
  },
  uppercase: {
    '& input': { textTransform: 'uppercase' },
  },
  input: {
    minWidth: '100%',
    maxWidth: '100%',
    padding: '3px 0',
    border: 0,
    position: 'relative',
    top: '1px',
    color: theme.colors.highlight,
    borderBottom: '1px solid #747e85',
    backgroundColor: 'transparent',
    outline: 'none',
    '&:focus': {
      borderBottom: '2px solid',
      borderBottomColor: theme.colors.highlight,
      pointerEvents: 'none',
    },
    '&:disabled': {
      borderBottom: '1px dotted #8c959b',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '190px',
      maxWidth: '600px',
    },
  },

  inputMaterial: {
    color: theme.colors.highlight,
    '&:after': {
      borderColor: theme.colors.highlight,
      transition: 'none',
    },
    '&:hover': {
      '&:before': {
        borderBottom: '1px solid #747e85 !important',
        transition: 'none',
      },
    },
  },

  formControl: {
    minWidth: 120,
    maxHeight: '32px',
    bottom: '14px',
    display: 'block',
    [theme.breakpoints.up('md')]: {
      bottom: '16px',
    },
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateY(5rem)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  animatedItem: {
    animation: '$fadeIn .5s ease-in-out',
  },
}))

export { inputStyles }
