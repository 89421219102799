/* eslint-disable max-len */
import React from 'react'
import {
  SectionFeatureInfo,
  SectionFeatureInfoVariantType,
  SectionImage,
  SectionImageVariantType,
  SectionVideo,
  SectionVideoVariantType,
} from '@youse-seguradora/cargo-components'

type Props = {
  variant?: SectionFeatureInfoVariantType
}

export function Help({ variant }: Props) {
  return (
    <SectionFeatureInfo
      title='Precisa de ajuda?'
      subtitle='É só chamar a gente. Estamos sempre disponíveis pra ajudar ;)'
      featureInfoList={[
        {
          title: 'Via App',
          description:
            'O jeito mais prático de falar com a gente. É só escolher o tipo de atendimento que você precisa e o nosso Time de Ajuda cuida de tudo.',
          imageUrl:
            'https://www.datocms-assets.com/16903/1587668682-illus-objects-cellphone-youse-4x3-light-2x.png',
        },
        {
          title: 'Via Chat',
          description:
            'A gente também está ligado em você pelas redes sociais. É só mandar uma mensagem pelo chat e dizer a sua dúvida.',
          imageUrl:
            'https://www.datocms-assets.com/16903/1587671297-illus-objects-computer-youse-4x3-light-2x.png',
        },
        {
          title: 'Via Telefone',
          description:
            'Prefere levar um papo pelo telefone? Pra dúvidas sobre vistoria, é só ligar para <b>3003 5770</b> (capitais e regiões metropolitanas) ou <b>0800 730 9901</b> (outras localidades).',
          imageUrl:
            'https://www.datocms-assets.com/16903/1587671195-illus-objects-cellphone-screen-call-4x3-dark-2x.png',
        },
      ]}
      variant={variant ? variant : 'white'}
    />
  )
}

type PropsVideo = {
  variant?: SectionVideoVariantType
}

export function YouseFriends({ variant }: PropsVideo) {
  return (
    <SectionVideo
      title='Youse Friends'
      subtitle='Ganhe dinheiro de volta'
      body='O Youse Friends é o nosso programa de recompensas gratuito. Com ele, você acumula pontos para serem trocados por dinheiro de verdade todo ano! É só usar o nosso app para participar de um grupo com outras pessoas que também têm o Seguro Auto Youse.'
      video={{
        url: 'youtube.com/UytY3gOHzUg',
        thumbnailUrl: 'https://place-hold.it/200x200',
        provider: 'youtube',
        providerUid: 'UytY3gOHzUg',
      }}
      meta={{ publishedAt: 'asasas' }}
      ctaLabel='Saiba mais'
      ctaLink='https://www.youse.com.br/seguro-auto/friends/'
      placeVideoAtTheLeftSide
      variant={variant ? variant : 'white'}
    />
  )
}

type PropsImage = {
  variant?: SectionImageVariantType
}

export function AppYouse({ variant }: PropsImage) {
  return (
    <SectionImage
      title='Baixe o app da Youse'
      bodyNode={{
        childMarkdownRemark: {
          html: 'Tudo na palma da mão, 100% online pelo nosso aplicativo. Com ele vc acompanha o status da vistoria, tem acesso aos benefícios do Clube Youser e aciona coberturas e assistências contratadas com poucos cliques, sempre que precisar ;)',
        },
      }}
      image='https://www.datocms-assets.com/16903/1587668682-illus-objects-cellphone-youse-4x3-light-2x.png'
      variant={variant ? variant : 'white'}
    />
  )
}
