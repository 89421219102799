/**
 * @deprecated This feature will be removed soon. use `useDataLayer` hook instead. This new feature was created in `@tiny-fronts/basement`
 */
export default class DataLayerClient {
  pathName: string
  eventName: string
  constructor(eventName = 'route-change') {
    this.eventName = eventName
    this.pathName = window.location.pathname
  }

  trigger(title: string, params?: any) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: this.eventName,
        page: {
          url: this.pathName,
          title: title,
        },
        ...params,
      })
    }
  }

  triggerOrder(orderId: string, salesChannel: string) {
    if (orderId !== null || undefined) {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'order-filled',
          order: {
            uuid: orderId,
            sales_channel: salesChannel,
          },
        })
      }
    }
  }
}
