import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Loading } from '@tiny-fronts/basement'
import {
  Card,
  Checkbox,
  ColumnsContainer,
  Message,
  MessageType,
  View,
} from '@youse-seguradora/cargo-components'
import useAuth from 'hooks/useAuth'
import { Consent } from 'types/Consent'
import { Policy } from 'types/Policy'
import { formatHour } from 'utils/formatHour'

import PolicySelectOption from 'components/PolicySelectOption/PolicySelectOption'

class ResponseError extends Error {
  response!: Response
}

export default function DataSourcePage({
  selectedPolicies,
  setPolicies,
  consent,
  url
}: {
  selectedPolicies: Policy[]
  setPolicies: React.Dispatch<Policy[]>
  consent: Consent
  url: string
}) {
  const [loading, setLoading] = useState(true)
  const [policiesList, setPoliciesList] = useState<Policy[]>([])
  const { token } = useAuth()

  useEffect(() => {
    setLoading(true)
    fetch(`${process.env.REACT_APP_OPIN_SERVICE_URL}/consents/${consent.interactionId}/policies/available`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response
        } else {
          const error = new ResponseError(response.statusText)
          error.response = response
          throw error
        }
      })
      .then((response) => response.json())
      .then((response) => {
        if (!response.length) return history.push(`${url.replace(/\/$/, '')}/confirmacao`)
        setPoliciesList(response)
      })
      .catch(() => {
        history.push('/erro-desconhecido')
      })
      .finally(() => setLoading(false))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const history = useHistory()

  const selectPolicy = (policy) => {
    setPolicies([...selectedPolicies, policy])
  }

  const unselectPolicy = (policy) => {
    setPolicies(selectedPolicies.filter((p) => p.number !== policy.number))
  }

  const selectedPoliciesCheck = selectedPolicies
    .map((p) => `${p.product}:${p.number}`)
    .reduce((acc, c) => {
      acc[c] = true
      return acc
    }, {})

  return (
    <>
      <Message
        icon='calendar'
        title='Prazo da solicitação'
        description={`Essa solicitação deve ser confirmada até ${formatHour(consent.creationDateTime)}. Após esse prazo você deve fazer uma nova`}
        type={MessageType.warning}
      />

      {loading && <Loading />}
      {policiesList && (
        <Card
          style={{
            borderRadius: 8,
            padding: 34,
            marginTop: 16,
            marginBottom: 40,
          }}
        >
          {policiesList.length > 1 && (
            <View
              style={{
                borderBottom: '1px solid #EBEDEE',
              }}
            >
              <View
                style={{
                  marginLeft: -16,
                  marginBottom: -16,
                  marginTop: -32,
                }}
              >
                <Checkbox
                  register={{
                    checked:
                      selectedPolicies?.length > 0 && selectedPolicies.length === policiesList.length,
                  }}
                  value={
                    selectedPolicies?.length > 0 && selectedPolicies.length === policiesList.length
                  }
                  label='Selecionar todos'
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  onChange={(e) => {
                    setPolicies(selectedPolicies.length === policiesList.length ? [] : policiesList)
                  }}
                />
              </View>
            </View>
          )}

          <ColumnsContainer>
            {(policiesList || []).map((policy, index) => (
              <PolicySelectOption
                key={index}
                policy={policy}
                checked={selectedPoliciesCheck[`${policy.product}:${policy.number}`]}
                selectPolicy={selectPolicy}
                unselectPolicy={unselectPolicy}
              />
            ))}
          </ColumnsContainer>
        </Card>
      )}
    </>
  )
}
