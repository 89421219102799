/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { FormControl, formFieldName } from '@tiny-fronts/resource-flow-components'
import { Select, Text, View } from '@youse-seguradora/cargo-components'
import _ from 'lodash'
import styled from 'styled-components'

type FormSelectProps = {
  classes: any
  value?: string | number
  options?: []
  errors?: any
  name: string
  obj: string
  label: string
  onChange?: any
  horizontal?: boolean
  weight?: any
  align?: 'inherit' | 'left' | 'right' | 'center'
}

const Option = styled.option`
  &:hover {
    cursor: pointer;
    background-color: #f5f5f5;
  }
`

const FormSelect = ({
  classes,
  value = '',
  options = [],
  errors,
  name,
  obj,
  label,
  onChange,
  horizontal = true,
  ...props
}: FormSelectProps) => {
  const formField = formFieldName(obj, name)

  return (
    <FormControl fullWidth={true} resourceerrors={errors} errorfor={formField}>
      <View
        layout={{
          desktopHorizontal: horizontal,
          desktopAlignHorizontalCenter: true,
        }}
        spacing={{ top: 6 }}
      >
        <View spacing={{ top: 4 }}>
          <Text
            size='big'
            weight={props.weight && 'bold'}
            as='label'
            htmlFor={formField}
            align={props.align}
          >
            {label}
          </Text>
        </View>
        <View spacing={{ desktopLeft: 2 }}>
          <FormControl className={classes.formControl}>
            <Select
              name={formField}
              emptyOptionText='Selecione'
              dataTestId={`${name}-select-input`}
              value={value || ''}
              onChange={(e: any) => onChange(name, e.target.value)}
              options={options}
            />
          </FormControl>
        </View>
      </View>
    </FormControl>
  )
}

export default React.memo(FormSelect, (p, n) => {
  if (p.options !== n.options) return false

  return p.value === n.value || (!p.value && !n.value)
})
