import React from 'react'
import { DataLayerClient } from '@tiny-fronts/resource-flow-components'
import { SectionImage } from '@youse-seguradora/cargo-components'

type GenericErrorProps = {
  title?: string
  body?: string
  ctaOnClick?: () => void
  ctaLabel?: string
}

export default function GenericError({ title, body, ctaOnClick, ctaLabel }: GenericErrorProps) {
  React.useEffect(() => {
    new DataLayerClient().trigger('pageview_admin_generic_error')
  }, [])

  return (
    <SectionImage
      variant='white'
      title={title || 'Opa! Ocorreu um erro na página.'}
      bodyNode={{
        childMarkdownRemark: {
          html: body || 'Pode ficar numa boa, não foi culpa sua. ;)',
        },
      }}
      image='https://www.datocms-assets.com/16903/1602774696-illus-characters-empty-state-4x3-dark.png'
      ctaLabel={ctaLabel ? ctaLabel : 'Tentar novamente'}
      ctaOnClick={ctaOnClick ? ctaOnClick : () => location.reload()}
      eventActionClick='hit_admin_generic_error_try_again'
      leftContent
    />
  )
}
