import React from 'react'
import { Column, ColumnsContainer, Link, Text, View } from '@youse-seguradora/cargo-components'

type AcceptTermsConditions = {
  productName?: string
}

const AcceptTermsConditions = ({ productName }: AcceptTermsConditions) => {
  return (
    <ColumnsContainer>
      <Column desktopSize={6} desktopOffset={3}>
        <Text size='small' align='center'>
          Ao finalizar a compra, declaro que li e concordo com as informações contidas no link{' '}
          <Link
            targetBlank
            href={'https://cdn.youse.com.br/docs/caixa/condicoes-gerais-plano-auto-v2.pdf'}
            title={`Condições Gerais do ${productName}`}
          >
            Condições Gerais do {productName}
          </Link>{' '}
          e com o{' '}
          <Link
            targetBlank
            href='https://cdn.youse.com.br/docs/caixa/manual-de-assistencia-atualizado-v2.pdf'
            title='Manual de Assistências'
          >
            Manual de Assistências
          </Link>
          , e confirmo que todas informações fornecidas são verdadeiras.
        </Text>

        <View spacing={{ bottom: 4 }} />
        <Text size='small' align='center'>
          A cobrança no cartão será realizada pela Youse, propriedade da Caixa Seguradora.
        </Text>
      </Column>
    </ColumnsContainer>
  )
}

export default AcceptTermsConditions
