import React from 'react'
import { Divider, Image, Modal, Title, View } from '@youse-seguradora/cargo-components'

import { ServiceProps, Services } from './Services'

import { useTheme } from '@material-ui/core/styles'

type ModalDownloadDocsProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  assistance: any
  policy: { number: string }
  setIsOpenService: React.Dispatch<React.SetStateAction<boolean>>
  isOpen: boolean
}

const ModalServices = ({
  assistance,
  policy,
  isOpen,
  setIsOpenService,
}: ModalDownloadDocsProps) => {
  const theme = useTheme()

  const content = (
    <>
      <View layout={{ alignHorizontalCenter: true }} spacing={{ bottom: 2 }}>
        <Image alt='documentos' src={assistance.iconUrl} height={14} />
      </View>
      <Title align='center' size='small' color={theme.colors.highlight}>
        {assistance?.name}
      </Title>
      <View spacing={{ top: 5 }} />
      <Divider top={1} color={theme.divisors.standard} />

      {assistance?.services
        .filter((service) => service.canRequestAssistance)
        .map((service: ServiceProps, i: number) => (
          <div key={i}>
            <Services service={service} i={i} assistance={assistance} policy={policy} />
          </div>
        ))}
    </>
  )

  const modalServices = {
    title: 'Serviços inclusos',
    content: content,
  }

  return (
    <Modal
      {...modalServices}
      isOpen={isOpen}
      onClose={() => {
        setIsOpenService(false)
      }}
    />
  )
}

export default ModalServices
