/* eslint-disable @typescript-eslint/no-var-requires */
const qs = require('qs')
const humps = require('humps')
const axios = require('axios').default

class BFFClient {
  baseUrl: string
  constructor() {
    this.baseUrl = `${process.env.REACT_APP_MICROFRONTEND_BFF_BASE_URL}`
  }

  fetchMissingPersonDetails(documentNumber) {
    const url = this._buildUrl(
      `${this.baseUrl}/v1/person_details/${documentNumber}/missing_details`,
    )
    return this._request('GET', url).then(humps.camelizeKeys)
  }

  fetchAddressDetails(zipCode) {
    const url = this._buildUrl(`${this.baseUrl}/v1/zip_code_details/${zipCode}`)
    return this._request('GET', url).then(humps.camelizeKeys)
  }

  fetchVehicleDetails(licensePlate) {
    const url = this._buildUrl(`${this.baseUrl}/v1/vehicle_details/${licensePlate}`)
    return this._request('GET', url).then(humps.camelizeKeys)
  }

  fetchList(itemType) {
    const url = this._buildUrl(`${this.baseUrl}/v1/listas/${itemType}`)
    return this._request('GET', url).then(humps.camelizeKeys)
  }

  fetchVehicleMakes() {
    const url = this._buildUrl(`${this.baseUrl}/v1/auto/marcas/`)
    return this._request('GET', url).then(humps.camelizeKeys)
  }

  fetchVehicleModels(vehicleMake) {
    const url = this._buildUrl(`${this.baseUrl}/v1/auto/marcas/${vehicleMake}/modelos/`)
    return this._request('GET', url).then(humps.camelizeKeys)
  }

  fetchVehicleYears(vehicleMake, vehicleModel) {
    const url = this._buildUrl(
      `${this.baseUrl}/v1/auto/marcas/${vehicleMake}/modelos/${vehicleModel}/anos/`,
    )
    return this._request('GET', url).then(humps.camelizeKeys)
  }

  fetchVehicleVersions(vehicleMake, vehicleModel, vehicleYear) {
    const url = this._buildUrl(
      `${this.baseUrl}/v1/auto/marcas/${vehicleMake}/modelos/${vehicleModel}/anos/${vehicleYear}/versoes/`,
    )
    return this._request('GET', url).then(humps.camelizeKeys)
  }

  fetchVehicleByVersion(vehicleMake, vehicleModel, vehicleYear, vehicleVersion) {
    const url = this._buildUrl(
      `${this.baseUrl}/v1/auto/marcas/${vehicleMake}/modelos/${vehicleModel}/anos/${vehicleYear}/versoes/${vehicleVersion}`,
    )
    return this._request('GET', url).then(humps.camelizeKeys)
  }

  fetchCoverages(product, resourceId) {
    const url = this._buildUrl(`${this.baseUrl}/v1/cotacao/${product}/${resourceId}/coberturas`)
    return this._request('GET', url).then(humps.camelizeKeys)
  }

  fetchAssistances(product, subproduct, glassCategory) {
    const url = this._buildUrl(
      `${this.baseUrl}/v1/cotacao/${product}/assistencias?glass_category=${glassCategory}&subproduct=${subproduct}&insurance_type=${product}`,
    )
    return this._request('GET', url).then(humps.camelizeKeys)
  }

  fetchPaymentConditions(product, resourceFlowId, params = {}) {
    const url = this._buildUrl(
      `${this.baseUrl}/v1/cotacao/${product}/${resourceFlowId}/condicoes-de-pagamento`,
      params,
    )
    return this._request('GET', url).then(humps.camelizeKeys)
  }

  fetchPaymentConditionQRCode(product, resourceFlowId, paymentMethod) {
    const url = this._buildUrl(
      `${this.baseUrl}/v1/cotacao/${product}/${resourceFlowId}/condicoes-de-pagamento/qr-code/${paymentMethod}`,
    )
    return this._request('POST', url).then(humps.camelizeKeys)
  }

  fetchPropertyTypes() {
    const url = this._buildUrl(`${this.baseUrl}/v1/property_types`)
    return this._request('GET', url).then(humps.camelizeKeys)
  }

  sendOrderByEmail(product, resourceFlowId, applicationHost, email) {
    const url = this._buildUrl(
      `${this.baseUrl}/v1/cotacao/${product}/${resourceFlowId}/notificar/email`,
    )
    const params = {
      applicationHost: applicationHost,
      email: email,
    }
    return this._request('POST', url, params).then(humps.camelizeKeys)
  }

  sendOrderBySMS(product, resourceFlowId, applicationHost, phone) {
    const url = this._buildUrl(
      `${this.baseUrl}/v1/cotacao/${product}/${resourceFlowId}/notificar/sms`,
    )
    const params = {
      applicationHost: applicationHost,
      phone: phone,
    }
    return this._request('POST', url, params).then(humps.camelizeKeys)
  }

  /**
   * Calls the HTTP request methods
   * @private
   */
  _request(method, url, body = null) {
    const snakeKeysBody = humps.decamelizeKeys(body)
    const stringifiedBody = body ? JSON.stringify(snakeKeysBody) : null

    const etagKey = `${method}::${url}`
    const etag = localStorage.getItem(etagKey)
    const etagObjectKey = `${etagKey}::object`

    return axios(url, {
      method: method,
      data: stringifiedBody,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'If-None-Match': etag,
      },
      validateStatus: function (status) {
        return (status >= 200 && status < 300) || status === 304 || status === 422
      },
    })
      .then((response) => {
        localStorage.setItem(etagKey, response.headers.etag)

        if (response.status >= 200 && response.status < 300) {
          localStorage.setItem(etagObjectKey, JSON.stringify(response))
        } else if (response.status === 304) {
          const cachedResponse = localStorage.getItem(etagObjectKey)
          return JSON.parse(cachedResponse)
        }

        return response
      })
      .catch((error) => {
        return {
          error: true,
          status: error.response?.status,
          data: error.response?.data,
        }
      })
  }

  /**
   * Builds an URL transforming params into query string.
   * @private
   */
  _buildUrl(path, params = {}) {
    if (params === null || Object.keys(params).length === 0) {
      return path
    } else {
      const convertedParams = humps.decamelizeKeys(params)
      return path + '?' + qs.stringify(convertedParams)
    }
  }
}

export default BFFClient
