import React, { useContext } from 'react'
import { Icon, Spacer, View, Visible } from '@youse-seguradora/cargo-components'

import { ResourceFlowContext } from '../../../../contexts/ResourceFlowContext'

export type SidebarIconsProps = {
  icons: string[]
}

const SidebarIcons = ({ icons }: SidebarIconsProps) => {
  const context = useContext<any>(ResourceFlowContext)
  return (
    <Visible
      tablet
      mobile
      desktop={context.data.currentSection === 'payment_condition_selection' ? true : false}
    >
      <View
        spacing={{ bottom: 4 }}
        layout={{
          alignVerticalCenter: true,
          alignHorizontalCenter: true,
          wrap: true,
          horizontal: true,
        }}
      >
        {icons.map((item, i) => (
          <Spacer key={i} left={i === 0 ? 0 : 6}>
            <Icon
              size={context.data.currentSection === 'payment_condition_selection' ? 8 : 6}
              name={item}
            />
          </Spacer>
        ))}
      </View>
    </Visible>
  )
}

export default SidebarIcons
