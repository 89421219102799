import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { inspectionTypes, usePolicyContext } from '@tiny-fronts/basement'
import { DataLayerClient } from '@tiny-fronts/resource-flow-components'
import {
  InvisibleButton,
  Tag,
  Title,
  TrackingContainer,
  View,
} from '@youse-seguradora/cargo-components'

import { formatDate } from '../../helpers/date'
import { compareTranslation } from '../../helpers/functions'
import { inspectionTagColor } from '../../helpers/status'
import { translationStatusInspection } from '../../helpers/translationType'
import { InspectionData } from '../../types/types'

import TableStructure from './TableStructure'

import { useTheme } from '@material-ui/core/styles'

type InspectionListProps = {
  status: React.ReactNode
  inspectionType: typeof inspectionTypes | '-'
  createdAt: string
  updatedAt: string
  provider: string
  details: React.ReactNode
}

export default function InspectionsList() {
  const { policy, inspections } = usePolicyContext()
  const theme = useTheme()
  const history = useHistory()

  const { product } = useParams<{
    product: 'auto' | 'vida' | 'residencial'
  }>()

  const handleClick = (inspection) => {
    history.push(`/apolices/${product}/${policy?.number}/vistorias/${inspection.id}`)
  }

  useEffect(() => {
    new DataLayerClient().trigger('pageview_admin_inspection')
  }, [])

  const InspectionList: InspectionListProps[] = []

  inspections && inspections?.length > 0
    ? inspections.map((inspection: InspectionData) =>
      InspectionList.push({
        inspectionType: compareTranslation(inspectionTypes, inspection?.inspectionType),
        createdAt: formatDate(inspection.createdAt),
        provider: inspection?.provider?.name?.toUpperCase(),
        updatedAt: formatDate(inspection.updatedAt),
        status: (
          <View size={{ width: 'max-content' }}>
            <Tag
              isUppercase={false}
              name={compareTranslation(translationStatusInspection, inspection.status)}
              type={inspectionTagColor(inspection.status)}
            />
          </View>
        ),
        details: (
          <TrackingContainer action='hit_inspection_details' label='Ver detalhes'>
            <InvisibleButton
              children='Detalhes'
              onClick={() => handleClick(inspection)}
              leftIcon='search'
            />
          </TrackingContainer>
        ),
      }),
    )
    : InspectionList.push({
      createdAt: '-',
      updatedAt: '-',
      status: '-',
      inspectionType: '-',
      provider: '-',
      details: '-',
    })

  return (
    <>
      <Title color={theme.colors.primary} size='xsmall'>
        Histórico de vistorias
      </Title>
      <View spacing={{ top: 6 }} style={{ overflowX: 'scroll', overflowY: 'hidden' }}>
        <TableStructure inspectionsList={InspectionList} />
      </View>
    </>
  )
}
