const regexVin = /^[A-HJ-NPR-Z0-9]{8}[0-9A-HJ-NPR-Z][A-HJ-NPR-Z0-9]{8}$/

export const isVin = (vin: string) => {
  const vinTemp = vin.toUpperCase()
  
  if (!regexVin.test(vinTemp)) {
    return false
  } else if (vinTemp[0] === '0') {
    return false
  } else if (/^.{4,}([0-9A-Z])\\1{5,}/.test(vinTemp)) {
    return false
  } else if (!/[0-9]{4}$/.test(vinTemp)) {
    return false
  } else {
    return true
  }
}

export const vinMask = '*****************'
