import React from 'react'
import { designTokens, Image, Text, View } from '@youse-seguradora/cargo-components'

import LoadingYouse from '../../image/loading-youse.gif'

export default function Loading() {

  return (
    <View layout={{ alignHorizontalCenter: true }} spacing={{ top: 4 }}>
      <View spacing={{ top: 8 }} size={{ width: '112px' }}>
        <Image alt='Carregando...' src={LoadingYouse} height={16} />
      </View>
      <Text color={designTokens.colors.purple600} size='big'>
        Carregando...
      </Text>
    </View>
  )
}
