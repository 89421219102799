import React from 'react'
import { Spacer, Title } from '@youse-seguradora/cargo-components'

import AssistanceItem from './AssistanceItem'

import { useTheme } from '@material-ui/core/styles'

const Assistances = ({ data }: any) => {
  const theme = useTheme()

  return (
    <>
      <Title size='xsmall' color={theme.colors.primary}>
        Assistências selecionadas
      </Title>
      <Spacer top={3} />
      {data.map((item: any, index: number) => {
        return <AssistanceItem key={index} data={item} />
      })}
    </>
  )
}

export default Assistances
