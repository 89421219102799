/* eslint-disable no-script-url */
import React from 'react'
import { Text } from '@youse-seguradora/cargo-components'

import { useTheme } from '@material-ui/core/styles'

const ModalLGPDContent = () => {
  const theme = useTheme()

  return (
    <Text color={theme.colors.secondary}>
      Pode ficar numa boa: os seus dados estão seguros com a gente. Precisamos deles pra realizar a
      cobrança de parcelas do seu contrato. Sempre que quiser, dá pra alterar estas informações no
      nosso aplicativo.
    </Text>
  )
}

export default ModalLGPDContent
