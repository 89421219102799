/* eslint-disable max-len */
// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../../../../../.typescript/custom.d.ts" />
import React from 'react'
import {
  Background,
  Column,
  ColumnsContainer,
  Container,
  Icon,
  IconName,
  Image,
  Layout,
  Spacer,
  Text,
  View,
} from '@youse-seguradora/cargo-components'

import LogoGlobalsign from '../../images/globalsign-logo.png'

import { useTheme } from '@material-ui/core/styles'

const creditCardOptions: IconName[] = [
  'visa',
  'mastercard',
  'amex',
  'visaelectron',
  'elo',
  'maestro',
  'diners',
  'discover',
  'caixa',
  'hipercard',
  'jcb',
  'aura',
]

const Footer = () => {
  const theme = useTheme()
  const disclaimer =
    'O grupo Caixa Seguradora reúne empresas de Seguros, Previdência, Consórcios, Capitalização e Saúde. CNPJ da Seguradora: 34.020.354/0001-10. Processo SUSEP 15414.900039/2016-18. O registro dos planos na Susep não implica, por parte da Autarquia, em incentivo ou recomendação à sua comercialização. Ouvidoria Caixa Seguradora: 0800 702 4240 - reclamações não atendidas satisfatoriamente por outros canais, ou sugestões e elogios. Para facilitar/ agilizar o atendimento da Ouvidoria, informe o número de protocolo anterior fornecido pelo SAC ou demais canais de atendimento. Horário de atendimento das 8 às 18 horas, de segunda a sexta, exceto feriados nacionais.'

  return (
    <footer>
      <Background color={theme.backgrounds.disable}>
        <Container>
          <Spacer top={7} bottom={7}>
            <ColumnsContainer>
              <Column desktopSize={9}>
                <Text color={theme.colors.primary} size='xsmall'>
                  {disclaimer}
                </Text>
              </Column>
              <Column desktopSize={3}>
                <Spacer vertical={6} desktopVertical={0}>
                  <Layout vertical desktopAlignRight>
                    <View
                      layout={{
                        wrap: true,
                        horizontal: true,
                        alignHorizontalCenter: true,
                        desktopAlignRight: true,
                      }}
                    >
                      {creditCardOptions.map((item, i) => (
                        <Spacer key={i} left={i === 0 ? 0 : 2}>
                          <Icon size={5} name={item} maxwidth='40px' />
                        </Spacer>
                      ))}
                    </View>
                    <Spacer top={4}>
                      <Image src={LogoGlobalsign} alt='globalsign' height={6} />
                    </Spacer>
                  </Layout>
                </Spacer>
              </Column>
            </ColumnsContainer>
          </Spacer>
        </Container>
      </Background>
    </footer>
  )
}

export default Footer
