import React from 'react'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import Radio from '@material-ui/core/Radio'
import Typography from '@material-ui/core/Typography'
import { Text, View } from '@youse-seguradora/cargo-components'

type VehicleVersionSelectorProps = {
  options: any
  setOptions?: any
  fipeCode?: string
  setFipeCode?: any
  classes?: any
  vehicleChange?: any
  setHandleModalCarNotAccepted?: any
  setHandleModalCarAccepted?: any
}

const VehicleVersionSelector = ({
  options,
  fipeCode,
  classes,
  vehicleChange,
  setFipeCode,
  setOptions,
  setHandleModalCarNotAccepted,
  setHandleModalCarAccepted,
}: VehicleVersionSelectorProps) => {
  const goToVehicleNotAccepted = () => {
    setHandleModalCarNotAccepted(true)
    vehicleChange('fipeCode', '')
    vehicleChange('licensePlate', '')
    setOptions([])
  }

  const handleVehicleAge = (year: string) => {
    const currentYear = new Date().getFullYear()
    const convertedYear = parseInt(year)
    const ageVehicle = currentYear - convertedYear

    if (ageVehicle >= 16 && ageVehicle <= 25) {
      return setHandleModalCarAccepted(true)
    }
  }

  const handleVehicleNotAcceptedSelected = (value: string, accepted: string, year: string) => {
    if (accepted) {
      setFipeCode(value)
      handleVehicleAge(year)
    } else goToVehicleNotAccepted()
  }

  return (
    <View
      layout={{
        desktopAlignHorizontalCenter: true,
      }}
      spacing={{ top: 6 }}
    >
      <Text align='center' size='small'>
        Selecione a versão do seu veículo
      </Text>

      <View spacing={{ top: 2 }}>
        {options.map((option: any) => (
          <Paper elevation={2} key={`vehicle_${option.externalCode}`} style={{ marginBottom: 10 }}>
            <Grid container alignItems='center'>
              <Grid item xs={2} sm={1}>
                <Radio
                  classes={{
                    root: classes.radio,
                    checked: classes.checked,
                  }}
                  id={`vehicle_${option.externalCode}`}
                  checked={fipeCode === option.externalCode}
                  value={option.externalCode}
                  onChange={(e: any) =>
                    handleVehicleNotAcceptedSelected(e.target.value, option.accepted, option.year)
                  }
                />
              </Grid>
              <Grid item xs={10} sm={11}>
                <Typography variant='h6'>
                  <InputLabel htmlFor={`vehicle_${option.externalCode}`}>
                    {option.version}
                  </InputLabel>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        ))}
      </View>
    </View>
  )
}

VehicleVersionSelector.defaultProps = {
  options: [],
}

export default VehicleVersionSelector
