import React, { useEffect } from 'react'
import {
  Input,
  View } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import { desktopContent, mobileContent } from 'styles/ContentStyle'
import { isMobile } from 'utils/IsMobile'

export const LoginForm = ({ form, setForm }) => {
  useEffect(() => {
    const loginpassword: any = document.getElementsByName('loginpassword')

    loginpassword[0].type = 'password'
  }, [])

  return (
    <View
      style={isMobile() ? mobileContent : desktopContent}
    >
      <Input
        name='loginpassword'
        type={'text'}
        value={form.password}
        label='Senha'
        onChange={(e) => setForm({ ...form, password: e })}
        color={defaultTheme.colors.secondary}
      />
    </View>
  )
}
