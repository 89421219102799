import React from 'react'
import { SectionColor, SectionUpSell } from '@youse-seguradora/cargo-components'

import Hero from '../Hero/Hero'
import { Help } from '../SectionsDefault'

type SuccessDefaultProps = {
  productName: string
  data?: any
}

export default function SuccessDefault({ data, productName }: SuccessDefaultProps) {
  return (
    <>
      <Hero
        data={data}
        titleHeader='Parabéns! Tudo pronto para vc curtir a vida numa boa.'
        productName={productName}
      />

      <Help />

      <SectionColor
        title='Vamos lá? Baixe o app e comece já! ;)'
        variant='white [highlight]'
        ctaLabel='[APPSTORES]'
        ctaLink='#'
      />

      {data.product === 'home' ? (
        <SectionUpSell
          title='Seguros online tipo vc. Clique e saiba mais!'
          illustratedLinkList={[
            {
              title: 'Seguro Auto',
              subtitle: 'Saiba mais',
              imageUrl: 'https://www.datocms-assets.com/16903/1596226932-logo-auto.png',
              link: 'https://www.youse.com.br/seguro-auto/',
            },
            {
              title: 'Seguro Vida',
              subtitle: 'Saiba mais',
              imageUrl: 'https://www.datocms-assets.com/16903/1596227049-logo-vida.png',
              link: 'https://www.youse.com.br/seguro-de-vida/',
            },
          ]}
        />
      ) : (
        <SectionUpSell
          title='Seguros online tipo vc. Clique e saiba mais!'
          illustratedLinkList={[
            {
              title: 'Seguro Residencial',
              subtitle: 'Saiba mais',
              imageUrl:
                'https://www.datocms-assets.com/16903/1578061319-1x1homeseguroresid1440-2x.png',
              link: 'https://www.youse.com.br/seguro-residencial/',
            },
            {
              title: 'Seguro Auto',
              subtitle: 'Saiba mais',
              imageUrl: 'https://www.datocms-assets.com/16903/1596226932-logo-auto.png',
              link: 'https://www.youse.com.br/seguro-auto/',
            },
          ]}
        />
      )}
    </>
  )
}
