import React from 'react'
import {
  Column,
  ColumnsContainer,
  Select,
  View } from '@youse-seguradora/cargo-components'
import { desktopContent, mobileContent } from 'styles/ContentStyle'
import { isMobile } from 'utils/IsMobile'

const typeOptions = [
  {
    text: 'Residencial',
    value: 'Home'
  },
  {
    text: 'Vida',
    value: 'Life'
  },
  {
    text: 'Auto',
    value: 'Auto'
  }
]

export const AboutDataUsage = ({ form, setForm, isReception }) => {

  return (
    <View
      style={isMobile() ? mobileContent : desktopContent}
    >
      <ColumnsContainer>
        <Column tabletSize={6}>
          <Select
            disabled={!isReception}
            name='description'
            options={[
              {
                value: 'Ofertar serviços personalizados',
                text: 'Ofertar serviços personalizados',
              },
            ]}
          />
        </Column>

        <Column tabletSize={6}>
          <Select
            disabled={!isReception}
            name='type'
            defaultValue='Home'
            onChange={(e) => setForm({ ...form, segment: e.target.value })}
            options={typeOptions}
          />
        </Column>
      </ColumnsContainer>
    </View>
  )
}
