export function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item))
}

export function mergeObject(objectOne, ...moreObjects) {
  if (!moreObjects.length) return objectOne
  const source = moreObjects.shift()
  
  if (isObject(objectOne) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!objectOne[key]) Object.assign(objectOne, { [key]: {} })
        mergeObject(objectOne[key], source[key])
      } else {
        Object.assign(objectOne, { [key]: source[key] })
      }
    }
  }
  
  return mergeObject(objectOne, ...moreObjects)
}
