import React from 'react'
import {
  Image,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Title,
  View,
} from '@youse-seguradora/cargo-components'

import { useTheme } from '@material-ui/core/styles'

type ModalSimpleProps = {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  hiddenOverflow?: boolean
  title: string
  mainText: string
  imageURL: string
  imageAlt: string
  buttonText: string
  onClick: () => void
}

export default function ModalSimple({
  isOpen,
  setIsOpen,
  title,
  mainText,
  imageURL,
  imageAlt,
  buttonText,
  onClick,
  hiddenOverflow
}: ModalSimpleProps) {
  const theme = useTheme()

  function onClose() {
    setIsOpen(false)
  }

  function handleClick() {
    onClick()
    setIsOpen(false)
  }

  const content = (
    <>
      <View layout={{ alignHorizontalCenter: true }} spacing={{ bottom: 4 }}>
        <Image alt={imageAlt} src={imageURL} height={20} />
      </View>

      <Title color={theme.colors.highlight} size='small' align='center'>
        {title}
      </Title>

      <View spacing={{ top: 4 }} style={{ textAlign: 'center' }}>
        {mainText}
      </View>
    </>
  )

  const footer = (
    <View layout={{ desktopHorizontal: true }}>
      <View spacing={{ right: 2 }}>
        <SecondaryButton onClick={onClose}>Voltar</SecondaryButton>
      </View>

      <PrimaryButton onClick={handleClick}>{buttonText}</PrimaryButton>
    </View>
  )

  const modalProps = {
    hiddenOverflow,
    content,
    footer,
    isOpen,
    onClose,
  }

  return <Modal {...modalProps} />
}
