import React from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'

import PrivateRoute from 'components/PrivateRoute'
import PublicLayoutBase from 'components/PublicLayoutBase'

import routes from '../pages'

const { pages, publicPages } = routes

const Router = () => {

  return (
    <BrowserRouter>
      <Switch>
        {
          publicPages.map((route, index) => (
            <PublicLayoutBase key={index} {...route}>
              <route.children />
            </PublicLayoutBase>
          ))
        }

        <Switch>
          {
            pages.map((route, index) => (
              <PrivateRoute key={index} {...route}>
                <route.children />
              </PrivateRoute>
            ))
          }
        </Switch>
      </Switch>
    </BrowserRouter>
  )
}

export default Router
