import { useState } from 'react'

export const useFetchCancel = () => {
  const [controller, setController] = useState(new AbortController())

  const cancel = () => {
    if (controller) {
      controller.abort()
      setController(new AbortController())
    }
  }

  return { cancel, signal: controller.signal }
}
