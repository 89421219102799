/* eslint-disable max-len */
import React from 'react'
import { SectionBulletPoints, SectionColor, SectionImage } from '@youse-seguradora/cargo-components'

import Hero from '../Hero/Hero'
import { Help, YouseFriends } from '../SectionsDefault'

type OnlineInspectionProps = {
  productName: string
  data?: any
}

const onlineInspectionList = [
  {
    content:
      'Se é a primeira vez que você contratou um Seguro Youse com o e-mail cadastrado, vai ser necessário criar uma senha. Mandamos um e-mail explicando tudo;',
  },
  {
    content: 'Baixe o aplicativo na loja do seu celular: [APPSTORES]',
  },
  {
    content: 'Entre com os seus dados de acesso;',
  },
  {
    content: 'Toque em "Fazer vistoria".',
  },
]

export default function OnlineInspection({ data, productName }: OnlineInspectionProps) {
  return (
    <>
      <Hero
        data={data}
        titleHeader='Pro seguro começar a valer, vc precisa fazer sua vistoria online em até 5 dias.'
        productName={productName}
      />
      <SectionBulletPoints
        title='Acesse sua conta no app Youse e faça a vistoria'
        listTitle='Como fazer isso:'
        listItems={onlineInspectionList}
        disclaimerTitle='Atenção'
        disclaimerBody='Se você não fizer a vistoria, o seu seguro pode ser cancelado. Caso isso aconteça, vamos estornar automaticamente o seu pagamento de acordo com o fechamento da fatura do cartão.'
        image='https://cdn.youse.com.br/inst_bra/vistoria-online/illusobjects_cellphoneinspectionattention_400x400.png'
      />

      <SectionColor
        title='Confira como fazer a sua vistoria pelo aplicativo Youse'
        variant='offwhite [highlight]'
      />

      <SectionImage
        title='Estacione seu carro num espaço aberto e bem iluminado'
        bodyNode={{
          childMarkdownRemark: {
            html: 'A gente vai precisar de algumas fotos do seu carro e também dos seus documentos.',
          },
        }}
        image='https://cdn.youse.com.br/inst_bra/vistoria-online/illusvehicles_caronixfront_4x3light.png'
        variant='white'
      />

      <SectionImage
        title='Tire as fotos e envie sua vistoria'
        bodyNode={{
          childMarkdownRemark: {
            html: 'É bem fácil: você só precisa seguir o passo a passo no aplicativo. Dá pra resolver em menos de 10 minutos! ;)',
          },
        }}
        image='https://cdn.youse.com.br/inst_bra/vistoria-online/illusvehicles__auto_preenchaosdadosdoseucarro_1440.png'
        variant='offwhite'
        placeImageAtTheLeftSide
      />

      <SectionImage
        title='Acompanhe o status da sua vistoria pelo app'
        bodyNode={{
          childMarkdownRemark: {
            html: 'Agora é com a gente: vamos conferir as suas fotos e responder em até 3 dias úteis, ok?',
          },
        }}
        image='https://cdn.youse.com.br/inst_bra/vistoria-online/illusobjects_cellphoneinsp.png'
        variant='white'
      />

      {data.subproduct === 'default' && (
        <>
          <SectionColor
            title='Conheça benefícios que você já pode começar a usar'
            variant='offwhite [highlight]'
          />

          <YouseFriends />
        </>
      )}

      <SectionColor
        title='Pronto! Se estiver tudo certinho, vc já pode dirigir por aí numa boa ;)'
        variant='offwhite [highlight]'
        ctaLabel='[APPSTORES]'
        ctaLink='#'
      />

      <Help />
    </>
  )
}
