import React, { Dispatch, SetStateAction } from 'react'
import { InvisibleButton, Modal, Text, Title, View } from '@youse-seguradora/cargo-components'

import { useTheme } from '@material-ui/core/styles'

interface ModalCarNotAcceptedProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  message: string
}

const ModalCarNotAccepted = ({ isOpen, setIsOpen, message }: ModalCarNotAcceptedProps) => {
  const theme = useTheme()

  const data = {
    title: '',
    content: (
      <>
        <Title align='center' size='small' color={theme.colors.highlight}>
          O seu carro não foi aceito :(
        </Title>
        <View spacing={{ top: 4 }} />
        <Text color={theme.colors.secondary}>
          <b>{message}</b>
        </Text>
        <View spacing={{ top: 2 }} />
        <Text color={theme.colors.secondary}>
          Fique ligado: estamos sempre atualizando esses critérios pra oferecer um seguro cada vez
          mais do seu jeito. A gente espera ter você por aqui também em breve! ;)
        </Text>
      </>
    ),
    footer: <InvisibleButton onClick={() => setIsOpen(false)}>Entendi</InvisibleButton>,
  }

  return <Modal {...data} isOpen={isOpen} onClose={() => setIsOpen(false)} />
}

export default ModalCarNotAccepted
