import React, { useContext, useEffect, useState } from 'react'
import {
  Container,
  getCargoSpacingPx,
  LabelChip,
  Text,
  View,
} from '@youse-seguradora/cargo-components'

import { ResourceFlowContext } from '../../../contexts/ResourceFlowContext'

import { useTheme } from '@material-ui/core/styles'

const HEIGHT = 14
const HEIGHT_PX = getCargoSpacingPx(HEIGHT)

type HeaderProps = {
  fixed?: boolean
}

const PaymentConditionHeader = ({ fixed }: HeaderProps) => {
  const theme = useTheme()
  const context = useContext<any>(ResourceFlowContext)
  const [messageStep, setMessageStep] = useState(
    'Pronto, agora você está mais perto de renovar seu seguro!',
  )
  const [labelChipStep] = useState('Pagamento')

  useEffect(() => {
    if (context.status === 200 && context.config.method === 'put') {
      setMessageStep('Pronto!')
    }
  }, [context])

  const HeaderContent = () => {
    return (
      <View
        background={{
          color: theme.backgrounds.header,
        }}
        size={{ height: HEIGHT_PX }}
        style={{ boxShadow: theme.shadow.inverse.idle }}
      >
        <Container>
          <View
            size={{ height: HEIGHT_PX }}
            layout={{
              alignVerticalCenter: true,
              alignHorizontalCenter: true,
              horizontal: false,
            }}
          >
            <Text align='center' size='small' weight='bold'>
              {messageStep}
            </Text>
            <View spacing={{ bottom: 3 }} />
            <LabelChip name={labelChipStep} backgroundColor='#633B8F' />
          </View>
        </Container>
      </View>
    )
  }

  return fixed ? (
    <View position={{ fixed: true, left: '0', right: '0', zIndex: 100 }}>{HeaderContent()}</View>
  ) : (
    <View position={{ sticky: true, top: '0', zIndex: 100 }}>{HeaderContent()}</View>
  )
}

export default PaymentConditionHeader
