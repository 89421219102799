
/* eslint-disable @typescript-eslint/no-explicit-any  */
/* eslint-disable @typescript-eslint/no-empty-function */

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'

import useFeatureToggle from '../../hooks/useFeatureToggle'
import {
  QUERY_NOTIFICATIONS_DETAILS,
  QUERY_NOTIFICATIONS_TOTAL,
  UPDATE_NOTIFICATIONS
} from '../../queries/NotificationsQuery'

import { NotificationContextType, NotificationDetatilType, NotificationFilter } from './types'

const NotificationContext = React.createContext<NotificationContextType>({
  errorsTotal: null,
  errorDetails: null,
  loadingTotal: true,
  loadingDetails: true,
  loadingMore: false,
  filter: {},
  totalData: {
    items: [],
    total: 0,
    unread: 0
  },
  detailsData: {
    nodes: [],
    pageInfo: {
      nextPage: ''
    }
  },
  limit: 15,
  handleFilter: () => {},
  nextPage: false,
  notificationList: [],
  readSelectedNotifications: () => {},
  isModalOpen: false,
  setModalOpen: () => {},
})

function NotificationProvider({ children }) {
  const [notificationList, setList] = useState<NotificationDetatilType[]>([])
  const [filter, setFilter] = useState<NotificationFilter>({})
  const [loadingMore, setLoadingMore] = useState(false)
  const [nextPage, setNextPage] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
    
  const limit = 15

  const { useFlag } = useFeatureToggle('partner-portal')
  const notificationsEnabled = useFlag('partner_portal_notifications')

  const [getNotificationTotal, {
    data: {
      notificationsTotal: totalData
    } = {},
    loading: loadingTotal,
    error: errorsTotal,
    refetch: refetchTotal,
    called: calledTotal,
  }] = useLazyQuery(QUERY_NOTIFICATIONS_TOTAL)

  const queryVariable = useMemo(() => ({
    ...(filter?.type ? { messageType: filter.type } : { limit }),
    ...(filter?.subType ? { messageSubtype: filter.subType } : {}),
  }), [filter])

  const [getNotificationsDetails, {
    data: {
      notifications: detailsData
    } = {},
    loading: loadingDetails,
    error: errorDetails,
    refetch: refetchDetails,
    called: calledDetails,
  }] = useLazyQuery(QUERY_NOTIFICATIONS_DETAILS, {
    variables: {
      ...queryVariable,
      page: null
    }
  })

  useEffect(() => {
    if (notificationsEnabled && !calledTotal) getNotificationTotal()
    if (notificationsEnabled && !calledDetails) getNotificationsDetails()
  }, [
    notificationsEnabled,
    calledTotal,
    calledDetails,
    getNotificationTotal,
    getNotificationsDetails
  ])

  const [markAsRead] = useMutation(UPDATE_NOTIFICATIONS)
  
  const readSelectedNotifications = useCallback(() => {
    if (totalData?.unread)
      markAsRead({
        variables: { ...queryVariable },
        onCompleted() {
          if (refetchTotal) refetchTotal()
          if (refetchDetails) refetchDetails()
        },
      })
  }, [
    markAsRead,
    queryVariable,
    refetchDetails,
    refetchTotal,
    totalData
  ])
  
  const handleNextPage = useCallback(() => {
    if (!nextPage) return
    
    setLoadingMore(true)
    return refetchDetails( {
      ...queryVariable,
      page: detailsData.pageInfo?.nextPage,
      limit: 15
    }).finally(() => {
      setLoadingMore(false)
    })
  }, [refetchDetails, detailsData, nextPage, queryVariable])
  
  useEffect(() => {
    setNextPage(detailsData?.nodes?.length === 15)
    setList(currentList =>
      currentList.concat(
        detailsData?.nodes?.filter(item => currentList.findIndex(n => n.id === item.id) < 0) || []
      )
    )
  }, [detailsData])

  useEffect(() => {
    setNextPage(detailsData?.nodes?.length === 15)
    setList((currentList) =>
      currentList.concat(detailsData?.nodes?.filter(
        item => currentList.findIndex(n => n.id === item.id) < 0
      ) || [])
    )
  }, [detailsData])

  const handleFilter = (filter: NotificationFilter) => {
    setFilter(filter)
    setList([])
  }

  return (
    <NotificationContext.Provider value={{
      totalData,
      loadingTotal,
      errorsTotal,
      detailsData,
      loadingDetails,
      errorDetails,
      filter,
      handleFilter,
      readSelectedNotifications,
      refetchTotal,
      refetchDetails,
      handleNextPage,
      nextPage,
      notificationList,
      limit,
      loadingMore,
      isModalOpen,
      setModalOpen
    }}>
      {children}
    </NotificationContext.Provider>
  )
}

const useNotifications = () => useContext<NotificationContextType>(NotificationContext)

export { NotificationContext, NotificationProvider, useNotifications }
