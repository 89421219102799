import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@material-ui/core'
import { EmptyState, Spacer, Title, View } from '@youse-seguradora/cargo-components'

export default function NotFoundInvoices() {
  const history = useHistory()
  const theme = useTheme()

  const redirectToIndex = () => history.push('/cotacoes')

  return (
    <>
      <View spacing={{ top: 8, bottom: 6 }}>
        <Title color={theme.colors.primary} size='small'>
          Notas Fiscais
        </Title>
      </View>
      <Spacer all={6}>
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <EmptyState
            icon='info-filled'
            description='Não há arquivos para serem exibidos.'
            buttonLabel='Voltar ao início'
            buttonOnClick={redirectToIndex}
          />
        </View>
      </Spacer>
    </>
  )
}
