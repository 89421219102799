/* eslint-disable for-direction */
export const cnpjValidator = (maskedCnpj: string): boolean => {
  const cnpj = maskedCnpj ? maskedCnpj.replace(/[^\d]+/g, '') : ''
  const KNOWN_VALUES_REGEX = /^([0-9])\1{13}$/
  const CORRECT_SIZE = 14
  const FIRST_VALIDATOR_POSITION = 12
  const isKnownCnpj = KNOWN_VALUES_REGEX.test(cnpj)
  const isEmpty = cnpj.length === 0
  const hasWrongCharCount = cnpj.length !== CORRECT_SIZE

  if (isEmpty || hasWrongCharCount || isKnownCnpj) {
    return false
  }

  // validation algorithm
  const firstValidationNumber = parseInt(
    cnpj.charAt(FIRST_VALIDATOR_POSITION),
    10,
  )
  const secondValidationNumber = parseInt(
    cnpj.charAt(FIRST_VALIDATOR_POSITION + 1),
    10,
  )

  const digitAt = (position: number) => {
    const digitsForValidation = cnpj.substring(0, position)
    let digitWeight = position - 7
    let sumOfDigits = 0
    let finalResult = 0

    for (let i = position; i >= 1; i -= 1) {
      const currentDigit = digitsForValidation.charAt(position - i)
      sumOfDigits += parseInt(currentDigit, 10) * digitWeight
      digitWeight -= 1
      if (digitWeight < 2) {
        digitWeight = 9
      }
    }

    finalResult = 11 - (sumOfDigits % 11)
    return finalResult > 9 ? 0 : finalResult
  }

  return (
    digitAt(12) === firstValidationNumber &&
    digitAt(13) === secondValidationNumber
  )
}
