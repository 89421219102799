import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import DataLayerClient from '../../../clients/DataLayerClient'
import { ResourceFlowContext } from '../../../contexts/ResourceFlowContext'
import Loader from '../../Loader'
import InsuranceNotAccepted from '../../pages/InsuranceNotAccepted'
import SomethingWrong from '../../pages/SomethingWrong'

const RETRY = 2000

type IssuanceSectionProps = {
  successPath: string
}

const IssuanceSection = ({ successPath }: IssuanceSectionProps) => {
  const history = useHistory()
  const context = useContext<any>(ResourceFlowContext)
  const [viewState, setViewState] = useState<any>('Loader')

  useEffect(() => {
    new DataLayerClient().triggerOrder(
      context?.data?.resourceId,
      context?.data?.resource?.salesChannel,
    )
    new DataLayerClient().trigger('Issuance')

    const interval = setInterval(() => {
      pooling()
    }, RETRY)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const pooling = () => {
    context.reload().then((response: any) => {
      const { data } = response

      if (data?.resource?.policy?.proposalNumber && data?.resource?.document?.publicUrl) {
        history.push(successPath, data?.resource)
        return
      }

      if (context.status === 403 && data.error === 'Resource status is "denied"') {
        setViewState('InsuranceNotAccepted')
        return
      }

      if (context.status !== 200) {
        setViewState('')
        return
      }
    })
  }

  function handleStatesView() {
    switch (viewState) {
    case 'InsuranceNotAccepted':
      return <InsuranceNotAccepted />
    case 'Loader':
      return <Loader data='Aguarde enquanto estamos emitindo sua apólice' />
    default:
      return <SomethingWrong />
    }
  }

  return handleStatesView()
}

export default IssuanceSection
