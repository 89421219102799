import { gql } from '@apollo/client'

export const GET_POLICY_TIME_LINE_EVENTS = gql`
  query PolicyTimelineEvents(
      $policyNumber: String!,
      $product: ProductsEnum!,
      $size: Int,
      $number: Int,
      $oldest: Boolean!,
      $tags: [String!]
    ) {
    policyTimelineEvents(
        number: $policyNumber,
        product: $product,
        page: {
          size: $size,
          number: $number
        },
        onlyPolicyEvents: true,
        oldest: $oldest,
        tags: $tags
      ) {
      nodes {
        title
        occurredAt
        description
        referenceId
        referenceType
        tags {
          code
          name
        }
      }
      pageInfo {
        totalCount
        totalPages
      }
    }
  }
`
