import React from 'react'
import { Column, ColumnsContainer, Container, Divider, Text, View } from '@youse-seguradora/cargo-components'

import { StatusProps } from '../../types/types'

import { useTheme } from '@material-ui/core/styles'

export default function Status({
  tag,
  title,
  buttons,
  columns,
  workshop,
  isScheduleAvailable,
}: StatusProps) {
  const theme = useTheme()

  const workshopFullAddress = () => {
    const streetWithNumber = workshop?.address?.number
      ? `${workshop?.address?.street}, ${workshop?.address?.number}`
      : workshop?.address?.street

    return `${streetWithNumber}. ${workshop?.address?.city}, ${workshop?.address?.state}`
  }

  const leftButtons = buttons?.filter((button) => button.button && button.position === 'left')
  const rightButtons = buttons?.filter((button) => button.button && button.position === 'right')
  
  return (
    <Container percentageWidth>
      <View
        style={{
          padding: '16px',
          borderRadius: '8px',
          border: '1px solid #EBEDEE',
        }}
        background={{ color: theme.backgrounds.secondary }}
      >
        <View>
          <View
            style={{ gap: '16px' }}
            layout={{ horizontal: true, alignVerticalCenter: true }}
          >
            <Text color={theme.colors.primary} weight='bold'>{title}</Text>
            {tag && (<>{tag}</>)}
          </View>

          {columns && (
            <>
              <View spacing={{ vertical: 4 }}>
                <ColumnsContainer>
                  {columns.map((column, i) => (
                    <Column key={i} size={column.size}>
                      <Text as='p' size='small' weight='regular'>
                        {column.title}
                      </Text>
                      <Text
                        as='span'
                        weight='bold'
                        color={theme.colors.primary}
                      >
                        {column.text}
                      </Text>
                    </Column>
                  ))}
                </ColumnsContainer>
              </View>

              {(!isScheduleAvailable && workshop) &&
                <View spacing={{ vertical: 4 }}>
                  <ColumnsContainer>
                    <Column size={9}>
                      <Text as='p' size='small' weight='regular'>
                        Endereço da oficina:
                      </Text>
                      <Text
                        as='span'
                        weight='bold'
                        color={theme.colors.primary}
                      >
                        {workshopFullAddress() || '-'}
                      </Text>
                    </Column>

                    <Column size={3}>
                      <Text as='p' size='small' weight='regular'>
                        Telefone da oficina:
                      </Text>
                      <Text
                        as='span'
                        weight='bold'
                        color={theme.colors.primary}
                      >
                        {workshop?.phone || '-'}
                      </Text>
                    </Column>
                  </ColumnsContainer>
                </View>
              }
            </>
          )}

          {(leftButtons || rightButtons) && (
            <>
              <Divider color={theme.divisors.standard} top={1} />
              
              <View layout={{ horizontal: true, spread: true  }} spacing={{ top: 4 }}>
                <View layout={{ horizontal: true, alignLeft: true }} style={{ gap: '16px' }}>
                  {leftButtons?.map(button => button.button)}
                </View>
                <View layout={{ horizontal: true, alignRight: true }} style={{ gap: '16px' }}>
                  {rightButtons?.map(button => button.button)}
                </View>
              </View>
            </>
          )}
        </View>
      </View>
    </Container>
  )
}
