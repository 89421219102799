/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Loading } from '@tiny-fronts/basement'
import useAuth from 'hooks/useAuth'
import useRequest from 'hooks/useRequest'

import {
  AuthenticationError,
  InteractionNotFoundError,
  LoginError,
  UnknownError,
} from 'components/Errors'

interface LoginParams {
  loginInteractionId: string
}

interface LoginResponseBody {
  consentInteractionId?: string
  consent: object
}

export default function LoginPage() {
  const { loginInteractionId } = useParams<LoginParams>()

  const [errorType, setErrorType] = useState<
    null | 'interaction_not_found' | 'auth_error' | 'unknown_error'
  >(null)

  const history = useHistory()

  const { handleLogout } = useAuth()

  const { body, error } = useRequest<LoginResponseBody>('POST', `login/${loginInteractionId}`)

  useEffect(() => {
    if (body) {
      window.localStorage.setItem(
        `consent:${body.consentInteractionId}`,
        JSON.stringify(body.consent),
      )

      history.push(`/transmissao/consentimento/${body.consentInteractionId}`)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [body, history])

  useEffect(() => {
    if (error) {
      if (error?.response?.status) {
        switch (error.response.status) {
        case 404:
          setErrorType('interaction_not_found')
          break
        case 401:
          handleLogout()
          setErrorType('auth_error')
          break
        default:
          setErrorType('unknown_error')
          break
        }
      } else {
        setErrorType('unknown_error')
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  if (error) {
    switch (errorType) {
    case 'interaction_not_found':
      return <InteractionNotFoundError />
    case 'auth_error':
      return <AuthenticationError />
    case 'unknown_error':
      return <UnknownError />
    default:
      return <LoginError />
    }
  } else {
    return <Loading />
  }
}
