import React, { useContext, useEffect, useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  Column,
  ColumnsContainer,
  Spacer,
  Text,
  Title,
  View,
} from '@youse-seguradora/cargo-components'

import BFFClient from '../../../../clients/BFFClient'
import { ResourceFlowContext } from '../../../../contexts/ResourceFlowContext'

import PaymentMethod from './PaymentMethods/PaymentMethod'
import PaymentConditionItem from './PaymentConditionItem'

import { useTheme } from '@material-ui/core/styles'

const bffClient = new BFFClient()

const PaymentConditions = ({
  onCreditCardFulfilled,
  isCreditCardValid,
  setIsCreditCardValid,
  isPaymentConditionEnabled,
  setIsPaymentConditionEnabled,
  productName
}: any) => {
  const theme = useTheme()
  const context = useContext<any>(ResourceFlowContext)

  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [paymentConditions, setPaymentConditions] = useState<any>([])
  const [selectedCondition, setSelectedCondition] = useState<any>({ paymentMethod: '' })
  const [,setSelectedPaymentMethod] = useState<any>({})

  useEffect(() => {
    bffClient
      .fetchPaymentConditions(context.data.resource.product, context.data.id)
      .then((response: any) => {
        if (response.status !== 200) {
          // Fallback to load the Order's default payment condition
          setSelectedCondition(context.data.resource.paymentCondition)
          setIsLoaded(true)
          setIsPaymentConditionEnabled(true)
          return
        }
        setPaymentConditions(response.data)

        setSelectedCondition(response.data.find((condition) => condition.selected))
        setIsPaymentConditionEnabled(true)
        setIsLoaded(true)
      })
  }, [])

  const changeSelectedCondition = (item) => {
    setIsPaymentConditionEnabled(false)
    paymentConditions.forEach((condition) => {
      if (condition.id === item.id) {
        condition.selected = true
        setSelectedCondition(condition)
        setSelectedPaymentMethod(condition.paymentMethod)
        context
          .updateResource({
            paymentCondition: {
              paymentMethod: condition.paymentMethod,
              contractDuration: condition.contractDuration,
              installmentsFrequency: condition.installmentsFrequency,
              installmentsPerYear: condition.installmentsPerYear,
            },
            pricing: condition.pricing,
          })
          .then(() => {
            setIsPaymentConditionEnabled(true)
          })
      } else {
        condition.selected = false
      }
    })
  }

  if (!isLoaded) {
    return (
      <View spacing={{ top: 6 }} layout={{ alignHorizontalCenter: true }}>
        <CircularProgress style={{ color: theme.colors.highlightInverse }} />
      </View>
    )
  }

  return (
    <>
      {paymentConditions.length > 1 && (
        <>
          <Title size='small' align='center' color={theme.colors.primary}>
            Como você quer pagar?
          </Title>

          <Spacer top={6} />

          {paymentConditions.map((item: any, index: number) => {
            return (
              <PaymentConditionItem
                key={index}
                item={item}
                dataTestId={`payment-condition-item-${index + 1}`}
                changeSelectedCondition={changeSelectedCondition}
                isSelectionDisabled={!isPaymentConditionEnabled}
                productName={productName}
              />
            )
          })}

          <Spacer top={2} />

          <ColumnsContainer>
            <Column desktopSize={8} desktopOffset={2}>
              <Text size='xsmall' align='center' color={theme.colors.secondary}>
                Não tem fidelidade. Pra conferir as regras de cancelamento, é só acessar as
                Condições Gerais do seguro.
              </Text>
            </Column>
          </ColumnsContainer>
        </>
      )}

      <Spacer top={4} />

      <PaymentMethod
        paymentMethod={selectedCondition.paymentMethod}
        onCreditCardFulfilled={onCreditCardFulfilled}
        isCreditCardValid={isCreditCardValid}
        setIsCreditCardValid={setIsCreditCardValid}
      />
    </>
  )
}

export default PaymentConditions
