import React from 'react'
import Done from '@material-ui/icons/Done'
import { Icon, Text, View } from '@youse-seguradora/cargo-components'

import { useTheme } from '@material-ui/core/styles'

const PlanItem = ({ planItem, plan }: any) => {
  const theme = useTheme()

  return (
    <View layout={{ horizontal: true }} spacing={{ bottom: 4 }}>
      {plan.items[planItem.id] ? (
        <>
          <View spacing={{ right: 2 }}>
            <Done
              style={{
                color: theme.colors.success,
              }}
            />
          </View>
          <Text size='default' color={theme.colors.secondary}>
            {planItem.name} {plan.details[planItem.id]}
          </Text>
        </>
      ) : (
        <>
          <View spacing={{ right: 2 }}>
            <Icon name='close' size={5} color={theme.backgrounds.error} />
          </View>
          <Text size='default' color={theme.colors.disable}>
            {planItem.name}
          </Text>
        </>
      )}
    </View>
  )
}

export default PlanItem
