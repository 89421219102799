import React, { useContext } from 'react'
import { Icon, Title, View } from '@youse-seguradora/cargo-components'

import { ResourceFlowContext } from '../../../../contexts/ResourceFlowContext'

import { useTheme } from '@material-ui/core/styles'

type SidebarCardHeaderProps = {
  product: string
  productName: string
}

const SidebarCardHeader = ({ product, productName }: SidebarCardHeaderProps) => {
  const theme = useTheme()
  const context = useContext<any>(ResourceFlowContext)

  return (
    <>
      <View />
      <View layout={{ horizontal: true }} spacing={{ bottom: 4 }}>
        {context?.data?.currentSection !== 'payment_condition_selection' && (
          <View spacing={{ right: 2 }} position={{ relative: true, top: '3px' }}>
            <Icon name={`icon-${product}`} size={5} />
          </View>
        )}

        <View
          position={{
            top: '7px',
            relative: true,
            desktopTop: '0',
          }}
        >
          <Title size='xsmall' color={theme.colors.textSidebar}>
            {productName}
          </Title>
        </View>
      </View>
    </>
  )
}

export default SidebarCardHeader
