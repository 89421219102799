/* eslint-disable no-unused-vars */
import React from 'react'
import ClientOAuth2 from 'client-oauth2'

// eslint-disable-next-line no-shadow
export enum Role {
  productOwner = 'product_owner',
  cxHelpAttendant = 'cx_help_attendant',
  cxHelpManager = 'cx_help_manager',
  cxClaimsAttendant = 'cx_claims_attendant',
  claimsAnalyst = 'claims_analyst',
  claimsManager = 'claims_manager',
  dataAnalyst = 'data_analyst',
  complianceAnalyst = 'compliance_analyst',
  financeAnalyst = 'finance_analyst',
  pricingAnalyst = 'pricing_analyst',
  underwritingAnalyst = 'underwriting_analyst',
  qualityEngineer = 'quality_engineer',
  softwareEngineer = 'software_engineer',
  devops = 'devops',
  friendsManager = 'friends_manager',
  telematicsManager = 'telematics_manager',
  legalAnalyst = 'legal_analyst',
  manager = 'manager',
  admin = 'admin',
  brokerAccessor = 'broker_accessor',
  financialManager = 'financial_manager',
  partnerManager = 'partner_manager',
  financeManager = 'finance_manager',
}

export default function useAuth() {
  const [token, setToken] = React.useState<string | null>(localStorage.getItem('@tokenId'))
  const [authorized, setAuthorized] = React.useState<boolean | null>(null)

  function handleLogin(newToken: string): void {
    localStorage.setItem('@tokenId', newToken)
    setToken(newToken)
  }

  function handleLogout(): void {
    setToken(null)
    localStorage.removeItem('@tokenId')
  }

  function redirectLogout(): void {
    window.location.href = `${process.env.REACT_APP_EMPLOYEE_ACCOUNT_LOGOUT}?redirect=${window.location.origin}`
    handleLogout()
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function setAuthorizedRoles(roles: Role[]): void {
    // TODO: check the roles and the logged roles
    setAuthorized(authenticated)
  }

  const oAuthClient = new ClientOAuth2({
    clientId: process.env.REACT_APP_EMPLOYEE_ACCOUNT_CLIENT_ID,
    clientSecret: process.env.REACT_APP_EMPLOYEE_ACCOUNT_CLIENT_SECRET,
    accessTokenUri: `${process.env.REACT_APP_EMPLOYEE_ACCOUNT_URL}/oauth/token`,
    authorizationUri: `${process.env.REACT_APP_EMPLOYEE_ACCOUNT_URL}/oauth/authorize`,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    scopes: ['public'],
  })

  const authenticated = !!token

  return {
    token,
    authenticated,
    authorized,
    handleLogin,
    handleLogout,
    redirectLogout,
    setAuthorizedRoles,
    oAuthClient,
  }
}
