import React from 'react'
import { View, Visible } from '@youse-seguradora/cargo-components'

import NotifyOrder from './NotifyOrder'
import SidebarCard from './SidebarCard'

import { styles } from './../styles'
import { useTheme } from '@material-ui/core/styles'

type SidebarProps = {
  productName: string
  active: boolean
  centerFooterButton?: boolean
  sidebarIcons?: React.ReactNode
  sidebarCard?: React.ReactNode
  isSidebarNotifyOrder?: boolean
  isMobileBackgroundColorAside?: boolean
}

const Sidebar = ({
  active,
  centerFooterButton,
  productName,
  sidebarIcons,
  sidebarCard,
  isSidebarNotifyOrder,
  isMobileBackgroundColorAside,
}: SidebarProps) => {
  const classes = styles({
    isActiveAside: active,
    sidebarIcons: sidebarIcons,
    centerFooterButton,
    isMobileBackgroundColorAside,
  })
  const theme = useTheme()

  return (
    <aside className={classes.aside}>
      {sidebarIcons && (
        <Visible mobile tablet>
          <View
            spacing={{ top: 4 }}
            layout={{ alignVerticalCenter: true, alignHorizontalCenter: true }}
            position={{ marginLeft: '50px', marginRight: '50px', marginBottom: '50px' }}
            background={{ color: theme.backgrounds.header }}
            style={{ borderRadius: '0 0px 8px 8px' }}
          >
            {sidebarIcons}
          </View>
        </Visible>
      )}
      {sidebarCard || <SidebarCard productName={productName} />}
      {isSidebarNotifyOrder && <NotifyOrder />}
    </aside>
  )
}

export default Sidebar
