import React from 'react'
import {
  Divider,
  InvisibleButton,
  PrimaryButton,
  Spacer,
  Text,
  Title,
  View,
} from '@youse-seguradora/cargo-components'
import styled from 'styled-components'

import { useTheme } from '@material-ui/core/styles'

type PixWarningProps = {
  setPixTutorialStep: React.Dispatch<React.SetStateAction<boolean>>
  setPixWarningStep: React.Dispatch<React.SetStateAction<boolean>>
  setPixQrCodeStep: React.Dispatch<React.SetStateAction<boolean>>
}

export const Image = styled.img`
  height: auto;
  width: 250px;
`

const PixWarning = ({
  setPixTutorialStep,
  setPixWarningStep,
  setPixQrCodeStep,
}: PixWarningProps) => {
  const theme = useTheme()

  const handleClick = () => {
    setPixWarningStep(false)
    setPixQrCodeStep(true)
  }

  const backToTutorial = () => {
    setPixWarningStep(false)
    setPixTutorialStep(true)
  }
  return (
    <>
      <View
        spacing={{ top: 4 }}
        style={{ padding: '26px' }}
        layout={{ alignHorizontalCenter: true }}
      >
        <Image src='https://www.datocms-assets.com/16903/1590782521-illus-characters-attention-04-4x3-light.png' />
        <Spacer top={3} />
        <Title size='xsmall' align='center' color={theme.colors.highlight}>
          Fique de olho
        </Title>
        <Spacer top={3} />
        <Text align='center' size='small' color={theme.colors.secondary}>
          Você precisa voltar aqui depois de realizar o pagamento pelo Pix. Vamos pedir a
          confirmação do seu e-mail e finalização da compra. Seu contrato só vai ser gerado se você
          finalizar a compra aqui após o pagamento, ok?
        </Text>
        <Spacer top={3} />
      </View>

      <Spacer top={4} />
      <Divider top={1} color={theme.divisors.standard} />
      <Spacer top={4} />

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <PrimaryButton children='Eu Entendi' onClick={handleClick} />
      </div>
      <Spacer top={1} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <InvisibleButton children='Voltar' onClick={backToTutorial} />
      </div>
      <Spacer top={4} />
    </>
  )
}

export default PixWarning
