import React, { useMemo } from 'react'
import {
  Accordion,
  Card,
  Column,
  ColumnsContainer,
  Spacer,
  Text,
  Title,
} from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import { Consent } from 'types/Consent'

import ConsentCard from 'components/ConsentCard/ConsentCard'
import FriendlyDate from 'components/FriendlyDate'

export default function ConfirmationPage({ consent }: { consent: Consent }) {
  const selectedPoliciesByProduct = useMemo(() => {
    return consent?.policies?.reduce((policies, policy) => {
      if (!policies[policy.product]) {
        policies[policy.product] = []
      }

      policies[policy.product].push(policy)

      return policies
    }, {}) || {}
  }, [consent])

  return (
    <Card
      style={{
        borderRadius: 8,
        padding: 34,
        marginTop: 16,
      }}
    >
      {
        !!consent?.policies?.length &&
        <Title
          size={'xsmall'}
          weight={'bold'}
          color={defaultTheme.colors.primary}
        >
          Origem dos dados
        </Title>
      }

      <Spacer top={4} />

      {consent?.policies?.length === 1 ? (
        <>
          <Text weight={'regular'} color={defaultTheme.colors.secondary}>
            {consent.policies[0].product}
          </Text>
          <Spacer top={2} />
          <Column>
            <Text size={'small'} weight={'regular'} color={defaultTheme.colors.secondary}>
              {consent.policies[0].summary}
            </Text>
            <Text size={'small'} weight={'regular'} color={defaultTheme.colors.secondary}>
              Contrato: {consent.policies[0].number}
            </Text>
            <Text size={'small'} weight={'regular'} color={defaultTheme.colors.secondary}>
              Vigência: <FriendlyDate date={consent.policies[0].effective_until} />
            </Text>
          </Column>
        </>
      ) : (
        Object.keys(selectedPoliciesByProduct).map((product) => {
          const policies = selectedPoliciesByProduct[product]

          return (
            <Accordion
              key={product}
              items={[
                {
                  title: `${policies.length} contratos do ${product}`,
                  children: (
                    <ColumnsContainer
                      verticalSpacing={4}
                      style={{ paddingLeft: -16, marginLeft: -16 }}
                    >
                      {policies.map((policy, index) => (
                        <Column key={index}>
                          <Text
                            size={'small'}
                            weight={'regular'}
                            color={defaultTheme.colors.secondary}
                          >
                            {policy.summary}
                          </Text>
                          <Text
                            size={'small'}
                            weight={'regular'}
                            color={defaultTheme.colors.secondary}
                          >
                            Contrato: {policy.number}
                          </Text>
                          <Text
                            size={'small'}
                            weight={'regular'}
                            color={defaultTheme.colors.secondary}
                          >
                            Vigência: <FriendlyDate date={policy.effective_until} />
                          </Text>
                        </Column>
                      ))}
                    </ColumnsContainer>
                  ),
                  dataTestId: 'originData',
                },
              ]}
            />
          )
        })
      )}

      <Spacer top={6} />

      <ConsentCard consent={consent} />
    </Card>
  )
}
