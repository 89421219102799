import React from 'react'
import { useHistory } from 'react-router-dom'
import * as Cargo from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import * as Styles from 'styles/Global'

import { useTheme } from '@material-ui/core/styles'

export const UnknownError = () => {
  const { colors } = useTheme()

  const history = useHistory()

  const redirect = () => {
    history.push('/')
  }

  return (
    <>

      <Cargo.Container>
        <Cargo.View
          layout={{ alignHorizontalCenter: true, alignVerticalCenter: true }}
          style={Styles.CargoView}
        >

          <Styles.Figure height='200px'>
            <Cargo.Image
              src='https://www.datocms-assets.com/16903/1590783190-illus-characters-error-04-4x3-light.png'
              alt={''}
            />
          </Styles.Figure>

          <Cargo.View style={{ maxWidth: '472px' }}>
            <Cargo.Title
              size={'default'}
              weight={'bold'}
              align={'left'}
              color={defaultTheme.colors.primary}
            >
              Algo inesperado aconteceu em nosso sistema
            </Cargo.Title>
            <Cargo.Text
              size={'default'}
              align={'left'}
              weight={'regular'}
              color={defaultTheme.colors.primary}
            >
              A gente não queria que você tivesse essa experiência. Já estamos trabalhando para resolver, ok? Tente de novo em alguns minutinhos.
            </Cargo.Text>
          </Cargo.View>

        </Cargo.View>
      </Cargo.Container>

      <Cargo.View
        as='div'
        background={{ color: colors.textSidebar }}
        style={{
          boxShadow: '0px 2px 16px rgba(47, 57, 65, 0.16)',
          bottom: 0,
          padding: '25px 0px'
        }}
        size={{ width: '100%' }}
        position={{ absolute: true }}
      >
        <Cargo.Container>
          <Cargo.View
            style={{ flexDirection: 'row', justifyContent: 'center' }}
            spacing={{ left: 0, desktopLeft: 20, right: 0, desktopRight: 20 }}
          >
            <Cargo.PrimaryButton full onClick={redirect}>
              OK, ENTENDI
            </Cargo.PrimaryButton>
          </Cargo.View>
        </Cargo.Container>
      </Cargo.View>
    </>
  )
}
