import { decamelizeKeys, undefinedResolver } from '../helpers/resolvers'
import { DataLayerProps } from '../types/types'

export default function useDataLayer() {
  const setDataLayer = ({ event, params }: DataLayerProps) => {
    if (typeof window === 'undefined') {
      return
    }

    const payload = {
      event: event.toLowerCase(),
      params: params,
    }

    window.dataLayer && window.dataLayer.push(undefinedResolver(decamelizeKeys((payload))))
  }

  return { useDataLayer, setDataLayer }
}
