import React, { useState } from 'react'
import {
  Column,
  ColumnsContainer,
  InvisibleButton,
  Spacer,
  Text,
  View } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import { desktopContent, mobileContent } from 'styles/ContentStyle'
import { isMobile } from 'utils/IsMobile'

import { DataDetail } from 'components/Modal/DataDetail'
import { RequestSummary } from 'components/RequestSummary'

export const RequiredSharedData = ({ form, permissions }) => {
  const [openRegistrationData, setOpenRegistrationData] = useState(false)
  const [openAdditionalInformation, setOpenAdditionalInformation] = useState(false)

  return (
    <>
      <View
        style={isMobile() ? mobileContent : desktopContent}
      >
        <ColumnsContainer>
          {window.innerWidth < 900 &&
            <Column tabletSize={6}>
              <RequestSummary segment={form.segment} brand={form.brand.CustomerFriendlyName} />

              <Spacer top={4} />
            </Column>
          }

          <Column tabletSize={6}>
            <View layout={{ horizontal: false }}>
              <Text size={'default'} weight={'bold'} color={defaultTheme.colors.primary}>
                Dados cadastrais
              </Text>

              <Spacer top={2} />

              <Text size={'small'} weight={'regular'} color={defaultTheme.colors.secondary}>
                CPF, nome completo, tipo de pessoa, função da pessoa perante a apólice entre outros dados
              </Text>

              <Spacer top={2} />

              <InvisibleButton contextual onClick={() => setOpenRegistrationData(true)} rightIcon='chevron-right'>
                Exibir lista completa
              </InvisibleButton>
            </View>

            <View layout={{ horizontal: false }}>
              <Text size={'default'} weight={'bold'} color={defaultTheme.colors.primary}>
                Informações complementares
              </Text>

              <Spacer top={2} />

              <Text size={'small'} weight={'regular'} color={defaultTheme.colors.secondary}>
                CPF, endereço, número de documentos, entre outros dados
              </Text>

              <Spacer top={2} />

              <InvisibleButton contextual onClick={() => setOpenAdditionalInformation(true)} rightIcon='chevron-right'>
                Exibir lista completa
              </InvisibleButton>
            </View>
          </Column>

          {window.innerWidth > 900 &&
            <Column tabletSize={6}>
              <RequestSummary segment={form.segment} brand={form.brand.CustomerFriendlyName} />
            </Column>
          }
        </ColumnsContainer>
      </View>

      <DataDetail
        modalData={permissions?.permissions_sections.find((item) => item.title === 'Dados cadastrais')}
        openDataDetail={openRegistrationData}
        setOpenDataDetail={setOpenRegistrationData}
      />

      <DataDetail
        modalData={permissions?.permissions_sections.find((item) => item.title === 'Dados complementares')}
        openDataDetail={openAdditionalInformation}
        setOpenDataDetail={setOpenAdditionalInformation}
      />
    </>
  )
}
