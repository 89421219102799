import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  ButtonContainer,
  Collapsable,
  Icon,
  Image,
  Title,
  View,
} from '@youse-seguradora/cargo-components'

import DataLayerClient from '../../../clients/DataLayerClient'
import AvatarAttention from '../../images/ops-light.svg'

import { useTheme } from '@material-ui/core/styles'

const InsuranceNotAccepted = () => {
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const location = useLocation()

  useEffect(() => {
    new DataLayerClient().trigger('Seu seguro não foi aceito')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <View
        size={{ minHeight: '100vw' }}
        style={{ background: theme.backgrounds.gradient, overflow: 'scroll' }}
        spacing={{ vertical: 6 }}
        layout={{ desktopHorizontal: true }}
      >
        <View
          spacing={{
            desktopLeft: 21,
            desktopTop: 14,
            desktopRight: 0,
            left: 6,
            top: 8,
            right: 6,
          }}
          size={{ desktopWidth: '600px', width: 'auto' }}
        >
          <View spacing={{ desktopBottom: 6, bottom: 5 }}>
            <Title size='big' color={theme.colors.highlightInverse}>
              Ops!
            </Title>
          </View>
          <Title size='big' color={theme.colors.primaryInverse}>
            Seu seguro não foi aceito :(
          </Title>
          <View spacing={{ top: 5, desktopTop: 6, bottom: 4 }}>
            <ButtonContainer
              borderColor={theme.backgrounds.highlight}
              onClick={() => setIsOpen(!isOpen)}
              height
            >
              <Title size='small' weight='light' color={theme.colors.primaryInverse}>
                Entenda o que pode ter acontecido aqui
                <View
                  inline
                  as='span'
                  position={{ relative: true, top: '6px', desktopLeft: '5px' }}
                >
                  <Icon
                    name={isOpen ? 'expand-less' : 'expand-more'}
                    color={theme.colors.primaryInverse}
                    size={5}
                  />
                </View>
              </Title>
            </ButtonContainer>
          </View>
          <Collapsable
            isOpen={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            maxHeight={50}
          >
            <View spacing={{ bottom: 6, desktopBottom: 0 }}>
              <View spacing={{ bottom: 2 }}>
                <Title size='xsmall' weight='light' color={theme.colors.primaryInverse}>
                  Devido à política de aceitação da Caixa Seguradora S/A, não podemos seguir com a
                  contratação do seu seguro.
                </Title>
              </View>
              <View spacing={{ bottom: 2 }}>
                <Title size='xsmall' weight='light' color={theme.colors.primaryInverse}>
                  A gente leva em conta critérios que estão de acordo com a legislação e as regras
                  da SUSEP, nosso órgão regulador. Nenhum deles é pessoal, ok?
                </Title>
              </View>
              <Title size='xsmall' weight='light' color={theme.colors.primaryInverse}>
                Fique ligado: estamos sempre atualizando esses critérios pra oferecer um seguro cada
                vez mais do seu jeito. A gente espera ter você por aqui também em breve! ;)
              </Title>
            </View>
          </Collapsable>
        </View>

        <View
          layout={{ alignRight: true }}
          position={{
            desktopAbsolute: true,
            desktopRight: '100px',
          }}
          spacing={{ all: 5 }}
        >
          <View size={{ maxWidth: '320px' }}>
            <Image
              alt='avatar sinalizando atenção'
              src={AvatarAttention}
              height={80}
              desktopHeight={100}
            />
          </View>
        </View>
      </View>
    </>
  )
}

export default InsuranceNotAccepted
