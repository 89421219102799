import React from 'react'
import { useHistory } from 'react-router-dom'
import { AuthContext, Loading,useLocalStorage } from '@tiny-fronts/basement'
import {
  InvisibleButtonLink,
  Spacer,
  Title,
  View } from '@youse-seguradora/cargo-components'

export default function Login() {
  const { authenticated, oAuthClient } = React.useContext<any>(AuthContext)
  const history = useHistory()
  const [currentLocation,] = useLocalStorage('current_location')

  React.useEffect(() => {
    if (authenticated && currentLocation) {
      history.push(currentLocation)
    } else {
      window.location = oAuthClient.token.getUri()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, history])

  return (
    <View
      layout={{ alignHorizontalCenter: true }}
      size={{ minHeight: '100vw' }}
      spacing={{ all: 8 }}
    >
      <Spacer bottom={8} />
      <Loading />
      <Spacer bottom={8} />
      <Title weight='regular' size='xsmall'>
        Caso não seja redirecionado em alguns segundos {' '}
        <InvisibleButtonLink target='_self' css='' href={oAuthClient.token.getUri()}>
          Clique aqui
        </InvisibleButtonLink>.
      </Title>
    </View>
  )
}
