export const mobileContent = {
  marginTop: 16, 
  marginBottom: 40,
}

export const desktopContent = {
  borderRadius: 8,
  padding: 24,
  marginTop: 16, 
  marginBottom: 40,
  border: '1px solid #EBEDEE',
  background: '#FFFFFF'
}
