import React, { Dispatch, SetStateAction } from 'react'
import {
  Modal,
  SecondaryButton,
  Text,
  Title,
  TrackingContainer,
  TrackingContext,
  View,
} from '@youse-seguradora/cargo-components'

import { useTheme } from '@material-ui/core/styles'

interface ModalPlanSelectionNotAllowedProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  contextNavigation: any
  title: string
  description: string
  buttonText: string
}

const ModalPlanSelectionNotAllowed = ({
  isOpen,
  setIsOpen,
  title,
  description,
  buttonText,
  contextNavigation,
}: ModalPlanSelectionNotAllowedProps) => {
  const theme = useTheme()
  const data = {
    content: (
      <>
        <Title align='center' size='xsmall' color={theme.colors.highlight}>
          {title}
        </Title>
        <View spacing={{ top: 4 }} />
        <Text color={theme.colors.secondary}>{description}</Text>
      </>
    ),
    footer: (
      <TrackingContext category='route-change'>
        <TrackingContainer action='info' label='voltar'>
          <SecondaryButton onClick={contextNavigation}>{buttonText}</SecondaryButton>
        </TrackingContainer>
      </TrackingContext>
    ),
  }

  return <Modal {...data} title='' isOpen={isOpen} onClose={() => setIsOpen(false)} />
}

export default ModalPlanSelectionNotAllowed
