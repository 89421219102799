import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '60px',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      paddingTop: '0',
    },
  },
  mainContent: {
    [theme.breakpoints.up('md')]: {
      width: '66.5%',
    },
  },
  aside: {
    order: -1,
    zIndex: 100,
    position: 'fixed',
    bottom: 0,
    top: 0,
    right: 0,
    left: 0,
    overflow: 'hidden',
    transition: 'all 0.4s cubic-bezier(0, 0, 1, 1)',

    [theme.breakpoints.down('sm')]: {
      background: (props: any) =>
        props.isMobileBackgroundColorAside ? theme.backgrounds.header : theme.backgrounds.focus,
      maxHeight: (props: any) => (props.isActiveAside ? '100%' : '0'),
      padding: (props: any) =>
        props.sidebarIcons
          ? props.isActiveAside
            ? '0 15px'
            : '0'
          : props.isActiveAside
          ? '60px 15px'
          : '0',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      minHeight: '100vh',
      overflowY: 'scroll',
      order: 0,
      width: '33.5%',
      bottom: 0,
      left: '66.5%',
      transition: 'none',
      padding: '20px',
      borderLeft: 'solid 1px #dbdbdb',
      background: theme.backgrounds.focus,
    },
  },
  triggerAside: {
    color: theme.colors.expandIcon,
    background: 0,
    border: 0,
    zIndex: 1000,
    position: 'fixed',
    right: '12px',
    top: '17px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  footerButton: {
    width: 'auto',
    padding: '13px 15px',
    position: 'fixed',
    display: 'flex',
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 10,
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: 'solid 1px #dbdbdb',
    margin: 0,
    backgroundColor: theme.backgrounds.secondary,
    flexDirection: (props: any) => (props.backButton ? 'column' : 'row-reverse'),
    height: (props: any) => (props.backButton ? '140px' : '95px'),

    [theme.breakpoints.up('md')]: {
      width: '66.5%',
      padding: '0 80px',
      justifyContent: (props: any) => (props.centerFooterButton ? 'center' : 'space-between'),
      flexDirection: (props: any) => props.backButton && 'row-reverse',
      height: (props: any) => props.backButton && '95px',
    },
  },
}))

export { styles }
