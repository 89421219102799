import { Theme } from '@material-ui/core'

import { PARTNERS_WITH_BROKER_ALLOWED } from './constants'
import { CompanyType } from './enums'

export const compareTranslation = (obj: {}, parameter: string) =>
  Object.entries(obj)
    .map(([key, value]) => parameter === key && value)
    .find((element) => element !== false) || ('-')

export const statusEndorsementColor = (value: string, theme: Theme) =>
  value === 'FINISHED'
    ? theme.backgrounds.success
    : value === 'PENDING' || value === 'APPROVED'
    ? theme.backgrounds.warning
    : value === 'DRAFT'
    ? theme.backgrounds.disable
    : value === 'EXPIRED'
    ? theme.colors.highlightInverse
    : theme.backgrounds.error

export const conditionBoolean = (attr) => {
  return typeof attr === 'boolean' ? attr === true : attr === 'true'
}

export const statusClaim = (value: string, theme: Theme) =>
  value === 'open' ||
  value === 'reopen' ||
  value === 'accept' ||
  value === 'settled' ||
  value === 'registered'
    ? theme.backgrounds.success
    : value === 'validation_pending'
    ? theme.backgrounds.warning
    : theme.backgrounds.error

export const claimAddress = (claim) => {
  if (claim?.occurrence?.address?.street && claim?.occurrence?.address?.number) {
    if (claim?.occurrence?.address?.complement) {
      return `${claim?.occurrence?.address?.street} - ${claim?.occurrence?.address?.number}/ ${claim?.occurrence?.address?.complement}`
    } else {
      return `${claim?.occurrence?.address?.street} - ${claim?.occurrence?.address?.number}`
    }
  }
  return '-'
}

export const formatAddress = (text: string) => {
  const words = text?.trim()?.split(/\s+/)

  return words
    ?.map((word: string) => {
      const lowerCaseWord = word?.toLowerCase()
      return lowerCaseWord[0]?.toUpperCase() + lowerCaseWord?.substring(1)
    })
    .join(' ')
}

export const numberToCurrency = (number: string | number) => {
  const parsedNumber = typeof number === 'string' ? parseFloat(number) : number
  return `R$ ${parsedNumber.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
}

export const hasBrokerAllowed = (partnerType: CompanyType) => (
  PARTNERS_WITH_BROKER_ALLOWED.includes(partnerType)
)

export const toBase64 = (file: File) => new Promise<string>((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result as string)
  reader.onerror = reject
})
