/* eslint-disable max-len */
import React from 'react'
import { Column, ColumnsContainer, Link, Text } from '@youse-seguradora/cargo-components'

import { useTheme } from '@material-ui/core/styles'

type AcceptTermsConditions = {
  productName?: string
  resource?: any
}

const AcceptTermsConditions = ({ resource, productName }: AcceptTermsConditions) => {
  const theme = useTheme()

  return (
    <ColumnsContainer>
      <Column desktopSize={6} desktopOffset={3}>
        <Text size='xsmall' align='center' color={theme.colors.secondary}>
          Ao finalizar a compra, declaro que li e concordo com as informações contidas no link{' '}
          <Link
            data-testid='terms-and-conditions-link'
            targetBlank
            href={resource.termsAndConditions}
            title={`Condições Gerais do ${productName}`}
          >
            Condições Gerais do {productName}
          </Link>{' '}
          e com o{' '}
          <Link
            data-testid='assistance-manual-link'
            targetBlank
            href='https://cdn.youse.com.br/docs/manual-assistencia.pdf?_ga=2.35411940.439365078.1605278508-228990851.1580398304&_gac=1.149537476.1605020293.CjwKCAiAkan9BRAqEiwAP9X6Uf1MQx6jQMPy0FzHd4mM0d6r5cDWV7loga0jKanm_5j54Ic0jxGWtxoCCbYQAvD_BwE&_gl=1*a0bdce*_gcl_aw*R0NMLjE2MDUwMjAyOTQuQ2p3S0NBaUFrYW45QlJBcUVpd0FQOVg2VWYxTVF4NmpRTVB5MEZ6SGQ0bU0wZDZyNWNEV1Y3bG9nYTBqS2FubV81ajU0SWMwanhHV3R4b0NDYllRQXZEX0J3RQ'
            title='Manual de Assistências'
          >
            Manual de Assistências
          </Link>
          , e confirmo que todas informações fornecidas são verdadeiras.
        </Text>
      </Column>
    </ColumnsContainer>
  )
}

export default AcceptTermsConditions
