export const FAQ_DATA_HOME = [
  {
    title: 'O que é o Open Insurance?',
    data: [
      'O Open Insurance, ou Sistema de Seguros Aberto, é a possibilidade de consumidores de ' +
      'produtos e serviços de seguros, previdência complementar aberta e capitalização permitirem o ' +
      'compartilhamento de suas informações entre diferentes Seguradoras, de forma segura, ' +
      'ágil, precisa e conveniente.',
      'Para entregar esses benefícios ao consumidor, o Open Insurance padroniza o compartilhamento ' +
      'de dados e serviços por meio de abertura e integração de sistemas, com privacidade e alto ' + 
      'nível de segurança.'
    ]
  },
  {
    title: 'Como vai funcionar o Open Insurance?',
    data: [
      'O Open Insurance será implementado de forma gradual. A primeira fase iniciou em 15 de ' +
      'dezembro de 2021 e foi concluída em 30 de junho de 2022 com um caráter mais burocrático, ' +
      'contemplando o compartilhamento de dados públicos das empresas referentes a produtos, ' +
      'canais de atendimento, rede referenciada e intermediários.',
      'Na segunda fase, que começou em 1º de setembro de 2022, os clientes já podiam compartilhar ' +
      'seus dados pessoais e de movimentação dos seus contratos a partir de seu consentimento. ' +
      'Já a terceira fase, que prevê a iniciação de serviços por meio do Open Insurance, iniciou ' +
      'em 1º de dezembro de 2022.'
    ]
  },
  {
    title: 'É seguro compartilhar meus dados pelo Open Insurance?',
    data: [
      'Sim! O que torna o Open Insurance um sistema mais ágil e seguro para o mercado são: suas ' +
      'regras e rigorosos requisitos que garantem a autenticidade, segurança e sigilo das ' +
      'informações compartilhadas. Além disso, somente as instituições supervisionadas pela ' +
      'SUSEP estão autorizadas a participar.'
    ]
  },
  {
    title: 'O Open Insurance está de acordo com a LGPD?',
    data: [
      'Sim! O Open Insurance segue todas as orientações da Lei Geral de Proteção de Dados.'
    ]
  },
  {
    title: 'O compartilhamento de dados no Open Insurance é automático?',
    data: [
      'Não! É preciso que você dê o seu consentimento primeiro.',
      'Sempre que for necessário o compartilhamento dos seus dados em alguma das instituições ' +
      'participantes, você será questionado se deseja ou não compartilhar.',
      'Após confirmar o desejo de compartilhar, você será redirecionado para a instituição ' +
      'portadora dos dados para autenticar e confirmar esta solicitação.',
      'Seus dados serão compartilhados somente após esse processo.'
    ]
  },
  {
    title: 'Como e por onde autorizo o compartilhamento dos meus dados no Open Insurance?',
    data: [
      'Todo processo será realizado apenas nos canais digitais das instituições, com toda ' +
      'segurança e comodidade para você.',
      '- Primeiro, você precisa se identificar (com login e senha, por exemplo) em um ambiente ' +
      'digital (como um App) da instituição que você quer que tenha acesso aos seus dados.',
      '- Depois, você será redirecionado para a instituição que possui seus dados para confirmar ' +
      'e autorizar o compartilhamento.',
      '- Por fim, você retorna para o ambiente onde solicitou o compartilhamento e só assim ' +
      'seus dados serão informados para a outra instituição.'
    ]
  }
]
