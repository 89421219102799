/* eslint-disable react/jsx-key */
import React, { useContext, useEffect, useState } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import {
  Accordion,
  Card,
  Container,
  InvisibleButton,
  Message,
  MessageType,
  PrimaryButton,
  Spacer,
  Switch,
  Text,
  Title,
  View,
} from '@youse-seguradora/cargo-components'

import BFFClient from '../../../clients/BFFClient'
import { ResourceFlowContext } from '../../../contexts/ResourceFlowContext'
import SeoInterface from '../../../interfaces/SeoInterface'
import LayoutBaseWithSidebar from '../../LayoutBaseWithSidebar'
import Loader from '../../Loader'
import { inputStyles } from '../../styles/inputStyles'

import { useTheme } from '@material-ui/core/styles'

const bffClient = new BFFClient()

const AssistancesSelectionSection = (props: any) => {
  const classes = inputStyles()

  const { productName, header } = props

  const theme = useTheme()
  const context = useContext<any>(ResourceFlowContext)
  const product = context.data.resource.product
  const glassCategory = context.data.resource.glassCategory
  const subproduct = context.data.resource.subproduct

  const [firstRender, setFirstRender] = useState<boolean>(true)
  const [interactionEnabled, setInteractionEnabled] = useState<boolean>(false)
  const [hasPricing, setHasPricing] = useState<boolean>(
    context.data.resource.pricing.monthlyTotalCost !== null,
  )

  const [assistances, setAssistances] = useState<any>([])
  const [assistanceList, setAssistanceList] = useState<any>([])
  const [assistanceSelectedList, setAssistanceSelectedList] = useState<any>({})
  const [selectedAssistancesContext, setSelectedAssistancesContext] = useState<any>([])

  const seo: SeoInterface = {
    title: `${productName} | Youse`,
  }

  useEffect(() => setFirstRender(false), [])

  useEffect(() => {
    if (!firstRender) return
    bffClient.fetchAssistances(product, subproduct, glassCategory).then((response: any) => {
      if (response.status !== 200) return

      setAssistanceList(response.data)
      setAssistances(response.data)

      response.data.forEach((assistance) => {
        setAssistanceSelectedList((prevItemSelected) => ({
          ...prevItemSelected,
          [assistance.id]: assistance,
        }))
      })

      if (context.data.resource.assistances.length === 0) {
        updateResource(response.data)
      } else {
        const assistancesSelected: any[] = []
        response.data.forEach((assistance) => {
          assistancesSelected.push(assistance)

          if (
            context.data.resource.assistances
              .map((assistancesIds) => assistancesIds.assistanceId)
              .includes(assistance.id)
          ) {
            Object.assign(assistance, { selected: true })
          } else {
            Object.assign(assistance, { selected: false })
          }
        })
        setAssistances(assistancesSelected)
        if (selectedAssistancesContext.length === 0) {
          updateResource(assistancesSelected)
        }
      }
      setInteractionEnabled(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateAssistanceSelection = (updatedAssistance) => {
    const updatedAssistanceList: any[] = []

    assistances.forEach((assistance) => {
      if (assistance === updatedAssistance) {
        assistance.selected = !assistance.selected
      }

      if (
        assistance.excludesOn?.length > 0 &&
        assistance.excludesOn.includes(updatedAssistance.id)
      ) {
        assistanceSelectedList[assistance.id].selected = false
      }

      updatedAssistanceList.push(assistance)
    })

    // Deselects dependent assistances that are not available
    const updatedSelectAssistanceList: any[] = []

    assistanceList.forEach((assistance) => {
      if (disabledAssistance(assistance)) {
        assistance.selected = false
      }

      updatedSelectAssistanceList.push(assistance)
    })

    setAssistances(updatedAssistanceList)
    updateResource(updatedSelectAssistanceList)
  }

  const handleChangeOption = (serviceOptionId, updatedAssistance) => {
    const updatedAssistanceList: any[] = []
    assistances.forEach((assistance) => {
      if (assistance === updatedAssistance) {
        const serviceWithOptions = assistance.services
          .filter((service) => service.options.length > 0)
          .shift()

        serviceWithOptions.options.map((option) =>
          option.id === serviceOptionId
            ? Object.assign(option, { selected: true })
            : Object.assign(option, { selected: false }),
        )
      }
      updatedAssistanceList.push(assistance)
    })

    setAssistances(updatedAssistanceList)
    updateResource(updatedAssistanceList)
  }

  const updateResource = (updatedAssistanceList) => {
    const selectedAssistances: any[] = []
    updatedAssistanceList.forEach((assistance) => {
      if (assistance.selected) {
        const hasOptions = assistance.services
          .map((service) => (service.options.length > 0 ? true : false))
          .includes(true)

        if (hasOptions) {
          const serviceId = assistance.services
            .filter((service) => service.options.length > 0)
            .shift().id

          const serviceOptionId = assistance.services
            .filter((service) => service.options.length > 0)
            .shift()
            .options.filter((option) => option.selected)
            .shift().id

          selectedAssistances.push({
            assistance_id: assistance.id,
            uid: assistance.uid,
            name: assistance.name,
            service_id: serviceId,
            service_option_id: serviceOptionId,
            description: assistance.description.afterSale,
          })
        } else {
          selectedAssistances.push({
            assistance_id: assistance.id,
            uid: assistance.uid,
            name: assistance.name,
            description: assistance.description.afterSale,
          })
        }
      }
    })

    setInteractionEnabled(false)
    setSelectedAssistancesContext(selectedAssistances)

    context.updateResource({ assistances: selectedAssistances }).then(() => {
      setHasPricing(true)
      setInteractionEnabled(true)
    })
  }

  const selectedAssistances = (assistanceList) => {
    const selectedAssistanceList: any[] = []

    assistanceList.forEach((assistance) => {
      if (assistance.selected) {
        selectedAssistanceList.push(assistance.id)
      }
    })

    return selectedAssistanceList
  }

  const disabledAssistance = (assistance) => {
    // Assistance is disabled when no assistance it depends on is selected
    if (assistance.dependsOn?.length > 0 && !selectedAssistances(assistances).some(selectedAssistance=> assistance.dependsOn.includes(selectedAssistance))) {
      return true
    }

    return false
  }

  if (!hasPricing || assistances.length === 0) {
    return <Loader data='Carregando...' />
  }

  const renderAssistancesDetails = (
    description,
    options,
    warning,
    selectedAssistance,
    updatedAssistance,
  ) => {
    const labelOptions: any[] = []
    if (options.length > 0) {
      options.map((option) => {
        labelOptions.push({
          id: option.id,
          name: option.name,
          defaultChecked: option.selected,
        })
      })
    }

    const defaultValue = labelOptions
      .filter((option) => option.defaultChecked)
      .map((option) => option.id)
      .shift()

    return (
      <>
        <Text size='default'>{description}</Text>
        <View spacing={{ top: 2 }} />
        {warning && (
          <Message type={MessageType.warning} title='' description={warning} icon='info' />
        )}
        <View spacing={{ top: 5 }} />
        <RadioGroup
          row={labelOptions.length > 4 ? false : true}
          defaultValue={defaultValue}
          onChange={(e) =>
            handleChangeOption((e.target as HTMLInputElement).value, updatedAssistance)
          }
        >
          {labelOptions.map((option) => (
            <FormControlLabel
              value={option.id}
              disabled={!selectedAssistance}
              control={
                <Radio
                  inputProps={{
                    // @ts-ignore
                    'data-testid': `assistance-option-${option.id}-radio-input`,
                  }}
                  classes={{
                    root: classes.radio,
                    checked: classes.checked,
                  }}
                />
              }
              label={option.name}
            />
          ))}
        </RadioGroup>
      </>
    )
  }
  return (
    <LayoutBaseWithSidebar seo={seo} productName={productName}>
      <View
        spacing={{
          bottom: 6,
          right: 4,
          left: 4,
        }}
      >
        {header}
      </View>
      <Container>
        <Title size='small'>
          Use as assistências do {productName} quando precisar. Elas não afetam a franquia! :)
        </Title>
        <Spacer bottom={10} />
        {assistances.map((assistance) => (
          <View key={`assistance_${assistance.id}`}>
            <Card>
              <View
                spacing={{
                  top: 5,
                  bottom: 5,
                  right: 5,
                  left: 5,
                }}
              >
                <View
                  as='div'
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    flexDirection: 'row',
                  }}
                >
                  <Title align='left' size='xsmall'>
                    {assistance.name}
                  </Title>
                  {!assistance.immutable && (
                    <Switch
                      dataTestId={`assistance-${assistance.id}-switch`}
                      checked={assistance.selected}
                      disabled={disabledAssistance(assistance) || !interactionEnabled || assistance.immutable}
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      onChange={(e) => updateAssistanceSelection(assistance)}
                      key={`assistance_${assistance.id}`}
                    />
                  )}
                </View>

                <Text align='left' color={theme.colors.secondary}>
                  {assistance.description.selected}
                </Text>
                <Spacer all={2} />

                <Text
                  align='left'
                  color={theme.colors.highlight}
                  markdown={assistance.warning?.description}
                />

                <Spacer all={2} />

                {assistance.services.length > 0 && (
                  <>
                    <Title align='left' size='xsmall' color={theme.colors.secondary}>
                      Serviços inclusos
                    </Title>
                  </>
                )}
                {assistance?.services.map((service) => (
                  <Accordion
                    items={[
                      {
                        title: service.name ? service.name : 'Mostrar detalhes da assistência',
                        children: renderAssistancesDetails(
                          service.description.selected,
                          service.options,
                          service.warning?.description,
                          assistance.selected,
                          assistance,
                        ),
                        dataTestId: `assistance-${assistance.id}-service-${service.id}`,
                      },
                    ]}
                  />
                ))}
                {assistance.deductibles.length > 0 && (
                  <>
                    <View spacing={{ top: 5 }} />
                    <Text align='left' weight='bold'>
                      Valor de cada franquia
                    </Text>
                    <View spacing={{ top: 3 }} />
                    {assistance.deductibles.map((deductible) => (
                      <View spacing={{ bottom: 3 }}>
                        <Text align='left' color={theme.colors.secondary} size='small'>
                          {deductible.name}:{' '}
                          {`R$ ${parseFloat(deductible.price).toFixed(2).replace('.', ',')}`}
                        </Text>
                      </View>
                    ))}
                  </>
                )}
              </View>
            </Card>
            <Spacer all={2} />
          </View>
        ))}
        <Spacer top={8} />
        <View layout={{ alignHorizontalCenter: true }}>
          <PrimaryButton
            data-testid='assistances-selection-submit-button'
            onClick={() => context.submit({ assistances: selectedAssistancesContext })}
          >
            Continuar
          </PrimaryButton>
          <Spacer top={3} />
          <InvisibleButton
            data-testid='assistances-selection-back-button'
            onClick={context.navigateBack}
          >
            Voltar
          </InvisibleButton>
        </View>
      </Container>
    </LayoutBaseWithSidebar>
  )
}

export default AssistancesSelectionSection
