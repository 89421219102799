import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Card,
  InvisibleButton,
  Tag,
  Text,
  View } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import { format, parseISO } from 'date-fns'
import { statusStrategy } from 'utils/StatusStrategy'

export const ConsentCardDesktop = ({ consent, tab }) => {
  const history = useHistory()

  return (
    <Card
      style={{
        borderRadius: 8,
        padding: 24,
        marginTop: 24,
      }}
    >
      <View
        layout={{ alignVerticalCenter: true, desktopHorizontal: true }}
        style={{ gap: '32px' }}
      >
        <View
          layout={{ alignVerticalCenter: true, horizontal: true }}
          style={{ minWidth: '240px' }}
        >
          <Text size={'big'} weight={'bold'} color={defaultTheme.colors.primary}>
            {consent.organizationName}
          </Text>
        </View>

        <View
          layout={{ alignVerticalCenter: true, horizontal: true }}
          style={{ justifyContent: 'space-between', width: '100%' }}
        >
          <Tag
            name={consent.status}
            backgroundColor={defaultTheme.backgrounds.disable}
            color={statusStrategy(consent.status)}
            isUppercase={false}
          />

          <View layout={{ alignVerticalCenter: true }}>
            <Text size={'small'} weight={'regular'} color={defaultTheme.colors.secondary}>
              Início
            </Text>

            <Text size={'default'} weight={'regular'} color={defaultTheme.colors.secondary}>
              {format(parseISO(consent.creationDateTime), 'dd/MM/yyyy',)}
            </Text>
          </View>

          <View layout={{ alignVerticalCenter: true }}>
            <Text size={'small'} weight={'regular'} color={defaultTheme.colors.secondary}>
              Encerramento
            </Text>

            <Text size={'default'} weight={'regular'} color={defaultTheme.colors.secondary}>
              {format(parseISO(consent.expirationDateTime), 'dd/MM/yyyy',)}
            </Text>
          </View>

          <InvisibleButton
            children='Exibir detalhes'
            rightIcon='chevron-right'
            contextual
            onClick={() => history.push(`/gestao/consentimento-detalhes/${tab.toLowerCase()}/${consent.consentId}`)}
          />
        </View>
      </View>
    </Card>
  )
}
