import React from 'react'
import { FormControl, FormSelect } from '@tiny-fronts/resource-flow-components'
import { Message as CargoMessage, MessageType, View } from '@youse-seguradora/cargo-components'

type CarDetailsProps = {
  vehicle: {
    vin: string
    licensePlate: string
    fipeCode?: string | undefined
    usage?: string | undefined
    ownershipStatus?: string | undefined
    bulletProof?: boolean | undefined
    brandNew?: boolean | undefined
    address: {
      zipCode?: string
    }
    make?: string
    model?: string
    year?: string
    version?: string
  }
  vehicleMakes?: { value: string; text: string }[]
  vehicleModels?: { value: string; text: string }[]
  vehicleYears?: { value: string; text: string }[]
  vehicleVersions?: { value: string; text: string }[]
  errors?: string
  classes?: any
  onChange?: any
}

const CarDetails = ({
  vehicleMakes,
  vehicleModels,
  vehicleYears,
  vehicleVersions,
  errors,
  classes,
  vehicle,
  onChange,
}: CarDetailsProps) => {
  return (
    <FormControl fullWidth={true} resourceerrors={errors} errorfor='carDetails'>
      <View
        layout={{
          desktopHorizontal: false,
          desktopAlignHorizontalCenter: true,
        }}
        spacing={{ top: 6 }}
      >
        <View size={{ maxWidth: '415px' }}>
          <CargoMessage
            icon='info'
            title='Sobre a cotação'
            type={MessageType.highlight}
            description='O valor do seguro é calculado a partir das informações inseridas. Então, fique de olho, pode haver variações entre cotar por carro, placa ou chassi, ok? ;)'
          />
        </View>
        <FormSelect
          classes={classes}
          options={vehicleMakes}
          errors={errors}
          name={'make'}
          value={vehicle.make}
          obj={'vehicle'}
          label={'O carro é um'}
          onChange={onChange}
        />

        {vehicle.make && (
          <FormSelect
            classes={classes}
            options={vehicleModels}
            errors={errors}
            name={'model'}
            value={vehicle.model}
            obj={'vehicle'}
            label={'Modelo'}
            onChange={onChange}
          />
        )}

        {vehicle.model && (
          <FormSelect
            classes={classes}
            options={vehicleYears}
            errors={errors}
            name={'year'}
            value={vehicle.year}
            obj={'vehicle'}
            label={'Ano'}
            onChange={onChange}
          />
        )}

        {vehicle.year && (
          <FormSelect
            classes={classes}
            options={vehicleVersions}
            errors={errors}
            name={'version'}
            value={vehicle.version}
            obj={'vehicle'}
            label={'Versão'}
            onChange={onChange}
          />
        )}
      </View>
    </FormControl>
  )
}

export default CarDetails
