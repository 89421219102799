/* eslint-disable @typescript-eslint/no-empty-function */
import React, { PropsWithChildren, useContext } from 'react'

import { BookingData, DetailsRenewalData, Endorsements, InspectionData, Policy } from '../types/types'

export type PolicyContextType = {
  policy?: Policy
  inspections?: InspectionData[]
  detailsRenewal?: DetailsRenewalData
  endorsements: Endorsements
  latestInspection?: InspectionData
  latestBooking?: BookingData
  modalScheduleInspectionOpen: boolean
  setModalScheduleInspectionOpen: React.Dispatch<React.SetStateAction<boolean>>
  modalCancelBookingOpen: boolean
  setModalCancelBookingOpen: React.Dispatch<React.SetStateAction<boolean>>
  showInspectionInfo?: boolean
  allowSchedulingInspection: boolean
  allowCancelingInspection: boolean
  loading?: boolean
}

type PolicyProviderProps = {
  policy?: Policy
  loading?: boolean
  product: 'auto' | 'vida' | 'residencial'
  inspections?: InspectionData[]
  detailsRenewal?: DetailsRenewalData
  endorsements: Endorsements
}

const PolicyContext = React.createContext<PolicyContextType>({
  policy: undefined,
  inspections: undefined,
  detailsRenewal: undefined,
  endorsements: {} as Endorsements,
  latestInspection: undefined,
  latestBooking: undefined,
  modalScheduleInspectionOpen: false,
  setModalScheduleInspectionOpen: () => {},
  modalCancelBookingOpen: false,
  setModalCancelBookingOpen: () => {},
  showInspectionInfo: undefined,
  allowSchedulingInspection: false,
  allowCancelingInspection: false,
  loading: false,
})

function usePolicyContext() {
  return useContext(PolicyContext)
}

function PolicyProvider({
  policy,
  product,
  inspections,
  detailsRenewal,
  endorsements,
  children,
  loading,
}: PropsWithChildren<PolicyProviderProps>) {
  const [modalScheduleInspectionOpen, setModalScheduleInspectionOpen] = React.useState(false)
  const [modalCancelBookingOpen, setModalCancelBookingOpen] = React.useState(false)

  const latestInspection = inspections?.at(0)
  const latestBooking = latestInspection?.bookings?.at(0)

  const showInspectionInfo =
    latestInspection &&
    product === 'auto' &&
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ['PENDING', 'ACTIVE', 'CANCELLED', 'EXPIRED'].includes(policy!.status)

  const allowSchedulingInspection =
    latestInspection?.inspectionType === 'PHYSICAL' &&
    ['PENDING', 'CANCELED'].includes(latestInspection.status)

  const allowCancelingInspection =
    latestBooking?.status === 'SCHEDULED' &&
    latestInspection?.inspectionType === 'PHYSICAL' &&
    ['PENDING', 'MOVED'].includes(latestInspection.status)

  return (
    <PolicyContext.Provider
      value={{
        allowCancelingInspection,
        allowSchedulingInspection,
        inspections,
        detailsRenewal,
        latestBooking,
        latestInspection,
        modalCancelBookingOpen,
        modalScheduleInspectionOpen,
        policy,
        setModalCancelBookingOpen,
        setModalScheduleInspectionOpen,
        showInspectionInfo,
        loading,
        endorsements
      }}
    >
      {children}
    </PolicyContext.Provider>
  )
}

export { PolicyProvider, usePolicyContext }
