import React from 'react'
import {
  Input,
  InvisibleButton,
  View
} from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import { desktopContent, mobileContent } from 'styles/ContentStyle'
import { isMobile } from 'utils/IsMobile'

export const EmailForm = ({ form, setForm, error, setError }) => {
  const validEmail = () => {
    if (!form.email.includes('@')) {
      setError({
        ...error,
        email: {
          isError: true,
          message: 'Insira um e-mail válido.'
        }
      })
    } else {
      setError({
        ...error,
        email: {
          isError: false,
          message: ''
        }
      })
    }
  }

  return (
    <View
      style={isMobile() ? mobileContent : desktopContent}
    >
      <Input
        name='email'
        type={'text'}
        value={form.email}
        label='E-mail*'
        onChange={(e: any) => setForm({ ...form, email: e })}
        color={defaultTheme.colors.secondary}
        onBlur={() => validEmail()}
        messageError={{
          isError: error.email.isError,
          message: error.email.message
        }}
      />

      <View
        layout={{
          alignVerticalCenter: true,
          alignHorizontalCenter: true
        }}
      >
        <InvisibleButton
          onClick={() => window.location.href = process.env.REACT_APP_LOGIN || ''}
        >
          Já possui conta?
        </InvisibleButton>
      </View>
    </View>
  )
}
