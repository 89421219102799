export const salaryRange = {
  MINIMUM: 'Até R$ 800,00',
  BAND1: 'De R$ 800,01 a R$ 2.500,00',
  BAND2: 'De R$ 2.500,01 a R$ 4.500,00',
  BAND3: 'De R$ 4.500,01 a R$ 7.000,00',
  BAND4: 'Acima de R$ 7.000,00',
}

export const translationStatusInvoices = {
  REFUSED: 'NF-e recusada',
  APPROVED: 'NF-e aceita',
  PENDING: 'Envio de NF-e pendente',
  WAITING_FOR_APPROVAL: 'Análise de NF-e pendente'
}

export const translationStatusPolicy = {
  PROPOSAL: 'Proposta',
  REFUSED: 'Recusada',
  PENDING: 'Pendente',
  ACTIVE: 'Ativa',
  CANCELLED: 'Cancelada',
  FAILED: 'Erro',
  EXPIRED: 'Expirada',
}

export const translationStatusOrder = {
  ACTIVE: 'Ativa',
  FINISHED: 'Finalizada',
  EXPIRED: 'Expirada',
  DENIED: 'Recusada',
}

export const translationStatusInspection = {
  PENDING: 'Pendente',
  ACCEPTED: 'Aceita',
  CANCELED: 'Cancelada',
  REFUSED: 'Recusada',
  EXPIRED: 'Expirada',
  MOVED: 'Movida',
  EXEMPTED: 'Isenta',
  READY_TO_ANALYSIS: 'Em análise',
  RETRY: 'Retentativa',
}

export const translationInspectionRequesterType = {
  ISSUANCE: 'Ativação',
  ENDORSEMENT: 'Endosso',
}

export const translationStatusRenewal = {
  REFUSED: 'Recusada',
  CANCELLED: 'Cancelada',
  ACCEPTED: 'Aprovada',
  PENDING: 'Aguardando aprovação',
  SUSPENDED: 'Renovação suspensa',
  RENEWED: 'Apólice renovada',
  PENDING_PAYMENT: 'Pagamento pendente',
  FAILED: 'Falha',
}

export const translationTooltipRenewal = {
  REFUSED: 'A renovação foi recusada pela Youse',
  CANCELLED: 'A renovação foi cancelada sob demanda do usuário',
  ACCEPTED: 'A renovação foi aprovada pela Youse',
  PENDING: 'Estamos analisando o risco para aprovar ou não a renovação.',
  SUSPENDED: 'A renovação foi suspensa devido ao cancelamento da apólice',
  RENEWED: 'A apólice foi renovada e uma nova apólice foi emitida',
  PENDING_PAYMENT: 'Não houve sucesso na cobrança da renovação...',
  FAILED: 'Falha na renovação',
}

export const translationStatusBooking = {
  SCHEDULED: 'Agendada',
  DONE: 'Realizada',
  NO_SHOW: 'Cliente não compareceu',
  CANCELED: 'Cancelada',
}

export const translationBookingPlaceType = {
  HOME: 'Residencial',
  STATION: 'Posto/Parceiro',
}

export const translationEndorsementStatus = {
  DRAFT: 'Rascunho',
  PENDING: 'Pendente',
  APPROVED: 'Aprovado',
  REJECTED: 'Rejeitado',
  REFUSED: 'Recusado',
  CANCELLED: 'Cancelado',
  EXPIRED: 'Expirado',
  FINISHED: 'Finalizado',
}

export const products = {
  AUTO: 'auto',
  HOME: 'residencial',
  LIFE: 'vida',
}

export const vehicleUse = {
  ONLY_PRIVATE: 'Particular',
  PRIVATE_AND_COMMERCIAL: 'Motorista de Aplicativo',
  COMMERCIAL_REPRESENTATIVE: 'Representante Comercial/Vendas',
  CURRENT_COMMERCIAL_REPRESENTATIVE: 'Comercial',
  TAXI: 'Táxi',
  CARGO: 'Transporte de Carga',
  PEOPLE_TRANSPORT: 'Transporte de passageiros (Ex: Vans escolares ou Vans de transporte)',
  OTHERS: 'Outros',
}

export const gender = {
  MALE: 'Masculino',
  FEMALE: 'Feminino',
}

export const maritalStatus = {
  SINGLE: 'Solteiro(a)',
  MARRIED: 'Casado(a)',
  DIVORCED: 'Divorciado(a)',
  WIDOWER: 'Viúvo(a)',
  NOT_INFORMED: 'Não informado',
}

export const typeProperty = {
  HOUSE: 'Casa',
  APARTMENT: 'Apartamento',
}

export const installmentStatuses = {
  PENDING: 'Pendente',
  BILLED: 'A pagar',
  PAID: 'Paga',
  OVERDUE: 'Vencida',
  CANCELLED: 'Cancelada',
  SUSPENDED: 'Suspensa',
  FORGIVEN: 'Estornada',
  REFUNDED: 'Reembolsada',
  EXTENDED: 'Prorrogada',
  CHARGEBACK: 'Contestada'
}

export const salesChannel = {
  call_center: 'Call Center',
  internet: 'Internet',
  mobile: 'Aplicativo',
  automatic: 'Renovação',
  conversation_bot: 'Bot',
  outbroker: 'Parceiro',
}

export const claimStatus = {
  open: 'Aberto',
  reopen: 'Reaberto',
  close: 'Fechado',
  validation_pending: 'Pendente',
  cancelled: 'Cancelado',
  refused: 'Recusado',
  accept: 'Aceito',
  denied: 'Negado',
  registered: 'Registrado',
  settled: 'Aprovado',
}

export const driverRelationship = {
  ME: 'O próprio segurado',
  RELATIVE: 'Parente',
  FRIEND: 'Amigo',
  EMPLOYEE: 'Empregado',
  SPOUSE: 'Cônjuge',
  CHILD: 'Filho',
  PARENT: 'Pais',
  GRANDPARENT: 'Avós',
  DOMESTICPARTNER: 'União estável',
  OTHER: 'Outros',
}

export const formattedProduct = {
  auto: 'Auto',
  home: 'Residencial',
  life: 'Vida',
}

export const inspectionTypes = {
  PHYSICAL: 'Física',
  ONLINE: 'Online',
  EXTERNAL: 'Externa',
}

export const relationshipLife = {
  MOTHER: 'Mãe',
  FATHER: 'Pai',
  DAUGHTER: 'Filha',
  SON: 'Filho',
  SISTER: 'Irmã',
  BROTHER: 'Irmão',
  SPOUSE: 'Cônjuge',
  OTHER: 'Outro',
}

export const inspectionDescription = {
  type: 'Modalidade de vistoria que o cliente realizará.',
  createdAt: 'Data de criação da vistoria.',
  updatedAt: 'Data da última alteração na vistoria ou agendamento.',
  scheduledAt: 'Data para a qual foi agendada a realização da vistoria.',
  expireAt:
    'Se a vistoria não for realizada até esta data a apólice será cancelada automaticamente.',
  address: 'Local onde o cliente realizará a vistoria.',
  company: 'Empresa responsável pela vistoria.',
}

export const inspectionStatusMessage = {
  ACCEPTED: 'A vistoria foi aceita.',
  CANCELED:
    'A vistoria foi cancelada e pode ser reagendada até o prazo de expiração indicado acima.',
  EXPIRED: 'A vistoria não foi realizada dentro do prazo de 7 dias.',
  MOVED: 'O tipo de vistoria foi alterado.',
  EXEMPTED: 'Apólice isenta de vistoria.',
  READY_TO_ANALYSIS:
    'O cliente enviou todas as fotos e a vistoria está pronta para o analista avaliar.',
  REFUSED:
    // eslint-disable-next-line max-len
    'A vistoria não foi aprovada. A Youse leva em conta conta vários fatores para aceitar um seguro, entre eles, as características do veículo. Tudo isso está de acordo com a legislação e com o regulamento da Susep.',
  PENDING: 'A vistoria ainda não foi agendada.',
  PENDINGSCHEDULED: 'A vistoria foi agendada e ainda não foi realizada.',
  RETRY:
    'Não foi possível realizar a análise das fotos enviadas, precisamos que o cliente faça o reenvio pelo aplicativo.',
}

export const translationCognitoMessages = {
  'Incorrect username or password.': 'Usuário ou senha incorretos.',
  'User is disabled.': 'Usuário ou senha incorretos.',
  'Username cannot be empty': 'Usuário precisa ser preenchido.',
  'Password cannot be empty': 'Senha precisa ser preenchida.',
  'Invalid verification code provided, please try again.': 'Código de verificação inválido, por favor tente novamente.',
  'Your passwords must match': 'As senhas precisam ser iguais.',
  'Password does not conform to policy: Password not long enough': 'A senha não está em conformidade com a política: A senha não é longa o suficiente',
  'The username should either be a string or one of the sign in types': 'Por favor, entre com um e-mail válido.',
  'Send code': 'Receber código de recuperação de senha',
  'Attempt limit exceeded, please try after some time.': 'Limite de tentativas excedido. Por favor, tente novamente depois de algum tempo.'
}

export const translationOwnershipStatus = {
  owned_by_person: 'Pessoa Física',
  owned_by_company: 'Pessoa Jurídica',
  being_transferred_to_person: 'Transferência de Pessoa Jurídica para Física',
}
