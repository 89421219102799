import React from 'react'
import { Column, ColumnsContainer, View } from '@youse-seguradora/cargo-components'

type RowProps = {
  columns: {
    children?: React.ReactNode
  }[]
}

const Row = ({ columns }: RowProps) => {
  return (
    <View spacing={{ vertical: 0 }} position={{ relative: true }} size={{ width: '100%' }}>
      <ColumnsContainer>
        {columns.map(
          (
            column: { children?: React.ReactNode },
            id: number,
          ) => (
            <Column desktopSize={12 / columns.length} key={id}>
              {column.children}
            </Column>
          ),
        )}
      </ColumnsContainer>
    </View>
  )
}


export default Row
