import React from 'react'
import {
  Card,
  PrimaryButton,
  Ribbon,
  Text,
  TrackingContainer,
  TrackingContext,
  View,
} from '@youse-seguradora/cargo-components'

import PlanItem from '../PlanItem/PlanItem'
import { planSelectionStyled } from '../PlanSelectionStyled'
import PricingMonthly from '../PricingMonthly.tsx/PricingMonthly'

export type PlanCardProps = {
  plans: []
  withoutPartnershipHighlight?: boolean
  priceSubtitle?: (event: Event) => {}
  message?: (event: Event) => {}
  selectPlan: (planId: number) => void
  coverages: []
  assistances: []
  theme: any
}

const PlanCard = ({
  plans,
  withoutPartnershipHighlight,
  priceSubtitle,
  message,
  coverages,
  assistances,
  selectPlan,
  theme,
}: PlanCardProps) => {
  const classes = planSelectionStyled()
  return (
    <>
      {plans.map((plan: any, index: any) => (
        <Card
          className={
            (plan.selected && !withoutPartnershipHighlight && classes.planSelected) ||
            (withoutPartnershipHighlight && index === 0 && classes.planSelected)
          }
          key={index}
        >
          {plan.selected ? (
            <div
              style={{
                justifyContent: plan.highlight && 'left',
                paddingLeft: plan.highlight && '8px',
              }}
              className={classes.planSelectedBox}
            >
              PLANO SELECIONADO
              {plan.highlight && !withoutPartnershipHighlight && <Ribbon label='pra vc ;)' />}
            </div>
          ) : (
            plan.highlight && !withoutPartnershipHighlight && <Ribbon label='pra vc ;)' />
          )}
          {index === 0 && withoutPartnershipHighlight && (
            <div
              style={{
                justifyContent: 'center',
                paddingLeft: '8px',
              }}
              className={classes.planSelectedBox}
            >
              PLANO ESSENCIAL
            </div>
          )}
          <View spacing={{ horizontal: 4 }} size={{ height: 'auto' }}>
            <View
              layout={{
                horizontal: true,
                alignHorizontalCenter: true,
              }}
              spacing={{ top: 6 }}
            >
              <PricingMonthly data={plan.pricing.monthly} />
            </View>

            {priceSubtitle && (
              <View
                layout={{
                  horizontal: true,
                  alignHorizontalCenter: true,
                }}
                spacing={{ bottom: message ? 5 : 0 }}
              >
                {priceSubtitle(plan)}
              </View>
            )}

            {message && message(plan)}

            <View spacing={{ top: 5 }}>
              <TrackingContext category='route-change'>
                <TrackingContainer action='buy' label={`plano ${index + 1} escolhido`}>
                  <PrimaryButton
                    data-testid={`plan-card-button-${index + 1}`}
                    full
                    onClick={() => selectPlan(plan.id)}
                  >
                    QUERO ESSE
                  </PrimaryButton>
                </TrackingContainer>
              </TrackingContext>
            </View>
            <View spacing={{ top: 6, bottom: 4 }}>
              <Text size='small' color={theme.colors.secondary} weight='bold'>
                COBERTURAS
              </Text>
            </View>
            {coverages.map((planItem: any, index: number) => (
              <PlanItem key={index} planItem={planItem} plan={plan} />
            ))}
            {assistances.length !== 0 && (
              <>
                <View spacing={{ top: 4, bottom: 4 }}>
                  <Text size='small' color={theme.colors.secondary} weight='bold'>
                    ASSISTÊNCIAS
                  </Text>
                </View>
                {assistances.map((planItem: any, index: number) => (
                  <PlanItem key={index} planItem={planItem} plan={plan} />
                ))}
              </>
            )}
          </View>
        </Card>
      ))}
    </>
  )
}

export default PlanCard
