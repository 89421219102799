import React, { useEffect, useState } from 'react'
import {
  Input,
  View } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import { desktopContent, mobileContent } from 'styles/ContentStyle'
import { isMobile } from 'utils/IsMobile'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface ErrorProps {
  isError: boolean,
  message: string
}

export const PasswordForm = ({ form, setForm, error, setError }) => {
  const [confirmPassword, setConfirmPassword] = useState<string>('')

  const validInputs = () => {
    if (form.password.length < 8) {
      return setError({ ...error, password: { message: 'Sua senha precisa ter, no mínimo, 8 caracteres. É só por segurança, tá?', isError: true } })
    }

    if (confirmPassword !== form.password) {
      return setError({ ...error, password: { message: 'As senhas precisam ser iguais.', isError: true } })
    }

    return setError({ ...error, password: { message: '', isError: false } })
  }

  useEffect(() => {
    const password: any = document.getElementsByName('password')
    const confirmpassword: any = document.getElementsByName('confirmpassword')

    password[0].type = 'password'
    confirmpassword[0].type = 'password'
  }, [])

  return (
    <View
      style={isMobile() ? mobileContent : desktopContent}
    >
      <Input
        name='password'
        onBlur={validInputs}
        value={form.password}
        label='Senha'
        onChange={(e) => setForm({ ...form, password: e })}
        color={defaultTheme.colors.secondary}
        messageError={{
          isError: error.password.isError,
          message: error.password.message
        }}
      />

      <Input
        name='confirmpassword'
        type={'text'}
        value={confirmPassword}
        label='Confirmar senha'
        onChange={(e) => setConfirmPassword(e)}
        color={defaultTheme.colors.secondary}
        onBlur={validInputs}
        messageError={{
          isError: error.password.isError,
          message: error.password.message
        }}
      />
    </View>
  )
}
