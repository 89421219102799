import React from 'react'
import { DataLayerClient } from '@tiny-fronts/resource-flow-components'
import { Card, Image, Spacer, Text, Title, View } from '@youse-seguradora/cargo-components'

type NotFoundProps = {
  page: 'policies' | 'orders' | 'vistorias' | 'claims'
}

export function NotFound({ page }: NotFoundProps) {
  React.useEffect(() => {
    new DataLayerClient().trigger(`pageview_admin_${page}_not_found`)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card>
      <Spacer all={6}>
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Title size='small'>Poxa, não encontramos resultado para a sua pesquisa</Title>
          <Spacer top={8} />
          <div>
            <Image
              height={25}
              alt={'Nenhuma busca for encontrada'}
              src='https://www.datocms-assets.com/16903/1590782860-illus-characters-confused-03-4x3-light.png'
            />
          </div>
          <Spacer top={4} />
          <Text size='big' weight='bold'>
            Bora tentar de novo?
          </Text>
        </View>
      </Spacer>
    </Card>
  )
}
