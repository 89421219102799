import React from 'react'
import {
  Container,
  getCargoSpacingPx,
  Icon,
  Spacer,
  View,
} from '@youse-seguradora/cargo-components'

import { useTheme } from '@material-ui/core/styles'

const HEIGHT = 10
const HEIGHT_PX = getCargoSpacingPx(HEIGHT)

type HeaderProps = {
  success?: boolean
  fixed?: boolean
  icons: string[]
}

const Header = ({ success, fixed, icons }: HeaderProps) => {
  const theme = useTheme()

  const HeaderContent = () => {
    return (
      <View
        background={{
          color: success ? theme.backgrounds.success : theme.backgrounds.header,
        }}
        size={{ height: HEIGHT_PX }}
        style={{ boxShadow: theme.shadow.inverse.idle }}
      >
        <Container>
          <View
            size={{ height: HEIGHT_PX }}
            layout={{
              alignVerticalCenter: true,
              alignHorizontalCenter: true,
              horizontal: true,
            }}
          >
            {icons?.map((item: string, i: any) => (
              <Spacer top={2} key={i} left={i === 0 ? 0 : 6}>
                <Icon size={6} name={item} />
              </Spacer>
            ))}
          </View>
        </Container>
      </View>
    )
  }

  return fixed ? (
    <View position={{ fixed: true, left: '0', right: '0', zIndex: 100 }}>{HeaderContent()}</View>
  ) : (
    <View position={{ sticky: true, top: '0', zIndex: 100 }}>{HeaderContent()}</View>
  )
}

export default Header
