export interface permissionsProps {
  permissions: string[]
  section: string
  title: string
}

export interface UserProps {
  name: string
  cpf: string
}

export interface ConsentParams {
  consentId: string
  tab: string
}

export class ResponseError extends Error {
  response!: Response
}

export interface ConsentProps {
  organizationName: string
  status: string
  creationDateTime: string
  expirationDateTime: string
  formatedExpirationDateTime?: string
  formatedCreationDataTime?: string
  formatedstatusUpdateDateTime?: string
  formatedCPF?: string
  consentId: string
  permissions_sections: permissionsProps[]
  segmentDescription: string
  statusUpdateDateTime: string
  user: UserProps
}
