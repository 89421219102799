import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { mergeObject } from '../helpers/objects'
import { camelizeKeys, decamelizeKeys } from '../helpers/string'
import { FetchError, FetchInstance, FetchRequestConfig } from '..'

import { useFetchCancel } from './useFetchCancel'

export type ExecuteType<T, D> = (options: FetchRequestConfig<D>) => Promise<T>

export type UseFetchProps<D> = {
    instance: FetchInstance
    lazy?: boolean
    jwtAuthorization?: string,
    disabledTransformData?: boolean
} & FetchRequestConfig<D>

export type UseFetchReturn<T, D> = {
    loading: boolean
    data?: T
    error?: FetchError
    execute: ExecuteType<T, D>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useFetch<T = any, D = any>({ instance, lazy = false, jwtAuthorization, disabledTransformData = false, ...initialOptions }: UseFetchProps<D>): UseFetchReturn<T, D> {
  const [options] = useState<FetchRequestConfig<D>>(initialOptions)
  const [data, setData] = useState<T>()
  const [loading, setLoading] = useState(!lazy)
  const [error, setError] = useState<FetchError>()

  const { cancel, signal } = useFetchCancel()

  const instanceRef = useRef(instance)
  const isMount = useRef(true)

  const execute: ExecuteType<T, D> = useCallback(async (optionsExecute = {}) => {
    setLoading(true)
    setError(undefined)

    const optionsMerge = mergeObject(
      options, 
      { headers: jwtAuthorization ? { AUTHORIZATION: `Bearer ${jwtAuthorization}` } : {} }, 
      optionsExecute
    ) 
        
    if (optionsMerge?.data && !disabledTransformData)
      optionsMerge.data = decamelizeKeys(optionsExecute.data)

    if (!isMount) throw Error('Canceled request')
        
    const response = await instanceRef.current
      .request<T>({ ...optionsMerge, signal })
      .catch((error: FetchError) => {
        setError(error)
        throw error
      })
      .finally(() => setLoading(false))

    const responseData = disabledTransformData ? response.data : camelizeKeys<T>(response.data)
        
    setData(responseData)
    return responseData

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoading, setData, setError, instanceRef, options])

  useEffect(() => {
    if (!lazy) execute(options)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute, options])

  useEffect(() => {
    return () => {
      cancel()
      isMount.current = false
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return useMemo(() => ({ loading, data, error, execute }), [loading, data, error, execute])
}
