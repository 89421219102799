import React from 'react'
import { Text, View } from '@youse-seguradora/cargo-components'

import { useTheme } from '@material-ui/core/styles'

type SidebarCardFooterProps = {
  text: string
}

const SidebarCardFooter = ({ text }: SidebarCardFooterProps) => {
  const theme = useTheme()

  return (
    <View spacing={{ top: 4 }}>
      <Text align='center' weight='bold' color={theme.colors.textSidebar}>
        {text}
      </Text>
    </View>
  )
}

export default SidebarCardFooter
