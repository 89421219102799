import React, { useContext, useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { View, Visible } from '@youse-seguradora/cargo-components'

import DataLayerClient from '../../clients/DataLayerClient'
import { ResourceFlowContext } from '../../contexts/ResourceFlowContext'
import SeoInterface from '../../interfaces/SeoInterface'
import Footer from '../Footer'
import Header from '../Header'

import HeaderWithPricing from './Header/HeaderWithPricing'
import { SidebarCardProps } from './Sidebar/SidebarCard'
import { SidebarIconsProps } from './Sidebar/SidebarIcons'
import Sidebar from './Sidebar'

import { styles } from './styles'
import { useTheme } from '@material-ui/core/styles'

type LayoutBaseWithSidebarProps = {
  seo: SeoInterface
  children: React.ReactNode
  customHeader?: React.ReactNode
  customFooter?: React.ReactNode
  productName: string
  sidebarIcons?: SidebarIconsProps
  sidebarCard?: SidebarCardProps
  footerButton?: any
  centerFooterButton?: boolean
  iconHeaderPartner?: any
  isSidebarNotifyOrder?: boolean
  isMobileBackgroundColorAside?: boolean
  backButton?: boolean
}

const LayoutBaseWithSidebar = ({
  seo,
  children,
  customHeader,
  customFooter,
  productName,
  sidebarIcons,
  sidebarCard,
  footerButton,
  centerFooterButton,
  iconHeaderPartner,
  isSidebarNotifyOrder,
  isMobileBackgroundColorAside,
  backButton,
}: LayoutBaseWithSidebarProps) => {
  const context = useContext<any>(ResourceFlowContext)
  const theme = useTheme()
  const { title } = seo

  const [sidebarActive, setSidebarActive] = useState<boolean>(false)

  useEffect(() => {
    new DataLayerClient().trigger(title)
    new DataLayerClient().triggerOrder(
      context?.data?.resourceId,
      context?.data?.resource?.salesChannel,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const classes = styles({
    sidebarIcons,
    sidebarActive,
    centerFooterButton,
    backButton,
    isMobileBackgroundColorAside,
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const impersonate = context?.data?.resource?.impersonate

  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className={classes.wrapper}>
        <div className={classes.mainContent}>
          <Visible desktop>
            {customHeader ? (
              customHeader
            ) : (
              <Header icons={context?.data?.resource?.partnerId ? iconHeaderPartner : ['youse']} />
            )}
          </Visible>

          {!sidebarActive && (
            <Visible mobile tablet>
              <HeaderWithPricing productName={productName} />
            </Visible>
          )}

          <button className={classes.triggerAside} onClick={() => setSidebarActive(!sidebarActive)}>
            {sidebarActive ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </button>

          <View
            spacing={{ top: 4, bottom: 4, left: 4, right: 4 }}
            background={{ color: theme.backgrounds.primary }}
          >
            {children}
          </View>

          <View spacing={{ bottom: 0 }}>{customFooter ? customFooter : <Footer />}</View>
        </div>

        <Sidebar
          productName={productName}
          active={sidebarActive}
          centerFooterButton={centerFooterButton}
          sidebarIcons={sidebarIcons}
          sidebarCard={sidebarCard}
          isSidebarNotifyOrder={isSidebarNotifyOrder}
          isMobileBackgroundColorAside={isMobileBackgroundColorAside}
        />

        {footerButton && <div className={classes.footerButton}>{footerButton}</div>}
      </div>
    </HelmetProvider>
  )
}

export default LayoutBaseWithSidebar
