import React from 'react'
import {
  Column,
  ColumnsContainer,
  Icon,
  InvisibleButton,
  Modal,
  Spacer,
  Text,
  Title,
  View } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'

export const InstituitionDetails = ({ openDetails, setOpenDetails, instituition }) => {
  const redirect = () => {
    window.open('https://opinbrasil.com.br/', '_blank')
  }

  const formatCNPJ = (value) => {
    return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
  }

  const content = (
    <>
      <View layout={{ alignHorizontalCenter: true, alignVerticalCenter: true }} spacing={{ bottom: 2 }}>
        <Title
          size={'small'}
          weight={'bold'}
          color={defaultTheme.colors.highlight}
          align={window.innerWidth > 551 ? 'center' : 'left'}
        >
          Detalhes da Instituição
        </Title>

        <Spacer top={6} />

        <View layout={{ horizontal: true }} style={{ width: '100%' }}>
          <View>
            <Text
              size={'default'}
              weight={'bold'}
              color={defaultTheme.colors.primary}
            >
              {instituition.CustomerFriendlyName}
            </Text>

            <Text
              size={'small'}
              weight={'regular'}
              color={defaultTheme.colors.secondary}
            >
              CNPJ: {formatCNPJ(instituition.RegistrationNumber)}
            </Text>
          </View>
        </View>

        <Spacer bottom={8} />

        <View style={{ width: '100%' }}>
          <Text
            size={'default'}
            weight={'bold'}
            color={defaultTheme.colors.primary}
          >
            Marcas da Instituição:
          </Text>

          <Spacer bottom={2} />

          <ColumnsContainer>
            {instituition.brands.map((brand, index) => (
              <Column key={index} tabletSize={6}>
                <View layout={{ horizontal: true, alignVerticalCenter: true }}>
                  <Text
                    size={'default'}
                    weight={'regular'}
                    color={defaultTheme.colors.primary}
                  >
                    &#8226; {brand.CustomerFriendlyName}
                  </Text>
                </View>
              </Column>
            ))}
          </ColumnsContainer>
        </View>

        <Spacer bottom={6} />

        <View
          style={{
            border: '1px solid #CED1D4',
            borderRadius: '8px',
            padding: '12px',
            width: '100%'
          }}
        >
          <ColumnsContainer>
            <View layout={{ horizontal: true }} style={{ width: 'max-content' }}>
              <Column tabletSize={1}>
                <Icon name='info' size={5} color='#CED1D4' />
              </Column>

              <Spacer right={3} />

              <Column tabletSize={11}>
                <Text
                  size={'small'}
                  weight={'regular'}
                  color={defaultTheme.colors.primary}
                  align='left'
                >
                  Para mais detalhes acesse o
                </Text>

                <Spacer top={1} />

                <InvisibleButton color={defaultTheme.colors.primary} contextual onClick={redirect} rightIcon='chevron-right'>
                  Portal do Open Insurance
                </InvisibleButton>
              </Column>
            </View>
          </ColumnsContainer>
        </View>

        <InvisibleButton onClick={() => setOpenDetails(false)}>
          Fechar
        </InvisibleButton>
      </View>
    </>
  )

  const modalCancel = {
    content: content,
  }

  return (
    <Modal
      {...modalCancel}
      hiddenOverflow={false}
      isOpen={openDetails}
      onClose={() => {
        setOpenDetails(false)
      }}
    />
  )
}
