import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { checkPermission, ErrorComponent, RoutePermissionsKeys, useViewer } from '@tiny-fronts/basement'
import {
  FeatureCardLargeList,
  Image,
  Modal,
  Text,
  Title,
  View,
} from '@youse-seguradora/cargo-components'

import { useTheme } from '@material-ui/core/styles'

type PolicyProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  policy: any
  activateCoverage: boolean
}

export default function Coverages({ policy, activateCoverage }: PolicyProps) {
  const theme = useTheme()
  const history = useHistory()
  const { product } = useParams<{ product: string }>()

  const { viewer } = useViewer()
  let permissionsUser: boolean | Permissions = false
  let isPermitted = false

  if (viewer) {
    permissionsUser = viewer?.user?.permissions
    const permissionsCompany = viewer?.company?.permissions

    isPermitted = permissionsCompany && permissionsUser && checkPermission(RoutePermissionsKeys.SINISTRO, permissionsCompany, permissionsUser)
  }

  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [coverageDetail, setCoverageDetail] = React.useState<{
    iconUrl: string
    name: string
    description: string
  }>({
    iconUrl: '',
    name: '',
    description: '',
  })

  const autoBasicCoverages = [
    'bra/auto/coverage/1',
    'bra/auto/coverage/3',
    'bra/auto/coverage/4',
    'bra/auto/coverage/2',
  ]

  const getProps = (item) => ({
    title: item.name,
    label:
      'bra/auto/coverage/8' === item.uid
        ? 'Valor da franquia'
        : autoBasicCoverages.find((coverage) => coverage === item.uid)
          ? 'Valor da indenização'
          : 'Valor contratado',
    subtitle:
      'bra/auto/coverage/8' === item.uid
        ? `<b>${item.deductible.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })}</b>`
        : `<b>${item.maximumIndemnityLimitText}</b>`,
    imageComponent: <Image alt={item.name} src={item.iconUrl} height={20} />,
    ctaLabel: 'Ver detalhes',
    onClick: () => {
      setIsOpen(true), setCoverageDetail(item)
    },
    buttonAsLink: false,
  })

  const getPropsActivateCoverage = (item) => ({
    title: item.name,
    label:
      'bra/auto/coverage/8' === item.uid
        ? 'Valor da franquia'
        : autoBasicCoverages.find((coverage) => coverage === item.uid)
          ? 'Valor da indenização'
          : 'Valor contratado',
    subtitle:
      'bra/auto/coverage/8' === item.uid
        ? `<b>${item.deductible.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        })}</b>`
        : `<b>${item.maximumIndemnityLimitText}</b>`,
    imageComponent: <Image alt={item.name} src={item.iconUrl} height={20} />,
    ctaLabel: 'Ver detalhes',
    onClick: () => {
      setIsOpen(true), setCoverageDetail(item)
    },
    buttonAsLink: false,
    ctaLabelSecondaryButton: isPermitted && 'Abrir sinistro',
    onClickSecondaryButton: () =>
      history.push(
        `/apolices/${product}/${policy.number}/acionamento-de-sinistro/${item.coverageId}`,
      ),
  })

  const content = (
    <>
      <View layout={{ alignHorizontalCenter: true }} spacing={{ bottom: 2 }}>
        <Image alt='documentos' src={coverageDetail.iconUrl} height={16} />
      </View>
      <View spacing={{ top: 2 }} />
      <Title align='center' size='small' color={theme.colors.highlight}>
        {coverageDetail.name}
      </Title>
      <View spacing={{ top: 4 }} />
      <Text color={theme.colors.secondary}>{coverageDetail.description}</Text>
    </>
  )

  const modalAvailableDocs = {
    title: 'Coberturas',
    content: content,
  }

  return (
    <ErrorComponent policy={policy}>
      <View>
        <View spacing={{ vertical: 6 }}>
          <Title color={theme.colors.primary} size='xsmall' dataTestId='coverages-title'>
            Coberturas
          </Title>
        </View>
        {(activateCoverage && product === 'auto' && policy?.status === 'ACTIVE') ||
        policy?.status === 'CANCELLED' ||
        policy?.status === 'EXPIRED' ? (
          <FeatureCardLargeList items={policy?.coverages.map((i) => getPropsActivateCoverage(i))} />
        ) : (
          <FeatureCardLargeList items={policy?.coverages.map((i) => getProps(i))} />
        )}
        <Modal hiddenOverflow {...modalAvailableDocs} isOpen={isOpen} onClose={() => setIsOpen(false)} />
      </View>
    </ErrorComponent>
  )
}
