export const clearEcommerce = () => window.dataLayer?.push({ ecommerce: null })

const clearAnalyticsData = () => {
  window.dataLayer?.push(function (this: { reset: () => void }) { this.reset() })
}

export const createEvent = <T>(params: T) => {
  window.dataLayer?.push(params)
}

export const createNoCacheEvent = <T>(params: T) => {
  clearAnalyticsData()
  window.dataLayer?.push(params)
  clearAnalyticsData()
}
