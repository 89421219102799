import React, { useEffect,useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Container,
  Spacer,
  Tabs,
  Title,
  View } from '@youse-seguradora/cargo-components'
import defaultTheme from 'config/defaultTheme'
import useAuth from 'hooks/useAuth'

import { SkeletonConsentList } from './SkeletonConsentList'
import { TabContent } from './TabContent'

interface ConsentProps {
  organizationName: string
  status: string
  creationDateTime: string
  expirationDateTime: string
  consentId: string
}

class ResponseError extends Error {
  response!: Response
}

export const ConsentList = () => {
  const [currentItemId, setCurrentItemId] = useState('Recebidos')
  const [loading, setLoading] = useState<boolean>(true)
  const [consentList, setConsentList] = useState<ConsentProps[]>([])

  const { token } = useAuth()

  const history = useHistory()

  const flowStrategy = {
    'Recebidos': 'data-reception',
    'Enviados': 'data-transmission'
  }

  useEffect(() => {
    setConsentList([])

    setLoading(true)

    fetch(`${process.env.REACT_APP_OPIN_SERVICE_URL}/consents/${flowStrategy[currentItemId]}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response
        } else {
          const error = new ResponseError(response.statusText)
          error.response = response
          throw error
        }
      })
      .then<ConsentProps[]>((response) => response.json())
      .then((response) => setConsentList(response))
      .catch(() => history.push('/erro-desconhecido'))
      .finally(() => setLoading(false))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItemId])

  const items = [
    {
      label: 'Recebidos',
      content: (
        <TabContent
          tab={currentItemId}
          consentList={consentList}
        />
      ),
      onClick: () => setCurrentItemId('Recebidos'),
    },
    {
      label: 'Enviados',
      content: (
        <TabContent
          tab={currentItemId}
          consentList={consentList}
        />
      ),
      onClick: () => setCurrentItemId('Enviados'),
    },
  ]

  const currentItem = items.find((item) => item.label === currentItemId)

  return (
    <View>
      <Container>
        <Spacer top={4} />

        <Title size={'small'} weight={'bold'} color={defaultTheme.colors.primary}>
          Dados compartilhados
        </Title>

        <Spacer top={2} />

        <Title size={'xsmall'} weight={'light'} color={defaultTheme.colors.secondary}>
          Acompanhe e gerencie os dados que você compartilhou.
        </Title>

        <Spacer top={4} />

        {!loading && <Tabs centered items={items} currentItem={currentItem} />}

        {loading && <SkeletonConsentList />}
      </Container>
    </View>
  )
}
